/* eslint-disable complexity */
import React, { useEffect } from 'react'
import { s } from '@vega/styled'
import {
  Title,
  Subtitle,
} from 'features/creditAssessor/applications/components/typography'
import { CreditReportSummary } from './CreditReportSummary'
import {
  startCreditCheck,
  selectCreditReport,
  selectStartCreditCheckStatus,
} from 'modules/application'
import { useSelector, useDispatch } from 'react-redux'
import { ASYNC_STATUSES } from '@vega/constants'
import Loader from './CreditReportLoadingSection'

function View({ applicantPathId, applicantId }) {
  const dispatch = useDispatch()
  const creditCheckStartedStatus = useSelector(selectStartCreditCheckStatus())
  const creditReport = useSelector(selectCreditReport(applicantPathId))

  const isReportLoading = creditCheckStartedStatus === ASYNC_STATUSES.PENDING
  const isReportReady = creditCheckStartedStatus === ASYNC_STATUSES.FULFILLED

  useEffect(() => {
    dispatch(startCreditCheck(applicantId))
  }, [applicantId, dispatch])

  return (
    <div>
      <Title style={s('mb-4')}>Let&apos;s have a look at the Credit Score</Title>
      <Subtitle style={s('mb-6')}>
        Based on your client credit score, we will decide whether to proceed with the
        application or not. We are partners with Equifax, who supply the credit report.
        If you have any concerns, please contact your broker.
      </Subtitle>

      {isReportLoading && <Loader />}

      {isReportReady && (
        <CreditReportSummary
          applicantPathId={applicantPathId}
          applicantId={applicantId}
          reportData={creditReport}
        />
      )}
    </div>
  )
}

export { View }
