import React from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { selectProfile } from '@vega/redux.profile'
import { s, styled } from '@vega/styled'
import { format } from 'date-fns'
import { Button, MonthPicker } from '@vega/components'
import { ReactComponent as ArrowRight } from '@vega/components/src/assets/images/arrowRight.svg'

const Container = styled.div(s('flex justify-between items-center bg-white'), {
  padding: '24px 28px 15px 28px',
})
const LeftContainer = styled.div(s(''))
const Greeting = styled.div(s('flex flex-column text-grey-500'))
const WelcomeText = styled.span(s('text-xl text-black mb-2'))
const DateText = styled.span(s('text-xs'))

const RightContainer = styled.div({ transform: 'translateY(18px)' })
const LastUpdatedText = styled.p(s('text-grey-500 font-light text-sm'))
const UpdatedTime = styled.span(s('text-grey-600 font-semibold'))

const OperationContainer = styled.div(s('flex items-center justify-end'))

function Header() {
  const profile = useSelector(selectProfile)
  const { setFieldValue } = useFormikContext()

  const onMonthPickerMonthChanged = ({ year, month }) => {
    setFieldValue('year', year)
    setFieldValue('month', month)
  }

  const loggedInUserFirstName = profile?.firstName

  return (
    <Container>
      <LeftContainer>
        <Greeting>
          <WelcomeText>👋 {loggedInUserFirstName}</WelcomeText>
          <DateText>{format(new Date(), 'EEEE, d MMM yyyy')}</DateText>
        </Greeting>
      </LeftContainer>

      <RightContainer>
        <LastUpdatedText>
          Last updated on <UpdatedTime>{format(new Date(), 'd MMM yyyy')}</UpdatedTime>{' '}
          at <UpdatedTime>{format(new Date(), 'p')}</UpdatedTime>
        </LastUpdatedText>
        <OperationContainer>
          <MonthPicker
            onMonthChanged={onMonthPickerMonthChanged}
            pickerStyle={{ left: 'initial', right: 158 }}
          />
          <Button
            containerStyle={s(
              'h-3 border-grey-200 rounded-lg text-grey-600 font-semibold ml-5',
              { width: 111 }
            )}
            EndIcon={
              <ArrowRight
                style={s('w-1 h-1 text-grey-500 ml-1', { transform: 'rotate(-45deg)' })}
              />
            }
            variant="outlined"
          >
            Export
          </Button>
        </OperationContainer>
      </RightContainer>
    </Container>
  )
}

export { Header }
