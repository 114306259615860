import React from 'react'
import {
  useMenuState,
  Menu as MenuBase,
  MenuItem as MenuItemBase,
  MenuButton as MenuButtonBase,
} from 'reakit/Menu'

import { useDispatch } from 'react-redux'

import { resendInvitation, revokeInvitation } from 'modules/invitation'

import { styled, s } from '@vega/styled'
import { ReactComponent as ArrowDropdownIcon } from '@vega/components/src/assets/images/arrow_drop_down-24px.svg'

const MenuButtonContainer = styled.div(s('flex justify-end relative'))
const MenuButton = styled(MenuButtonBase)(
  s('flex items-center bg-white border-0 p-0 bg-transparent')
)

const Text = styled.span(s('text-center pt-1 text-xs font-semibold text-grey-500'))
const StyledArrowDropdownIcon = styled(ArrowDropdownIcon)({
  '& path': { '&:nth-of-type(2)': { fill: '#394A64' } },
})

const Menu = styled(MenuBase)(
  s(
    'flex flex-column border-grey-100 border-solid border-1 bg-white z-1 shadow-md min-w-6'
  ),
  {
    overflow: 'hidden',
    borderRadius: 6,
    borderTopRightRadius: 0,
  }
)

const MenuItem = styled(MenuItemBase)(
  s('border-0 text-left bg-white pt-1 text-xs text-grey-600'),
  {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    '&:focus': {
      backgroundColor: '#F7FCFC',
    },
  }
)

function DropdownMenu({ code }) {
  const menu = useMenuState()
  const dispatch = useDispatch()

  return (
    <MenuButtonContainer>
      <MenuButton {...menu}>
        <Text>More</Text>
        <StyledArrowDropdownIcon />
      </MenuButton>
      <Menu {...menu} aria-label="Action">
        <MenuItem
          {...menu}
          onClick={() => {
            dispatch(resendInvitation({ code }))
            menu.hide()
          }}
        >
          Resend
        </MenuItem>
        <MenuItem
          {...menu}
          onClick={() => {
            dispatch(revokeInvitation({ code }))
            menu.hide()
          }}
        >
          Remove invite
        </MenuItem>
        <MenuItem {...menu} onClick={menu.hide}>
          Cancel
        </MenuItem>
      </Menu>
    </MenuButtonContainer>
  )
}

export { DropdownMenu }
