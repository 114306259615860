import React from 'react'
import { RadioButtonField, RadioButton, SelectField } from '@vega/components'
import {
  SectionTitle,
  Subtitle,
  Prompt as OriginalPrompt,
} from 'features/creditAssessor/applications/components/typography'
import { useFormikContext } from 'formik'
import { ANTICIPATED_EVENT_OPTS, ADAPTATION_PLAN_OPTS } from './selectOptions'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@vega/constants'

const FieldContainer = styled.div(s('mb-6'))

const Prompt = styled(OriginalPrompt)(s('mb-4'))

function FuturePlan({ applicantName, parentPath, applicantPathId }) {
  const { values: formikValues } = useFormikContext()
  const futurePath = `${parentPath}.futurePlan`
  const futureValuePath = formikValues?.financials[applicantPathId]?.futurePlan

  const hasAnticipatedChanges = futureValuePath?.hasAnticipatedChanges

  return (
    <>
      <SectionTitle style={s('mt-6 mb-5')}>
        {applicantName}&apos;s long term future
      </SectionTitle>
      <Subtitle style={s('mb-6')}>
        Speaking of the future, has {applicantName} shared their wishes and plans for
        the future after receiving the loan? We will ask them a couple questions to
        better understand situation.
      </Subtitle>

      <FieldContainer>
        <Prompt>
          Does {applicantName} anticipate any changes to their current circumstances
          that may impact their ability to meet the loan repayments?
        </Prompt>
        <RadioButtonField
          name={`${futurePath}.hasAnticipatedChanges`}
          aria-label="Anticipated Changes"
          testId={TEST_IDS.FUTURE_CHANGES_RB}
          isReadOnly
        >
          <RadioButton value={true}>Yes</RadioButton>
          <RadioButton value={false}>No</RadioButton>
        </RadioButtonField>
      </FieldContainer>

      {hasAnticipatedChanges && (
        <>
          <FieldContainer>
            <Prompt>What are these changes?</Prompt>
            <SelectField
              name={`${futurePath}.anticipatedEvent`}
              aria-label="Anticipated Event"
              placeholder="Upcoming events"
              options={ANTICIPATED_EVENT_OPTS}
              style={s('w-1/2')}
              testId={TEST_IDS.FUTURE_CHANGES_DROPDOWN}
              menuId={TEST_IDS.FUTURE_CHANGES_DROPDOWN_MENU}
              readonly
            />
          </FieldContainer>
          <FieldContainer>
            <Prompt>
              Lastly, how does {applicantName} plan to repay their home loan when these
              changes take place?
            </Prompt>
            <SelectField
              name={`${futurePath}.adaptationPlan`}
              aria-label="Adaptation Plan"
              placeholder="Your plan"
              options={ADAPTATION_PLAN_OPTS}
              style={s('w-1/2')}
              testId={TEST_IDS.FUTURE_CHANGES_PLAN_DROPDOWN}
              menuId={TEST_IDS.FUTURE_CHANGES_PLAN_DROPDOWN_MENU}
              readonly
            />
          </FieldContainer>
        </>
      )}
    </>
  )
}

export { FuturePlan }
