import React from 'react'
import { styled, s } from '@vega/styled'
import { noop } from '@neo/ramda-extra'
import { ReactComponent as ChevronLeftIconBase } from '@vega/components/src/assets/images/chevron-left.svg'
import { ReactComponent as VegaLogo } from '@vega/components/src/assets/images/vega-logo.svg'
import { ReactComponent as XIconBase } from '@vega/components/src/assets/images/x.svg'

const Container = styled.div(s('flex items-center justify-between w-full mt-5'))
const Left = styled.div(s('flex items-center'))

const ChevronLeftIcon = styled(ChevronLeftIconBase)(s('mr-4'), { cursor: 'pointer' })
const XIcon = styled(XIconBase)({ cursor: 'pointer' })

const TopNavBar = ({ onClose = noop, toPrevPage = noop }) => {
  return (
    <Container>
      <Left>
        <ChevronLeftIcon onClick={toPrevPage} />
        <VegaLogo style={s('pt-1')} />
      </Left>
      <XIcon onClick={onClose} />
    </Container>
  )
}

export { TopNavBar }
