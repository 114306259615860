import React, { useEffect, useMemo, useCallback } from 'react'
import { map, values, isNilOrEmpty } from '@neo/ramda-extra'
import {
  Title,
  SectionTitle as SecTitle,
  Subtitle,
} from 'features/broker/applications/components/typography'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'
import { Expenses } from './Expense'
import { useDispatch, useSelector } from 'react-redux'
import {
  createExpensesAndLiabilities,
  updateExpensesAndLiabilities,
  setHasUnexpectedError,
  resetForm,
  updateFormProgress,
  selectProgressStepStatus,
  selectFormProgress,
  selectHasUnexpectedError,
} from 'modules/application'
import { unwrapResult } from '@reduxjs/toolkit'
import { FuturePlan } from './FuturePlan'
import { FormProgress } from '../../../components/FormProgress/FormProgress'
import { PersonalLoan, CreditCard } from './Liabilities'
import { InfoSidebar } from './InfoSidebar'
import { BottomNavigation } from './BottomNavigation'
import { validationSchema } from './validationSchema'
import { routes } from 'routes'
import { styled, s } from '@vega/styled'
import {
  checkStepStatus,
  markProgressStepAsVisited,
} from 'features/broker/applications/components/FormProgress/utils'
import * as CONSTANTS from '@neo/constants'
import notesParentStyle from 'features/broker/applications/CreateApplicationWizard/notesParentStyle'
import { formSubmitErrorHandler } from './formSubmitErrorHandler'
import { ErrorSummary, UnexpectedError } from 'features/common/applications'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
    STEP_NAMES: { EXPENSES_AND_LIABILITIES },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 1052,
  '> :last-child': s('pb-12'),
  ...notesParentStyle,
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const SectionTitle = styled(SecTitle)(s('mt-5'))

const extractFuturePlans = (financials) => {
  const allFinancialInfo = values(financials)

  return map((financialInfo) => {
    return financialInfo?.futurePlan
  }, allFinancialInfo)
}

function YourExpensesAndLiabilitiesStep({
  stepId,
  applicantName,
  applicantId,
  applicantPathId,
}) {
  const parentPath = `financials.${applicantPathId}`

  const history = useHistory()
  const dispatch = useDispatch()
  const {
    values: formikValues,
    errors,
    submitCount,
    isValid,
    isSubmitting,
  } = useFormikContext()
  const { previous, currentStep, currentStepIndex, next } = useWizard()
  const formProgress = useSelector(selectFormProgress)
  const hasUnexpectedError = useSelector(selectHasUnexpectedError)

  const isCurrentStepCompleted = checkStepStatus(
    formProgress,
    EXPENSES_AND_LIABILITIES,
    COMPLETED,
    applicantId
  )
  const saveAndContinue = (applicantId) => async (formikValues) => {
    try {
      const formValues = formikValues?.financials[applicantPathId]
      const allFuturePlans = extractFuturePlans(formikValues?.financials)

      if (isCurrentStepCompleted) {
        const updateResult = await dispatch(
          updateExpensesAndLiabilities({ formValues, allFuturePlans, applicantId })
        )
        unwrapResult(updateResult)
      } else {
        const createResult = await dispatch(
          createExpensesAndLiabilities({ formValues, allFuturePlans, applicantId })
        )
        unwrapResult(createResult)
      }

      dispatch(
        updateFormProgress({
          step: EXPENSES_AND_LIABILITIES,
          status: COMPLETED,
          applicantId,
        })
      )

      next()
    } catch (error) {
      formSubmitErrorHandler(error)
    }
  }

  const goToApplicationList = () => history.replace(routes.broker.applications.list)
  const saveAndExit = (formikValues, applicantId) => {
    const formValues = formikValues?.financials[applicantPathId]
    dispatch(createExpensesAndLiabilities({ formValues, applicantId }))
    dispatch(resetForm())
    goToApplicationList()
  }

  const goBack = () => previous()

  const currentStepStatus = useSelector(
    selectProgressStepStatus(EXPENSES_AND_LIABILITIES, applicantId)
  )
  useEffect(() => {
    if (currentStep?.id === stepId)
      markProgressStepAsVisited(
        EXPENSES_AND_LIABILITIES,
        currentStepStatus,
        applicantId
      )
  }, [applicantId, currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Your financials"
      validationSchema={useMemo(() => validationSchema(applicantPathId), [
        applicantPathId,
      ])}
      onSubmit={useCallback(saveAndContinue(applicantId), [
        applicantId,
        formProgress,
        currentStepIndex,
      ])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={() => saveAndExit(formikValues, applicantId)} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-5')}>Let&apos;s talk financials</Title>
              <SectionTitle style={s('mt-0 mb-4')}>
                Next, {applicantName}&apos;s expenses
              </SectionTitle>
              <Subtitle style={s('mb-6')}>
                So we can get a better idea of {applicantName}&apos;s financial
                position, please tell us the expenses that apply to their household on a
                monthly basis. Estimates are fine, but the more accurate you can be, the
                better.
              </Subtitle>

              <Expenses
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <PersonalLoan
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <CreditCard
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <FuturePlan
                applicantName={applicantName}
                parentPath={parentPath}
                applicantPathId={applicantPathId}
              />

              <ErrorSummary
                visible={submitCount > 0 && !isValid}
                errors={errors}
                style={s('mt-10 pb-0')}
              />
              <UnexpectedError
                visible={isNilOrEmpty(errors) && hasUnexpectedError}
                style={s('pb-0')}
                hideError={() => dispatch(setHasUnexpectedError(false))}
              />
              <div style={s('pb-32')} />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} isLoading={isSubmitting} />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { YourExpensesAndLiabilitiesStep }
