import { pipe, isNil, map, find, propEq, prop, assoc } from '@neo/ramda-extra'

const isNewApplicant = (app) => app.isNew && isNil(app.id)

const getJustCreatedApplicantId = (createdApplicants, email) => {
  return pipe(find(propEq('email', email)), prop('id'))(createdApplicants)
}

const addIdToInvitedApplicant = (app, id) => assoc('id', id, app)

// new clients have newly created applicantId, old clients already has id associated with
// need to add the applicantId into the invitedApplicants, invitedApplicants is in applicant order but doesn't have applicantIds for new applicants
export const getOrderedApplicants = (invitedApplicants, createdApplicants) =>
  pipe(
    map((app) => {
      if (!isNewApplicant(app)) return app

      const createdId = getJustCreatedApplicantId(createdApplicants, app.email)

      return addIdToInvitedApplicant(app, createdId)
    })
  )(invitedApplicants)
