import { pick, isNilOrEmpty, propEq, find } from '@neo/ramda-extra'

export const getApplicantStatus = (formSteps, applicantId) => {
  const newSteps = pick(
    [
      'clientDetails',
      'clientIdentification',
      'creditReport',
      'incomeAndAssets',
      'expensesAndLiabilities',
    ],
    formSteps
  )

  let finalStatus = 'Submitted'

  // eslint-disable-next-line no-unused-vars
  for (const [_, stepValue] of Object.entries(newSteps)) {
    const targetApplicant = find(propEq('applicantId', applicantId), stepValue)

    const applicantNotFound = isNilOrEmpty(targetApplicant)

    const applicantHasNotCompletedStep = targetApplicant?.status !== 'COMPLETED'

    if (applicantNotFound || applicantHasNotCompletedStep) {
      finalStatus = 'Pending'
      break
    }
  }

  return finalStatus
}
