import React from 'react'
import { AssessingLoanDialog } from './AssessingLoanDialog'
import { styled, s } from '@vega/styled'

const Container = styled.div(s('h-full pt-16 flex justify-center'))

function LoanOutcome() {
  return (
    <Container>
      <AssessingLoanDialog />
    </Container>
  )
}

export { LoanOutcome }
