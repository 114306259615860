import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { BrokerService } from './service'
import { mergeDeepRight, pick } from '@neo/ramda-extra'
import { normalizeError } from '@vega/services'

export const fetchBrokers = createAsyncThunk(
  'brokers/fetchBrokers',
  async ({ next }, { getState, rejectWithValue }) => {
    try {
      const { limit, filterQuery } = getState().brokers

      const { items: brokers, paging: newPaging } = await BrokerService.list({
        query: filterQuery,
        limit,
        next,
      })

      return { brokers, newPaging }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const brokersAdapter = createEntityAdapter()

const initialState = brokersAdapter.getInitialState({
  entities: {},
  ids: [],
  limit: 10,
  filterQuery: undefined,
  filters: {},
  paging: { next: undefined, total: undefined },
})

const brokerSlice = createSlice({
  name: 'brokers',
  initialState,
  reducers: {
    setFilterParams: (state, action) => {
      const updateParams = pick(['filterQuery', 'limit', 'filters'], action.payload)
      return mergeDeepRight(state, updateParams)
    },
  },
  extraReducers: {
    [fetchBrokers.fulfilled]: (state, action) => {
      const { brokers, newPaging } = pick(['brokers', 'newPaging'], action.payload)

      brokersAdapter.setAll(state, brokers)
      state.paging = newPaging
    },
  },
})

export const { setFilterParams } = brokerSlice.actions

const { reducer: brokerReducer } = brokerSlice
export { brokerReducer }
