import { prop } from '@neo/ramda-extra'
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { activityLogService } from 'apiService'

export const searchActivityLogs = createAsyncThunk(
  'activityLog/searchActivityLogs',
  async ({ refId }, { rejectWithValue }) => {
    try {
      const { items, total } = await activityLogService.search({ refId })

      return { activityLogs: items, total }
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

export const activityLogsAdapter = createEntityAdapter()

const {
  selectAll: selectActivityLogs,
  selectById: selectActivityLogById,
} = activityLogsAdapter.getSelectors(prop('activityLogs'))

const initialState = activityLogsAdapter.getInitialState({
  entities: {},
  ids: [],
  total: undefined,
  inspectedActivityLogId: undefined,
})

const activityLogsSlice = createSlice({
  name: 'activityLogs',
  initialState,
  extraReducers: {
    [searchActivityLogs.fulfilled]: (state, action) => {
      const { activityLogs, total } = action.payload

      activityLogsAdapter.setAll(state, activityLogs)
      state.total = total
    },
  },
})

const { reducer: activityLogsReducer } = activityLogsSlice
export { activityLogsReducer, selectActivityLogs, selectActivityLogById }
