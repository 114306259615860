/* eslint-disable complexity */
import React from 'react'
import { useDispatch } from 'react-redux'
import { noop, isEmpty, or } from '@neo/ramda-extra'
import { Button, LoaderWithLabel } from '@vega/components'
import { s, styled } from '@vega/styled'
import { setInspectedPropertyId } from 'modules/property'
import { PropertyDetailsCard } from './PropertyDetailsCard'

const Container = styled.div(s('flex flex-column'))
const Grid = styled.div(s('h-full mb-8 px-6'), {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
  columnGap: 20,
  rowGap: 24,
})

const EmptyPropertiesContainer = styled.div(
  s('flex flex-column h-full justify-center items-center', {
    transform: 'translateY(-15px)',
  })
)

const LoadMore = styled(Button)(
  s('bg-white mx-a my-6 mb-10 px-4 py-3 justify-evenly border-grey-200 w-8')
)

const GridView = ({
  properties = [],
  nextPage = noop,
  openPropertyDetailsModal = noop,
  openSubmitPropertyModal = noop,
  canLoadMore = false,
  isLoading = false,
}) => {
  const dispatch = useDispatch()

  const onCardClicked = (propertyId) => {
    dispatch(setInspectedPropertyId(propertyId))
    openPropertyDetailsModal()
  }
  const onSubmitBtnClicked = (event, propertyId) => {
    event.stopPropagation()
    dispatch(setInspectedPropertyId(propertyId))
    openSubmitPropertyModal()
  }

  const isFirstTimeFetchingProperties = isLoading && isEmpty(properties)
  const noPropertiesFound = !isLoading && isEmpty(properties)

  if (isFirstTimeFetchingProperties)
    return (
      <LoaderWithLabel
        style={{ marginTop: 220 }}
        labelStyle={s('mt-6 text-base')}
        label="Fetching properties"
      />
    )

  if (noPropertiesFound)
    return (
      <EmptyPropertiesContainer>
        <h2 style={s('my-0 mb-3 text-green-700 font-normal text-3xl')}>
          No properties yet
        </h2>
        <span style={s('text-grey-600 text-lg')}>Let&apos;s add them in</span>
      </EmptyPropertiesContainer>
    )

  return (
    <Container>
      <Grid>
        {properties.map(
          ({
            id,
            address,
            imageSrc,
            details = {},
            valuation = {},
            createdBy = {},
            isDeleted,
          }) => (
            <PropertyDetailsCard
              key={id}
              imageSrc={imageSrc}
              estimatedValue={valuation?.estimatedValue}
              address={address}
              numOfBedrooms={details?.numOfBedrooms}
              numOfBathrooms={details?.numOfBathrooms}
              numOfCarparks={details?.numOfGarages}
              floorArea={details?.floorArea}
              landArea={details?.landArea}
              createdBy={createdBy}
              isDeleted={isDeleted}
              onCardClicked={() => onCardClicked(id)}
              onSubmitBtnClicked={(e) => onSubmitBtnClicked(e, id)}
            />
          )
        )}
      </Grid>

      {or(isLoading, canLoadMore) && (
        <LoadMore variant="outlined" onClick={nextPage} loading={isLoading}>
          <span style={s('font-semibold text-base text-grey-700 ')}>Load More ...</span>
        </LoadMore>
      )}
    </Container>
  )
}

export { GridView }
