import { prop, path } from '@neo/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { clientsAdapter } from './module'

const {
  selectEntities: selectClientEntities,
  selectAll: selectClientList,
} = clientsAdapter.getSelectors(prop('clients'))

const selectFetchClientsStatus = path(['asyncState', 'clients/fetchclients'])

const selectClients = prop('clients')

const selectTotalResults = createSelector(selectClients, prop('total'))

const selectInspectedClientId = createSelector(selectClients, prop('inspectedClientId'))

const selectInspectedClient = createSelector(
  selectClientEntities,
  selectInspectedClientId,
  (entities, inspectedClientId) => prop(inspectedClientId, entities)
)

const selectIsFetchClientsLoading = createSelector(
  selectFetchClientsStatus,
  (status) => status === 'pending'
)

export {
  selectClientEntities,
  selectClientList,
  selectInspectedClient,
  selectTotalResults,
  selectIsFetchClientsLoading,
}
