import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { s, styled } from '@vega/styled'

import { AppSwitcher } from 'features/common/appSwitcher'

import { routes } from 'routes'

import { CreditAssessorNavigationDrawer } from './navigationDrawer'
import { ApplicationsPage } from './applications'
import {
  ApplicationDetails,
  ApplicationApproval,
} from './applications/applicationOverview/applicationDetails'
import { PropertyAssessment } from 'features/creditAssessor/applications/applicationOverview/propertyAssessment'
import { AccountSettings } from 'features/common/accountSettings'
import { Overview as CreditAssessorOverview } from './overview'

const Root = styled.div(s('flex h-full min-h-32 bg-green-50'))

const CreditAssessorRouter = () => {
  return (
    <Root>
      <AppSwitcher />
      <CreditAssessorNavigationDrawer />

      <Switch>
        <Route
          exact
          path={routes.creditAssessor.overview}
          component={CreditAssessorOverview}
        />
        <Route
          path={routes.creditAssessor.applications.list}
          component={ApplicationsPage}
        />
        <Route
          exact
          path={routes.creditAssessor.applications.overview.personalAndFinance}
          component={ApplicationDetails}
        />
        <Route
          exact
          path={routes.creditAssessor.applications.overview.property}
          component={PropertyAssessment}
        />
        <Route
          exact
          path={routes.creditAssessor.applications.overview.approval}
          component={ApplicationApproval}
        />
        <Route exact path={routes.creditAssessor.account} component={AccountSettings} />

        <Redirect to={routes.creditAssessor.overview} />
      </Switch>
    </Root>
  )
}

export { CreditAssessorRouter }
