import React, { Fragment } from 'react'

import { YourIncomeAndPropertiesStep } from './YourIncomeAndPropertiesStep'
import { YourExpensesAndLiabilitiesStep } from './YourExpensesAndLiabilitiesStep'
import { YourFillOptionsStep } from './YourFillOptionsStep'
import { CreditReportStep } from './CreditReportStep'
import {
  makeCreditReportStepId,
  makeExpensesAndLiabilitiesStepId,
  makeFillMethodsStepId,
  makeIncomeAndAssetsStepId,
} from '../utils'

function YourFinancialsSteps({ sharedProps }) {
  return sharedProps.map((props, idx) => (
    <Fragment key={idx}>
      <CreditReportStep {...props} stepId={makeCreditReportStepId(idx)} />
      <YourFillOptionsStep {...props} stepId={makeFillMethodsStepId(idx)} />
      <YourIncomeAndPropertiesStep {...props} stepId={makeIncomeAndAssetsStepId(idx)} />
      <YourExpensesAndLiabilitiesStep
        {...props}
        stepId={makeExpensesAndLiabilitiesStepId(idx)}
      />
    </Fragment>
  ))
}

export { YourFinancialsSteps }
