import { isNotNilOrEmpty } from '@neo/ramda-extra'
import * as CONSTANTS from '@neo/constants'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { useFormikContext } from 'formik'
import { s, styled } from '@vega/styled'
import { unwrapResult } from '@reduxjs/toolkit'
import { markProgressStepAsVisited } from 'features/broker/applications/components/FormProgress/utils'
import {
  resetForm,
  selectProgressStepStatus,
  updateApplicant,
  updateFormProgress,
} from 'modules/application'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'
import { FormProgress } from '../../components/FormProgress/FormProgress'
import { BottomNavigation } from './BottomNavigation'
import { InfoSidebar } from './InfoSidebar'
import { removePhoneNumberSpaces } from './utils'
import { validationSchema } from './validationSchema'
import { formSubmitErrorHandler } from './formSubmitErrorHandler'
import { View } from './View'

const {
  FORM_STEPS,
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
    STEP_NAMES: { CLIENT_DETAILS },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

function DetailsForm({ stepId, applicantPathId, applicantId }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isSubmitting } = useFormikContext()
  const { previous, currentStep, currentStepIndex, next } = useWizard()
  const goBack = () => previous()

  const goToApplicationList = () => history.replace(routes.broker.applications.list)

  const saveAndExit = () => {
    dispatch(resetForm())
    goToApplicationList()
  }

  const saveAndContinue = async (values) => {
    try {
      const cleanValues = removePhoneNumberSpaces(values, applicantPathId)

      const updateApplicantResult = await dispatch(
        updateApplicant(cleanValues?.details?.[applicantPathId])
      )
      unwrapResult(updateApplicantResult)

      if (isNotNilOrEmpty(applicantId)) {
        dispatch(
          updateFormProgress({ step: CLIENT_DETAILS, status: COMPLETED, applicantId })
        )
      }

      next()
    } catch (error) {
      formSubmitErrorHandler(error)
    }
  }

  const currentStepStatus = useSelector(
    selectProgressStepStatus(CLIENT_DETAILS, applicantId)
  )

  useEffect(() => {
    if (currentStep?.id === stepId && isNotNilOrEmpty(applicantId))
      markProgressStepAsVisited(CLIENT_DETAILS, currentStepStatus, applicantId)
  }, [applicantId, currentStep, currentStepStatus, stepId])

  const canBack = currentStep?.id !== `${FORM_STEPS.DETAILS_DETAILS}-applicant1`

  return (
    <Wizard.Step
      id={stepId}
      title="Client details"
      validationSchema={useMemo(() => validationSchema(applicantPathId), [
        applicantPathId,
      ])}
      onSubmit={useCallback(saveAndContinue, [currentStepIndex])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <View applicantPathId={applicantPathId} />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation
              goBack={goBack}
              canBack={canBack}
              isLoading={isSubmitting}
            />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { DetailsForm }
