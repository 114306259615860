import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { pick } from '@neo/ramda-extra'
import { USER_STATUSES } from '@vega/constants'
import { normalizeError } from '@vega/services'
import { userService } from 'apiService'

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, limit = 20 } = searchParams
      return await userService.fetchUsers(
        {
          q,
          filters,
          start: limit * pageIndex,
          limit,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const toggleSuspension = createAsyncThunk(
  'users/toggleSuspension',
  async ({ id, suspended }, { rejectWithValue }) => {
    try {
      await userService.toggleSuspension({ id, suspended: !suspended })
      return { id, suspended: !suspended }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const toggleStatus = createAsyncThunk(
  'users/toggleStatus',
  async ({ id, currentStatus }, { rejectWithValue }) => {
    try {
      const newStatus =
        currentStatus === USER_STATUSES.INACTIVE
          ? USER_STATUSES.ACTIVE
          : USER_STATUSES.INACTIVE
      return { id, status: newStatus }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const usersAdapter = createEntityAdapter()

const initialState = usersAdapter.getInitialState({
  entities: {},
  ids: [],
  total: undefined,
})

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsers.fulfilled]: (state, action) => {
      const { items: users, total } = action.payload

      usersAdapter.setAll(state, users)
      state.total = total
    },
    [toggleSuspension.fulfilled]: (state, action) => {
      const { id, suspended } = pick(['id', 'suspended'], action.payload)
      usersAdapter.updateOne(state, { id, changes: { suspended } })
    },
    [toggleStatus.fulfilled]: (state, action) => {
      const { id, status } = pick(['id', 'status'], action.payload)
      usersAdapter.updateOne(state, { id, changes: { status } })
    },
  },
})

const { reducer: userReducer } = userSlice
export { userReducer }
