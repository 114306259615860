import React from 'react'
import { styled, s } from '@vega/styled'
import { OverviewCard } from './OverviewCard'
import { Button } from '@vega/components'
import { noop } from '@neo/ramda-extra'
import * as VEGA_CONSTANTS from '@vega/constants'

const {
  LOAN_APPLICATION: { STAGES },
} = VEGA_CONSTANTS

const LoadMore = styled(Button)(
  s('mx-a my-6 mb-10 px-4 py-3 justify-evenly border-grey-200 w-8')
)

const Grid = styled.div(s('mx-6 mt-1 flex-1 mb-8'), {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'minmax(250px)',
  columnGap: 20,
  rowGap: 24,
})

const GridView = ({
  viewerRole,
  canNextPage,
  nextPage = noop,
  applications = [],
  loading,
  onCardClicked = noop,
  testId,
}) => {
  const lookUpCurrentStage = (stage) =>
    ({
      [STAGES.DRAFT]: 'APPLICATION_SUBMISSION',
      [STAGES.SUBMITTED]: 'APPLICATION_SUBMISSION',
      [STAGES.APPROVED]: 'APPROVED',
    }[stage])

  const mapToCardProps = (app) => ({
    createdAt: app?.createdAt,
    currentStage: lookUpCurrentStage(app?.status?.stage),
    applicants: app?.applicantsDetails,
    maxLoanAmount: app?.intent?.borrowingAmount,
    onClick: () => onCardClicked(app?.id),
  })

  return (
    <div style={{ overflowY: 'auto', height: '100%' }}>
      <Grid data-test-id={testId}>
        {applications.map((props) => (
          <OverviewCard
            key={props?.id}
            viewerRole={viewerRole}
            testId={props?.id}
            {...mapToCardProps(props)}
          />
        ))}
      </Grid>
      {(canNextPage || loading) && (
        <LoadMore variant="outlined" onClick={nextPage} loading={loading}>
          <span style={s('font-semibold text-base text-grey-700 ')}>Load More ...</span>
        </LoadMore>
      )}
    </div>
  )
}

export { GridView }
