import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ValocityAddressField } from '@vega/components'
import { ReactComponent as SearchIconBase } from '@vega/components/src/assets/images/search.svg'
import { s, styled } from '@vega/styled'
import { useFormikContext } from 'formik'
import { routes } from 'routes'
import { TopNavBar } from 'features/common/property'
import { getPropertyDetails } from 'modules/property'

const Container = styled.div(
  s('w-full h-full px-10', {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)

const SearchContainer = styled.div(s('flex items-center justify-center mt-10'))
const Wrapper = styled.div(s('w-full max-w-64 px-8'))
const Title = styled.h2(s('text-grey-800 text-3xl font-normal mb-6'))
const SearchIcon = styled(SearchIconBase)(s('mr-2'))

const PropertySearch = ({ onClose, setCurrentPage }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const toNextPage = () => setCurrentPage('propertyDetails')
  const toPrevPage = () => {
    history.push(routes.client.applications.overview)
    onClose()
  }

  const { setFieldValue } = useFormikContext()

  const onAddressSelectChange = (selectedResult) => {
    setFieldValue('search', selectedResult?.propertyAddress)
    dispatch(getPropertyDetails(selectedResult?.propertyId))
    toNextPage()
  }

  return (
    <Container>
      <TopNavBar onClose={onClose} toPrevPage={toPrevPage} />

      <SearchContainer>
        <Wrapper>
          <Title>Search properties</Title>

          <ValocityAddressField
            name="search"
            placeholder="Enter the address"
            onSelectionChange={onAddressSelectChange}
            aria-label="property address"
            style={s('bg-white')}
            InputPrefixIcon={<SearchIcon />}
            menuStyles={s('max-h-24 mt-8', {
              overflowY: 'auto',
            })}
          />
        </Wrapper>
      </SearchContainer>
    </Container>
  )
}

export { PropertySearch }
