import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { styled, s } from '@vega/styled'
import {
  getLoanApplication,
  selectApplicants,
  selectGetApplicationStatus,
  selectLoanStructure,
} from 'modules/application'
import { getApplicantFullNames } from './utils'
import { Header } from '../Header'
import { Button as ButtonBase, ErrorPage, Loading } from '@vega/components'
import { SummaryItem } from './SummaryItem'
import fmt from './utils/formatters'
import { ASYNC_STATUSES } from '@vega/constants'

const { PENDING, REJECTED } = ASYNC_STATUSES

const Root = styled.div(s('flex flex-1 flex-column'))
const OverviewContainer = styled.div(s('p-8'))
const Heading = styled.p(
  s('m-0 text-xl text-grey-800 font-semibold mb-6', {
    lineHeight: 1.5,
    letterSpacing: '0.03em',
  })
)
const SummaryItemContainer = styled.div(s('flex'))
const Question = styled.p(
  s('m-0 text-base text-grey-800 font-semibold mt-9 mb-4', { lineHeight: 1.5 })
)
const ButtonContainer = styled.div(s('flex'))
const Button = styled(ButtonBase)(s('border-grey-300', { height: 48, width: 280 }))

const ApplicationApproval = () => {
  const { id } = useParams()

  const dispatch = useDispatch()

  const applicants = useSelector(selectApplicants)
  const getApplicationStatus = useSelector(selectGetApplicationStatus)
  const loanStructure = useSelector(selectLoanStructure)

  const applicantNames = getApplicantFullNames(applicants)
  const { loanRateType, loanTerm, offsetAccount } = loanStructure

  const approveApplication = () => 'APPROVE'
  const rejectApplication = () => 'REJECT'

  useEffect(() => {
    dispatch(getLoanApplication(id))
  }, [dispatch, id])

  if (getApplicationStatus === REJECTED) return <ErrorPage />

  if (getApplicationStatus === PENDING)
    return (
      <div style={s('w-full')}>
        <Loading style={s('m-a')} />
      </div>
    )

  return (
    <Root>
      <Header names={applicantNames} inspectedApplicationId={id} />

      <OverviewContainer>
        <Heading>Application&apos;s financial summary</Heading>

        <SummaryItemContainer>
          <SummaryItem value="2.00" label="Interest rate" suffix="% p.a" />
          <SummaryItem
            value={1500}
            label="Repayment"
            suffix="p.mth"
            formatValue={fmt.formatMoneyValue}
          />
          <SummaryItem
            value={loanTerm}
            label="Loan term"
            formatValue={fmt.formatLoanTerm}
          />
          <SummaryItem
            value={loanRateType}
            label="Loan preferences"
            formatValue={fmt.formatLoanRateType}
          />
          <SummaryItem
            value={offsetAccount}
            label="Offset account"
            formatValue={fmt.formatBoolean}
            containerStyle={s('mr-0')}
          />
        </SummaryItemContainer>

        <Heading style={s('mt-9')}>Property summary</Heading>

        <SummaryItem
          value={1200000}
          label="Value"
          formatValue={fmt.formatMoneyValue}
          containerStyle={s('mr-0')}
        />

        <Question>Do you approve this application?</Question>
        <ButtonContainer>
          <Button variant="outlined" onClick={approveApplication} style={s('mr-4')}>
            Approve
          </Button>
          <Button variant="outlined" onClick={rejectApplication}>
            Reject
          </Button>
        </ButtonContainer>
      </OverviewContainer>
    </Root>
  )
}

export { ApplicationApproval }
