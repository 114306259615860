import React, { useRef, memo } from 'react'
import { prop } from '@neo/ramda-extra'
import { styled, s } from '@vega/styled'
import fmt from '../formatters'

const Root = styled.div(
  s('border-0 w-full flex flex-column'),
  s('text-grey-800 text-base', { lineHeight: 1.5 }),
  prop('containerStyle')
)
const LabelContainer = styled.div(s('flex justify-between'))
const Label = styled.label(
  s('text-grey-500 font-medium text-base', {
    pointerEvents: 'none',
  })
)
const InputContainer = styled.div(
  s('rounded-b-lg rounded-tl-lg border-1 border-solid border-grey-200 bg-transparent'),
  s('flex items-center justify-between p-0 h-full w-full relative'),
  ({ isReadOnly }) => isReadOnly && s('bg-white border-0', { pointerEvents: 'none' }),

  prop('inputContainerStyle')
)
const Prefix = styled.span(s('text-xl flex items-center'))
const Input = styled.input(
  s('h-full w-full border-0 bg-transparent'),
  s('text-grey-800 text-xl font-medium'),
  {
    outline: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ({ isReadOnly }) => isReadOnly && s('text-grey-800'),
  prop('inputStyle')
)

const TextField = ({
  children,
  prefix,
  containerStyle,
  inputStyle,
  inputContainerStyle,
  formatValue = fmt.formatNonNumeric,
  ...props
}) => {
  const { label, isReadOnly, value } = props
  const ref = useRef()
  const formattedValue = formatValue(value)

  return (
    <Root containerStyle={containerStyle} {...props}>
      <LabelContainer>
        <Label>{label}</Label>
      </LabelContainer>
      <InputContainer
        isFilled={Boolean(formattedValue)}
        isReadOnly={isReadOnly}
        inputContainerStyle={inputContainerStyle}
      >
        {prefix && <Prefix>{prefix}</Prefix>}
        <Input
          ref={ref}
          inputStyle={inputStyle}
          isReadOnly={isReadOnly}
          value={formattedValue}
        />
      </InputContainer>
      {children}
    </Root>
  )
}

export default memo(TextField)
