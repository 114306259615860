import React, { useState } from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as TrashIcon } from '@vega/components/src/assets/images/trash.svg'
import { RemoveApplicant } from './RemoveApplicant'

const Root = styled.div(s(''))
const TrashContainer = styled.div(s('pl-1 pt-1', { height: 28 }))

const Trash = ({ applicantId, firstName, lastName }) => {
  const [isModalOpened, setIsModalOpened] = useState(false)

  const open = () => setIsModalOpened(true)
  const close = () => setIsModalOpened(false)

  const applicantName = `${firstName} ${lastName}`

  return (
    <Root>
      <TrashContainer>
        <TrashIcon
          style={s('text-grey-500', { height: 24, cursor: 'pointer' })}
          onClick={open}
        />
      </TrashContainer>
      <RemoveApplicant
        isOpen={isModalOpened}
        closePreview={close}
        applicantId={applicantId}
        applicantName={applicantName}
      />
    </Root>
  )
}

export { Trash }
