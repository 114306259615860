import store from 'app/store'
import { setHasUnexpectedError } from 'modules/application'

export const formSubmitErrorHandler = (error, action) => {
  switch (error?.type) {
    case 'applicantAlreadyExists':
      // eslint-disable-next-line no-unused-expressions
      error?.meta?.formIndexesOfInvalidInvitation.forEach((invitationIdx) => {
        const emailFieldName = `invitations.${invitationIdx}.email`
        // hack to prevent formik override custom set error behaviour in submission
        setTimeout(() => {
          action.setFieldError(emailFieldName, 'Email is already in use')
          action.setFieldTouched(emailFieldName, true, false)
        }, 1)
      })
      break
    default:
      store.dispatch(setHasUnexpectedError(true))
      document.getElementById('unexpectedError').scrollIntoView({ behavior: 'smooth' })
      break
  }
}
