export const PageLong = {
  items: [
    {
      id: '5f3b7b07df5956ffc7b8aa09',
      avatar:
        'https://s.gravatar.com/avatar/7833bc861ead830334667d8ceb66bba2?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fni.png',
      fullName: 'NNNobody',
      email: 'franky+200817_staff4@soltalabs.com',
      numOfClients: 25,
      numOfProperties: 5,
    },
    {
      id: '5f3b815a59737f1ef0b09353',
      avatar:
        'https://s.gravatar.com/avatar/200e19cf5abf01f0132644f702c696ca?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fni.png',
      fullName: 'commonaa',
      email: 'nigel+200817_staff4@soltalabs.com',
      numOfClients: 12,
      numOfProperties: 2,
    },
  ],
  paging: {
    limit: 5,
    next: 5,
    sortBy: 'firstName',
    total: 2,
  },
}
