import store from 'app/store'
import { setHasUnexpectedError } from 'modules/application'

export const formSubmitErrorHandler = (error) => {
  switch (error?.type) {
    default:
      store.dispatch(setHasUnexpectedError(true))
      document.getElementById('unexpectedError').scrollIntoView({ behavior: 'smooth' })
      break
  }
}
