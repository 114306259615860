import React from 'react'
import { RadioButtonField, RadioButton, SelectField } from '@vega/components'
import {
  SectionTitle,
  Subtitle,
  Prompt as OriginalPrompt,
} from 'features/broker/applications/components/typography'
import { useFormikContext } from 'formik'
import { ANTICIPATED_EVENT_OPTS, ADAPTATION_PLAN_OPTS } from './selectOptions'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@vega/constants'

const FieldContainer = styled.div(s('mb-6'))

const Prompt = styled(OriginalPrompt)(s('mb-4'))

function FuturePlan({ parentPath, applicantPathId }) {
  const { values: formikValues } = useFormikContext()
  const futurePath = `${parentPath}.futurePlan`
  const futureValuePath = formikValues?.financials[applicantPathId]?.futurePlan

  const hasAnticipatedChanges = futureValuePath?.hasAnticipatedChanges

  return (
    <>
      <SectionTitle style={s('mt-6 mb-5')}>Your long term future</SectionTitle>
      <Subtitle style={s('mb-6')}>
        Speaking of the future, have you shared your wishes and plans for the future
        after receiving the loan? We will ask a couple of questions to better understand
        situation.
      </Subtitle>

      <FieldContainer>
        <Prompt>
          Do you anticipate any changes to your current circumstances that may impact
          your ability to meet the loan repayments?
        </Prompt>
        <RadioButtonField
          name={`${futurePath}.hasAnticipatedChanges`}
          aria-label="Anticipated Changes"
          testId={TEST_IDS.FUTURE_CHANGES_RB}
        >
          <RadioButton value={true}>Yes</RadioButton>
          <RadioButton value={false}>No</RadioButton>
        </RadioButtonField>
      </FieldContainer>

      {hasAnticipatedChanges && (
        <>
          <FieldContainer>
            <Prompt>What are these changes?</Prompt>
            <SelectField
              name={`${futurePath}.anticipatedEvent`}
              aria-label="Anticipated Event"
              placeholder="Upcoming events"
              options={ANTICIPATED_EVENT_OPTS}
              style={s('w-1/2')}
              testId={TEST_IDS.FUTURE_CHANGES_DROPDOWN}
              menuId={TEST_IDS.FUTURE_CHANGES_DROPDOWN_MENU}
            />
          </FieldContainer>
          <FieldContainer>
            <Prompt>
              Lastly, how do you plan to repay your home loan when these changes take
              place?
            </Prompt>
            <SelectField
              name={`${futurePath}.adaptationPlan`}
              aria-label="Adaptation Plan"
              placeholder="Your plan"
              options={ADAPTATION_PLAN_OPTS}
              style={s('w-1/2')}
              testId={TEST_IDS.FUTURE_CHANGES_PLAN_DROPDOWN}
              menuId={TEST_IDS.FUTURE_CHANGES_PLAN_DROPDOWN_MENU}
            />
          </FieldContainer>
        </>
      )}
    </>
  )
}

export { FuturePlan }
