import React from 'react'
import { useSelector } from 'react-redux'

import { s, styled } from '@vega/styled'
import { GreetingTopBar } from '@vega/components'
import { selectUserName } from '@vega/redux.profile/src'

const Container = styled.div(s('flex-1 h-full p-12'))

function Overview() {
  const userName = useSelector(selectUserName)

  return (
    <Container>
      <GreetingTopBar userName={userName} />
    </Container>
  )
}

export { Overview }
