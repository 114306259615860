import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
  name: 'ui',
  initialState: { isNavDrawerOpen: true },
  reducers: {
    setIsNavDrawerOpen: (state, action) => {
      state.isNavDrawerOpen = action.payload
    },
  },
})

export const { setIsNavDrawerOpen } = uiSlice.actions

const { reducer: uiReducer } = uiSlice
export { uiReducer }
