import { prop, path } from '@neo/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { invitationsAdapter } from './module'

const selectCreateInvitationStatus = path([
  'asyncState',
  'invitations/createInvitation',
  'status',
])

const selectFetchInvitationsStatus = path([
  'asyncState',
  'invitations/fetchInvitations',
])

const selectCreateInvitationError = path([
  'asyncState',
  'invitations/createInvitation',
  'error',
])

const selectInvitations = prop('invitations')

const selectInvitationUrl = createSelector(selectInvitations, prop('invitationUrl'))

const selectTotal = createSelector(selectInvitations, prop('total'))

const selectIsFetchInvitationsLoading = createSelector(
  selectFetchInvitationsStatus,
  (status) => status === 'pending'
)

const {
  selectEntities: selectInvitationEntities,
  selectAll: selectInvitationList,
} = invitationsAdapter.getSelectors(prop('invitations'))

export {
  selectInvitationUrl,
  selectCreateInvitationStatus,
  selectCreateInvitationError,
  selectInvitationEntities,
  selectInvitationList,
  selectTotal,
  selectIsFetchInvitationsLoading,
}
