import React from 'react'
import { Formik as FormProvider, Form as FormBase } from 'formik'
import * as Validator from 'yup'

import { pick, noop, defaultTo } from '@neo/ramda-extra'

import { styled, s } from '@vega/styled'

import { View } from './View'

const Form = styled(FormBase)(
  s('bg-white h-full w-full flex-1 flex flex-column justify-center', {
    overflowY: 'hidden',
  })
)

const { string, object } = Validator

const validationSchema = Validator.object({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  middleName: string().optional(),
  preferredName: string().optional(),
  dob: string().required(),
  gender: string()
    .required('Please select gender')
    .oneOf(['female', 'male', 'other', 'unspecified']),
  email: string().email().required('Email is required'),
  phoneNumber: string()
    .matches(/^[28]\d{7,9}$/, 'Not a valid phone number')
    .required('Please enter phone number'),
  address: object({
    streetAddress: string().required('Street address is required'),
    suburb: string().required('Suburb is required'),
    city: string().required('City is required'),
    postcode: string().required('Postcode is required'),
  }),
})

function Overview({ client }) {
  const {
    firstName,
    lastName,
    middleName,
    preferredName,
    email,
    phoneNumber,
    dob,
    gender,
    address,
  } = pick(
    [
      'firstName',
      'lastName',
      'middleName',
      'preferredName',
      'email',
      'phoneNumber',
      'dob',
      'gender',
      'address',
    ],
    client
  )

  const { streetAddress, suburb, city, postcode } = address || {}

  return (
    <FormProvider
      enableReinitialize
      initialValues={{
        firstName: defaultTo('', firstName),
        lastName: defaultTo('', lastName),
        middleName: defaultTo('', middleName),
        preferredName: defaultTo('', preferredName),
        dob: defaultTo('', dob),
        gender: defaultTo('', gender),
        email: defaultTo('', email),
        phoneNumber: defaultTo('', phoneNumber),
        address: {
          streetAddress: defaultTo('', streetAddress),
          suburb: defaultTo('', suburb),
          city: defaultTo('', city),
          postcode: defaultTo('', postcode),
        },
      }}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      <Form>
        <View />
      </Form>
    </FormProvider>
  )
}

export { Overview }
