import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'

import { styled, s } from '@vega/styled'
import { useHistory } from 'react-router'

import { useDispatch } from 'react-redux'
import { useWizard, Wizard } from '@vega/formik-wizard'

import { InfoSidebar } from './InfoSidebar'
import { BottomNavigation } from './BottomNavigation'
import {
  Title,
  Subtitle,
} from 'features/creditAssessor/applications/components/typography'

import { LoanOptionsSummary } from './LoanOptionsSummary'
import { CreditAssessorCheckbox } from '../../components/CreditAssessorCheckbox'
import { resetForm, updateCreditAssessorCheck } from 'modules/application'
import { routes } from 'routes'
import { FormProgress } from '../../components/FormProgress'
import { TEST_IDS } from '@vega/constants'

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const PreviewContainer = styled.div({ '& > div': s('mb-8') })

const LoanOptionsStep = ({ stepId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    values: { creditAssessorCheck },
  } = useFormikContext()

  const saveAndContinue = async (values) => {
    dispatch(updateCreditAssessorCheck(values.creditAssessorCheck))
  }

  const goToApplicationList = () =>
    history.replace(routes.creditAssessor.applications.list)

  const saveAndExit = () => {
    dispatch(updateCreditAssessorCheck(creditAssessorCheck))
    dispatch(resetForm())
    goToApplicationList()
  }

  const { previous } = useWizard()
  const goBack = () => previous()

  return (
    <Wizard.Step
      id={stepId}
      title="Loan Options"
      onSubmit={useCallback(saveAndContinue, [])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-4')}>Loan Options</Title>

              <Subtitle style={s('p-0 mb-8')}>
                Based on what you&apos;ve told us, here is a loan package we&apos;d
                recommend for you
              </Subtitle>

              <PreviewContainer>
                <LoanOptionsSummary />
              </PreviewContainer>

              <CreditAssessorCheckbox
                name="creditAssessorCheck.loanOptions.isVerified"
                ariaLabel="Confirm information"
                testId={TEST_IDS.ASSESSOR_APP_REVIEW_CHECKBOX}
              />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} canNext />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { LoanOptionsStep }
