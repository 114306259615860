import React from 'react'
import { BarChartBase } from './BarChartBase'

function EmptyBarChart({
  width,
  height,
  approxHighestValueForEmptyChart,
  ...otherProps
}) {
  const emptyChartData = [
    {
      label: 'render y axis base on approxHighestValueForEmptyChart provided',
      value: approxHighestValueForEmptyChart,
    },
  ]

  return (
    <BarChartBase
      {...otherProps}
      width={width}
      height={height}
      data={emptyChartData}
      barColor="transparent"
      XAxisTickComponent={() => <></>}
      TooltipChildren={() => <></>}
    />
  )
}

export { EmptyBarChart }
