import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { financialInfo } from 'modules/application/initialFormValues'
import { ReactComponent as PlusIcon } from '@vega/components/src/assets/images/plus.svg'
import {
  ValocityAddressField,
  Button,
  TextField,
  RadioButton,
  RadioButtonField,
  SelectField,
} from '@vega/components'
import { isFalse, or } from '@neo/ramda-extra'
import PROPERTY from './constants'

import {
  SectionTitle as SecTitle,
  Prompt as OriginalPrompt,
} from 'features/broker/applications/components/typography'
import { LOAN_TYPE_OPTS } from '../selectOptions'
import * as CONSTANTS from '@neo/constants'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@vega/constants'
import { NotesProvider } from 'features/broker/applications/components/NotesProvider'

const { OWNERSHIP_TYPE } = PROPERTY

const {
  LOAN_APPLICATION: {
    INTENT: { PROPERTY_PURPOSE },
  },
} = CONSTANTS

const PropertyTitle = styled.h2(s('text-xl mb-4'))
const SectionTitle = styled(SecTitle)(s('mt-10'))

const Prompt = styled(OriginalPrompt)(s('mt-6 mb-4'))

const DeletePropertyBtn = styled(Button)(
  s(
    'bg-transparent border-b-1 border-solid rounded-none p-0 mt-4 text-grey-800 border-grey-800'
  )
)

const AddPropertyBtn = styled(Button)(
  s(
    'bg-transparent border-b-1 border-solid rounded-none p-0 mt-4 text-primary border-primary'
  )
)

function Properties({ applicantName, parentPath, applicantPathId }) {
  const { values, setFieldValue } = useFormikContext()

  const propertyPath = `${parentPath}.assets.property`

  const properties =
    values?.financials?.[applicantPathId]?.assets?.property?.properties || []
  const hasProperty = properties.length > 0

  const onAddressSelectChange = (propertyPath) => (selectedResult) => {
    setFieldValue(`${propertyPath}.propertyAddress`, selectedResult)
  }

  if (!hasProperty) return null

  return (
    <FieldArray
      name={`${propertyPath}.properties`}
      render={(arrayHelpers) => (
        <div>
          {properties.map((property, idx) => {
            const propertyValuePath =
              values?.financials[applicantPathId]?.assets?.property?.properties[idx]

            const isInvestmentProperty =
              propertyValuePath?.propertyPurpose === PROPERTY_PURPOSE.INVESTMENT

            const isPropertyShared = or(
              propertyValuePath?.ownershipStructure ===
                OWNERSHIP_TYPE.CLIENT_AND_PARTNER,
              propertyValuePath?.ownershipStructure === OWNERSHIP_TYPE.CLIENT_AND_OTHER
            )

            const propertyIsNotPaidOff = isFalse(propertyValuePath?.isPaidOff)

            return (
              <div key={idx} name={property[idx]}>
                <PropertyTitle>Property {idx + 1}</PropertyTitle>

                <NotesProvider
                  name={`${propertyPath}.properties.${idx}.propertyPurpose`}
                >
                  <Prompt style={s('mt-0 mb-4')}>
                    Is this a home or investment property?
                  </Prompt>
                  <RadioButtonField
                    name={`${propertyPath}.properties.${idx}.propertyPurpose`}
                    aria-label="Property Purpose"
                    containerStyle={s('mb-6')}
                    testId={`${TEST_IDS.OTHER_PROPERTY_PURPOSE_RB}${idx + 1}`}
                  >
                    <RadioButton value={PROPERTY_PURPOSE.LIVING}>Home</RadioButton>
                    <RadioButton value={PROPERTY_PURPOSE.INVESTMENT}>
                      Investment
                    </RadioButton>
                  </RadioButtonField>
                </NotesProvider>

                <NotesProvider
                  name={`${propertyPath}.properties.${idx}.propertyAddress`}
                >
                  <Prompt>What’s the address of this property?</Prompt>
                  <ValocityAddressField
                    name={`${propertyPath}.properties.${idx}.propertyAddress`}
                    onSelectionChange={onAddressSelectChange(
                      `${propertyPath}.properties.${idx}`
                    )}
                    aria-label="property address"
                    testId={`${TEST_IDS.PROPERTY_ADDRESS_INPUT}${idx + 1}`}
                    menuId={`${TEST_IDS.PROPERTY_ADDRESS_MENU}${idx + 1}`}
                  />
                </NotesProvider>

                <NotesProvider
                  name={`${propertyPath}.properties.${idx}.estimatedPropertyValue`}
                >
                  <Prompt style={s('mb-0')}>
                    What does {applicantName} estimate its value to be?
                  </Prompt>
                  <TextField
                    name={`${propertyPath}.properties.${idx}.estimatedPropertyValue`}
                    aria-label="estimated value"
                    prefix="$"
                    containerStyle={s('w-1/2')}
                    testId={`${TEST_IDS.PROPERTY_VALUE_INPUT}${idx + 1}`}
                  />
                </NotesProvider>

                {isInvestmentProperty && (
                  <NotesProvider
                    name={`${propertyPath}.properties[${idx}.rentalIncome]`}
                  >
                    <Prompt style={s('mb-0')}>
                      And how much rent does {applicantName} receive from it?
                    </Prompt>
                    <TextField
                      name={`${propertyPath}.properties.${idx}.rentalIncome`}
                      aria-label="rent revenue"
                      prefix="$"
                      suffix="p.mth"
                      containerStyle={s('w-1/2')}
                      testId={`${TEST_IDS.PROPERTY_INVESTMENT_INCOME_INPUT}${idx + 1}`}
                    />
                  </NotesProvider>
                )}

                <NotesProvider
                  name={`${propertyPath}.properties.${idx}.ownershipStructure`}
                >
                  <Prompt>Also, please confirm the ownership structure</Prompt>
                  <RadioButtonField
                    name={`${propertyPath}.properties.${idx}.ownershipStructure`}
                    aria-label="ownership"
                    defaultValue="client"
                    testId={`${TEST_IDS.OWNERSHIP_STRUCTURE_RB}${idx + 1}`}
                  >
                    <RadioButton value={OWNERSHIP_TYPE.CLIENT}>
                      Belongs to client
                    </RadioButton>
                    <RadioButton value={OWNERSHIP_TYPE.CLIENT_AND_PARTNER}>
                      Client owns it with their partner
                    </RadioButton>
                    <RadioButton value={OWNERSHIP_TYPE.CLIENT_AND_OTHER}>
                      Client owns it with someone else
                    </RadioButton>
                  </RadioButtonField>
                </NotesProvider>

                {isPropertyShared && (
                  <NotesProvider
                    name={`${propertyPath}.properties.${idx}.equityPercentage`}
                  >
                    <Prompt style={s('mb-0')}>
                      How much does {applicantName} own of the property in % term?
                    </Prompt>
                    <TextField
                      name={`${propertyPath}.properties.${idx}.equityPercentage`}
                      aria-label="Equity Percentage"
                      suffix="%"
                      placeholder="5.2"
                      containerStyle={s('w-1/2 mb-6')}
                      testId={`${TEST_IDS.OWNERSHIP_PERCENTAGE_INPUT}${idx + 1}`}
                    />
                  </NotesProvider>
                )}

                <NotesProvider name={`${propertyPath}.properties.${idx}.isPaidOff`}>
                  <Prompt style={s('mt-6 mb-4')}>
                    Has {applicantName} paid off their home loan?
                  </Prompt>
                  <RadioButtonField
                    name={`${propertyPath}.properties.${idx}.isPaidOff`}
                    aria-label="Is home loan paid off"
                    containerStyle={s('mb-6')}
                    testId={`${TEST_IDS.PAID_OFF_EXISITING_LOAN_RB}${idx + 1}`}
                  >
                    <RadioButton value={true}>Yes</RadioButton>
                    <RadioButton value={false}>No</RadioButton>
                  </RadioButtonField>
                </NotesProvider>

                {propertyIsNotPaidOff && (
                  <>
                    <NotesProvider name={`${propertyPath}.properties.${idx}.totalLoan`}>
                      <Prompt style={s('mb-0')}>What is the loan total?</Prompt>
                      <TextField
                        name={`${propertyPath}.properties.${idx}.totalLoan`}
                        aria-label="Total Loan"
                        prefix="$"
                        containerStyle={s('w-1/2 mb-6')}
                        testId={`${TEST_IDS.EXISTING_LOAN_TOTAL_INPUT}${idx + 1}`}
                      />
                    </NotesProvider>

                    <NotesProvider
                      name={`${propertyPath}.properties.${idx}.loanRepayment`}
                    >
                      <Prompt style={s('mb-0')}>
                        How much does {applicantName} pay monthly?
                      </Prompt>
                      <TextField
                        name={`${propertyPath}.properties.${idx}.loanRepayment`}
                        aria-label="Loan Repayment per month"
                        prefix="$"
                        suffix="p.mth"
                        containerStyle={s('w-1/2 mb-6')}
                        testId={`${TEST_IDS.EXISTING_LOAN_REPAYMENT_INPUT}${idx + 1}`}
                      />
                    </NotesProvider>

                    <NotesProvider
                      name={`${propertyPath}.properties.${idx}.loanRepaymentType`}
                    >
                      <Prompt>
                        What type of loan does {applicantName} have against it?
                      </Prompt>
                      <SelectField
                        name={`${propertyPath}.properties.${idx}.loanRepaymentType`}
                        aria-label="loanRepaymentType"
                        options={LOAN_TYPE_OPTS}
                        placeholder="Select loan"
                        testId={`${TEST_IDS.EXISITING_LOAN_TYPE_DROPDOWN}${idx + 1}`}
                        menuId={`${TEST_IDS.EXISITING_LOAN_TYPE_DROPDOWN_MENU}${
                          idx + 1
                        }`}
                      />
                    </NotesProvider>
                  </>
                )}

                <NotesProvider
                  name={`${propertyPath}.properties.${idx}.intendedAsSecurity`}
                >
                  <Prompt>
                    Does {applicantName} want to use this property as a security against
                    the new loan?
                  </Prompt>
                  <RadioButtonField
                    name={`${propertyPath}.properties.${idx}.intendedAsSecurity`}
                    aria-label="Is Security"
                    containerStyle={s('mb-6')}
                    testId={`${TEST_IDS.INTENDED_AS_SECURITY_RB}${idx + 1}`}
                  >
                    <RadioButton value={true}>Yes</RadioButton>
                    <RadioButton value={false}>No</RadioButton>
                  </RadioButtonField>
                </NotesProvider>

                {idx !== 0 && (
                  <DeletePropertyBtn
                    type="button"
                    onClick={() => arrayHelpers.remove(idx)}
                    data-test-id={`${TEST_IDS.DELETE_PROPERTY_BTN}${idx + 1}`}
                  >
                    Delete property
                  </DeletePropertyBtn>
                )}
              </div>
            )
          })}

          <SectionTitle>Other investment properties (if applicable)</SectionTitle>

          <AddPropertyBtn
            StartIcon={<PlusIcon width={13} />}
            type="button"
            onClick={() =>
              arrayHelpers.push(financialInfo?.assets?.property?.properties?.[0])
            }
          >
            <span style={s('mt-1 ml-1')} data-test-id={TEST_IDS.ADD_PROPERTY_BTN}>
              Add a property
            </span>
          </AddPropertyBtn>
        </div>
      )}
    />
  )
}

export { Properties }
