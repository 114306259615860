/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { selectTopClients, selectGetTopClientsStatus } from 'modules/report'
import { BarChart } from '@vega/components'
import { mapTopClientsReportToChartData } from './utils/mapTopClientsReportToChartData'
import { styled, s } from '@vega/styled'
import formatters from 'utils/formatters'
import { ASYNC_STATUSES } from '@vega/constants'

const { PENDING } = ASYNC_STATUSES
const { formatMonetaryAmount } = formatters

const Container = styled.div(
  s(
    'flex flex-column justify-between items-center bg-white rounded-lg border-1 border-solid border-grey-300 pb-4',
    {
      height: 420,
      width: '65%',
      flexShrink: 2,
      '@media (max-width: 1060px)': s('w-full mb-8'),
    }
  )
)

const Header = styled.div(s('flex w-full items-center justify-between px-8 pt-4'))

const Title = styled.h2(s('text-grey-800 text-xl font-semibold'))
const TabsContainer = styled.div(s('relative flex items-center justify-center'))
const Tab = styled.div(
  s('text-grey-500 text-sm px-2 py-2 border-0'),
  ({ isSelected }) => ({
    cursor: 'pointer',
    color: isSelected && s('text-grey-800').color,
  })
)
const TabBottomLine = styled.div(
  s('absolute bg-green-600 pin-bl-0', {
    width: 72,
    height: 2,
    transition: 'left 200ms ease-in-out, width 200ms ease-in-out',
  })
)

const XAxisTickComponent = ({ barPositionX, yMax, barData }) => {
  const circleRadius = 12
  const circleOffsetX = 5
  const circleOffsetY = 8

  return (
    <g>
      <circle
        cx={barPositionX + circleRadius + circleOffsetX}
        cy={yMax + circleRadius + circleOffsetY}
        r={circleRadius}
        fill={s('text-green-200').color}
      />
      <text
        x={barPositionX + circleRadius + circleOffsetX}
        y={yMax + circleRadius + circleOffsetY}
        textAnchor="middle"
        fontSize={10}
        dy=".3em"
      >
        {barData?.clientInitial}
      </text>
    </g>
  )
}

const ChartTooltip = (topClientCriterion) => ({ tooltipData: { label, value } }) => {
  const valueFormatterByCriterion = {
    borrowingAmount: (value) => formatMonetaryAmount(value),
    numOfLoan: (value) => value,
  }
  const valueFormatter = valueFormatterByCriterion[topClientCriterion]

  return (
    <div
      style={s('text-grey-500 px-3 py-2 text-xs', {
        boxShadow:
          '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
      })}
    >
      {/* remove the {-index} we put after the label */}
      <div style={s('mb-1')}>{label.replace(/-\d*/, '')}</div>
      <div style={s('font-semibold text-grey-800')}>{valueFormatter(value)}</div>
    </div>
  )
}

function TopClientsBarChart({ ...otherProps }) {
  const tabBottomLineRef = React.useRef()
  const { setFieldValue, values: formikValues } = useFormikContext()
  const { topClientCriterion } = formikValues

  const approxHighestValueForEmptyChart =
    topClientCriterion === 'borrowingAmount' ? 1000000 : 10

  const topClients = useSelector(selectTopClients)
  const chartData = mapTopClientsReportToChartData(topClients, topClientCriterion)

  const isGettingTopClients = useSelector(selectGetTopClientsStatus) === PENDING

  const onTabClicked = (event, criterion) => {
    const { offsetLeft, clientWidth } = event.target

    tabBottomLineRef.current.style.left = `${offsetLeft}px`
    tabBottomLineRef.current.style.width = `${clientWidth}px`
    setFieldValue('topClientCriterion', criterion)
  }

  return (
    <Container {...otherProps}>
      <Header style={s('mb-4')}>
        <Title>Top clients</Title>
        <TabsContainer>
          <Tab
            onClick={(e) => onTabClicked(e, 'borrowingAmount')}
            isSelected={topClientCriterion === 'borrowingAmount'}
            style={s('mr-2')}
          >
            Loan size
          </Tab>
          <Tab
            onClick={(e) => onTabClicked(e, 'numOfLoan')}
            isSelected={topClientCriterion === 'numOfLoan'}
          >
            No. of loan
          </Tab>
          <TabBottomLine ref={tabBottomLineRef} />
        </TabsContainer>
      </Header>

      <BarChart
        containerStyle={{
          width: '100%',
          height: 295,
          padding: '0 50px',
        }}
        data={chartData}
        tooltipOffsetX={-16}
        tooltipOffsetY={10}
        tooltipContainerStyle={{ padding: 0 }}
        XAxisTickComponent={XAxisTickComponent}
        yAxisProps={{ strokeWidth: 0 }}
        xAxisProps={{ left: -5 }}
        gridRowsProps={{ strokeDasharray: 4 }}
        TooltipChildren={ChartTooltip(topClientCriterion)}
        approxHighestValueForEmptyChart={approxHighestValueForEmptyChart}
        isLoading={isGettingTopClients}
      />
    </Container>
  )
}

export { TopClientsBarChart }
