import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { invitationService } from 'apiService'
import { pick, prop } from '@neo/ramda-extra'
import { INVITATION_URL } from '@vega/constants'
import { normalizeError } from '@vega/services'
import { thunkErrorProcessor } from '@vega/error-standardizer'
import { selectProfileId } from '@vega/redux.profile'

export const createInvitation = createAsyncThunk(
  'invitations/createInvitation',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const invitedBy = selectProfileId(getState())

      const { firstName, email, role } = payload
      return await invitationService.createInvitation({
        invitedBy,
        firstName,
        email,
        role,
      })
    } catch (err) {
      const error = await thunkErrorProcessor(err)

      return rejectWithValue(error)
    }
  }
)

export const fetchInvitations = createAsyncThunk(
  'invitations/fetchInvitations',
  async ({ pageIndex, limit, filters }, { rejectWithValue }) => {
    try {
      return await invitationService.list({
        limit,
        start: limit * pageIndex,
        statuses: prop('statuses', filters),
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const resendInvitation = createAsyncThunk(
  'invitations/resendInvitation',
  async ({ code }, { rejectWithValue }) => {
    try {
      const invitation = await invitationService.resend(code)
      return invitation
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const revokeInvitation = createAsyncThunk(
  'invitations/revokeInvitation',
  async ({ code }, { rejectWithValue }) => {
    try {
      return await invitationService.revoke(code)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const invitationsAdapter = createEntityAdapter()

const initialState = invitationsAdapter.getInitialState({
  entities: {},
  ids: [],
  total: undefined,
  invitationUrl: '',
})

const invitationSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    resetInvitationUrl: (state) => {
      state.invitationUrl = initialState?.invitationUrl
    },
  },
  extraReducers: {
    [createInvitation.fulfilled]: (state, action) => {
      const { HOST } = INVITATION_URL
      const { invitationCode } = action?.payload
      const invitationUrl = `${HOST}create-account?code=${invitationCode}`

      state.invitationUrl = invitationUrl
    },
    [fetchInvitations.fulfilled]: (state, action) => {
      const { items: invitations, total } = pick(['items', 'total'], action.payload)
      invitationsAdapter.setAll(state, invitations)
      state.total = total
    },
    [revokeInvitation.fulfilled]: (state, action) => {
      const { id } = pick(['id'], action.payload)

      invitationsAdapter.removeOne(state, id)
    },
  },
})

export const { resetInvitationUrl, setFilterParams } = invitationSlice.actions

const { reducer: invitationReducer } = invitationSlice
export { invitationReducer }
