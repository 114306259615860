import { forEachObjIndexed, includes } from '@neo/ramda-extra'

export const mapToCreditAssessorCheckModel = (model) => {
  const mappedModel = {}

  const dynamicSteps = [
    'clientDetails',
    'clientIdentification',
    'creditReport',
    'incomeAndAssets',
    'expensesAndLiabilities',
  ]

  forEachObjIndexed((checkStatus, stepName) => {
    const isDynamicStep = includes(stepName, dynamicSteps)

    if (isDynamicStep) {
      const applicantsChecks = []

      forEachObjIndexed(({ isVerified }, applicantId) => {
        applicantsChecks.push({ applicantId, isVerified })
      }, checkStatus)

      mappedModel[stepName] = applicantsChecks
    }

    if (!isDynamicStep) mappedModel[stepName] = checkStatus
  }, model)

  return mappedModel
}
