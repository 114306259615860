import React, { useState } from 'react'
import { ReactComponent as DropdownArrowIconBase } from '@vega/components/src/assets/images/dropdownArrow.svg'
import { ReactComponent as TickIconBase } from '@vega/components/src/assets/images/tick.svg'
import { ReactComponent as XIconBase } from '@vega/components/src/assets/images/x.svg'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s(
    'p-0 no-decoration text-base text-grey-500 text-sm font-medium flex flex-column justify-between items-start'
  ),
  {
    listStyleType: 'none',
    lineHeight: 1.5,
    margin: '20px 0',
  }
)
const Circle = styled.div(
  s('w-2 h-2 show-box border-grey-300 flex items-center mr-2 justify-center'),
  {
    borderRadius: '50%',
  },
  ({ isComplete }) => isComplete && s('border-green-700'),
  ({ isFailed }) => isFailed && s('border-error-500'),
  ({ isCurrent }) => isCurrent && s('text-white font-semibold bg-green-700 border-0')
)
const TickIcon = styled(TickIconBase)(s('text-green-700 w-1 h-1'))
const XIcon = styled(XIconBase)(
  s('text-red-300', {
    '& path': {
      stroke: s('text-error-500').color,
    },
  })
)

const DropdownArrowIcon = styled(DropdownArrowIconBase, {
  shouldForwardProp: (p) => p !== 'isExpanded',
})(({ isExpanded }) =>
  s('text-grey-400', {
    transition: 'all 50ms ease-in-out',
    transform: isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)',
  })
)
const DrawerToggler = styled.div(
  s('flex items-center justify-between w-full w-12', {
    cursor: 'pointer',
  })
)
const StepNumber = styled.p()
const Label = styled.p(s('m-0 mr-4'))

const renderIcon = (isCurrent, isComplete, isFailed, stepNumber) => {
  if (isCurrent) return <StepNumber>{stepNumber}</StepNumber>
  if (isComplete) return <TickIcon />
  if (isFailed) return <XIcon />

  return <StepNumber>{stepNumber}</StepNumber>
}

// eslint-disable-next-line react/no-multi-comp
export const ProgressDrawer = ({
  label,
  isComplete = false,
  isCurrent = false,
  isFailed = false,
  stepNumber,
  children,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const togglerDrawer = () => setIsExpanded(!isExpanded)

  return (
    <Container {...props}>
      <DrawerToggler onClick={togglerDrawer}>
        <div style={s('flex items-center justify-between')}>
          <Circle isCurrent={isCurrent} isComplete={isComplete} isFailed={isFailed}>
            {renderIcon(isCurrent, isComplete, isFailed, stepNumber)}
          </Circle>
          <Label>{label}</Label>
        </div>

        <DropdownArrowIcon isExpanded={isExpanded} />
      </DrawerToggler>
      {isExpanded && children}
    </Container>
  )
}
