/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import {
  append,
  forEach,
  findIndex,
  ifElse,
  isArray,
  isNil,
  isNotEmpty,
  isNotNilOrEmpty,
  map,
  prop,
  pluck,
  propEq,
  update,
  pick,
  mergeDeepRight,
  find,
} from '@neo/ramda-extra'
import { createInvitation } from 'modules/invitation'
import { creditReportService, loanApplicationService } from 'apiService'
import initialFormValues from './initialFormValues'
import {
  mapToApplicantDocModel,
  mapToInitialApplicantApproval,
  mapToInitialFormValues,
  mapToNotesViewModel,
  mapToCreditAssessorCheckViewModel,
} from './apiToViewModelMappers'
import {
  selectApplicantIds,
  selectApplicationId,
  selectFormProgress,
  selectFormStatus,
} from './selectors'
import { getOrderedApplicants } from './utils/getOrderedApplicants'
import mapToApplicantModel from './viewModelToApiMappers/mapToApplicantModel'
import mapToEmploymentModel from './viewModelToApiMappers/mapToFinancialsModel/mapToEmploymentModel'
import mapToFinancialsModel from './viewModelToApiMappers/mapToFinancialsModel/mapToFinancialsModel'
import mapToLoanStructureModel from './viewModelToApiMappers/mapToLoanStructureModel'
import getCircumstanceChangesModel from './viewModelToApiMappers/getCircumstanceChangesModel/getCircumstanceChangesModel'
import { mapToFinancialsViewModel } from './apiToViewModelMappers/mapToFinancialsViewModel'
import { mapToCreditAssessorCheckModel } from './viewModelToApiMappers/mapToCreditAssessorCheckModel'
import { normalizeError } from '@vega/services'
import { selectProfileId } from '@vega/redux.profile'
import * as CONSTANTS from '@neo/constants'
import { mapToAddApplicantModel } from './viewModelToApiMappers/mapToAddApplicantModel'
import mapToEmploymentUpdateModel from './viewModelToApiMappers/mapToFinancialsUpdateModel/mapToEmploymentUpdateModel'
import mapToFinancialsUpdateModel from './viewModelToApiMappers/mapToFinancialsUpdateModel/mapToFinancialsUpdateModel'
import { throwIfAnyNewApplicantsAlreadyExists } from './utils/throwIfAnyNewApplicantsAlreadyExists'
import { thunkErrorProcessor } from '@vega/error-standardizer'

const { FINANCIALS_RETRIEVAL_STATUS } = CONSTANTS.LOAN_APPLICATION
const {
  mapToIncomeAndAssetsModel,
  mapToExpensesAndLiabilitiesModel,
} = mapToFinancialsModel

const {
  mapToIncomeAndAssetsUpdateModel,
  mapToExpensesAndLiabilitiesUpdateModel,
} = mapToFinancialsUpdateModel

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
  },
  USER_ROLES: { CLIENT },
} = CONSTANTS

export const createLoanApplication = createAsyncThunk(
  'applications/create',
  async (_, { rejectWithValue, getState }) => {
    try {
      const createdBy = selectProfileId(getState())

      return await loanApplicationService.createApplication(createdBy)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const downloadApplicantDocuments = createAsyncThunk(
  'applications/downloadApplicantDocuments',
  async (applicantId, { rejectWithValue, getState }) => {
    try {
      const applicantIds = selectApplicantIds(getState())
      const applicantIndex = findIndex((id) => id === applicantId)(applicantIds)
      const applicantPathId = `applicant${applicantIndex + 1}`

      const documents = await loanApplicationService.downloadApplicantDocuments(
        applicantId
      )
      return { documents, applicantPathId }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const getLoanApplication = createAsyncThunk(
  `applications/get`,
  async (payload, { rejectWithValue }) => {
    try {
      const loanApplicationId = payload

      const loanApplicationData = await loanApplicationService.getApplication(
        loanApplicationId
      )

      const { id, applicantsDetails, notes } = loanApplicationData.loanApplication

      const initialFormValues = mapToInitialFormValues(loanApplicationData)

      const mappedNotes = mapToNotesViewModel(notes)

      return {
        initialFormValues,
        id,
        applicantIds: map(prop('id'), applicantsDetails),
        selectedLoanApplication: loanApplicationData,
        notes: mappedNotes,
      }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const createIncomeAndAssets = createAsyncThunk(
  'createFinancials-income-and-assets',
  async ({ formValues, applicantId }, { rejectWithValue, getState }) => {
    try {
      const loanApplicationId = selectApplicationId(getState())
      const applicantIds = selectApplicantIds(getState())

      const { income, assets, employment, salary } = formValues

      const apiFinancialPayload = mapToIncomeAndAssetsModel({
        income,
        salary,
        assets,
      })

      const apiEmploymentPayload = mapToEmploymentModel({
        employment,
      })

      const [updatedFinancials] = await Promise.all([
        loanApplicationService.createFinancials(loanApplicationId, {
          applicantIds: [applicantId],
          ...apiFinancialPayload,
        }),
        loanApplicationService.updateEmployment(applicantId, {
          ...apiEmploymentPayload,
        }),
      ])

      const applicantIndex = findIndex((id) => id === applicantId)(applicantIds)
      const applicantPathId = `applicant${applicantIndex + 1}`

      const financialsViewModel = mapToFinancialsViewModel(applicantId)(
        updatedFinancials
      )

      return {
        applicantPathId,
        updatedIncomeAndAssetsViewModel: pick(
          ['salary', 'income', 'assets'],
          financialsViewModel
        ),
      }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const createExpensesAndLiabilities = createAsyncThunk(
  'createFinancials-expenses-and-liabilities',
  async (
    { formValues, allFuturePlans, applicantId },
    { rejectWithValue, getState }
  ) => {
    try {
      const loanApplicationId = selectApplicationId(getState())
      const applicantIds = selectApplicantIds(getState())

      const { expenses, liabilities } = formValues

      const apiFinancialPayload = mapToExpensesAndLiabilitiesModel({
        expenses,
        liabilities,
      })

      const apiCircumstanceChangesPayload = getCircumstanceChangesModel(
        allFuturePlans,
        applicantIds
      )

      const [updatedFinancials] = await Promise.all([
        loanApplicationService.createFinancials(loanApplicationId, {
          applicantIds: [applicantId],
          ...apiFinancialPayload,
        }),
        loanApplicationService.updateApplication(
          loanApplicationId,
          apiCircumstanceChangesPayload
        ),
      ])

      const applicantIndex = findIndex((id) => id === applicantId)(applicantIds)
      const applicantPathId = `applicant${applicantIndex + 1}`

      const financialsViewModel = mapToFinancialsViewModel(applicantId)(
        updatedFinancials
      )
      return {
        applicantPathId,
        updatedExpensesAndLiabilitiesViewModel: pick(
          ['expenses', 'liabilities'],
          financialsViewModel
        ),
      }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateIncomeAndAssets = createAsyncThunk(
  'updateFinancials-income-and-assets',
  async ({ formValues, applicantId }, { rejectWithValue, getState }) => {
    try {
      const loanApplicationId = selectApplicationId(getState())

      const { income, assets, employment, salary } = formValues

      const apiFinancialPayload = mapToIncomeAndAssetsUpdateModel({
        income,
        salary,
        assets,
      })

      const apiEmploymentPayload = mapToEmploymentUpdateModel({
        employment,
      })

      await Promise.all([
        loanApplicationService.updateFinancials(loanApplicationId, {
          applicantIds: [applicantId],
          ...apiFinancialPayload,
        }),
        loanApplicationService.updateEmployment(applicantId, {
          ...apiEmploymentPayload,
        }),
      ])
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateExpensesAndLiabilities = createAsyncThunk(
  'updateFinancials-expenses-and-liabilities',
  async (
    { formValues, allFuturePlans, applicantId },
    { rejectWithValue, getState }
  ) => {
    try {
      const loanApplicationId = selectApplicationId(getState())
      const applicantIds = selectApplicantIds(getState())

      const { expenses, liabilities } = formValues

      const apiFinancialPayload = mapToExpensesAndLiabilitiesUpdateModel({
        expenses,
        liabilities,
      })

      const apiCircumstanceChangesPayload = getCircumstanceChangesModel(
        allFuturePlans,
        applicantIds
      )

      await Promise.all([
        loanApplicationService.updateFinancials(loanApplicationId, {
          applicantIds: [applicantId],
          ...apiFinancialPayload,
        }),
        loanApplicationService.updateApplication(
          loanApplicationId,
          apiCircumstanceChangesPayload
        ),
      ])
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateFormStatus = createAsyncThunk(
  'applications/:id/updateStatus',
  async (currentStepId, { rejectWithValue, getState }) => {
    const progress = selectFormProgress(getState())
    const id = selectApplicationId(getState())

    try {
      const updatedStatus = { progress, currentStepId }
      return await loanApplicationService.updateStatus(id, updatedStatus)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateCreditAssessorCheck = createAsyncThunk(
  'applications/:id/check',
  async (newCheck, { rejectWithValue, getState }) => {
    const id = selectApplicationId(getState())

    const mappedNewCheck = mapToCreditAssessorCheckModel(newCheck)

    try {
      const {
        creditAssessorCheck,
      } = await loanApplicationService.updateCreditAssessorCheck(id, mappedNewCheck)

      return mapToCreditAssessorCheckViewModel(creditAssessorCheck)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const addApplicantsToApplication = createAsyncThunk(
  'applications/addApplicantsToApplication',
  // eslint-disable-next-line complexity
  async (applicantsToBeInvited, { rejectWithValue, getState, dispatch }) => {
    try {
      const newApplicants = []
      const formIndexesOfNewApplicants = []

      applicantsToBeInvited.forEach((app, idx) => {
        const isNewApplicant = app.isNew && isNil(app.id)
        if (isNewApplicant) {
          newApplicants.push(app)
          formIndexesOfNewApplicants.push(idx)
        }
      })

      await throwIfAnyNewApplicantsAlreadyExists(
        newApplicants,
        formIndexesOfNewApplicants
      )

      // 1. invite new applicants
      forEach(({ firstName, email }) => {
        dispatch(
          createInvitation({
            firstName,
            email,
            role: CLIENT,
          })
        )
      }, newApplicants)

      // 2. create new applicants
      let createdApplicants = []
      if (isNotEmpty(newApplicants)) {
        const createModels = map(
          ({ firstName, email }) => ({
            firstName,
            email,
          }),
          newApplicants
        )
        const createApplicantPromises = map(
          (model) => loanApplicationService.createApplicant(model),
          createModels
        )

        createdApplicants = await Promise.all(createApplicantPromises)
      }

      // 3. Link all applicants into Loan Application
      const applicationId = selectApplicationId(getState())
      const orderedApplicants = getOrderedApplicants(
        applicantsToBeInvited,
        createdApplicants
      )

      const addApplicantsModels = map(mapToAddApplicantModel, orderedApplicants)
      await loanApplicationService.addApplicants(applicationId, addApplicantsModels)

      const orderedApplicantIds = pluck('id', orderedApplicants)
      return {
        applicantIds: orderedApplicantIds,
      }
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const updateApplicant = createAsyncThunk(
  'applications/updateApplicant',
  async ({ id, ...details }, { rejectWithValue }) => {
    try {
      const applicantModel = mapToApplicantModel(details)

      return await loanApplicationService.updateApplicant(applicantModel, id)
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const updateFinancial = createAsyncThunk(
  'loan-application/{id}/financials/{financeId}',
  async (payload, { rejectWithValue }) => {
    try {
      const { id, applicantId, category, ...data } = payload

      return await loanApplicationService.updateFinancial(
        id,
        applicantId,
        category,
        data
      )
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const updateIntent = createAsyncThunk(
  'loan-application/intent',
  async (payload, { rejectWithValue, getState }) => {
    const id = selectApplicationId(getState())
    try {
      const {
        loanPurpose,
        propertyPurpose,
        estimatedPropertyValue,
        borrowingAmount,
        expectedRentAmount,
      } = payload

      return await loanApplicationService.updateIntent(id, {
        loanPurpose,
        propertyPurpose,
        estimatedPropertyValue: Number(estimatedPropertyValue),
        borrowingAmount: Number(borrowingAmount),
        expectedRentAmount: Number(expectedRentAmount),
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateStructure = createAsyncThunk(
  'loan-application/updateStructure',
  async (payload, { rejectWithValue, getState }) => {
    const id = selectApplicationId(getState())

    try {
      return await loanApplicationService.updateStructure(
        id,
        mapToLoanStructureModel(payload)
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const submitApplication = createAsyncThunk(
  'applications/submit',
  async (_, { rejectWithValue, getState }) => {
    const { progress, currentStepId } = selectFormStatus(getState())
    const updatedStatus = {
      progress: { ...progress, preApproval: COMPLETED },
      currentStepId,
    }
    const id = selectApplicationId(getState())
    try {
      return await Promise.all([
        loanApplicationService.submitApplication(id),
        loanApplicationService.updateStatus(id, updatedStatus),
      ])
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const getFinancialsRetrievalData = createAsyncThunk(
  'applications/getFinancialsRetrievalData',
  async (applicantId, { rejectWithValue, getState, dispatch }) => {
    const loanApplicationId = selectApplicationId(getState())
    try {
      const dataRetrieval = await loanApplicationService.getFinancialsRetrievalMetadata(
        loanApplicationId,
        applicantId
      )

      const { status } = dataRetrieval

      if (status === FINANCIALS_RETRIEVAL_STATUS.COMPLETED) {
        dispatch(getLoanApplication(loanApplicationId))
      }

      return dataRetrieval
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const getFinancialsRetrievalMetadata = createAsyncThunk(
  'applications/getFinancialsRetrievalMetadata',
  async (applicantId, { rejectWithValue, getState }) => {
    const loanApplicationId = selectApplicationId(getState())
    const applicantIds = selectApplicantIds(getState())
    try {
      const {
        status,
        accessMethod,
        url,
      } = await loanApplicationService.getFinancialsRetrievalMetadata(
        loanApplicationId,
        applicantId
      )

      const applicantIndex = findIndex((id) => id === applicantId)(applicantIds)
      const applicantPathId = `applicant${applicantIndex + 1}`

      return { status, url, autoFillMethod: accessMethod, applicantId, applicantPathId }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const sendRequestApplicantFinancialsEmail = createAsyncThunk(
  'applicants/sendRequestApplicantFinancialsEmail',
  async (applicantId, { rejectWithValue, getState, dispatch }) => {
    const loanApplicationId = selectApplicationId(getState())
    try {
      await loanApplicationService.sendRequestApplicantFinancialsEmail(
        loanApplicationId,
        applicantId
      )
      return dispatch(getFinancialsRetrievalMetadata(applicantId))
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateFinancialsAccessMethod = createAsyncThunk(
  'applicants/updateFinancialsAccessMethod',
  async ({ applicantId, autoFillMethod }, { rejectWithValue, getState }) => {
    const loanApplicationId = selectApplicationId(getState())
    try {
      return await loanApplicationService.updateFinancialsAccessMethod(
        loanApplicationId,
        applicantId,
        autoFillMethod
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateClientIdentification = createAsyncThunk(
  'applications/updateClientIdentification',
  async ({ applicantId, documents }, { rejectWithValue }) => {
    try {
      const formattedDocuments = mapToApplicantDocModel(documents)

      const applicantData = await loanApplicationService.updateClientIdentification(
        applicantId,
        formattedDocuments
      )

      return applicantData
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const startCreditCheck = createAsyncThunk(
  'applications/startCreditCheck',
  async (applicantId, { rejectWithValue, getState }) => {
    try {
      const applicantIds = selectApplicantIds(getState())
      const applicantIndex = findIndex((id) => id === applicantId)(applicantIds)
      const applicantPathId = `applicant${applicantIndex + 1}`

      const loanApplicationId = selectApplicationId(getState())
      const creditReport = await creditReportService.startCreditCheck(
        loanApplicationId,
        applicantId
      )
      return { creditReport, applicantPathId }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const getLoanApplications = createAsyncThunk(
  'applications/getLoanApplications',
  async ({ searchTerm: q, pageIndex, limit, filters }, { rejectWithValue }) => {
    try {
      return await loanApplicationService.getLoanApplications({
        q,
        filters,
        start: limit * pageIndex,
        limit,
      })
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

export const getCreditReport = createAsyncThunk(
  'applications/getCreditReport',
  async (applicantId, { rejectWithValue, getState }) => {
    try {
      const applicantIds = selectApplicantIds(getState())

      const requests = applicantIds.map((id) => creditReportService.getCreditReport(id))

      const creditReports = await Promise.all(requests)
      return creditReports
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const createNote = createAsyncThunk(
  'applications/createNote',
  async (note, { getState, rejectWithValue }) => {
    const applicationId = selectApplicationId(getState())
    try {
      return await loanApplicationService.createNote(applicationId, note)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const approveApplicant = createAsyncThunk(
  'applications/approveApplicant',
  async ({ applicantId, applicantPathId }, { getState, rejectWithValue }) => {
    const applicationId = selectApplicationId(getState())
    try {
      const {
        assessment: { applicants },
      } = await loanApplicationService.approveApplicant(applicationId, applicantId)

      const applicantAssessment = mapToInitialApplicantApproval(
        find((a) => a.applicantId === applicantId, applicants)
      )

      return { applicantPathId, applicantAssessment }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const rejectApplicant = createAsyncThunk(
  'applications/rejectApplicant',
  async (
    {
      applicantId,
      idVerified,
      creditCheckVerified,
      financialsVerified,
      rejectionReason,
      applicantPathId,
    },
    { getState, rejectWithValue }
  ) => {
    const applicationId = selectApplicationId(getState())
    try {
      const {
        assessment: { applicants },
      } = await loanApplicationService.rejectApplicant(
        applicationId,
        applicantId,
        idVerified,
        creditCheckVerified,
        financialsVerified,
        rejectionReason
      )

      const applicantAssessment = mapToInitialApplicantApproval(
        find((a) => a.applicantId === applicantId, applicants)
      )

      return { applicantPathId, applicantAssessment }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const removeApplicant = createAsyncThunk(
  'applications/removeApplicant',
  async (applicantId, { getState, rejectWithValue }) => {
    const loanApplicationId = selectApplicationId(getState())

    try {
      const loanApplication = await loanApplicationService.removeApplicant(
        loanApplicationId,
        applicantId
      )

      return { loanApplication }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const applicationsAdapter = createEntityAdapter()

const { selectAll: selectLoanApplications } = applicationsAdapter.getSelectors(
  prop('applications')
)

const initialState = applicationsAdapter.getInitialState({
  entities: {},
  ids: [],
  total: undefined,
  inspectedApplicationId: undefined,
  applicationForm: initialFormValues,
  applicantIds: [],
  financialsRetrieval: {
    metadata: {},
  },
  selectedLoanApplication: {},
  notes: {},
  hasUnexpectedError: false,
})

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    updateFormProgress: (state, action) => {
      const { step, status, applicantId } = action.payload
      const stepStatus = state.applicationForm.status.progress[step]

      const isDynamic = isArray(stepStatus)
      if (!isDynamic) state.applicationForm.status.progress[step] = status

      if (isDynamic) {
        const applicantStatusIndex = findIndex(
          propEq('applicantId', applicantId),
          stepStatus
        )
        const isNewApplicant = (idx) => idx === -1
        const newStatus = { applicantId, status }

        const addNewStepStatus = () => {
          state.applicationForm.status.progress[step] = append(newStatus, stepStatus)
        }
        const updateStepStatus = () => {
          state.applicationForm.status.progress[step] = update(
            applicantStatusIndex,
            newStatus,
            stepStatus
          )
        }
        ifElse(isNewApplicant, addNewStepStatus, updateStepStatus)(applicantStatusIndex)
      }
    },
    updateFormCurrentStepId: (state, action) => {
      state.applicationForm.status.currentStepId = action.payload
    },
    setInspectedApplicationId: (state, action) => {
      state.inspectedApplicationId = action.payload
    },
    setHasUnexpectedError: (state, action) => {
      state.hasUnexpectedError = action.payload
    },
    resetForm: (state) => {
      state.applicantIds = []
      state.inspectedApplicationId = undefined
      state.applicationForm = initialFormValues
      state.selectedLoanApplication = {}
    },
  },
  extraReducers: {
    [createLoanApplication.fulfilled]: (state, action) => {
      const { id: applicationId } = action.payload
      state.inspectedApplicationId = applicationId
    },

    [getLoanApplication.fulfilled]: (state, action) => {
      const {
        initialFormValues,
        id,
        applicantIds,
        selectedLoanApplication,
        notes,
      } = action.payload

      state.applicationForm = initialFormValues
      state.inspectedApplicationId = id
      state.applicantIds = applicantIds
      state.selectedLoanApplication = selectedLoanApplication
      state.notes = notes
    },
    [updateCreditAssessorCheck.fulfilled]: (state, action) => {
      const creditAssessorCheck = action.payload

      state.applicationForm.creditAssessorCheck = creditAssessorCheck
    },
    [createIncomeAndAssets.fulfilled]: (state, action) => {
      const { applicantPathId, updatedIncomeAndAssetsViewModel } = action.payload

      state.applicationForm.financials[applicantPathId] = mergeDeepRight(
        state.applicationForm.financials[applicantPathId],
        updatedIncomeAndAssetsViewModel
      )
    },
    [createExpensesAndLiabilities.fulfilled]: (state, action) => {
      const { applicantPathId, updatedExpensesAndLiabilitiesViewModel } = action.payload

      state.applicationForm.financials[applicantPathId] = mergeDeepRight(
        state.applicationForm.financials[applicantPathId],
        updatedExpensesAndLiabilitiesViewModel
      )
    },
    [addApplicantsToApplication.fulfilled]: (state, action) => {
      state.applicantIds = action.payload.applicantIds
    },
    [submitApplication.fulfilled]: (state) => {
      state.applicantIds = []
      state.inspectedApplicationId = undefined
      state.applicationForm = initialFormValues
    },
    [getFinancialsRetrievalData.fulfilled]: (state, action) => {
      if (isNotNilOrEmpty(action.payload)) {
        const { status, applicantId, url } = action.payload
        state.financialsRetrieval.metadata[applicantId] = {
          status,
          applicantId,
          url,
        }
      }
    },
    [getFinancialsRetrievalMetadata.fulfilled]: (state, action) => {
      const {
        status,
        autoFillMethod,
        applicantId,
        applicantPathId,
        url,
      } = action.payload
      state.financialsRetrieval.metadata[applicantId] = {
        status,
        autoFillMethod,
        applicantId,
        url,
      }
      state.applicationForm.financials[
        applicantPathId
      ].fillMethod.autoFillMethod = autoFillMethod
    },
    [startCreditCheck.fulfilled]: (state, action) => {
      const { applicantPathId } = action.payload
      state.applicationForm.financials[applicantPathId].creditReport =
        action.payload.creditReport
    },
    [getCreditReport.fulfilled]: (state, action) => {
      state.selectedLoanApplication.loanApplication.creditReports = action.payload
    },
    [downloadApplicantDocuments.fulfilled]: (state, action) => {
      const { documents, applicantPathId } = action.payload
      documents.forEach(({ type, files }) => {
        state.applicationForm.details[applicantPathId].documents[type].files = files
      })
    },
    [getLoanApplications.fulfilled]: (state, action) => {
      const { items: loanApplications, total } = action.payload
      applicationsAdapter.setAll(state, loanApplications)
      state.total = total
    },
    [createNote.fulfilled]: (state, action) => {
      const { formQuestionRef: name, ...newNote } = action.payload

      if (!state.notes[name]) state.notes[name] = []
      state.notes[name].push(newNote)
    },
    [approveApplicant.fulfilled]: (state, action) => {
      const { applicantAssessment, applicantPathId } = action.payload
      state.applicationForm.applicantApproval[applicantPathId] = applicantAssessment
    },
    [rejectApplicant.fulfilled]: (state, action) => {
      const { applicantAssessment, applicantPathId } = action.payload
      state.applicationForm.applicantApproval[applicantPathId] = applicantAssessment
    },
    [removeApplicant.fulfilled]: (state, action) => {
      const application = action.payload
      state.selectedLoanApplication = application
    },
  },
})

export const {
  updateFormProgress,
  updateFormCurrentStepId,
  setInspectedApplicationId,
  setHasUnexpectedError,
  resetForm,
} = applicationsSlice.actions

const { reducer: applicationsReducer } = applicationsSlice
export { applicationsReducer, selectLoanApplications }
