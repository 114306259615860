/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

import {
  getInteractionModality,
  isFocusVisible,
  useHover,
} from '@react-aria/interactions'
import { useEffect, useRef } from 'react'
import { mergeProps, useId } from '@react-aria/utils'
import { useFocusable } from '@react-aria/focus'

/**
 * Provides the behavior and accessibility implementation for a tooltip trigger, e.g. a button
 * that shows a description when focused or hovered.
 */
export function useTooltipTrigger(props, state, ref) {
  const { isDisabled, trigger } = props

  const tooltipId = useId()

  const isHovered = useRef(false)
  const isFocused = useRef(false)

  const handleShow = () => {
    if (isHovered.current || isFocused.current) {
      state.open(isFocused.current)
    }
  }

  const handleHide = (immediate) => {
    if (!isHovered.current && !isFocused.current) {
      state.close(immediate)
    }
  }

  useEffect(() => {
    const onKeyDown = (e) => {
      if (ref && ref.current) {
        // Escape after clicking something can give it keyboard focus
        // dismiss tooltip on esc key press
        if (e.key === 'Escape') {
          state.close(true)
        }
      }
    }
    if (state.isOpen) {
      document.addEventListener('keydown', onKeyDown, true)
      return () => {
        document.removeEventListener('keydown', onKeyDown, true)
      }
    }

    return null
  }, [ref, state])

  const onHoverStart = () => {
    if (trigger === 'focus') {
      return
    }
    // In chrome, if you hover a trigger, then another element obscures it, due to keyboard
    // interactions for example, hover will end. When hover is restored after that element disappears,
    // focus moves on for example, then the tooltip will reopen. We check the modality to know if the hover
    // is the result of moving the mouse.
    if (getInteractionModality() === 'pointer') {
      isHovered.current = true
    } else {
      isHovered.current = false
    }
    handleShow()
  }

  const onHoverEnd = () => {
    if (trigger === 'focus') {
      return
    }
    // no matter how the trigger is left, we should close the tooltip
    isFocused.current = false
    isHovered.current = false
    handleHide()
  }

  const onFocus = () => {
    const isVisible = isFocusVisible()
    if (isVisible) {
      isFocused.current = true
      handleShow()
    }
  }

  const onBlur = () => {
    isFocused.current = false
    isHovered.current = false
    handleHide(true)
  }

  const { hoverProps } = useHover({
    isDisabled,
    onHoverStart,
    onHoverEnd,
  })

  const { focusableProps } = useFocusable(
    {
      isDisabled,
      onFocus,
      onBlur,
    },
    ref
  )

  return {
    triggerProps: {
      'aria-describedby': state.isOpen ? tooltipId : undefined,
      ...mergeProps(focusableProps, hoverProps),
    },
    tooltipProps: {
      id: tooltipId,
    },
  }
}
