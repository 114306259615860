import { isNotNilOrEmpty } from '@neo/ramda-extra'
import * as CONSTANTS from '@neo/constants'
import { useFormikContext } from 'formik'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { s, styled } from '@vega/styled'
import { markProgressStepAsVisited } from 'features/creditAssessor/applications/components/FormProgress/utils'
import {
  resetForm,
  selectProgressStepStatus,
  updateCreditAssessorCheck,
} from 'modules/application'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'
import { FormProgress } from '../../components/FormProgress'
import { BottomNavigation } from './BottomNavigation'
import { InfoSidebar } from './InfoSidebar'
import { View } from './View'

const {
  FORM_PROGRESS: {
    STEP_NAMES: { CLIENT_DETAILS },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

function DetailsForm({ stepId, applicantPathId, applicantId }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    values: { creditAssessorCheck },
  } = useFormikContext()

  const { previous, currentStep } = useWizard()
  const goBack = () => previous()

  const goToApplicationList = () =>
    history.replace(routes.creditAssessor.applications.list)

  const saveAndExit = () => {
    dispatch(updateCreditAssessorCheck(creditAssessorCheck))
    dispatch(resetForm())
    goToApplicationList()
  }

  const saveAndContinue = async (values) => {
    dispatch(updateCreditAssessorCheck(values.creditAssessorCheck))
  }

  const currentStepStatus = useSelector(
    selectProgressStepStatus(CLIENT_DETAILS, applicantId)
  )

  useEffect(() => {
    if (currentStep?.id === stepId && isNotNilOrEmpty(applicantId))
      markProgressStepAsVisited(CLIENT_DETAILS, currentStepStatus, applicantId)
  }, [applicantId, currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Client details"
      onSubmit={useCallback(saveAndContinue, [])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <View applicantPathId={applicantPathId} applicantId={applicantId} />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { DetailsForm }
