import React from 'react'
import { styled, s } from '@vega/styled'

import { ReactComponent as CloseIcon } from '@vega/components/src/assets/images/x.svg'
import { LinkContainer } from '@vega/components'

const Container = styled.div(s('w-full'))

const Title = styled.span(s('text-xl'))

const Header = styled.div(s('flex items-center justify-between mb-6'))

const Description = styled.p(s('text-grey-600 mb-1'))

function InvitationSent({ invitationUrl, onClose }) {
  return (
    <Container>
      <Header>
        <Title>Your invitation has been sent!</Title>
        <CloseIcon width={20} onClick={onClose} style={{ cursor: 'pointer' }} />
      </Header>

      <Description>
        A link will be sent via email but you may also share this private link.
      </Description>
      <LinkContainer link={invitationUrl} style={s('mb-8')} />
    </Container>
  )
}

export { InvitationSent }
