import React from 'react'
import { useSelector } from 'react-redux'
import { s } from '@vega/styled'
import { selectIsNavDrawerOpen } from 'modules/ui'

import { NavigationDrawer, NavigationDrawerItem } from '@vega/components'
import { ReactComponent as UserIcon } from '@vega/components/src/assets/images/user-circle.svg'
import { ReactComponent as HomeIcon } from '@vega/components/src/assets/images/home.svg'
import { ReactComponent as ApplicationsIcon } from '@vega/components/src/assets/images/applications.svg'

function ClientNavigationDrawer() {
  const prefixRoute = '/client'

  const isNavDrawerOpen = useSelector(selectIsNavDrawerOpen)

  if (!isNavDrawerOpen) return null

  return (
    <NavigationDrawer homeRoute={`${prefixRoute}/overview`}>
      <NavigationDrawerItem
        name="Overview"
        path={`${prefixRoute}/overview`}
        Icon={HomeIcon}
      />
      <NavigationDrawerItem
        name="Applications"
        path={`${prefixRoute}/applications`}
        Icon={ApplicationsIcon}
      />
      <NavigationDrawerItem
        name="Account"
        path={`${prefixRoute}/account`}
        Icon={UserIcon}
        style={s('mt-a mb-2')}
      />
    </NavigationDrawer>
  )
}

export { ClientNavigationDrawer }
