import React from 'react'
import { Formik as FormProvider, Form } from 'formik'

import { SearchBar } from '@vega/components'

function FormikSearchBar({ onSubmit, name = 'searchTerm', placeholder, ...props }) {
  return (
    <FormProvider initialValues={{ [name]: '' }} onSubmit={onSubmit}>
      {({ values, handleChange }) => {
        return (
          <Form {...props}>
            <SearchBar
              placeholder={placeholder}
              value={values[name]}
              onInputChange={handleChange}
              name={name}
              id={name}
            />
          </Form>
        )
      }}
    </FormProvider>
  )
}

export { FormikSearchBar }
