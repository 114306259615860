import React from 'react'
import { isNotNilOrEmpty, isFalse } from '@neo/ramda-extra'
import { styled, s } from '@vega/styled'
import {
  Prompt as OriginalPrompt,
  SectionTitle as OriginalSectionTitle,
} from '../../components/typography'
import { RadioButtonField, RadioButton, SelectField } from '@vega/components'
import { useFormikContext } from 'formik'
import { TEST_IDS } from '@vega/constants'

const Prompt = styled(OriginalPrompt)(s('mt-5 mb-4'))
const SectionTitle = styled(OriginalSectionTitle)(s('mt-5'))

const loanTermOptions = [
  { value: 29, label: '29 years' },
  { value: 28, label: '28 years' },
  { value: 27, label: '27 years' },
  { value: 26, label: '26 years' },
  { value: 25, label: '25 years' },
  { value: 24, label: '24 years' },
  { value: 23, label: '23 years' },
  { value: 22, label: '22 years' },
  { value: 21, label: '21 years' },
  { value: 20, label: '20 years' },
  { value: 19, label: '19 years' },
  { value: 18, label: '18 years' },
  { value: 17, label: '17 years' },
  { value: 16, label: '16 years' },
  { value: 15, label: '15 years' },
  { value: 14, label: '14 years' },
  { value: 13, label: '13 years' },
  { value: 12, label: '12 years' },
  { value: 11, label: '11 years' },
  { value: 10, label: '10 years' },
  { value: 9, label: '9 years' },
  { value: 8, label: '8 years' },
  { value: 7, label: '7 years' },
  { value: 6, label: '6 years' },
  { value: 5, label: '5 years' },
  { value: 4, label: '4 years' },
  { value: 3, label: '3 years' },
  { value: 2, label: '2 years' },
  { value: 1, label: '1 year' },
]

function LoanTerms() {
  const {
    setFieldValue,
    values: {
      structure: { maxLoanTerm },
    },
  } = useFormikContext()

  React.useEffect(() => {
    if (maxLoanTerm) setFieldValue('structure.loanTerm', 30)
  }, [maxLoanTerm, setFieldValue])

  return (
    <>
      <SectionTitle style={s('mt-10')}>Great, moving on to the loan term</SectionTitle>
      <Prompt>Our maximum possible loan term is 30 years. Do you want that?</Prompt>
      <RadioButtonField
        name="structure.maxLoanTerm"
        aria-label="wants max loan term"
        testId={TEST_IDS.MAX_LOAN_TERM_RB}
      >
        <RadioButton value={true}>Yes</RadioButton>
        <RadioButton value={false}>No</RadioButton>
      </RadioButtonField>
      {isFalse(maxLoanTerm) && isNotNilOrEmpty(maxLoanTerm) && (
        <>
          <Prompt style={s('mt-6')}>How long would you like?</Prompt>
          <SelectField
            name="structure.loanTerm"
            aria-label="Select Loan Term Length"
            placeholder="Year"
            options={loanTermOptions}
            style={s('w-1/2')}
            selectMenuStyles={s('max-h-12', { overflow: 'auto' })}
            testId={TEST_IDS.LOAN_TERM_DROPDOWN}
            menuId={TEST_IDS.LOAN_TERM_DROPDOWN_MENU}
          />
        </>
      )}
      <SectionTitle style={s('mt-10')}>And other loan features</SectionTitle>
      <Prompt>Would you like an offset account</Prompt>
      <RadioButtonField
        name="structure.offsetAccount"
        aria-label="wants offset account"
        containerStyle={s('mb-12')}
        testId={TEST_IDS.OFFSET_ACCOUNT_RB}
      >
        <RadioButton value={true}>Yes</RadioButton>
        <RadioButton value={false}>No</RadioButton>
      </RadioButtonField>
    </>
  )
}

export default LoanTerms
