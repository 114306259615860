export const EMPLOYMENT_BASIS_OPTS = [
  { value: 'full_time', label: 'Full-time' },
  { value: 'part_time', label: 'Part-time' },
  { value: 'contract', label: 'Contract' },
  { value: 'casual', label: 'Casual' },
  { value: 'temporary', label: 'Temporary' },
]

export const LOAN_TYPE_OPTS = [
  {
    value: 'interest_and_principal',
    label: 'Principal and interest',
  },
  {
    value: 'interest_only',
    label: 'Interest only',
  },
  {
    value: 'line_of_credit',
    label: 'Line of credit',
  },
]

export const EMPLOYMENT_NATURE_OPTS = [
  { value: 'salary', label: 'Salary/Wages' },
  { value: 'self_employed', label: 'Self-Employed' },
]

export const ROLES_OPTS = [
  {
    value: 'agricultural_manager',
    label: 'Agricultural manager',
  },
  { value: 'arts_or_media_professional', label: 'Arts or media professional' },
  {
    value: 'admin_worker',
    label: 'Clerical or administration worker',
  },
  {
    value: 'community_worker',
    label: 'Community or personal service worker',
  },
  {
    value: 'construction_manager',
    label: 'Construction, distribution or production manager',
  },
  {
    value: 'designer_engineer_scientist',
    label: 'Designer, engineer or scientist',
  },
  {
    value: 'health_or_welfare_worker',
    label: 'Education, health or welfare services professional',
  },
  {
    value: 'finance_or_business_professional',
    label: 'Finance or business professional',
  },
  {
    value: 'finance_business_marketing_or_technology_manager',
    label: 'Finance, business, marketing, technology manager',
  },
  {
    value: 'hospital_worker',
    label: 'Hospitality worker',
  },
  {
    value: 'labourer',
    label: 'Labourer',
  },
  {
    value: 'legal_professional',
    label: 'Legal professional',
  },
  {
    value: 'machinery_operator',
    label: 'Machinery operator or driver',
  },
  {
    value: 'marketing_or_communication_professional',
    label: 'Marketing, PR or communications professional',
  },
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'professor_teacher_education_worker',
    label: 'Professor, teacher or education worker',
  },
  {
    value: 'real_estate_or_insurance_agent',
    label: 'Real estate or insurance agent',
  },
  {
    value: 'retail_or_hospitality_manager',
    label: 'Retail, hospital or service manager',
  },
  {
    value: 'sales_representative',
    label: 'Sales representative',
  },
  {
    value: 'sports_professional',
    label: 'Sportsperson or sports related professional',
  },
  {
    value: 'technology_professional',
    label: 'Technology professional',
  },
  {
    value: 'trades_person',
    label: 'Trades person or technician',
  },
]
