const data = {
  propertyId: 'NZ82132178',
  address: '68 FORT LINCOLN LOOP, KARAKA, AUCKLAND',
  details: {
    floorArea: 214,
    landArea: 325,
    numOfBedrooms: 4,
    numOfBathrooms: 0,
    numOfGarages: 0,
    propertyType: 'Residential',
    yearBuilt: 2020,
  },
  valuation: {
    estimatedValue: 1260000,
    lastValued: '2017-06-30T12:00:00.000Z',
    capitalValue: 980000,
    improvementValues: 480000,
    landValue: 500000,
  },
  imageSrc:
    'https://valocitycdn.blob.core.windows.net/images/Property_NZ82132178_Aerial_640x428_1.0.jpeg',
}

export default data
