import React from 'react'
import { useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { selectFormProgress, selectApplicantIds } from 'modules/application'
import { ProgressDrawer } from './ProgressDrawer'
import { FormProgressLink } from './FormProgressLink'
import { useWizard } from '@vega/formik-wizard'
import { noop } from '@neo/ramda-extra'
import {
  makeDetailsStepId,
  makeIdentificationStepId,
  makeCreditReportStepId,
  makeIncomeAndAssetsStepId,
  makeExpensesAndLiabilitiesStepId,
  makeApplicantApprovalStepId,
} from 'features/creditAssessor/applications/CreateApplicationWizard/utils'
import { checkStepStatus, checkIsSectionCompleted } from './utils'
import { ReactComponent as VegaLogoBase } from '@vega/components/src/assets/images/vega-logo.svg'
import * as CONSTANTS from '@neo/constants'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { VISITED, COMPLETED },
  },
} = CONSTANTS

const Root = styled.div(
  s(
    'relative flex flex-column items-center h-full border-0 border-r-1 border-solid border-grey-200 w-16 pt-8',
    {
      userSelect: 'none',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 10,
      },
      '&::-webkit-scrollbar-thumb': s(
        'bg-grey-300 border-solid border-white rounded-full'
      ),
    }
  )
)

const Header = styled.div(s('flex items-center justify-between w-full mb-4'))
const VegaLogo = styled(VegaLogoBase)(s('ml-8'))
const SaveAndExitBtn = styled.li(
  s('no-decoration underline text-base text-grey-600 font-semibold mr-6'),
  {
    cursor: 'pointer',
    listStyleType: 'none',
    lineHeight: 1.5,
  }
)

const StepsContainer = styled.div(s('flex-1 flex justify-center'))

const DrawerContent = styled.div(s('flex flex-column pl-10'))
const ApplicantHeading = styled.p(s('my-2'))

const ScrollViewPaddingBottomDiv = styled.div(s('w-full h-1'))

export const FormProgress = ({ saveAndExit = noop }) => {
  const {
    currentStep: { id: currentStepId },
  } = useWizard()

  const isCurrentStepSection = (stepIdPrefix) =>
    new RegExp(stepIdPrefix, 'i').test(currentStepId)

  const applicantIds = useSelector(selectApplicantIds)
  const numOfApplicants = applicantIds.length
  const formProgress = useSelector(selectFormProgress)

  const isSectionCompleted = (sectionName) =>
    checkIsSectionCompleted(formProgress, sectionName, numOfApplicants)
  const isStepCompleted = (stepName, applicantId) =>
    checkStepStatus(formProgress, stepName, COMPLETED, applicantId)
  const isStepVisited = (stepName, applicantId) =>
    checkStepStatus(formProgress, stepName, VISITED, applicantId)

  const getApplicantHeading = (idx) => {
    const hasOnlyOneApplicant = numOfApplicants === 1

    if (hasOnlyOneApplicant) return ''

    let applicantTitle = 'Main applicant'
    if (idx > 0) {
      applicantTitle = `Co-applicant ${idx}`
    }
    return applicantTitle
  }

  return (
    <Root>
      <Header>
        <VegaLogo />
        <SaveAndExitBtn onClick={saveAndExit}>Save and exit</SaveAndExitBtn>
      </Header>

      <StepsContainer>
        <div>
          <ProgressDrawer
            label="About applicants"
            isComplete={isSectionCompleted('details')}
            isCurrent={isCurrentStepSection('details')}
            disabled={currentStepId === CONSTANTS.FORM_STEPS.DETAILS_INVITATION}
            stepNumber="1"
          >
            <DrawerContent>
              {applicantIds.map((applicantId, idx) => (
                <div key={applicantId}>
                  <ApplicantHeading>{getApplicantHeading(idx)}</ApplicantHeading>
                  <FormProgressLink
                    label="Personal details"
                    stepId={makeDetailsStepId(idx)}
                    isComplete={isStepCompleted('clientDetails', applicantId)}
                    isVisited={isStepVisited('clientDetails', applicantId)}
                    isCurrent={makeDetailsStepId(idx) === currentStepId}
                  />
                  <FormProgressLink
                    label="Verify IDs"
                    stepId={makeIdentificationStepId(idx)}
                    isComplete={isStepCompleted('clientIdentification', applicantId)}
                    isVisited={isStepVisited('clientIdentification', applicantId)}
                    isCurrent={makeIdentificationStepId(idx) === currentStepId}
                  />
                </div>
              ))}
            </DrawerContent>
          </ProgressDrawer>

          <ProgressDrawer
            label="About financials"
            isComplete={isSectionCompleted('financials')}
            isCurrent={isCurrentStepSection('financials')}
            isFailed={false}
            disabled={currentStepId === 'details.invitation'}
            stepNumber="2"
          >
            <DrawerContent>
              {applicantIds.map((applicantId, idx) => (
                <div key={applicantId}>
                  <ApplicantHeading>{getApplicantHeading(idx)}</ApplicantHeading>
                  <FormProgressLink
                    label="Credit score"
                    stepId={makeCreditReportStepId(idx)}
                    isComplete={isStepCompleted('creditReport', applicantId)}
                    isVisited={isStepVisited('creditReport', applicantId)}
                    isFailed={false}
                    isCurrent={makeCreditReportStepId(idx) === currentStepId}
                  />
                  <FormProgressLink
                    label="Income &amp; assets"
                    stepId={makeIncomeAndAssetsStepId(idx)}
                    isComplete={isStepCompleted('incomeAndAssets', applicantId)}
                    isVisited={isStepVisited('incomeAndAssets', applicantId)}
                    isCurrent={makeIncomeAndAssetsStepId(idx) === currentStepId}
                  />
                  <FormProgressLink
                    label="Expenses &amp; liabilities"
                    stepId={makeExpensesAndLiabilitiesStepId(idx)}
                    isComplete={isStepCompleted('expensesAndLiabilities', applicantId)}
                    isVisited={isStepVisited('expensesAndLiabilities', applicantId)}
                    isCurrent={makeExpensesAndLiabilitiesStepId(idx) === currentStepId}
                  />
                  <FormProgressLink
                    label="Decision"
                    stepId={makeApplicantApprovalStepId(idx)}
                    isComplete={isStepCompleted('applicantApproval', applicantId)}
                    isVisited={isStepVisited('applicantApproval', applicantId)}
                    isCurrent={makeApplicantApprovalStepId(idx) === currentStepId}
                  />
                </div>
              ))}
            </DrawerContent>
          </ProgressDrawer>

          <FormProgressLink
            label="About goals"
            stepId={CONSTANTS.FORM_STEPS.GOALS}
            isComplete={isStepCompleted('goals')}
            isVisited={isStepVisited('goals')}
            isCurrent={CONSTANTS.FORM_STEPS.GOALS === currentStepId}
            type="section"
            stepNumber="3"
            size="lg"
            style={{ margin: '20px 0' }}
          />

          <FormProgressLink
            label="About options"
            stepId={CONSTANTS.FORM_STEPS.OPTIONS}
            isComplete={isStepCompleted('options')}
            isVisited={isStepVisited('options')}
            isCurrent={CONSTANTS.FORM_STEPS.OPTIONS === currentStepId}
            type="section"
            stepNumber="4"
            size="lg"
            style={{ margin: '20px 0' }}
          />

          <FormProgressLink
            label="Pre-approved"
            stepId={CONSTANTS.FORM_STEPS.PRE_APPROVAL}
            isComplete={isStepCompleted('preApproval')}
            isVisited={isStepVisited('preApproval')}
            isCurrent={CONSTANTS.FORM_STEPS.PRE_APPROVAL === currentStepId}
            stepNumber="5"
            type="section"
            size="lg"
            style={{ margin: '20px 0' }}
          />
          <ScrollViewPaddingBottomDiv />
        </div>
      </StepsContainer>
    </Root>
  )
}
