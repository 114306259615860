/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { isNilOrEmpty, isNotNilOrEmpty } from '@neo/ramda-extra'
import {
  Checkbox,
  CountryComboBoxField,
  DateField,
  ValocityAddressField,
  RadioButton,
  RadioButtonField,
  SelectField,
  TextField as TextFieldBase,
  NumberField,
} from '@vega/components'
import { setHasUnexpectedError, selectHasUnexpectedError } from 'modules/application'
import { TEST_IDS } from '@vega/constants'
import { s, styled } from '@vega/styled'

import { detailsViewModel } from 'modules/application/initialFormValues'
import { Prompt, SectionTitle, Title } from '../../components/typography'
import CONSTANTS from './constants/selectFieldOptions'
import { sameAddresses } from './utils'
import fmt from 'utils/formatters'
import { ErrorSummary, UnexpectedError } from 'features/common/applications'

const TextField = styled(TextFieldBase)(s('mb-6'))

function View({ applicantPathId }) {
  const dispatch = useDispatch()
  const hasUnexpectedError = useSelector(selectHasUnexpectedError)
  const { values, setFieldValue, errors, submitCount, isValid } = useFormikContext()
  const { isNZPR, firstName, currentAddress, mailingAddress } = values?.details?.[
    applicantPathId
  ]

  const [isIdenticalAddress, setIsIdenticalAddress] = useState(
    sameAddresses(currentAddress, mailingAddress)
  )

  const pathTo = useCallback((field) => `details.${applicantPathId}.${field}`, [
    applicantPathId,
  ])

  useEffect(() => {
    prefillCitizenshipForNZPR()
    function prefillCitizenshipForNZPR() {
      if (isNZPR) {
        setFieldValue(pathTo('citizenCountry'), 'nz')
        setFieldValue(pathTo('residentCountry'), 'nz')
        setFieldValue(pathTo('residencyStatus'), 'permanent_resident')
      }
    }
  }, [isNZPR, pathTo, setFieldValue, values])

  const checkboxOnChange = (isChecked) => {
    if (isChecked) {
      setFieldValue(pathTo('mailingAddress'), currentAddress)
      setIsIdenticalAddress(true)
    } else {
      setFieldValue(pathTo('mailingAddress'), detailsViewModel.currentAddress)
      setIsIdenticalAddress(false)
    }
  }

  useEffect(() => {
    syncAddressesOnCurrentAddressUpdate()
    function syncAddressesOnCurrentAddressUpdate() {
      if (isIdenticalAddress && !sameAddresses(currentAddress, mailingAddress)) {
        setFieldValue(pathTo('mailingAddress'), currentAddress)
      }
    }
  }, [currentAddress, isIdenticalAddress, mailingAddress, pathTo, setFieldValue])

  const [applicantName, setApplicantName] = useState(firstName)
  const handleFirstNameFieldOnBlur = () => {
    if (isNilOrEmpty(firstName)) {
      setApplicantName('your client')
    } else {
      setApplicantName(fmt.capitalize(firstName))
    }
  }

  return (
    <>
      <Title style={s('mb-6')}>{`Get to know ${applicantName}`}</Title>
      <TextField
        name={pathTo('firstName')}
        aria-label="firstName"
        label="First legal name"
        placeholder="First Name"
        onBlur={handleFirstNameFieldOnBlur}
        testId={TEST_IDS.FIRST_NAME_INPUT}
      />
      <TextField
        name={pathTo('lastName')}
        aria-label="lastName"
        label="Last legal name"
        placeholder="Last Name"
        testId={TEST_IDS.LAST_NAME_INPUT}
      />
      <TextField
        name={pathTo('middleName')}
        aria-label="middleName"
        label="Middle name"
        placeholder="Name"
        isOptional
        testId={TEST_IDS.MIDDLE_NAME_INPUT}
      />
      <TextField
        name={pathTo('preferredName')}
        aria-label="preferredName"
        label="Preferred name"
        placeholder="Name"
        isOptional
      />
      <DateField
        name={pathTo('dob')}
        label="Date of birth"
        inputFormat="dd/MM/yyyy"
        placeholder="DD/MM/YYYY"
        style={s('w-1/2 mb-6')}
        testId={TEST_IDS.DOB_INPUT}
      />
      <SelectField
        name={pathTo('gender')}
        aria-label="selectGender"
        label="Gender"
        placeholder="Select gender"
        style={s('w-1/2')}
        options={CONSTANTS.GENDER_OPTIONS}
        testId={TEST_IDS.GENDER_DROPDOWN}
        menuId={TEST_IDS.GENDER_DROPDOWN_MENU}
      />
      <SelectField
        name={pathTo('marital')}
        aria-label="selectMaritalStatus"
        label="Marital Status"
        placeholder="Select marital status"
        style={s('w-1/2 mt-6')}
        options={CONSTANTS.MARITAL_OPTIONS}
        testId={TEST_IDS.MARITAL_STATUS_DROPDOWN}
        menuId={TEST_IDS.MARITAL_STATUS_DROPDOWN_MENU}
      />
      <TextField
        name={pathTo('numOfDependants')}
        aria-label="number of dependants"
        label="Number of dependants"
        style={s('w-1/2 mt-6')}
        testId={TEST_IDS.NUMBER_OF_DEPENDENTS_INPUT}
      />
      <SectionTitle style={s('mt-2 mb-5')}>
        What are {applicantName}&apos;s contact details?
      </SectionTitle>
      <ValocityAddressField
        label="Current Address"
        onSelectionChange={(res) => setFieldValue(pathTo('currentAddress'), res)}
        name={pathTo('currentAddress')}
        aria-label="current address"
        style={!isIdenticalAddress ? s('mb-6') : s('mb-2')}
        testId={TEST_IDS.CURRENT_ADDRESS_INPUT}
        menuId={TEST_IDS.CURRENT_ADDRESS_MENU}
      />
      {!isIdenticalAddress && (
        <ValocityAddressField
          name={pathTo('mailingAddress')}
          onSelectionChange={(res) => setFieldValue(pathTo('mailingAddress'), res)}
          label="Mailing address"
          aria-label="mailing address"
          style={s('mb-2')}
          testId={TEST_IDS.MAILING_ADDRESS_INPUT}
          menuId={TEST_IDS.MAILING_ADDRESS_MENU}
        />
      )}
      <Checkbox
        isSelected={isIdenticalAddress}
        onChange={checkboxOnChange}
        aria-label="checkIdenticalAddress"
        style={s('mb-6')}
        testId={TEST_IDS.SAME_ADDRESS_CHECKBOX}
      >
        Same as current address
      </Checkbox>
      <SelectField
        name={pathTo('livingArrangement')}
        aria-label="Living arrangement"
        label="What is the current housing arrangement?"
        placeholder="Select living arrangement"
        style={s('w-1/2')}
        options={CONSTANTS.LIVING_ARRANGEMENT_OPTIONS}
        testId={TEST_IDS.LIVING_ARRANGEMENT_DROPDOWN}
        menuId={TEST_IDS.LIVING_ARRANGEMENT_DROPDOWN_MENU}
      />
      <TextField
        style={s('mt-6')}
        name={pathTo('email')}
        aria-label="email"
        label="Email address"
        isReadOnly={true}
        testId={TEST_IDS.CLIENT_EMAIL_ADDRESS_INPUT}
      />
      <NumberField
        name={pathTo('phoneNumber')}
        aria-label="phoneNumber"
        label="Mobile phone number"
        prefix="+64"
        format="## ### ####"
        style={s('m-0')}
        testId={TEST_IDS.CLIENT_PHONE_INPUT}
      />
      <SectionTitle style={s('mt-10 mb-5')}>
        We also need their citizenship
      </SectionTitle>
      <Prompt style={s('mb-4')}>
        Is {applicantName} a citizen and permanent resident of New Zealand?
      </Prompt>
      <RadioButtonField
        name={pathTo('isNZPR')}
        aria-label="isNewZealandPermanentResident"
        testId={TEST_IDS.CLIENT_IS_CITIZEN_RB}
      >
        <RadioButton value={true}>Yes</RadioButton>
        <RadioButton value={false}>No</RadioButton>
      </RadioButtonField>
      {isNotNilOrEmpty(isNZPR) && !isNZPR && (
        <>
          <CountryComboBoxField
            name={pathTo('citizenCountry')}
            aria-label="select client citizen of country"
            label="What country is your client a citizen of?"
            placeholder="Select country"
            style={s('my-6')}
            testId={TEST_IDS.CLIENT_CITIZEN_COUNTRY_INPUT}
          />

          <CountryComboBoxField
            name={pathTo('residentCountry')}
            aria-label="select client resident of country"
            label="What country is your client a resident of?"
            placeholder="Select country"
            style={s('mb-6')}
            testId={TEST_IDS.CLIENTS_RESIDENT_COUNTRY_INPUT}
          />

          <SelectField
            name={pathTo('residencyStatus')}
            aria-label="select client residency status"
            label="What's your client's residency status?"
            placeholder="Select status"
            options={CONSTANTS.RESIDENCY_STATUS_OPTIONS}
            testId={TEST_IDS.CLIENT_RESIDENT_STATUS_DROPDOWN}
            menuId={TEST_IDS.CLIENT_RESIDENT_STATUS_DROPDOWN_MENU}
          />
        </>
      )}

      <ErrorSummary
        visible={submitCount > 0 && !isValid}
        errors={errors}
        style={s('mt-10 pb-0')}
      />
      <UnexpectedError
        visible={isNilOrEmpty(errors) && hasUnexpectedError}
        style={s('pb-0')}
        hideError={() => dispatch(setHasUnexpectedError(false))}
      />
      <div style={s('pb-32')} />
    </>
  )
}

export { View }
