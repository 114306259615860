import React from 'react'
import { styled, s } from '@vega/styled'
import { Paper, DottedCircleLoader } from '@vega/components'

const Container = styled.div(s('flex inline-flex'))

export default function CreditReportLoadingSection() {
  return (
    <Paper>
      <Container>
        <span>Fetching Credit Report data ... </span>
        <DottedCircleLoader size="md" />
      </Container>
    </Paper>
  )
}
