/* eslint-disable camelcase */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { DropdownMenu } from './DropdownMenu'
import { prop, flip } from '@neo/ramda-extra'
import { INVITATION_STATUSES, USER_ROLES } from '@vega/constants'
import moment from 'moment'
import { s } from '@vega/styled'

const roleMapper = {
  [USER_ROLES.SUPER_ADMIN]: 'Super Admin',
  [USER_ROLES.BANKING_ADMIN]: 'Bank Admin',
  [USER_ROLES.LENDING_ADMIN]: 'Lending Admin',
  [USER_ROLES.CREDIT_ASSESSOR]: 'Credit Assessor',
  [USER_ROLES.LENDING_CUSTOMER_SUPPORT]: 'Lending Customer Support',
  [USER_ROLES.BROKER]: 'Broker',
  [USER_ROLES.CLIENT]: 'Client',
  [USER_ROLES.APPLICANT]: 'Applicant',
}

const statusMapper = {
  [INVITATION_STATUSES.INVITED]: 'Invited',
  [INVITATION_STATUSES.SENT]: 'Sent',
  [INVITATION_STATUSES.REVOKED]: 'Revoked',
  [INVITATION_STATUSES.EXPIRED]: 'Expired',
}

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'invitedAt',
        tableCellStyles: { width: '15%' },
        tableHeaderStyles: s('text-left', { width: '15%' }),
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableSortBy: true,
        tableCellStyles: { width: '30%', wordBreak: 'break-all' },
        tableHeaderStyles: s('text-left', { width: '30%' }),
      },
      {
        Header: 'Role',
        accessor: 'role',
        disableSortBy: true,
        tableCellStyles: { width: '15%' },
        tableHeaderStyles: s('text-left', { width: '15%' }),
      },
      {
        Header: 'Invited by',
        accessor: 'invitedBy',
        disableSortBy: true,
        tableCellStyles: { width: '20%' },
        tableHeaderStyles: s('text-left', { width: '20%' }),
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        tableCellStyles: { width: '10%' },
        tableHeaderStyles: s('text-left', { width: '10%' }),
      },
      {
        accessor: 'actions',
        disableSortBy: true,
        tableCellStyles: s('justify-center', { width: '10%' }),
        tableHeaderStyles: { width: '10%' },
      },
    ],
    []
  )
}

export const Rows = (invitationList) => {
  return React.useMemo(
    () =>
      invitationList.map((invitation) => {
        const propInvitation = flip(prop)(invitation)

        return {
          id: propInvitation('id'),
          invitedAt: moment(propInvitation('invitedAt')).format('DD-MM-YYYY'),
          email: propInvitation('email'),
          role: roleMapper[propInvitation('role')],
          invitedBy: propInvitation('invitedBy'),
          invitationCode: propInvitation('invitationCode'),
          status: statusMapper[propInvitation('status')],
          actions: (
            <DropdownMenu
              code={propInvitation('invitationCode')}
              style={{ 'mini-width': '40px' }}
            />
          ),
        }
      }),
    [invitationList]
  )
}
