import React from 'react'
import { useFormikContext } from 'formik'
import {
  TextField as TextFieldBase,
  DateField,
  SelectField,
  Button,
} from '@vega/components'
import { styled, s } from '@vega/styled'

import { GENDER_OPTIONS } from './selectFieldOptions'

const ContentContainer = styled.div(s('flex w-full h-full', { overflowY: 'scroll' }))

const FieldsContainer = styled.div(
  s('pt-4 mx-32', { width: '480px', '> :last-child': s('pb-16') })
)

const Header = styled.div(s('text-xl font-medium pb-4 pt-5 text-grey-800'))

const NavigationContainer = styled.div(
  s('flex w-full mt-a', {
    height: '76px',
    boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.1)',
  })
)

const NavButtonsContainer = styled.div(
  s('flex-1 flex items-center self-center px-32', { gap: 20 })
)
const NavButton = styled(Button)(
  s('bg-white border-solid border-1 border-grey-300 font-semibold text-base w-6', {
    lineHeight: 1.5,
    height: 40,
  }),
  ({ disabled }) => disabled && { cursor: 'default' }
)

const TextField = styled(TextFieldBase)(s('mb-5'))

function View() {
  const { dirty, resetForm } = useFormikContext()

  const handleReset = () => {
    resetForm()
  }

  return (
    <>
      <ContentContainer>
        <FieldsContainer>
          <Header>Personal details</Header>

          <TextField name="firstName" label="First name" placeholder="John" />

          <TextField name="lastName" label="Last name" placeholder="Johansson" />

          <TextField name="middleName" label="Middle name" placeholder="-" isOptional />

          <TextField
            name="preferredName"
            label="Preferred name"
            placeholder="-"
            isOptional
          />

          <DateField
            name="dob"
            label="Date of birth"
            placeholder="DD/MM/YYYY"
            inputFormat="dd/MM/yyyy"
            style={s('mb-5')}
          />

          <SelectField
            name="gender"
            label="Gender"
            placeholder="Select gender"
            options={GENDER_OPTIONS}
            style={s('mb-5')}
          />

          <Header>Contact details</Header>

          <TextField
            name="email"
            label="Email address"
            placeholder="example@gmail.com"
          />

          <TextField
            name="phoneNumber"
            aria-label="phoneNumber"
            label="Mobile phone number"
            placeholder="__ ____ ____"
            prefix="+64"
          />

          <TextField
            name="address.streetAddress"
            label="Address"
            placeholder="123 Smith St."
          />

          <div style={s('inline-flex w-full', { gap: 12 })}>
            <TextField name="address.suburb" label="Suburbs" placeholder="Suburbs" />

            <TextField name="address.city" label="City/Town" placeholder="City/Town" />
          </div>

          <TextField
            name="address.postcode"
            label="Postcode"
            placeholder="____"
            style={{ width: 'calc(50% - 6px)' }}
          />
        </FieldsContainer>
      </ContentContainer>

      <NavigationContainer>
        <NavButtonsContainer>
          <NavButton
            disabled={!dirty}
            type="submit"
            style={dirty ? s('bg-green-500') : s('bg-white')}
          >
            Save
          </NavButton>

          <NavButton onClick={handleReset} disabled={!dirty}>
            Cancel
          </NavButton>
        </NavButtonsContainer>
      </NavigationContainer>
    </>
  )
}

export { View }
