import React from 'react'
import { Formik, Form } from 'formik'
import { noop } from '@neo/ramda-extra'
import { routes as appRoutes } from 'routes'
import { Route, Switch, Redirect } from 'react-router-dom'

import { s, styled } from '@vega/styled'
import { ApplicationOverview } from './ApplicationOverview/ApplicationOverview'
import { ClientOverview } from './ClientOverview/ClientOverview'
import { Header } from 'features/common/overview/Header'
import { TopTabNavigator } from '@vega/components'

const Root = styled.div(
  s('flex flex-column w-full h-full min-h-32 bg-green-50', {
    overflow: 'hidden',
  })
)

const currentMonth = new Date().getMonth() + 1
const currentYear = new Date().getFullYear()

const OverviewSubRouter = () => {
  const applicationOverviewPath = appRoutes.broker.overview.applications
  const clientOverviewPath = appRoutes.broker.overview.clients

  const routes = [
    {
      name: 'Applications',
      path: applicationOverviewPath,
    },
    {
      name: 'Clients',
      path: clientOverviewPath,
    },
  ]

  return (
    <Root>
      <Formik
        enableReinitialize
        initialValues={{
          month: currentMonth,
          year: currentYear,
          topClientCriterion: 'borrowingAmount',
        }}
        onSubmit={noop}
      >
        <Form>
          <Header />
          <TopTabNavigator routes={routes} containerStyle={s('bg-white')} />

          <Switch>
            <Route
              exact
              path={applicationOverviewPath}
              component={ApplicationOverview}
            />
            <Route exact path={clientOverviewPath} component={ClientOverview} />

            <Redirect to={applicationOverviewPath} />
          </Switch>
        </Form>
      </Formik>
    </Root>
  )
}

export default OverviewSubRouter
