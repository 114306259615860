import { keys, isNilOrEmpty, includes, find, propEq } from '@neo/ramda-extra'
import * as CONSTANTS from '@neo/constants'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { VISITED, COMPLETED },
  },
} = CONSTANTS

const getAcceptableStatuses = {
  COMPLETED: [COMPLETED],
  VISITED: [VISITED, COMPLETED],
}

export const checkStepStatus = (
  progress,
  stepName,
  statusToBeChecked = COMPLETED,
  applicantId
) => {
  const validStepNames = keys(progress)
  const isValidStepName = includes(stepName, validStepNames)
  if (!isValidStepName)
    throw new Error(
      `LoanApplication:FormProgress:checkIsStepCompleted: ${stepName} is not valid, please provider a valid step key ${validStepNames}`
    )

  const stepStatus = progress[stepName]
  const acceptableStatuses = getAcceptableStatuses[statusToBeChecked]

  if (isNilOrEmpty(stepStatus)) return false
  if (typeof stepStatus === 'string') {
    return includes(stepStatus, acceptableStatuses)
  }
  if (Array.isArray(stepStatus)) {
    const stepStatusOfApplicant = find(propEq('applicantId', applicantId), stepStatus)
    return includes(stepStatusOfApplicant?.status, acceptableStatuses)
  }

  return false
}
