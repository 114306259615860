import React from 'react'
import { s, styled } from '@vega/styled'
import { prop } from '@neo/ramda-extra'

const Card = styled.div(
  s(
    'flex bg-white px-8 border-1 border-solid border-grey-300 justify-center items-center',
    {
      height: 200,
      minWidth: 204,
      paddingTop: 30,
      paddingBottom: 30,
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      cursor: 'pointer',
      ':not(:last-child)': s('mr-5'),
    }
  )
)

const RateContainer = styled('div', {
  shouldForwardProp: (p) => p !== 'containerStyle',
})(s('flex flex-column my-0 justify-center items-center'), prop('containerStyle'))
const RateValue = styled.p(s('font-bold my-0 mr-1', { fontSize: 42 }))
const Image = styled.img(s('max-h-4 max-w-8'))
const RateValueContainer = styled.div(s('flex my-0'))
const RateUnit = styled.p(
  s('my-0 text-grey-600 font-medium text-xs w-1 pt-2', {
    wordSpacing: 1000,
  })
)

const ComparisonRate = ({ rate, image }) => {
  return (
    <Card>
      <RateContainer>
        <RateValueContainer>
          <RateValue>{rate}</RateValue>
          <RateUnit>% p.a</RateUnit>
        </RateValueContainer>
        <Image src={image} />
      </RateContainer>
    </Card>
  )
}

export { ComparisonRate }
