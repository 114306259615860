import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { and, isNotNilOrEmpty } from '@neo/ramda-extra'
import { styled, s } from '@vega/styled'
import {
  resetInvitationUrl,
  selectInvitationUrl,
  selectCreateInvitationStatus,
} from 'modules/invitation'

import { ASYNC_STATUSES } from '@vega/constants'
import { Modal } from '@vega/components'
import { InvitationForm } from './InvitationForm'
import { InvitationComplete } from './InvitationComplete'

const Container = styled.div(
  s('bg-white py-6 px-8 w-32', {
    borderRadius: '12px 0px 12px 12px',
  })
)

function ClientInvitationModal({ isOpen, setIsOpen }) {
  const dispatch = useDispatch()

  const invitationStatus = useSelector(selectCreateInvitationStatus)
  const invitationUrl = useSelector(selectInvitationUrl)
  const isInvitationFulfilled = and(
    isNotNilOrEmpty(invitationUrl),
    invitationStatus === ASYNC_STATUSES.FULFILLED
  )

  const onClose = () => {
    setIsOpen(false)
    dispatch(resetInvitationUrl())
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        {isInvitationFulfilled ? (
          <InvitationComplete invitationUrl={invitationUrl} onClose={onClose} />
        ) : (
          <InvitationForm onClose={onClose} />
        )}
      </Container>
    </Modal>
  )
}

export { ClientInvitationModal }
