import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { routes } from 'routes'

import { ApplicationsList } from './ApplicationsList'
import { CreateApplicationWizard } from './CreateApplicationWizard'
import {
  ApplicationDetails,
  ApplicationApproval,
} from './applicationOverview/applicationDetails'

function ApplicationsPage() {
  return (
    <Switch>
      <Route
        exact
        path={routes.creditAssessor.applications.list}
        component={ApplicationsList}
      />

      <Route
        exact
        path={routes.creditAssessor.applications.overview.personalAndFinance}
        component={ApplicationDetails}
      />

      <Route
        exact
        path={routes.creditAssessor.applications.overview.approval}
        component={ApplicationApproval}
      />

      <Route
        path={routes.creditAssessor.applications.review}
        component={CreateApplicationWizard}
      />

      <Redirect to={routes.creditAssessor.applications.list} />
    </Switch>
  )
}

export { ApplicationsPage }
