/* eslint-disable complexity */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNotNil, all, isTrue, noop } from '@neo/ramda-extra'
import { useFormikContext } from 'formik'
import { submitProperty, selectSubmitPropertyStatus } from 'modules/property'
import { RadioButton, RadioButtonField, Button as ButtonBase } from '@vega/components'
import { ASYNC_STATUSES } from '@vega/constants'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('flex flex-column items-center w-full px-10 pt-8 pb-4 bg-white', {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    borderRadius: '12px 0px 12px 12px;',
  })
)

const Title = styled.h2(s('m-0 mb-3 font-semibold text-2xl'))

const FieldContainer = styled.div(s('mb-6'))
const Prompt = styled.p(
  s('text-grey-700 font-semibold text-base m-0 mb-4', {
    lineHeight: 1.5,
    letterSpacing: '0.015em',
  })
)

const SubmitPropertyBtn = styled(ButtonBase)(s('px-8 text-grey-800 font-semibold'))
const SearchMorePropertyBtn = styled(ButtonBase)(s('px-8 text-grey-800 font-semibold'))
const DeclinePropertyBtn = styled(ButtonBase)(s('px-8 text-grey-800 font-semibold'))

function SubmitPropertyQuestions({
  openSavePropertyModal = noop,
  onModalClose = noop,
  loanApplicationId,
  propertyId,
}) {
  const dispatch = useDispatch()
  const { values: formikValues, setFieldValue, resetForm } = useFormikContext()

  const hasMadeOffer = formikValues?.hasMadeOffer
  const hasWonOffer = formikValues?.hasWonOffer
  const isIntendedToSubmit = formikValues?.isIntendedToSubmit

  const showSearchPropertyBtn = all(isTrue)([
    isNotNil(hasMadeOffer),
    isTrue(hasWonOffer),
    !isIntendedToSubmit,
  ])

  const onSearchPropertyBtnClicked = () => {
    onModalClose()
    resetForm()

    openSavePropertyModal()
  }

  const isSubmittingProperty =
    useSelector(selectSubmitPropertyStatus) === ASYNC_STATUSES.PENDING

  const onSubmitBtnClick = () => {
    dispatch(submitProperty({ loanApplicationId, propertyId, callback: onModalClose }))
  }

  const onDeclineBtnClick = () => {}

  React.useEffect(() => {
    if (hasMadeOffer === false) {
      setFieldValue('hasWonOffer', undefined)
      setFieldValue('isIntendedToSubmit', undefined)
    }

    if (hasWonOffer === false) {
      setFieldValue('isIntendedToSubmit', undefined)
    }
  }, [hasMadeOffer, hasWonOffer, setFieldValue])

  return (
    <Container>
      <div>
        <Title>How was the auction?</Title>
        <FieldContainer>
          <Prompt>
            Have you attend to the auction for this property or have you made an offer?
          </Prompt>
          <RadioButtonField
            name="hasMadeOffer"
            aria-label="Have attended to auction of have offer been made?"
          >
            <RadioButton value={true}>Yes</RadioButton>
            <RadioButton value={false}>No</RadioButton>
          </RadioButtonField>
        </FieldContainer>

        {hasMadeOffer && (
          <FieldContainer>
            <Prompt>Did you win the offer for this property?</Prompt>
            <RadioButtonField
              name="hasWonOffer"
              aria-label="Did you win the offer for this property?"
            >
              <RadioButton value={true}>Yes</RadioButton>
              <RadioButton value={false}>No</RadioButton>
            </RadioButtonField>
          </FieldContainer>
        )}
        {hasMadeOffer === false && (
          <SearchMorePropertyBtn onClick={onSearchPropertyBtnClicked}>
            Search more properties
          </SearchMorePropertyBtn>
        )}

        {hasWonOffer && (
          <FieldContainer>
            <Prompt>Do you want to submit this property now?</Prompt>
            <RadioButtonField
              name="isIntendedToSubmit"
              aria-label="Do you want to submit this property now?"
            >
              <RadioButton value={true}>Yes</RadioButton>
              <RadioButton value={false}>No</RadioButton>
            </RadioButtonField>
          </FieldContainer>
        )}
        {hasWonOffer === false && (
          <>
            <Prompt style={s('mb-8')}>
              We&apos;re sorry. It&apos;s not you, it&apos;s them. Let&apos;s search for
              a new one..
            </Prompt>

            <DeclinePropertyBtn onClick={onDeclineBtnClick}>Submit</DeclinePropertyBtn>
          </>
        )}

        {isIntendedToSubmit && (
          <SubmitPropertyBtn
            style={s('mb-4')}
            onClick={onSubmitBtnClick}
            loading={isSubmittingProperty}
          >
            Submit property
          </SubmitPropertyBtn>
        )}

        {showSearchPropertyBtn && (
          <SearchMorePropertyBtn style={s('mb-4')} onClick={onSearchPropertyBtnClicked}>
            Search properties
          </SearchMorePropertyBtn>
        )}
      </div>
    </Container>
  )
}

export { SubmitPropertyQuestions }
