import { path, prop } from '@neo/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'

const selectSearchActivityLogsStatus = path([
  'asyncState',
  'activityLog/searchActivityLogs',
  'status',
])

const selectActivityLogsState = prop('properties')

const selectInspectedActivityLogId = createSelector(
  selectActivityLogsState,
  prop('inspectedActivityLogId')
)

const selectTotal = createSelector(selectActivityLogsState, prop('total'))

export { selectSearchActivityLogsStatus, selectInspectedActivityLogId, selectTotal }
