import React, { useState } from 'react'
import mockProperty from './mockdata'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PropertyInfo, PropertyRejectedModal } from 'features/common/property'
import { Button as ButtonBase } from '@vega/components'
import { Header } from '../Header'
import { approveProperty, selectApprovePropertyStatus } from 'modules/property'
import { setInspectedApplicationId } from 'modules/application'
import { styled, s } from '@vega/styled'
import { ASYNC_STATUSES } from '@vega/constants'

const { PENDING } = ASYNC_STATUSES

const Container = styled.div(s('flex flex-column items-center w-full h-full'))

const Title = styled.h2(s('m-0 text-grey-800 font-semibold text-xl self-start mb-8'))
const Prompt = styled.p(s('m-0 text-grey-800 font-semibold mb-6'))

const PropertyContainer = styled.div(
  s('flex flex-column items-center w-full px-6 py-10', {
    maxWidth: 1600,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const PropertyImage = styled.img(
  s('w-full object-cover', { borderRadius: '20px 0 20px 20px', maxHeight: 500 })
)

const DetailsContainer = styled.div(s('flex justify-between pt-8 w-full pr-24'))
const InfoContainer = styled.div(s('pr-4', { flexBasis: '70%' }))
const OperationContainer = styled.div(s('flex-1 mr-a', { maxWidth: 300 }))
const Button = styled(ButtonBase)(s('w-full border-grey-300 mb-3'))

function PropertyAssessment() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const property = mockProperty

  const isApprovingProperty = useSelector(selectApprovePropertyStatus) === PENDING

  const [isRejectedModalOpen, setIsRejectedModalOpen] = useState(false)

  const onPropertyApproveBtnClicked = () => {
    dispatch(setInspectedApplicationId(id))
    dispatch(approveProperty({}))
  }
  const onPropertyRejectBtnClicked = () => {
    dispatch(setInspectedApplicationId(id))
    setIsRejectedModalOpen(true)
  }

  return (
    <Container>
      <Header names={'abc'} inspectedApplicationId={id} />

      <PropertyContainer>
        <Title>Submitted property</Title>
        <PropertyImage src={property?.imageSrc} />

        <DetailsContainer>
          <InfoContainer>
            <PropertyInfo property={property} />
          </InfoContainer>

          <OperationContainer>
            <Prompt>Do you approve this property?</Prompt>
            <Button
              variant="outlined"
              onClick={onPropertyApproveBtnClicked}
              loading={isApprovingProperty}
              style={s('mb-4')}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              onClick={onPropertyRejectBtnClicked}
              style={s('mb-8')}
            >
              Reject
            </Button>

            <Prompt>Review the full property report?</Prompt>
            <Button variant="outlined">Review now</Button>
          </OperationContainer>
        </DetailsContainer>
      </PropertyContainer>

      <PropertyRejectedModal
        isOpen={isRejectedModalOpen}
        onClose={() => setIsRejectedModalOpen(false)}
      />
    </Container>
  )
}

export { PropertyAssessment }
