import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { WizardProvider } from './context'
import { SteppedForm } from './SteppedForm'
import {
  path,
  update,
  findIndex,
  isNil,
  isEmpty,
  isNotNil,
  omit,
  prop,
  propEq,
  map,
  find,
  pipe,
  filter,
  includes,
  __,
} from '@neo/ramda-extra'

function Wizard({
  isLoading,
  LoaderComponent = () => null,
  basePath,
  savedStepId,
  order = [],
  formatStepInUrl = (v) => v,
  children,
  ...props
}) {
  const history = useHistory()
  const routeMatch = useRouteMatch(`${ensureTrailingSlash(basePath)}:stepId`)

  const [submitContext, setSubmitContext] = useState({})
  const [steps, setSteps] = useState([])
  const [hasInitialized, setHasInitialized] = useState(false)

  const [currentStepIndex, setCurrentStepIndex] = useState(-1)
  const currentStep = currentStepIndex !== -1 ? steps[currentStepIndex] : undefined

  useEffect(() => {
    const noStepsAreRegistered = isEmpty(steps)
    const currentStepAlreadySet = isNotNil(currentStep)

    if (isLoading || hasInitialized || noStepsAreRegistered) {
      return
    }

    if (currentStepAlreadySet) setHasInitialized(true)

    const savedStepIndex = findIndex((step) => step.id === savedStepId, steps)
    setCurrentStepIndex(savedStepIndex)
  }, [savedStepId, steps, currentStepIndex, isLoading, currentStep, hasInitialized])

  useCurrentStepFromPath({ isLoading, currentStep, steps, routeMatch })
  useSyncedHistory({ basePath, currentStepIndex, history, steps })

  function useCurrentStepFromPath({ isLoading, currentStep, steps, routeMatch }) {
    useEffect(() => {
      ;(() => {
        // The wizard doesn't render steps until it is loaded. Running this effect
        // before loading is complete will cause the wizard to lose the step that the
        // user navigated to before loading, so the fix is to simply hold off on
        // grabbing the current step until loading is complete.
        if (isLoading) {
          return
        }

        const noStepsAreRegistered = isEmpty(steps)
        const currentStepAlreadySet = isNotNil(currentStep)

        if (noStepsAreRegistered || currentStepAlreadySet) {
          return
        }

        const stepId = path(['params', 'stepId'], routeMatch)
        const currentStepIndexFromPath = findIndex((step) => step.id === stepId, steps)
        const matchingStepNotFound = currentStepIndexFromPath === -1

        if (matchingStepNotFound) {
          setCurrentStepIndex(0)
          return
        }

        setCurrentStepIndex(currentStepIndexFromPath)
      })()
    }, [isLoading, currentStep, steps, routeMatch])
  }

  function useSyncedHistory({ basePath, currentStepIndex, history, steps }) {
    useLayoutEffect(() => {
      const currentStepAlreadySet = currentStepIndex !== -1
      if (currentStepAlreadySet) {
        const { id } = steps[currentStepIndex]
        const displayedStep = formatStepInUrl(id)
        history.push(`${ensureTrailingSlash(basePath)}${displayedStep}`)
      }
    }, [basePath, currentStepIndex, history, steps])
  }

  function next() {
    if (isNil(currentStep)) {
      return
    }

    const currentStepIndex = findIndex((step) => step.id === currentStep.id, steps)
    const nextStepIndex =
      currentStepIndex !== steps.length - 1 ? currentStepIndex + 1 : currentStepIndex

    setCurrentStepIndex(nextStepIndex)
  }

  function previous() {
    if (isNil(currentStep)) {
      return
    }

    const currentStepIndex = findIndex((step) => step.id === currentStep.id, steps)
    const previousStepIndex =
      currentStepIndex !== 0 ? currentStepIndex - 1 : currentStepIndex

    setCurrentStepIndex(previousStepIndex)
  }

  function go(id) {
    const gotoStepIndex = findIndex((step) => step.id === id, steps)

    setCurrentStepIndex(gotoStepIndex)
  }

  const register = useCallback(
    (step) => {
      setSteps((prevSteps) => {
        const includedInOrder = includes(__, order)
        const allowedSteps = filter(pipe(prop('id'), includedInOrder))(prevSteps)

        const existingStepIndex = findIndex(propEq('id', step.id))(allowedSteps)

        if (existingStepIndex !== -1) {
          return update(existingStepIndex, step, allowedSteps)
        }

        const newSteps = [step, ...allowedSteps]

        const findMatchingStepIn = (steps) => (orderedStepId) =>
          find(propEq('id', orderedStepId))(steps)
        const orderedSteps = pipe(
          map(findMatchingStepIn(newSteps)),
          filter(isNotNil)
        )(order)

        return orderedSteps
      })
    },
    [order]
  )

  return (
    <WizardProvider
      value={{
        steps,
        currentStep,
        currentStepIndex,
        submitContext,
        setSubmitContext,
        next,
        previous,
        go,
        register,
        hasInitialized,
      }}
    >
      <SteppedForm {...omit(['render', 'validate', 'validationSchema'], props)}>
        {isLoading ? <LoaderComponent /> : children}
      </SteppedForm>
    </WizardProvider>
  )
}

function ensureTrailingSlash(value) {
  if (value.charAt(value.length - 1) === '/') {
    return value
  }

  return `${value}/`
}

export { Wizard }
