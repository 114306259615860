import React from 'react'

import { YourIncomeAndPropertiesStep } from './YourIncomeAndPropertiesStep'
import { YourExpensesAndLiabilitiesStep } from './YourExpensesAndLiabilitiesStep'
import { YourFillOptionsStep } from './YourFillOptionsStep'
import { CreditReportStep } from './CreditReportStep'
import {
  makeCreditReportStepId,
  makeExpensesAndLiabilitiesStepId,
  makeFillMethodsStepId,
  makeIncomeAndAssetsStepId,
} from '../utils'

function YourFinancialsSteps({ sharedProps, loanApplicationId }) {
  return (
    <>
      <CreditReportStep
        {...sharedProps}
        stepId={makeCreditReportStepId(0)}
        loanApplicationId={loanApplicationId}
      />
      <YourFillOptionsStep
        {...sharedProps}
        stepId={makeFillMethodsStepId(0)}
        loanApplicationId={loanApplicationId}
      />
      <YourIncomeAndPropertiesStep
        {...sharedProps}
        stepId={makeIncomeAndAssetsStepId(0)}
        loanApplicationId={loanApplicationId}
      />
      <YourExpensesAndLiabilitiesStep
        {...sharedProps}
        stepId={makeExpensesAndLiabilitiesStepId(0)}
        loanApplicationId={loanApplicationId}
      />
    </>
  )
}

export { YourFinancialsSteps }
