import { prop, path } from '@neo/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { brokersAdapter } from './module'

const selectFetchBrokersStatus = path(['asyncState', 'brokers/fetchBrokers'])

const selectBrokers = prop('brokers')

const selectPagingTotal = createSelector(selectBrokers, path(['paging', 'total']))

const selectLimit = createSelector(selectBrokers, prop('limit'))

const selectFilters = createSelector(selectBrokers, prop('filters'))

const selectIsFetchBrokersLoading = createSelector(
  selectFetchBrokersStatus,
  (status) => status === 'pending'
)

const {
  selectEntities: selectBrokerEntities,
  selectAll: selectBrokerList,
} = brokersAdapter.getSelectors(prop('brokers'))

export {
  selectFilters,
  selectBrokerEntities,
  selectBrokerList,
  selectPagingTotal,
  selectLimit,
  selectIsFetchBrokersLoading,
}
