import { styled, s } from '@vega/styled'

const Title = styled.h1(
  s('text-grey-700 font-normal m-0', {
    fontSize: 32,
    lineHeight: 1.5,
  })
)

const SectionTitle = styled.h2(
  s('text-grey-700 font-semibold text-2xl m-0', {
    lineHeight: 1.5,
    letterSpacing: '0.015em',
  })
)

const Subtitle = styled.h3(
  s('text-grey-600 font-normal text-base m-0', {
    lineHeight: 1.5,
    letterSpacing: '0.015em',
  })
)

const Prompt = styled.p(
  s('text-grey-700 font-semibold text-base m-0', {
    lineHeight: 1.5,
    letterSpacing: '0.015em',
  })
)

export { Title, SectionTitle, Subtitle, Prompt }
