import React from 'react'
import { DateField } from '@vega/components'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@vega/constants'

const Row = styled.div(s('flex justify-between'))

function EmploymentPeriod({ employmentPath, inRole, idx }) {
  return (
    <Row>
      <DateField
        autoOk
        name={`${employmentPath}.startDate`}
        label="Start date"
        aria-label="startDate"
        containerProps={{ style: s('flex-1 mr-3') }}
        testId={`${TEST_IDS.EMPLOYMENT_START_INPUT}${idx + 1}`}
        isReadOnly
      />
      {inRole ? (
        <DateField
          name={`endDate`}
          label="End date"
          placeholder="Current"
          aria-label="endDate"
          containerProps={{ style: s('flex-1 ml-3') }}
          isReadOnly
        />
      ) : (
        <DateField
          autoOk
          name={`${employmentPath}.endDate`}
          label="End date"
          aria-label="endDate"
          containerProps={{ style: s('flex-1 ml-3') }}
          testId={`${TEST_IDS.EMPLOYMENT_END_INPUT}${idx + 1}`}
          isReadOnly
        />
      )}
    </Row>
  )
}

export { EmploymentPeriod }
