import React from 'react'
import { styled, s } from '@vega/styled'
import { Modal } from '@vega/components'
import { ReactComponent as X } from '@vega/components/src/assets/images/x.svg'
import { ReactComponent as User } from '@vega/components/src/assets/images/user.svg'
import { ReactComponent as UserGroup } from '@vega/components/src/assets/images/user-group.svg'

const Container = styled.div(
  s('bg-white rounded-lg py-10 px-8', {
    paddingLeft: 52,
    paddingRight: 52,
    width: 626,
  })
)
const TopContainer = styled.div(s('flex justify-between mb-8'))
const Title = styled.h1(s('m-0 text-xl text-grey-800'))
const CardsContainer = styled.div(s('flex justify-between'))
const Card = styled.div(
  s('flex flex-column justify-center items-center'),
  s('border-1 border-solid border-grey-200 rounded-l-lg rounded-br-lg', {
    height: 172,
    width: 250,
    cursor: 'pointer',
    ':hover': s('border-primary'),
  })
)
const CardText = styled.p(
  s('m-0 text-sm font-medium text-grey-700', {
    lineHeight: 1.3,
    letterSpacing: '0.03em',
  })
)

const InviteApplicant = ({ isOpen, setIsOpen }) => {
  const onClose = () => {
    setIsOpen(false)
  }

  const inviteSingleApplicant = () => 'INVITE SINGLE APPLICANT'

  const inviteTrust = () => 'INVITE TRUSTEE / BUSINESS PARTNER'

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <TopContainer>
          <Title>Select applicant type</Title>
          <X style={{ cursor: 'pointer' }} onClick={onClose} />
        </TopContainer>

        <CardsContainer>
          <Card onClick={inviteSingleApplicant}>
            <User style={s('mb-4 text-grey-700', { width: 25 })} />
            <CardText>Single applicant</CardText>
          </Card>

          <Card onClick={inviteTrust}>
            <UserGroup style={s('mb-4 text-grey-700', { width: 25 })} />
            <CardText>Trustee or business partner</CardText>
          </Card>
        </CardsContainer>
      </Container>
    </Modal>
  )
}

export { InviteApplicant }
