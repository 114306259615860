import React from 'react'
import { ExpenseRow } from './ExpenseRow'
import { sum } from '@neo/ramda-extra'
import { TotalExpenseText } from './TotalExpenseText'
import { styled, s } from '@vega/styled'

const Title = styled.h2(s('font-medium text-grey-600 text-lg'))

function TotalExpense({
  totalHousingExpenses,
  totalPersonalExpenses,
  totalOtherExpenses,
  parentPath,
}) {
  const totalExpense = sum([
    totalHousingExpenses,
    totalPersonalExpenses,
    totalOtherExpenses,
  ])

  return (
    <div>
      <Title>Total expense amount</Title>

      <TotalExpenseText
        name={`${parentPath}.expenses.totalExpense.total`}
        value={totalExpense}
      />

      <ExpenseRow type="Housing" value={totalHousingExpenses} style={s('mb-6')} />
      <ExpenseRow type="Personal" value={totalPersonalExpenses} style={s('mb-6')} />
      <ExpenseRow type="Other" value={totalOtherExpenses} />
    </div>
  )
}

export { TotalExpense }
