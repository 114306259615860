import React from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as Check } from '@vega/components/src/assets/images/check.svg'
import { ITEM_STATUSES } from './constants'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'

const { VERIFIED, FAILED } = ITEM_STATUSES

const textStyleByStatus = {
  default: s('text-grey-400'),
  verified: s('text-green-600'),
  failed: s('text-error-600'),
}

const circleStyleByStatus = {
  default: s('border-grey-400'),
  verified: s('border-green-600'),
  failed: s('border-error-400'),
}

const getStyleByStatus = (status, styleByStatus) => styleByStatus[status]

const Container = styled.div(
  s('flex text-sm text-grey-700 items-center', { lineHeight: 1.3 }),
  ({ status }) => getStyleByStatus(status, textStyleByStatus)
)
const Circle = styled.div(
  s('flex border-1 border-solid mr-2 rounded-full justify-center items-center', {
    height: 20,
    width: 20,
  }),
  ({ status }) => getStyleByStatus(status, circleStyleByStatus)
)
const X = styled(XBase)({
  '& path': { stroke: s('text-error-400').color },
})

const ProgressItemStatus = ({ status, styleByStatus, children, ...otherProps }) => {
  return (
    <Container status={status} {...otherProps}>
      <Circle status={status}>
        {status === VERIFIED && <Check style={s('text-green-600 w-1')} />}
        {status === FAILED && <X />}
      </Circle>
      {children}
    </Container>
  )
}

export { ProgressItemStatus }
