import React, { useEffect } from 'react'
import { styled, s } from '@vega/styled'
import { useFormikContext } from 'formik'
import { isNilOrEmpty } from '@neo/ramda-extra'
import { USER_ROLES } from '@vega/constants'
import { FormInput, FormSelect } from '@vega/components'

import { useSelector } from 'react-redux'
import { selectCreateInvitationError } from 'modules/invitation'

const EmailInput = styled(FormInput)(s('mb-8'))

const roleOptions = [
  {
    label: 'Broker',
    value: USER_ROLES.BROKER,
  },
  {
    label: 'Credit assessor',
    value: USER_ROLES.CREDIT_ASSESSOR,
  },
  {
    label: 'Banking Admin',
    value: USER_ROLES.BANKING_ADMIN,
  },
  {
    label: 'Treasury Admin',
    value: USER_ROLES.TREASURY_ADMIN,
  },
]

function EmailField() {
  const { setFieldError } = useFormikContext()
  const createInvitationError = useSelector(selectCreateInvitationError)

  useEffect(() => {
    if (isNilOrEmpty(createInvitationError)) return
    const { type, message } = createInvitationError
    setFieldError(type, message)
  }, [createInvitationError, setFieldError])

  return (
    <EmailInput label="Email" name="email" baseContainerStyle={s('pr-0')}>
      <FormSelect
        name="role"
        options={roleOptions}
        containerProps={{ style: s('h-full') }}
      />
    </EmailInput>
  )
}

export { EmailField }
