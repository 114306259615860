import React, { forwardRef } from 'react'

import { useTooltip } from '@react-aria/tooltip'
import { mergeProps } from '@react-aria/utils'

import { ReactComponent as BaseChatAltIcon } from '@vega/components/src/assets/images/chat-alt.svg'
import { s, styled } from '@vega/styled'

import { NotesContainer } from './NotesContainer'

const CollapsedNote = styled.div(s('p-2 flex items-center'))

const ChatAltIcon = styled(BaseChatAltIcon)(s('text-green-600 w-1 h-1'))
const NoteCount = styled.div(s('ml-1 text-sm font-medium'))

// eslint-disable-next-line react/display-name
export const NoteTooltip = forwardRef(
  ({ notes, onClick, state, tooltipProps }, ref) => {
    const { tooltipProps: useTooltipProps } = useTooltip(tooltipProps, state)

    const containerProps = mergeProps(tooltipProps, useTooltipProps)

    return (
      <NotesContainer ref={ref} expanded={false} onClick={onClick} {...containerProps}>
        <CollapsedNote>
          <ChatAltIcon /> {notes.length > 0 && <NoteCount>{notes.length}</NoteCount>}
        </CollapsedNote>
      </NotesContainer>
    )
  }
)
