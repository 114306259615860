import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { routes } from 'routes'

import { ApplicationsList } from './ApplicationsList'
import { CreateApplicationWizard } from './CreateApplicationWizard'
import { ApplicationOverviewRouter } from './applicationOverview/index'
import { StatusPreview } from './components/StatusPreview'

function ApplicationsPage() {
  return (
    <Switch>
      <Route
        path={routes.broker.applications.overview.entry}
        component={ApplicationOverviewRouter}
      />

      <Route
        exact
        path={routes.broker.applications.list}
        component={ApplicationsList}
      />

      <Route
        exact
        path={`${routes.broker.applications.create}/:id/status-preview`}
        component={StatusPreview}
      />

      <Route
        path={`${routes.broker.applications.create}/:id`}
        component={CreateApplicationWizard}
      />

      <Redirect to={routes.broker.applications.list} />
    </Switch>
  )
}

export { ApplicationsPage }
