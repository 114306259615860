import React from 'react'
import { styled, s } from '@vega/styled'

const Container = styled.div(s('w-24'))

const Title = styled.span(s('block text-grey-800 font-semibold text-xl'))

const Dialog = styled.div(
  s('rounded-md p-6', { backgroundColor: '#E0F2FE', border: '1px solid #0284C7' })
)
const Subtitle = styled.span(s('text-grey-800 font-semibold'))
const Text = styled.p(s('text-grey-600 text-sm'))

function AssessingLoanDialog() {
  return (
    <Container>
      <Title style={s('mb-4')}>Assessing Loan</Title>

      <Dialog>
        <Subtitle>In progress</Subtitle>
        <Text>Credit assessor is assessing this application...</Text>
        <Text>
          We will notify you about the outcome of this mortgage application when
          it&apos;s ready.
        </Text>
      </Dialog>
    </Container>
  )
}

export { AssessingLoanDialog }
