import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { styled, s } from '@vega/styled'

import { ClientNavigationDrawer } from './clientNavigationDrawer'
import { AppSwitcher } from 'features/common/appSwitcher'
import { Overview as ClientOverview } from 'features/client/overview'
import { ApplicationsPage } from 'features/client/applications'
import { AccountSettings } from 'features/common/accountSettings'

const Root = styled.div(s('flex h-full min-h-32 bg-green-50'))

const prefixRoute = '/client'

const ClientRouter = () => {
  return (
    <Root>
      <ClientNavigationDrawer />

      <AppSwitcher />

      <Switch>
        <Route exact path={`${prefixRoute}/overview`} component={ClientOverview} />
        <Route path={`${prefixRoute}/applications`} component={ApplicationsPage} />
        <Route exact path={`${prefixRoute}/account`} component={AccountSettings} />
        <Redirect to={`${prefixRoute}/overview`} />
      </Switch>
    </Root>
  )
}

export { ClientRouter }
