import { path, prop } from '@neo/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'

const selectGetPropertyDetailsStatus = path([
  'asyncState',
  'property/getPropertyDetails',
  'status',
])

const selectAddPropertyToWatchlistStatus = path([
  'asyncState',
  'property/addPropertyToWatchlist',
  'status',
])

const selectGetWatchlistedPropertiesStatus = path([
  'asyncState',
  'property/getWatchlistedProperties',
  'status',
])

const selectSubmitPropertyStatus = path([
  'asyncState',
  'property/submitProperty',
  'status',
])

const selectApprovePropertyStatus = path([
  'asyncState',
  'property/approveProperty',
  'status',
])

const selectRejectPropertyStatus = path([
  'asyncState',
  'property/rejectProperty',
  'status',
])

const selectPropertiesState = prop('properties')

const selectInspectedPropertyId = createSelector(
  selectPropertiesState,
  prop('inspectedPropertyId')
)

const selectSearchedProperty = createSelector(
  selectPropertiesState,
  prop('searchedProperty')
)

const selectTotal = createSelector(selectPropertiesState, prop('total'))

export {
  selectGetWatchlistedPropertiesStatus,
  selectAddPropertyToWatchlistStatus,
  selectGetPropertyDetailsStatus,
  selectSubmitPropertyStatus,
  selectApprovePropertyStatus,
  selectRejectPropertyStatus,
  selectSearchedProperty,
  selectInspectedPropertyId,
  selectTotal,
}
