const PROPERTY = {
  OWNERSHIP_TYPE: {
    CLIENT: 'client_only',
    CLIENT_AND_PARTNER: 'client_and_partner',
    CLIENT_AND_OTHER: 'client_and_other',
  },
  REPAYMENT_TYPE: {
    INTEREST_ONLY: 'interest_only',
    INTEREST_AND_PRINCIPAL: 'interest_and_principal',
    LINE_OF_CREDIT: 'line_of_credit',
    NONE: 'none',
  },
}

export default PROPERTY
