import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { noop } from '@neo/ramda-extra'
import { useHistory } from 'react-router-dom'
import { Formik as FormProvider, Form } from 'formik'
import { ClientInvitationModal } from 'features/broker/clients/components'
import { USER_ROLES, TEST_IDS } from '@vega/constants'

import { usePagedSearch } from 'hooks'
import {
  InviteButton,
  SearchBar,
  Table as TableBase,
  ViewSwitcher,
} from '@vega/components'
import { styled, s } from '@vega/styled'
import Total from './Total'

import { Columns, addRowActions } from './TableFormat'
import {
  fetchClients,
  setInspectedClientId,
  selectClientList,
  selectTotalResults,
  blockAccess,
} from 'modules/client'

const Root = styled.div(s('flex-1', { overflow: 'auto' }))

const Container = styled.div(s('flex flex-column w-full max-h-full bg-white h-full'))

const SearchForm = styled(Form)(s('mr-5', { flex: 2 }))

const UpperContainer = styled.div(s('flex justify-between m-4'))

const Table = styled(TableBase)(s('mt-0 mb-0'))

function ClientManagement() {
  const history = useHistory()
  const dispatch = useDispatch()

  const [isModalOpened, setIsModalOpened] = useState(false)

  const fetchData = (searchParams) => dispatch(fetchClients(searchParams))
  const total = useSelector(selectTotalResults)

  const {
    setters: { updateParams },
    paginationProps,
  } = usePagedSearch(fetchData, {
    total,
    searchTerm: '',
    pageIndex: 0,
    filters: {
      role: USER_ROLES.CLIENT,
    },
  })
  const rows = useSelector(selectClientList)

  const onTextSearch = ({ searchTerm }) => {
    updateParams({
      searchTerm,
      pageIndex: 0,
    })
  }

  const [selectedView, selectView] = useState('list')
  const switchToView = useCallback((view) => selectView(view), [])

  const removeAccess = (id) => () => {
    dispatch(blockAccess({ id }))
  }
  const actions = [
    {
      name: 'Block access',
      onClick: (id) => removeAccess(id),
    },
    {
      name: 'Cancel',
      onClick: noop,
    },
  ]
  const data = React.useMemo(
    () =>
      addRowActions({
        rows,
        actions,
      }),
    [rows, actions]
  )
  const columns = Columns()

  const onRowClick = (id) => {
    dispatch(setInspectedClientId(id))
    history.push(`profile/${id}`)
  }

  return (
    <Root>
      <Total total={total} />
      <Container>
        <UpperContainer>
          <FormProvider
            enableReinitialize={false}
            initialValues={{ searchTerm: '' }}
            onSubmit={onTextSearch}
          >
            {({ values, handleChange }) => {
              return (
                <SearchForm>
                  <SearchBar
                    data-test-id={TEST_IDS.BROKER_CLIENTS_SEARCH_INPUT}
                    placeholder={'Search client name or anything'}
                    onInputChange={handleChange}
                    value={values.searchTerm}
                    name="searchTerm"
                    id="searchTerm"
                  />
                </SearchForm>
              )
            }}
          </FormProvider>
          <ViewSwitcher
            switchToView={switchToView}
            selectedView={selectedView}
            containerStyle={s('mr-5')}
          />
          <InviteButton
            data-test-id={TEST_IDS.BROKER_CLIENTS_INVITE_BUTTON}
            onClick={() => setIsModalOpened(true)}
            invitee="client"
            style={s('text-base font-semibold pb-3')}
          />
        </UpperContainer>
        {selectedView === 'list' ? (
          <Table
            testId={TEST_IDS.BROKER_CLIENTS_LIST_TABLE}
            data={data}
            columns={columns}
            onClick={onRowClick}
            paginationProps={paginationProps}
            tBodyStyle={s('text-left')}
          />
        ) : (
          <div>Grid View Component</div>
        )}
      </Container>

      <ClientInvitationModal isOpen={isModalOpened} setIsOpen={setIsModalOpened} />
    </Root>
  )
}

export default ClientManagement
