export const EMPLOYMENT = {
  ROLES: [
    'agricultural_manager',
    'arts_or_media_professional',
    'admin_worker',
    'community_worker',
    'construction_manager',
    'designer_engineer_scientist',
    'health_or_welfare_worker',
    'finance_or_business_professional',
    'finance_business_marketing_or_technology_manager',
    'hospital_worker',
    'labourer',
    'legal_professional',
    'machinery_operator',
    'marketing_or_communication_professional',
    'other',
    'professor_teacher_education_worker',
    'real_estate_or_insurance_agent',
    'retail_or_hospitality_manager',
    'sales_representative',
    'sports_professional',
    'technology_professional',
    'trades_person',
  ],
  RECURRENCE: ['weekly', 'fortnightly', 'monthly', 'irregular'],
  NATURE: [
    'salary',
    'self_employed',
    'student',
    'retired',
    'unemployed',
    'home_duties',
  ],
  BASIS: ['full_time', 'part_time', 'contract', 'casual', 'temporary'],
}

export const INCOME = {
  RECURRENCE: ['monthly', 'quarterly', 'half_yearly', 'annually'],
}
