import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext } from 'formik'
import { getClientsCountReport, getTopClients } from 'modules/report'
import { calculatePrevMonth } from '../utils/calculatePrevMonth'
import { ComparisonSection } from './ComparisonSection'
import { TopClientsBarChart } from 'features/common/overview/TopClientsBarChart'
import { RecentCommunicationTable } from 'features/common/overview/RecentCommunicationTable'
import { s, styled } from '@vega/styled'

const Container = styled.div(
  s('flex-1 flex flex-column items-center', {
    padding: 28,
    overflowY: 'auto',
    height: 'calc(100vh - 163px)',
  })
)

const BottomContainer = styled.div(
  s('flex justify-between items-start w-full', {
    maxWidth: 1180,
    '@media (max-width: 1060px)': s('flex-column', {}),
  })
)

function ClientOverview() {
  const dispatch = useDispatch()
  const { values: formikValues } = useFormikContext()
  const { year, month, topClientCriterion } = formikValues
  const { prevYear, prevMonth } = calculatePrevMonth(year, month)

  React.useEffect(() => {
    dispatch(getClientsCountReport({ year, month }))
    dispatch(getClientsCountReport({ year: prevYear || year, month: prevMonth }))
  }, [dispatch, month, prevMonth, prevYear, year])

  React.useEffect(() => {
    dispatch(
      getTopClients({
        year,
        month,
        amountOfResult: 5,
        criterion: topClientCriterion,
      })
    )
  }, [dispatch, month, topClientCriterion, year])

  return (
    <Container>
      <ComparisonSection dateComparedWith={{ prevYear, prevMonth }} style={s('mb-8')} />

      <BottomContainer>
        <TopClientsBarChart style={s('mr-6')} />
        <RecentCommunicationTable />
      </BottomContainer>
    </Container>
  )
}

export { ClientOverview }
