/* eslint-disable react/no-multi-comp */
/* eslint-disable complexity */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { routes } from 'routes'

import { styled, s } from '@vega/styled'
import {
  PageHeader,
  Button,
  ViewSwitcher,
  Table,
  LoaderWithLabel,
} from '@vega/components'
import {
  createLoanApplication,
  getLoanApplications,
  selectApplicationId,
  selectCreateApplicationStatus,
  selectGetApplicationsStatus,
  selectLoanApplications,
  selectTotal,
} from 'modules/application'
import { selectProfileRoles } from '@vega/redux.profile'
import { ReactComponent as PlusIcon } from '@vega/components/src/assets/images/plus.svg'
import { ReactComponent as EmptyApplicationsImage } from '@vega/components/src/assets/images/broker-empty-applications.svg'
import { ASYNC_STATUSES, TEST_IDS, USER_ROLES } from '@vega/constants'
import {
  isNil,
  includes,
  values,
  pipe,
  propEq,
  prop,
  find,
  unionWith,
  eqBy,
  isEmpty,
  replace,
} from '@neo/ramda-extra'
import {
  Total,
  FormikSearchBar,
  GridView,
  TopTabFilter,
} from 'features/common/applications'
import { Rows, Columns } from './components'
import { usePagedSearch } from 'hooks'
import * as VEGA_CONSTANTS from '@vega/constants'

const {
  LOAN_APPLICATION: { STAGES },
} = VEGA_CONSTANTS

const Root = styled.div(
  s('flex flex-column w-full min-h-32 bg-green-50', {
    overflow: 'hidden',
  })
)

const ViewContainer = styled.div({
  height: 'calc(100% - 90px)',
})

const Container = styled.div(s('flex flex-column bg-white h-full'))

const TextSearch = styled(FormikSearchBar)(s('mr-5', { flex: 2 }))

const UpperContainer = styled.div(s('flex m-6'))

const EmptyViewContainer = styled.div(s('flex flex-column items-center mt-16'))

const EmptyApplicationsLabel = styled.div(s('text-3xl text-green-700 mt-4'))

const EmptyApplicationsCaption = styled.p(s('text-xl font-normal text-grey-600 mt-2'))

const { PENDING, FULFILLED } = ASYNC_STATUSES
const { BROKER } = USER_ROLES

function ApplicationsList() {
  const dispatch = useDispatch()
  const history = useHistory()

  const roles = useSelector(selectProfileRoles)
  const includesBroker = includes(BROKER, roles)

  const total = useSelector(selectTotal)
  const applications = useSelector(selectLoanApplications)
  const isFetchingApps = useSelector(selectGetApplicationsStatus) === PENDING

  const tabulatedApplications = Rows(applications)
  const columns = Columns()

  const GRID_VIEW_SEARCH_LIMIT = 9
  const LIST_VIEW_SEARCH_LIMIT = 20
  const GRID_VIEW = 'grid'
  const LIST_VIEW = 'list'

  const [selectedView, selectView] = useState(LIST_VIEW)
  const [displayedAppsForGrid, setDisplayedAppsForGrid] = useState(applications)

  const [justSwitchedView, setJustSwitchedView] = useState(false)
  useEffect(() => {
    setJustSwitchedView(false)
  }, [applications])

  const fetchData = (searchParams) => dispatch(getLoanApplications(searchParams))
  const {
    setters: { updateParams },
    paginationProps,
  } = usePagedSearch(fetchData, {
    total,
    pageIndex: 0,
    searchTerm: '',
    limit: LIST_VIEW_SEARCH_LIMIT,
    filters: {
      stage: values(STAGES),
    },
  })

  const onTextSearch = ({ searchTerm }) => {
    setDisplayedAppsForGrid([])
    updateParams({
      searchTerm,
      pageIndex: 0,
    })
  }

  const STATUSES = {
    ALL: 'all',
    IN_PROGRESS: 'in_progress',
    APPROVED: 'approved',
    FAILED: 'failed',
  }
  const statusFilters = [
    {
      value: STATUSES.ALL,
      label: 'All',
      stages: values(STAGES),
    },
    {
      value: STATUSES.IN_PROGRESS,
      label: 'In Progress',
      stages: [STAGES.DRAFT, STAGES.SUBMITTED, STAGES.PRE_APPROVED],
    },
    {
      value: STATUSES.APPROVED,
      label: 'Approved',
      stages: [STAGES.APPROVED],
    },
    {
      value: STATUSES.FAILED,
      label: 'Failed',
      stages: ['failed'],
    },
  ]
  const [statusFilter, setStatusFilter] = useState(STATUSES.ALL)
  const onStageFilterSelected = (status) => {
    setStatusFilter(status)

    const stage = pipe(find(propEq('value', status)), prop('stages'))(statusFilters)

    updateParams({
      pageIndex: 0,
      filters: {
        stage,
      },
    })
  }

  const switchToView = React.useCallback(
    (view) => {
      selectView(view)
      setJustSwitchedView(true)
      updateParams({
        pageIndex: 0,
        limit: view === LIST_VIEW ? LIST_VIEW_SEARCH_LIMIT : GRID_VIEW_SEARCH_LIMIT,
      })
    },
    [updateParams]
  )

  useEffect(() => {
    ensureResetAndContinuousPagingForGridView()

    function ensureResetAndContinuousPagingForGridView() {
      if (selectedView !== GRID_VIEW) setDisplayedAppsForGrid([])
      if (justSwitchedView) setDisplayedAppsForGrid(applications)
      setDisplayedAppsForGrid((prevApps) =>
        unionWith(eqBy(prop('id')), prevApps, applications)
      )
    }
  }, [applications, justSwitchedView, selectedView])

  const goToApplicationDetails = (id) => {
    history.push(replace(':id', id, routes.broker.applications.overview.details))
  }
  const showFullScreenLoader =
    isFetchingApps &&
    selectedView === GRID_VIEW &&
    (justSwitchedView || isEmpty(displayedAppsForGrid))
  const showEmptyPlaceholder = applications.length === 0 && !isFetchingApps

  const renderView = () => {
    if (showFullScreenLoader)
      return (
        <LoaderWithLabel
          style={{ marginTop: 220 }}
          labelStyle={s('mt-6 text-base')}
          label="Fetching applications"
        />
      )

    if (showEmptyPlaceholder) {
      return (
        <EmptyViewContainer>
          <EmptyApplicationsImage />
          <EmptyApplicationsLabel>No Application</EmptyApplicationsLabel>
          <EmptyApplicationsCaption>
            Create your first application with that Create an app button
          </EmptyApplicationsCaption>
        </EmptyViewContainer>
      )
    }

    if (selectedView === LIST_VIEW)
      return (
        <Table
          data={tabulatedApplications}
          columns={columns}
          onClick={goToApplicationDetails}
          paginationProps={paginationProps}
          tBodyStyle={s('text-left')}
          style={s('ml-6 mt-0')}
          loading={isFetchingApps}
        />
      )

    return (
      <GridView
        viewerRole={BROKER}
        nextPage={paginationProps.nextPage}
        canNextPage={paginationProps.canNextPage}
        applications={displayedAppsForGrid}
        onCardClicked={goToApplicationDetails}
        loading={isFetchingApps}
      />
    )
  }

  const loanApplicationId = useSelector(selectApplicationId)
  const createApplicationStatus = useSelector(selectCreateApplicationStatus)
  React.useEffect(() => {
    if (isNil(loanApplicationId)) return
    if (createApplicationStatus === FULFILLED)
      history.push(`${routes.broker.applications.create}/${loanApplicationId}`)
  }, [createApplicationStatus, history, loanApplicationId])

  return (
    <Root>
      <PageHeader title="Applications" style={{ paddingLeft: 28 }} />
      <TopTabFilter
        filters={statusFilters}
        onFilterSelected={onStageFilterSelected}
        currentFilter={statusFilter}
        style={s('pl-3')}
      />
      <ViewContainer>
        <Total total={total} style={{ right: 24, top: 66 }} />
        <Container>
          <UpperContainer style={selectedView === LIST_VIEW ? s('mb-4') : s('mb-6')}>
            <TextSearch
              onSubmit={onTextSearch}
              placeholder={'Search applicant first name or last name'}
            />
            <ViewSwitcher
              switchToView={switchToView}
              selectedView={selectedView}
              containerStyle={s('mr-5')}
            />
            {includesBroker && (
              <Button
                style={s('p-0 h-3 justify-evenly', { width: 170 })}
                loading={createApplicationStatus === PENDING}
                onClick={() => dispatch(createLoanApplication())}
                StartIcon={<PlusIcon style={s('w-1 h-1 text-grey-700')} />}
                data-test-id={TEST_IDS.BROKER_NEW_APP_BUTTON}
              >
                <span style={s('font-semibold text-base text-grey-800 mr-1')}>
                  Create an app
                </span>
              </Button>
            )}
          </UpperContainer>
          {renderView()}
        </Container>
      </ViewContainer>
    </Root>
  )
}

export { ApplicationsList }
