import React from 'react'
import { s } from '@vega/styled'

import { NavigationDrawer, NavigationDrawerItem } from '@vega/components'
import { ReactComponent as HomeIcon } from '@vega/components/src/assets/images/home.svg'
import { ReactComponent as UserIcon } from '@vega/components/src/assets/images/user-circle.svg'
import { ReactComponent as UserManagementIcon } from '@vega/components/src/assets/images/userManagement.svg'

function AdminNavigationDrawer() {
  const prefixRoute = '/admin'

  return (
    <NavigationDrawer homeRoute={`${prefixRoute}/overview`}>
      <NavigationDrawerItem
        name="Overview"
        path={`${prefixRoute}/overview`}
        Icon={HomeIcon}
      />

      <NavigationDrawerItem
        name="User Management"
        path={`${prefixRoute}/user-management`}
        Icon={UserManagementIcon}
      />

      <NavigationDrawerItem
        name="Account"
        path={`${prefixRoute}/account`}
        Icon={UserIcon}
        style={s('mt-a mb-2')}
      />
    </NavigationDrawer>
  )
}

export { AdminNavigationDrawer }
