import { defaultTo, map, pipe, reject, isNilOrEmpty } from '@neo/ramda-extra'

export const mapLiabilities = ({ personalLoan, creditCard }) => {
  const mappedPersonalLoans = pipe(defaultTo([]), mapToLoanModel)(personalLoan)
  const mappedCreditCards = pipe(defaultTo([]), mapToCreditCardModel)(creditCard)
  const removeEmptyLiabilities = reject(isNilOrEmpty)

  return removeEmptyLiabilities([...mappedPersonalLoans, ...mappedCreditCards])
}

function mapToLoanModel({ isOwned, loans }) {
  const mappedPersonalLoans = map(
    (loan) => ({
      id: loan?.id,
      name: 'personalLoan',
      description: loan?.type,
      amount: loan?.outstandingBalance,
      repaymentRecurrence: 'monthly',
      repaymentAmount: loan?.repayments,
      ownership: loan?.ownership,
      limit: 0,
    }),
    loans
  )

  return isOwned ? mappedPersonalLoans : []
}
function mapToCreditCardModel({ isOwned, cards }) {
  const mappedCreditCards = map(
    (creditCard) => ({
      id: creditCard?.id,
      name: 'creditCard',
      description: creditCard?.provider,
      amount: creditCard?.outstandingBalance,
      repaymentRecurrence: 'monthly',
      repaymentAmount: creditCard?.repayments,
      ownership: creditCard?.ownership,
      limit: creditCard?.limit,
    }),
    cards
  )

  return isOwned ? mappedCreditCards : []
}
