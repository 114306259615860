import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { styled, s, useLargeMediaQuery } from '@vega/styled'

import { AppSwitcher } from 'features/common/appSwitcher'
import AdminOverviewSubRouter from './overview/router'
import UserManagementSubRouter from './userManagement'
import { AdminNavigationDrawer } from 'features/admin/adminNavigationDrawer'
import { AccountSettings } from 'features/common/accountSettings'
import { UnsupportedScreenPage } from '@vega/components'

const Root = styled.div(s('flex h-full min-h-32 bg-green-50'))

const prefixRoute = '/admin'

const AdminRouter = () => {
  const isLargeScreen = useLargeMediaQuery('larger')
  if (!isLargeScreen)
    return (
      <Root>
        <Switch>
          <UnsupportedScreenPage app="Neo Lending" />
        </Switch>
      </Root>
    )

  return (
    <Root>
      <AppSwitcher />
      <AdminNavigationDrawer />

      <Switch>
        <Route path={`${prefixRoute}/overview`} component={AdminOverviewSubRouter} />

        {/* <Route
          exact
          path={`${prefixRoute}/applications`}
          component={AdminApplications}
        /> */}
        <Route path={`${prefixRoute}/user-management`}>
          <UserManagementSubRouter parentRoute={prefixRoute} />
        </Route>

        <Route exact path={`${prefixRoute}/account`} component={AccountSettings} />

        <Redirect to={`${prefixRoute}/overview`} />
      </Switch>
    </Root>
  )
}

export { AdminRouter }
