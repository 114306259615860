import { s, styled } from '@vega/styled'

import React from 'react'
import { GreetingTopBar } from '@vega/components'

const Container = styled.div(s('h-full flex-1 p-8'))

function Overview() {
  return (
    <Container>
      <GreetingTopBar userName="John" />
    </Container>
  )
}

export { Overview }
