import React, { useEffect } from 'react'
import { useWizard } from '@vega/formik-wizard'
import { useDispatch, useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'

import { concat, omit, path, pick, pipe, prop } from '@neo/ramda-extra'

import { YourGoalsStep } from './YourGoalsStep'
import { UploadIdStep as ClientIdentificationStep } from './ClientIdentificationStep'
import { DetailsForm as DetailsStep } from './YourDetailsStep'
import { YourOptionsStep } from './YourOptionsStep'
import { LoanOptionsStep } from './LoanOptionsStep'
import { YourFinancialsSteps } from './YourFinancialsSteps'
import { PreApprovalStep } from './PreApprovalStep'
import { selectApplicantIds } from 'modules/application'
import { useFormikContext } from 'formik'
import { rehydrate } from '@vega/redux.upload'
import * as CONSTANTS from '@neo/constants'
import { makeDetailsStepId, makeIdentificationStepId } from './utils'

const { FORM_STEPS } = CONSTANTS

const Content = styled.div(s('w-full h-full flex flex-column', { overflowY: 'hidden' }))
const FormContent = styled.div(s('flex-1', { overflowY: 'auto' }))

function View({ loanApplicationId }) {
  const { currentStep, hasInitialized } = useWizard()
  const { values: formValues } = useFormikContext()
  const dispatch = useDispatch()

  useEffect(() => {
    scrollTopOnNewStep()
    function scrollTopOnNewStep() {
      document.querySelector('#loan-application-form').scrollTop = 0
    }
  }, [currentStep])

  const applicantIds = useSelector(selectApplicantIds)

  const makeSharedProps = (formValues) => {
    const applicantPathId = `applicant1`
    const applicantId = applicantIds[0]
    const {
      firstName: applicantName = 'your client',
      documents,
    } = formValues?.details?.[applicantPathId]
    return {
      applicantId,
      applicantName,
      applicantPathId,
      isLastApplicant: true,
      documents,
    }
  }

  const sharedProps = makeSharedProps(formValues)
  const detailsStepProps = pick(['applicantPathId', 'applicantId'], sharedProps)
  const identificationStepProps = sharedProps
  const financialStepProps = omit(['documents'], sharedProps)
  const preApprovalStepProps = pick(['applicantId'], sharedProps)

  const isUploadModuleHydrated = React.useRef(false)
  useEffect(() => {
    rehydrateUploadModule()
    function rehydrateUploadModule() {
      if (hasInitialized && !isUploadModuleHydrated.current) {
        const getPassportFiles = path(['passport', 'files'])
        const getDriverLicenceFiles = path(['driverLicence', 'files'])
        const getEachApplicantFiles = (docs) =>
          concat(getPassportFiles(docs), getDriverLicenceFiles(docs))

        const files = pipe(prop('documents'), getEachApplicantFiles)(sharedProps)
        dispatch(rehydrate(files))
        isUploadModuleHydrated.current = true
      }
    }
  }, [dispatch, hasInitialized, sharedProps])

  return (
    <Content id="loan-application-form">
      <FormContent>
        <DetailsStep
          {...detailsStepProps}
          stepId={makeDetailsStepId(0)}
          loanApplicationId={loanApplicationId}
        />
        <ClientIdentificationStep
          {...identificationStepProps}
          stepId={makeIdentificationStepId(0)}
          loanApplicationId={loanApplicationId}
        />
        <YourFinancialsSteps
          sharedProps={financialStepProps}
          loanApplicationId={loanApplicationId}
        />
        <YourGoalsStep
          stepId={FORM_STEPS.GOALS}
          loanApplicationId={loanApplicationId}
        />
        <YourOptionsStep
          stepId={FORM_STEPS.OPTIONS}
          loanApplicationId={loanApplicationId}
        />
        <LoanOptionsStep
          stepId={FORM_STEPS.LOAN_OPTIONS}
          loanApplicationId={loanApplicationId}
        />
        <PreApprovalStep
          {...preApprovalStepProps}
          stepId={FORM_STEPS.PRE_APPROVAL}
          loanApplicationId={loanApplicationId}
        />
      </FormContent>
    </Content>
  )
}

export default View
