import React from 'react'
import { styled, s } from '@vega/styled'

const Heading = styled.p(
  s('m-0 mb-2 text-sm text-grey-600', { letterSpacing: '0.03em' })
)
const Value = styled.p(s('m-0 text-xl text-green-700', { letterSpacing: '0.03em' }))

const HeaderItem = ({ name, value, ...otherProps }) => {
  return (
    <div {...otherProps}>
      <Heading>{name}</Heading>
      <Value>{value}</Value>
    </div>
  )
}

export { HeaderItem }
