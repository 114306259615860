import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { routes } from 'routes'

import { styled, s } from '@vega/styled'
import { PageHeader, LoaderWithLabel } from '@vega/components'
import { SavePropertyModal } from './SavePropertyModal'
import { ReactComponent as EmptyApplicationsImage } from '@vega/components/src/assets/images/client-empty-applications.svg'
import { ASYNC_STATUSES, TEST_IDS } from '@vega/constants'

import { isNil, values, pipe, propEq, prop, find, replace } from '@neo/ramda-extra'
import * as VEGA_CONSTANTS from '@vega/constants'

import { GridView, TopTabFilter, Total } from '../../common/applications'

import { usePagedSearch } from 'hooks'
import {
  getLoanApplications,
  selectApplicationId,
  selectCreateApplicationStatus,
  selectGetApplicationsStatus,
  selectLoanApplications,
  selectTotal,
} from 'modules/application'

const GRID_VIEW_SEARCH_LIMIT = 9
const STATUSES = {
  ALL: 'all',
  IN_PROGRESS: 'in_progress',
  APPROVED: 'approved',
  FAILED: 'failed',
}
const { STAGES } = VEGA_CONSTANTS.LOAN_APPLICATION
const { PENDING, FULFILLED } = ASYNC_STATUSES
const { CLIENT } = VEGA_CONSTANTS.USER_ROLES

const Root = styled.div(s('flex flex-column w-full min-h-32 bg-green-50'))
const ViewContainer = styled.div(s('h-full', { overflow: 'hidden' }))
const Container = styled.div(s('flex flex-column bg-white h-full pt-6'))
const EmptyViewContainer = styled.div(s('flex flex-column items-center mt-16'))
const EmptyApplicationsLabel = styled.div(s('text-3xl text-green-700 mt-4'))

function ApplicationsList() {
  const dispatch = useDispatch()
  const history = useHistory()

  const total = useSelector(selectTotal)
  const applications = useSelector(selectLoanApplications)
  const isFetchingApps = useSelector(selectGetApplicationsStatus) === PENDING
  const loanApplicationId = useSelector(selectApplicationId)
  const createApplicationStatus = useSelector(selectCreateApplicationStatus)

  const [statusFilter, setStatusFilter] = useState(STATUSES.ALL)

  const statusFilters = [
    {
      value: STATUSES.ALL,
      label: 'All',
      stages: values(STAGES),
    },
  ]

  const goToApplicationDetails = (id) => {
    history.push(replace(':id', id, routes.client.applications.overview.details))
  }

  const fetchData = (searchParams) => dispatch(getLoanApplications(searchParams))

  const {
    setters: { updateParams },
    paginationProps,
  } = usePagedSearch(fetchData, {
    total,
    pageIndex: 0,
    searchTerm: '',
    limit: GRID_VIEW_SEARCH_LIMIT,
    filters: {
      stage: values(STAGES),
    },
  })

  const onStageFilterSelected = (status) => {
    setStatusFilter(status)

    const stage = pipe(find(propEq('value', status)), prop('stages'))(statusFilters)

    updateParams({
      pageIndex: 0,
      filters: {
        stage,
      },
    })
  }

  React.useEffect(() => {
    if (isNil(loanApplicationId)) return

    if (createApplicationStatus === FULFILLED)
      history.push(`${routes.client.applications.create}/${loanApplicationId}`)
  }, [createApplicationStatus, history, loanApplicationId])

  const [isModalOpened, setIsModalOpened] = useState(false)

  const closeModal = () => setIsModalOpened(false)
  const openModal = () => setIsModalOpened(true)

  return (
    <Root>
      <PageHeader title="My Applications" style={{ paddingLeft: 28 }} />
      <TopTabFilter
        filters={statusFilters}
        onFilterSelected={onStageFilterSelected}
        currentFilter={statusFilter}
        style={s('pl-3')}
      />
      {isFetchingApps ? (
        <LoaderWithLabel
          style={{ marginTop: 220 }}
          labelStyle={s('mt-6 text-base')}
          label="Fetching applications"
        />
      ) : (
        <ViewContainer>
          <Total
            total={total}
            testId={TEST_IDS.CLIENT_APPLICATIONS_TOTAL_NUM}
            style={{ right: 24, top: 66 }}
          />
          <Container>
            {applications.length === 0 ? (
              <EmptyViewContainer>
                <EmptyApplicationsImage />
                <button type="button" onClick={openModal}>
                  Add Property
                </button>
                <EmptyApplicationsLabel>No Application</EmptyApplicationsLabel>
              </EmptyViewContainer>
            ) : (
              <GridView
                viewerRole={CLIENT}
                nextPage={paginationProps.nextPage}
                canNextPage={paginationProps.canNextPage}
                applications={applications}
                onCardClicked={goToApplicationDetails}
                testId={TEST_IDS.CLIENT_APPLICATIONS_GRID_LIST}
              />
            )}
          </Container>
        </ViewContainer>
      )}

      <SavePropertyModal isOpen={isModalOpened} onClose={closeModal} />
    </Root>
  )
}

export { ApplicationsList }
