import { defaultTo } from '@neo/ramda-extra'
import { normalize, schema } from 'normalizr'

export const normalizeApplicationsBreakdownReport = (report) => {
  const breakdownReport = new schema.Entity('breakdownReport', {})

  const result = normalize(
    { target: report },
    {
      target: [breakdownReport],
    }
  )

  return defaultTo({}, result.entities.breakdownReport)
}
