import React, { forwardRef } from 'react'

import { FocusScope } from '@react-aria/focus'
import { useOverlay } from '@react-aria/overlays'
import { mergeProps } from '@react-aria/utils'

import { Note } from './Note'
import { AddNote } from './AddNote'
import { NotesContainer } from './NotesContainer'

// eslint-disable-next-line react/display-name
export const Notes = forwardRef(
  ({ name, notes = [], isOpen, onClose, style, ...props }, ref) => {
    const { overlayProps } = useOverlay({ onClose, isOpen, isDismissable: true }, ref)

    return (
      <FocusScope autoFocus>
        <NotesContainer {...mergeProps(overlayProps, props)} expanded={true} ref={ref}>
          {notes.map((noteData) => {
            return <Note key={noteData.id} {...noteData} />
          })}
          <AddNote name={name} hasNotes={notes.length > 0} close={onClose} ref={ref} />
        </NotesContainer>
      </FocusScope>
    )
  }
)
