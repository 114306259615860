import React from 'react'
import { Select } from './Select'
import { useField, useFormikContext } from 'formik'

function FormSelect({ name, id, options, ...otherProps }) {
  const { setFieldValue } = useFormikContext()
  const [{ value: currentValue }] = useField({
    name,
    id,
  })

  const onSelectChange = (selected) => setFieldValue(name, selected?.value)

  const lookUpCurrentOption = (currentValue, options) =>
    options.find((opt) => opt.value === currentValue)

  const currentOption = lookUpCurrentOption(currentValue, options)
  const initialOption = options[0]

  return (
    <Select
      value={currentOption || initialOption}
      onChange={onSelectChange}
      options={options}
      {...otherProps}
    />
  )
}

export { FormSelect }
