import { defaultWhen, isNilOrEmpty, pipe, trim } from '@neo/ramda-extra'

import { defaultOnErr } from '@vega/lending/src/modules/application/apiToViewModelMappers/defaultOnErr'

const stringPlaceholder = '-'
const moneyPlaceholder = '$-'

const formatNonNumeric = (value = '') =>
  pipe(trim, defaultWhen(isNilOrEmpty, stringPlaceholder))(value)

const formatMoneyValue = (value, digits = 0, placeholder = '$-') => {
  if (isNilOrEmpty(value) || typeof value !== 'number') return placeholder

  return new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: digits,
  }).format(value)
}
export const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1)

const formatBoolean = (val) => ({ true: 'Yes', false: 'No' }[val])

const addYear = (val = '') => {
  if (isNilOrEmpty(val)) return stringPlaceholder
  return val > 1 ? `${val} years` : `${val} year`
}
const formatLoanTerm = addYear
const formatFixedLoanRateTerm = addYear
const formatRepaymentFrequency = capitalize
const formatLoanRateType = capitalize

export default {
  formatBoolean: defaultOnErr(formatBoolean, stringPlaceholder),
  formatMoneyValue: defaultOnErr(formatMoneyValue, moneyPlaceholder),
  formatNonNumeric: defaultOnErr(formatNonNumeric, stringPlaceholder),
  formatLoanTerm: defaultOnErr(formatLoanTerm, stringPlaceholder),
  formatFixedLoanRateTerm: defaultOnErr(formatFixedLoanRateTerm, stringPlaceholder),
  formatRepaymentFrequency: defaultOnErr(formatRepaymentFrequency, stringPlaceholder),
  formatLoanRateType: defaultOnErr(formatLoanRateType, stringPlaceholder),
}
