import React, { useState } from 'react'
import { noop } from '@neo/ramda-extra'
import { ReactComponent as X } from '@vega/components/src/assets/images/x.svg'
import { Modal as ModalBase, Button } from '@vega/components'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('flex flex-column bg-white p-4 w-48', { borderRadius: 12 })
)
const Header = styled.div(s('w-full flex justify-end mb-4'))
const Body = styled.div(s('pb-8 pl-6 pr-10'))

const Title = styled.span(s('block text-grey-800 font-semibold text-2xl mb-6'))
const Textarea = styled.textarea(
  s('w-full border-1 border-solid border-grey-300 p-4 mb-6', {
    outline: 'none',
    resize: 'none',
    borderRadius: '12px 12px 0px 12px',
    '&::placeholder': s('text-grey-500'),
  })
)

function PropertyQueryModal({ isOpen, onClose = noop }) {
  const [question, setQuestion] = useState('')

  const onTextAreaChange = (event) => {
    const text = event.target.value
    setQuestion(text)
  }

  const onSubmit = () => question

  return (
    <ModalBase isOpen={isOpen} onClose={onClose}>
      <Container>
        <Header>
          <X onClick={onClose} style={{ cursor: 'pointer' }} />
        </Header>

        <Body>
          <Title>What is the question?</Title>

          <Textarea
            placeholder="Type your question"
            rows={5}
            onChange={onTextAreaChange}
          />

          <Button type="submit" style={s('w-8')} onClick={onSubmit}>
            Submit
          </Button>
        </Body>
      </Container>
    </ModalBase>
  )
}

export { PropertyQueryModal }
