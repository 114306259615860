/* eslint-disable complexity */
import React from 'react'
import { styled, s } from '@vega/styled'
import fmt from './utils/formatters'

const Root = styled.div({ marginRight: 52 })
const ValueContainer = styled.div(s('flex'))
const Value = styled.p(s('m-0 text-2xl text-grey-800 font-bold'))
const Suffix = styled.p(
  s('m-0 text-xs text-grey-600 font-medium self-end w-1', {
    wordSpacing: 1000,
  })
)
const Label = styled.p(
  s('m-0 text-grey-500 text-base font-normal', { lineHeight: 1.5, marginTop: 3 })
)

const SummaryItem = ({
  value,
  suffix,
  label,
  formatValue = fmt.formatNonNumeric,
  containerStyle,
  ...otherProps
}) => {
  const formattedValue = formatValue(value)

  return (
    <Root containerStyle={containerStyle} {...otherProps}>
      <ValueContainer>
        <Value>{formattedValue}</Value>
        <Suffix>{suffix}</Suffix>
      </ValueContainer>
      <Label>{label}</Label>
    </Root>
  )
}

export { SummaryItem }
