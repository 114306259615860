import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { unwrapResult } from '@reduxjs/toolkit'
import { createInvitation, selectCreateInvitationStatus } from 'modules/invitation'
import { prop } from '@neo/ramda-extra'
import { Formik as FormProvider, Form as FormBase } from 'formik'
import { ASYNC_STATUSES, USER_ROLES } from '@vega/constants'
import * as Validator from 'yup'
import { ReactComponent as CloseIcon } from '@vega/components/src/assets/images/x.svg'
import { Button } from '@vega/components'
import { EmailField } from './EmailField'

const Container = styled.div(s('w-full'))

const Title = styled.span(s('text-xl'))

const Header = styled.div(s('flex items-center justify-between mb-6'))

const InviteBtn = styled(Button)(s('ml-a text-base text-grey-600'))

const validationSchema = Validator.object({
  email: Validator.string().email().required('Email is required'),
  role: Validator.string().required('Role is required'),
})

function Form({ onClose }) {
  const dispatch = useDispatch()

  const onSubmit = async (values, action) => {
    try {
      const result = await dispatch(createInvitation(values))
      unwrapResult(result)
    } catch (error) {
      if (error?.description === 'EmailAlreadyUsed') {
        action.setFieldError(
          'email',
          'This email is already in use, please enter a different email'
        )
      }
    }
  }

  const isInvitingUser =
    useSelector(selectCreateInvitationStatus) === ASYNC_STATUSES.PENDING

  return (
    <Container>
      <Header>
        <Title>Invite new user</Title>
        <CloseIcon width={20} onClick={onClose} style={{ cursor: 'pointer' }} />
      </Header>

      <FormProvider
        enableReinitialize
        initialValues={{
          email: '',
          role: prop('BROKER', USER_ROLES),
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <FormBase>
          <EmailField />

          <InviteBtn type="submit" loading={isInvitingUser}>
            Invite
          </InviteBtn>
        </FormBase>
      </FormProvider>
    </Container>
  )
}

export { Form }
