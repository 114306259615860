import React from 'react'
import { STATUSES } from './constants'
import { styled, s } from '@vega/styled'

const { PENDING, APPROVED, REJECTED } = STATUSES

const Container = styled.div(
  s('px-2 py-1 rounded-sm flex items-center', {
    height: 21,
    fontSize: 10,
    lineHeight: 1.5,
  }),
  ({ status }) => status === PENDING && s('bg-grey-200 text-grey-700'),
  ({ status }) => status === APPROVED && s('bg-green-600 text-grey-50'),
  ({ status }) => status === REJECTED && s('bg-error-100 text-error-600')
)

const ApplicantStatus = ({ status }) => {
  return (
    <Container status={status}>
      {status === PENDING && 'Pending'}
      {status === APPROVED && 'Approved'}
      {status === REJECTED && 'Rejected'}
    </Container>
  )
}

export { ApplicantStatus }
