import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { s, styled } from '@vega/styled'
import { getWatchlistedProperties, selectProperties } from 'modules/property'
import { GridView } from './GridView'
import { ReviewPropertyModal } from './ReviewPropertyModal'

const Container = styled.div(s('flex-1', { overflow: 'auto' }))
const ContentContainer = styled.div(
  s('flex flex-column py-6', { minHeight: 'calc(100vh - 210px)' })
)

const ContentHeader = styled.div(s('flex justify-between items-center mb-6 px-6'))
const Title = styled.h2(s('m-0 text-grey-800 text-2xl'))

function SubmittedProperties({ loanApplicationId }) {
  const dispatch = useDispatch()
  const properties = useSelector(selectProperties)

  const [isReviewPropertyModalOpen, setIsReviewPropertyModalOpen] = useState(false)

  useEffect(() => {
    dispatch(
      getWatchlistedProperties({
        pageIndex: 0,
        limit: 10,
        filters: { loanApplicationId },
      })
    )
  }, [dispatch, loanApplicationId])

  return (
    <Container>
      <ContentContainer>
        <ContentHeader>
          <Title>Review pending</Title>
        </ContentHeader>

        <GridView
          properties={properties}
          openReviewPropertyModal={() => setIsReviewPropertyModalOpen(true)}
        />
      </ContentContainer>

      <ContentContainer>
        <ContentHeader>
          <Title>Rejected</Title>
        </ContentHeader>

        <GridView
          properties={properties}
          openReviewPropertyModal={() => setIsReviewPropertyModalOpen(true)}
        />
      </ContentContainer>

      <ReviewPropertyModal
        isOpen={isReviewPropertyModalOpen}
        onClose={() => setIsReviewPropertyModalOpen(false)}
      />
    </Container>
  )
}

export { SubmittedProperties }
