import React from 'react'
import { useFormikContext } from 'formik'
import { styled, s } from '@vega/styled'

const Text = styled.span(s('inline-block font-bold text-grey-700 mb-8'), {
  fontSize: '42px',
})
const Recurrence = styled.span(s('font-normal text-lg'))

function TotalExpenseText({ name, type, value, ...otherProps }) {
  const { setFieldValue } = useFormikContext()

  React.useEffect(() => {
    setFieldValue(name, value)
  }, [name, setFieldValue, value])

  return (
    <Text {...otherProps}>
      ${value}
      <Recurrence>monthly</Recurrence>
    </Text>
  )
}

export { TotalExpenseText }
