import React from 'react'
import { makeIdentificationStepId } from '../utils'
import { UploadIdStep } from './UploadIdStep'

const ClientIdentificationStep = ({ sharedProps }) => {
  return sharedProps.map((props, idx) => (
    <UploadIdStep key={idx} {...props} stepId={makeIdentificationStepId(idx)} />
  ))
}

export { ClientIdentificationStep }
