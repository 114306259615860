import { defaultTo, pipe, pluck, replaceAll, test } from '@neo/ramda-extra'
import * as Validator from 'yup'
import CONSTANTS from './constants/selectFieldOptions'

const { object, string, boolean, number } = Validator

const addressSchema = object({
  propertyId: string().label('Property Id'),
  propertyAddress: string()
    .required('Please search and select a valid address')
    .label('Address'),
})

const getValues = pluck('value')
const requiredString = (msg) => string().required(msg).label('This')
const optionalString = () => string().optional().label('This')
const requiredNumber = (msg) => number().required(msg).label('This')
const requiredEmail = (msg) => string().email().required(msg).label('This')
const requiredBoolean = (msg) => boolean().required(msg).label('This')

export const validationSchema = (applicantPathId) =>
  object({
    details: object({
      [applicantPathId]: object({
        firstName: requiredString('Please enter first name'),
        lastName: requiredString('Please enter last name'),
        middleName: optionalString(),
        preferredName: optionalString(),
        dob: requiredString('Please select date of birth').typeError(
          `Please enter a valid date`
        ),
        gender: requiredString('Please select gender').oneOf(
          getValues(CONSTANTS.GENDER_OPTIONS)
        ),
        marital: requiredString('Please select marital status').oneOf(
          getValues(CONSTANTS.MARITAL_OPTIONS)
        ),
        numOfDependants: requiredNumber('Please enter number of dependants')
          .integer('Please enter a valid number of dependants')
          .min(0, 'Please enter a valid number of dependants')
          .typeError('Please enter a valid number of dependants'),
        livingArrangement: requiredString('Please select living arrangement').oneOf(
          getValues(CONSTANTS.LIVING_ARRANGEMENT_OPTIONS)
        ),
        currentAddress: addressSchema,
        mailingAddress: addressSchema,
        email: requiredEmail('Please enter email'),
        phoneNumber: requiredString('Please enter phone number').test({
          test: pipe(defaultTo(''), replaceAll(' ', ''), test(/^[28]\d{7,9}$/)),
          message: 'Please enter a valid phone number',
        }),
        isNZPR: requiredBoolean(
          'Please indicate if your client is a New Zealand permanent resident'
        ),
        citizenCountry: string().when('isNZPR', {
          is: false,
          then: requiredString('Please select citizen country'),
        }),
        residentCountry: string().when('isNZPR', {
          is: false,
          then: requiredString('Please select resident country'),
        }),
        residencyStatus: string().when('isNZPR', {
          is: false,
          then: requiredString('Please select residency status').oneOf(
            getValues(CONSTANTS.RESIDENCY_STATUS_OPTIONS)
          ),
        }),
      }),
    }),
  })
