/* eslint-disable complexity */
import React, { useEffect } from 'react'
import { reduce } from '@neo/ramda-extra'
import { FieldArray, useFormikContext } from 'formik'
import {
  EMPLOYMENT_BASIS_OPTS,
  ROLES_OPTS,
  EMPLOYMENT_NATURE_OPTS,
} from '../selectOptions'
import {
  TextField,
  RadioButton,
  RadioButtonField,
  CheckboxField,
  SelectField,
} from '@vega/components'
import { EmploymentPeriod } from './EmploymentPeriod'

import { Prompt as OriginalPrompt } from 'features/creditAssessor/applications/components/typography'

import { styled, s } from '@vega/styled'

import { TEST_IDS } from '@vega/constants'

const Prompt = styled(OriginalPrompt)(s('mt-6 mb-4'))
const EmploymentTitle = styled.h2(s('text-xl mb-4'))

const accumulateTotalCurrentSalary = (accumulator, employment) => {
  if (employment?.inRole) return accumulator + Number(employment.remunerationAmount)
  return accumulator
}

function Employment({ applicantName, parentPath, applicantPathId }) {
  const { values, setFieldValue } = useFormikContext()
  const employments = values?.financials[applicantPathId]?.employment || []

  useEffect(() => {
    setTotalSalary()

    function setTotalSalary() {
      const totalSalary = reduce(accumulateTotalCurrentSalary, 0, employments)
      setFieldValue(`${parentPath}.salary.amount`, totalSalary)
    }
  }, [employments, parentPath, setFieldValue])

  return (
    <FieldArray
      name={`${parentPath}.employment`}
      render={() => (
        <div>
          {employments.map((employment, idx) => {
            const employmentPath = `${parentPath}.employment[${idx}]`

            const inRole = values?.financials[applicantPathId]?.employment[idx]?.inRole
            const isOvertimeEligible =
              values?.financials[applicantPathId]?.employment[idx]?.overtimeEligible
            const typeOfAdditionalPayment =
              values?.financials[applicantPathId]?.employment[idx]
                ?.typeOfAdditionalPayment

            return (
              <div key={idx} name={employment[idx]}>
                <EmploymentTitle>Employment {idx + 1}</EmploymentTitle>

                <Prompt style={s('mt-0')}>
                  What is the nature of {applicantName}&apos;s employment?
                </Prompt>
                <SelectField
                  name={`${employmentPath}.employmentNature`}
                  aria-label="nature of employment"
                  options={EMPLOYMENT_NATURE_OPTS}
                  testId={`${TEST_IDS.EMPLOYMENT_NATURE_DROPDOWN}${idx + 1}`}
                  menuId={`${TEST_IDS.EMPLOYMENT_NATURE_DROPDOWN_MENU}${idx + 1}`}
                  readonly
                />

                <Prompt>What is {applicantName}&apos;s employment basis?</Prompt>
                <SelectField
                  name={`${employmentPath}.employmentBasis`}
                  aria-label="nature of employment"
                  options={EMPLOYMENT_BASIS_OPTS}
                  testId={`${TEST_IDS.EMPLOYMENT_BASIS_DROPDOWN}${idx + 1}`}
                  menuId={`${TEST_IDS.EMPLOYMENT_BASIS_DROPDOWN_MENU}${idx + 1}`}
                  readonly
                />

                <Prompt style={s('mb-0')}>Who is the employer?</Prompt>
                <TextField
                  name={`${employmentPath}.employerName`}
                  aria-label="employer name"
                  options={EMPLOYMENT_BASIS_OPTS}
                  placeholder="ex. Google"
                  testId={`${TEST_IDS.EMPLOYER_NAME_INPUT}${idx + 1}`}
                  isReadOnly
                />

                <Prompt>How long has {applicantName} worked here?</Prompt>
                <CheckboxField
                  name={`${employmentPath}.inRole`}
                  size="md"
                  aria-label="still in role"
                  style={s('mb-4')}
                  testId={`${TEST_IDS.CURRENT_EMPLOYER_CHECKBOX}${idx + 1}`}
                  isReadOnly
                >
                  Currently working here
                </CheckboxField>

                <EmploymentPeriod
                  employmentPath={employmentPath}
                  inRole={inRole}
                  idx={idx}
                />

                <Prompt>
                  Which of these roles best describes what {applicantName} does?
                </Prompt>
                <SelectField
                  name={`${employmentPath}.roleType`}
                  options={ROLES_OPTS}
                  testId={`${TEST_IDS.ROLE_TYPE_DROPDOWN}${idx + 1}`}
                  menuId={`${TEST_IDS.ROLE_TYPE_DROPDOWN_MENU}${idx + 1}`}
                  readonly
                />

                <Prompt style={s('mb-0')}>
                  What is {applicantName}&apos;s annual salary? (excluding overtime,
                  commissions, and bonuses). How much do they earn before tax, but not
                  including super? (gross paid)
                </Prompt>
                <TextField
                  name={`${employmentPath}.remunerationAmount`}
                  aria-label="annualSalary"
                  prefix="$"
                  suffix="p.a."
                  containerStyle={s('w-1/2')}
                  testId={`${TEST_IDS.ANNUAL_SALARY_INPUT}${idx + 1}`}
                  isReadOnly
                />

                <Prompt>How frequently does {applicantName} get paid?</Prompt>
                <RadioButtonField
                  name={`${employmentPath}.payRecurrence`}
                  aria-label="payment frequency"
                  testId={`${TEST_IDS.PAY_FREQUENCY_RB}${idx + 1}`}
                  isReadOnly
                >
                  <RadioButton value="weekly">Weekly</RadioButton>
                  <RadioButton value="fortnightly">Fortnightly</RadioButton>
                  <RadioButton value="monthly">Monthly</RadioButton>
                  <RadioButton value="irregular">Irregular</RadioButton>
                </RadioButtonField>

                <Prompt>Does {applicantName} get paid regular overtime?</Prompt>
                <RadioButtonField
                  name={`${employmentPath}.overtimeEligible`}
                  aria-label="Overtime Eligibility"
                  testId={`${TEST_IDS.OVERTIME_PAY_RB}${idx + 1}`}
                  isReadOnly
                >
                  <RadioButton value={true}>Yes</RadioButton>
                  <RadioButton value={false}>No</RadioButton>
                </RadioButtonField>

                {isOvertimeEligible && (
                  <>
                    <Prompt style={s('mb-0')}>
                      What is the total overtime amount?
                    </Prompt>
                    <TextField
                      name={`${employmentPath}.overtimeAmount`}
                      aria-label="bonus amount"
                      prefix="$"
                      containerStyle={s('w-1/2')}
                      testId={`${TEST_IDS.OVERTIME_AMOUNT_INPUT}${idx + 1}`}
                      isReadOnly
                    />
                    <Prompt>
                      How frequently does {applicantName} get overtime payments?
                    </Prompt>
                    <RadioButtonField
                      name={`${employmentPath}.overtimePaymentRecurrence`}
                      aria-label="overtime recurrence"
                      testId={`${TEST_IDS.OVERTIME_FREQ_RB}${idx + 1}`}
                      isReadOnly
                    >
                      <RadioButton value="weekly">Weekly</RadioButton>
                      <RadioButton value="fortnightly">Fortnightly</RadioButton>
                      <RadioButton value="monthly">Monthly</RadioButton>
                      <RadioButton value="irregular">Irregular</RadioButton>
                    </RadioButtonField>
                  </>
                )}

                <Prompt>Does {applicantName} earn commission or bonus payments?</Prompt>

                <RadioButtonField
                  name={`${employmentPath}.typeOfAdditionalPayment`}
                  aria-label="Commission or Bonus"
                  containerStyle={s('mb-6')}
                  testId={`${TEST_IDS.BONUS_PAY_RB}${idx + 1}`}
                  isReadOnly
                >
                  <RadioButton value="no">No</RadioButton>
                  <RadioButton value="commission">Commission</RadioButton>
                  <RadioButton value="bonus">Bonus</RadioButton>
                </RadioButtonField>

                {typeOfAdditionalPayment === 'bonus' && (
                  <>
                    <Prompt style={s('mb-0')}>
                      What is the total bonus payments amount?
                    </Prompt>
                    <TextField
                      name={`${employmentPath}.bonusAmount`}
                      aria-label="bonus amount"
                      prefix="$"
                      containerStyle={s('w-1/2')}
                      testId={`${TEST_IDS.BONUS_AMOUNT_INPUT}${idx + 1}`}
                      isReadOnly
                    />
                    <Prompt>
                      How frequently does {applicantName} get bonus payments?
                    </Prompt>
                    <RadioButtonField
                      name={`${employmentPath}.bonusRecurrence`}
                      aria-label="bonus recurrence"
                      testId={`${TEST_IDS.BONUS_FREQ_RB}${idx + 1}`}
                      isReadOnly
                    >
                      <RadioButton value="weekly">Weekly</RadioButton>
                      <RadioButton value="fortnightly">Fortnightly</RadioButton>
                      <RadioButton value="monthly">Monthly</RadioButton>
                      <RadioButton value="irregular">Irregular</RadioButton>
                    </RadioButtonField>
                  </>
                )}
                {typeOfAdditionalPayment === 'commission' && (
                  <>
                    <Prompt style={s('mb-0')}>
                      What is the total commission amount?
                    </Prompt>
                    <TextField
                      name={`${employmentPath}.commissionAmount`}
                      aria-label="commission amount"
                      prefix="$"
                      containerStyle={s('w-1/2')}
                      testId={`${TEST_IDS.COMMISSION_AMOUNT_INPUT}${idx + 1}`}
                      isReadOnly
                    />
                    <Prompt>How frequently does {applicantName} get commission?</Prompt>
                    <RadioButtonField
                      name={`${employmentPath}.commissionRecurrence`}
                      aria-label="commission recurrence"
                      containerStyle={s('mb-6')}
                      testId={`${TEST_IDS.COMMISSION_FREQ_RB}${idx + 1}`}
                      isReadOnly
                    >
                      <RadioButton value="weekly">Weekly</RadioButton>
                      <RadioButton value="fortnightly">Fortnightly</RadioButton>
                      <RadioButton value="monthly">Monthly</RadioButton>
                      <RadioButton value="irregular">Irregular</RadioButton>
                    </RadioButtonField>
                  </>
                )}
              </div>
            )
          })}
        </div>
      )}
    />
  )
}

export { Employment }
