import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import {
  ValocityAddressField,
  TextField,
  RadioButton,
  RadioButtonField,
  SelectField,
} from '@vega/components'
import { isFalse, or } from '@neo/ramda-extra'
import PROPERTY from './constants'

import { Prompt as OriginalPrompt } from 'features/creditAssessor/applications/components/typography'
import { LOAN_TYPE_OPTS } from '../selectOptions'
import * as CONSTANTS from '@neo/constants'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@vega/constants'

const { OWNERSHIP_TYPE } = PROPERTY

const {
  LOAN_APPLICATION: {
    INTENT: { PROPERTY_PURPOSE },
  },
} = CONSTANTS

const PropertyTitle = styled.h2(s('text-xl mb-4'))

const Prompt = styled(OriginalPrompt)(s('mt-6 mb-4'))

function Properties({ applicantName, parentPath, applicantPathId }) {
  const { values, setFieldValue } = useFormikContext()

  const propertyPath = `${parentPath}.assets.property`

  const properties =
    values?.financials?.[applicantPathId]?.assets?.property?.properties || []
  const hasProperty = properties.length > 0

  const onAddressSelectChange = (propertyPath) => (selectedResult) => {
    setFieldValue(`${propertyPath}.propertyAddress`, selectedResult)
  }

  if (!hasProperty) return null

  return (
    <FieldArray
      name={`${propertyPath}.properties`}
      render={() => (
        <div>
          {properties.map((property, idx) => {
            const propertyValuePath =
              values?.financials[applicantPathId]?.assets?.property?.properties[idx]

            const isInvestmentProperty =
              propertyValuePath?.propertyPurpose === PROPERTY_PURPOSE.INVESTMENT

            const isPropertyShared = or(
              propertyValuePath?.ownershipStructure ===
                OWNERSHIP_TYPE.CLIENT_AND_PARTNER,
              propertyValuePath?.ownershipStructure === OWNERSHIP_TYPE.CLIENT_AND_OTHER
            )

            const propertyIsNotPaidOff = isFalse(propertyValuePath?.isPaidOff)

            return (
              <div key={idx} name={property[idx]}>
                <PropertyTitle>Property {idx + 1}</PropertyTitle>

                <Prompt style={s('mt-0 mb-4')}>
                  Is this a home or investment property?
                </Prompt>
                <RadioButtonField
                  name={`${propertyPath}.properties[${idx}].propertyPurpose`}
                  aria-label="Property Purpose"
                  containerStyle={s('mb-6')}
                  testId={`${TEST_IDS.OTHER_PROPERTY_PURPOSE_RB}${idx + 1}`}
                  isReadOnly
                >
                  <RadioButton value={PROPERTY_PURPOSE.LIVING}>Home</RadioButton>
                  <RadioButton value={PROPERTY_PURPOSE.INVESTMENT}>
                    Investment
                  </RadioButton>
                </RadioButtonField>

                <Prompt>What’s the address of this property?</Prompt>
                <ValocityAddressField
                  name={`${propertyPath}.properties[${idx}].propertyAddress`}
                  onSelectionChange={onAddressSelectChange(
                    `${propertyPath}.properties[${idx}]`
                  )}
                  aria-label="property address"
                  testId={`${TEST_IDS.PROPERTY_ADDRESS_INPUT}${idx + 1}`}
                  menuId={`${TEST_IDS.PROPERTY_ADDRESS_MENU}${idx + 1}`}
                  readOnly
                />

                <Prompt style={s('mb-0')}>
                  What does {applicantName} estimate its value to be?
                </Prompt>
                <TextField
                  name={`${propertyPath}.properties[${idx}].estimatedPropertyValue`}
                  aria-label="estimated value"
                  prefix="$"
                  containerStyle={s('w-1/2')}
                  testId={`${TEST_IDS.PROPERTY_VALUE_INPUT}${idx + 1}`}
                  isReadOnly
                />

                {isInvestmentProperty && (
                  <>
                    <Prompt style={s('mb-0')}>
                      And how much rent does {applicantName} receive from it?
                    </Prompt>
                    <TextField
                      name={`${propertyPath}.properties[${idx}].rentalIncome`}
                      aria-label="rent revenue"
                      prefix="$"
                      suffix="p.mth"
                      containerStyle={s('w-1/2')}
                      testId={`${TEST_IDS.PROPERTY_INVESTMENT_INCOME_INPUT}${idx + 1}`}
                      isReadOnly
                    />
                  </>
                )}

                <Prompt>Also, please confirm the ownership structure? </Prompt>
                <RadioButtonField
                  name={`${propertyPath}.properties[${idx}].ownershipStructure`}
                  aria-label="ownership"
                  defaultValue="client"
                  testId={`${TEST_IDS.OWNERSHIP_STRUCTURE_RB}${idx + 1}`}
                  isReadOnly
                >
                  <RadioButton value={OWNERSHIP_TYPE.CLIENT}>
                    Belongs to client
                  </RadioButton>
                  <RadioButton value={OWNERSHIP_TYPE.CLIENT_AND_PARTNER}>
                    Client owns it with their partner
                  </RadioButton>
                  <RadioButton value={OWNERSHIP_TYPE.CLIENT_AND_OTHER}>
                    Client owns it with someone else
                  </RadioButton>
                </RadioButtonField>

                {isPropertyShared && (
                  <>
                    <Prompt style={s('mb-0')}>
                      How much does {applicantName} own of the property in % term?
                    </Prompt>
                    <TextField
                      name={`${propertyPath}.properties[${idx}].equityPercentage`}
                      aria-label="Equity Percentage"
                      suffix="%"
                      placeholder="5.2"
                      containerStyle={s('w-1/2 mb-6')}
                      testId={`${TEST_IDS.OWNERSHIP_PERCENTAGE_INPUT}${idx + 1}`}
                      isReadOnly
                    />
                  </>
                )}

                <Prompt style={s('mt-6 mb-4')}>
                  Have the client payoff their home loan?
                </Prompt>
                <RadioButtonField
                  name={`${propertyPath}.properties[${idx}].isPaidOff`}
                  aria-label="Is home loan paid off"
                  containerStyle={s('mb-6')}
                  testId={`${TEST_IDS.PAID_OFF_EXISITING_LOAN_RB}${idx + 1}`}
                  isReadOnly
                >
                  <RadioButton value={true}>Yes</RadioButton>
                  <RadioButton value={false}>No</RadioButton>
                </RadioButtonField>

                {propertyIsNotPaidOff && (
                  <>
                    <Prompt style={s('mb-0')}>What is the loan total?</Prompt>
                    <TextField
                      name={`${propertyPath}.properties[${idx}].totalLoan`}
                      aria-label="Total Loan"
                      prefix="$"
                      containerStyle={s('w-1/2 mb-6')}
                      testId={`${TEST_IDS.EXISTING_LOAN_TOTAL_INPUT}${idx + 1}`}
                      isReadOnly
                    />

                    <Prompt style={s('mb-0')}>
                      How much does the client pay monthly?
                    </Prompt>
                    <TextField
                      name={`${propertyPath}.properties[${idx}].loanRepayment`}
                      aria-label="Loan Repayment per month"
                      prefix="$"
                      suffix="p.mth"
                      containerStyle={s('w-1/2 mb-6')}
                      testId={`${TEST_IDS.EXISTING_LOAN_REPAYMENT_INPUT}${idx + 1}`}
                      isReadOnly
                    />

                    <Prompt>
                      What type of loan does {applicantName} have against it?
                    </Prompt>
                    <SelectField
                      name={`${propertyPath}.properties[${idx}].loanRepaymentType`}
                      aria-label="loanRepaymentType"
                      options={LOAN_TYPE_OPTS}
                      placeholder="Select loan"
                      testId={`${TEST_IDS.EXISITING_LOAN_TYPE_DROPDOWN}${idx + 1}`}
                      menuId={`${TEST_IDS.EXISITING_LOAN_TYPE_DROPDOWN_MENU}${idx + 1}`}
                      readonly
                    />
                  </>
                )}

                <Prompt>
                  Does the client want to use this property as a security against the
                  new loan?
                </Prompt>
                <RadioButtonField
                  name={`${propertyPath}.properties[${idx}].intendedAsSecurity`}
                  aria-label="Is Security"
                  containerStyle={s('mb-6')}
                  testId={`${TEST_IDS.INTENDED_AS_SECURITY_RB}${idx + 1}`}
                  isReadOnly
                >
                  <RadioButton value={true}>Yes</RadioButton>
                  <RadioButton value={false}>No</RadioButton>
                </RadioButtonField>
              </div>
            )
          })}
        </div>
      )}
    />
  )
}

export { Properties }
