/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as PaperClip } from '@vega/components/src/assets/images/paper-clip.svg'
import { ReactComponent as Download } from '@vega/components/src/assets/images/download.svg'

const Container = styled.div(s('flex items-center mb-4'))
const Prefix = styled.div(s('w-1 h-1'))
const Suffix = styled.div(s('w-1 h-1 ml-a', { cursor: 'pointer' }))
const Text = styled.div(s('p-1 text-sm'))

const UploadFile = ({ fileName, fileURL }) => {
  return (
    <Container>
      <Prefix>
        <PaperClip style={s('text-grey-400')} />
      </Prefix>

      <Text style={{ color: '#1890FF' }}> {fileName}</Text>

      <Suffix>
        <a href={fileURL} target="_blank">
          <Download />
        </a>
      </Suffix>
    </Container>
  )
}

export { UploadFile }
