const routes = {
  broker: {
    root: '/broker',
    overview: {
      applications: '/broker/overview/applications',
      clients: '/broker/overview/clients',
    },
    applications: {
      list: '/broker/applications',
      create: '/broker/applications',
      statusPreview: '/broker/applications/:id/status-preview',
      overview: {
        entry: '/broker/applications/:id/overview',
        details: '/broker/applications/:id/overview/details',
        propertySearch: '/broker/applications/:id/overview/property-search',
        submittedProperty: '/broker/applications/:id/overview/submitted-property',
        loanOutcome: '/broker/applications/:id/overview/loan-outcome',
      },
    },
    account: '/broker/account',
  },
  client: {
    root: '/client',
    applications: {
      list: '/client/applications',
      statusPreview: '/client/applications/:id/status-preview',
      overview: {
        entry: '/client/applications/:id/overview',
        details: '/client/applications/:id/overview/details',
        propertySearch: '/client/applications/:id/overview/property-search',
        submittedProperty: '/client/applications/:id/overview/submitted-property',
        loanOutcome: '/client/applications/:id/overview/loan-outcome',
      },
      create: '/client/applications',
      applicationSubmission: '/client/applications/application-submission',
      propertySubmission: '/client/applications/property-submission',
      approval: '/client/applications/approval',
    },
    account: '/client/account',
  },
  creditAssessor: {
    root: '/credit-assessor',
    overview: '/credit-assessor/overview',
    applications: {
      list: '/credit-assessor/applications',
      overview: {
        personalAndFinance: '/credit-assessor/applications/:id/personal-and-finance',
        property: '/credit-assessor/applications/:id/property',
        approval: '/credit-assessor/applications/:id/approval',
      },
      review: '/credit-assessor/applications/:id/review',
    },
    account: '/credit-assessor/account',
  },
  admin: {
    root: '/admin',
    overview: {
      applications: '/admin/overview/applications',
      clients: '/admin/overview/clients',
    },
    account: '/admin/account',
  },
}

export { routes }
