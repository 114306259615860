import { clone, has, pipe } from '@neo/ramda-extra'

const ensureIsNumber = (props = []) => (payload) => {
  props.forEach((prop) => {
    if (has(prop)(payload)) {
      payload[prop] = Number(payload[[prop]])
    }
  })
  return payload
}

const ensureFloatingLoanHasNoFixedTerm = (payload) => {
  if (has('loanRateType')(payload) && payload.loanRateType === 'floating') {
    delete payload.fixedLoanRateTerm
  }
  return payload
}

export default (payload) => {
  return pipe(
    ensureIsNumber(['loanTerm', 'loanFlexibilityPreference', 'fixedLoanRateTerm']),
    ensureFloatingLoanHasNoFixedTerm
  )(clone(payload))
}
