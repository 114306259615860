import React from 'react'
import { makeDetailsStepId } from '../utils'

import { DetailsForm } from './DetailsForm'

function YourDetailsStep({ sharedProps }) {
  return sharedProps.map((props, idx) => (
    <DetailsForm key={idx} {...props} stepId={makeDetailsStepId(idx)} />
  ))
}

export { YourDetailsStep }
