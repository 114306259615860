import { useMemo } from 'react'
import {
  pipe,
  map,
  pick,
  join,
  ifElse,
  isNilOrEmpty,
  always,
  values,
} from '@neo/ramda-extra'

import * as CONSTANTS from '@neo/constants'

import fmt from 'utils/formatters'

const {
  LOAN_APPLICATION: { STAGES },
} = CONSTANTS

const lookUpStatus = (stage) =>
  ({
    [STAGES.DRAFT]: 'Draft',
    [STAGES.SUBMITTED]: 'Submitted',
    [STAGES.PRE_APPROVED]: 'Pre-Approved',
    [STAGES.APPROVED]: 'Approved',
    failed: 'Failed',
  }[stage])

const moreThanTwoApplicants = (names = []) => names.length > 2
const extractNames = pipe(map(pick(['firstName', 'lastName'])), map(values))
const makeFullName = pipe(map(fmt.capitalize), join(' '))
const joinApplicantNames = ifElse(
  isNilOrEmpty,
  always(''),
  pipe(
    extractNames,
    map(makeFullName),
    ifElse(moreThanTwoApplicants, (names) => `${names[0]} & Others`, join(', '))
  )
)

const mapToRowProps = map((app) => ({
  id: app?.id,
  applicantName: joinApplicantNames(app?.applicantsDetails),
  createdAt: fmt.formatDate(app?.createdAt),
  maxLoanAmount: fmt.formatMonetaryAmount(app?.intent?.borrowingAmount),
  status: lookUpStatus[app?.status?.stage],
}))

export const Rows = (apps) => useMemo(() => mapToRowProps(apps), [apps])
