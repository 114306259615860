export const ITEM_STATUSES = {
  DEFAULT: 'default',
  VERIFIED: 'verified',
  FAILED: 'failed',
}

export const APPLICATION_STATUSES = {
  DRAFT: 'draft',
  PRE_APPROVED: 'pre-approved',
  FAILED: 'failed',
}
