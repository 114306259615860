import React from 'react'
import { Paper } from '@vega/components'
import { Row, Column, Heading, Label, Value } from './layoutComponents'

function CreditProfileSection({
  profile: { firstName, lastName, middleName, dob, gender, addresses },
}) {
  const address = addresses[0]
  return (
    <Paper>
      <Heading>Personal details</Heading>
      <Row>
        <Column>
          <Label>First name:</Label>
          <Value>{firstName}</Value>
        </Column>
        <Column>
          <Label>Gender:</Label>
          <Value>{gender}</Value>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Last name:</Label>
          <Value>{lastName}</Value>
        </Column>
        <Column>
          <Label>Date of birth:</Label>
          <Value>{dob}</Value>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Other name:</Label>
          <Value>{middleName}</Value>
        </Column>
      </Row>
      <Heading>Address</Heading>
      <Row>
        <Column>
          <Label>Address:</Label>
          <Value>
            {address.streetNumber} {address.streetName}
          </Value>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Suburb:</Label>
          <Value>{address.suburb}</Value>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>City:</Label>
          <Value>{address.city}</Value>
        </Column>
      </Row>
    </Paper>
  )
}

export default CreditProfileSection
