import React, { useState } from 'react'
import { ReactComponent as DropdownArrowIconBase } from '@vega/components/src/assets/images/dropdownArrow.svg'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s(
    'p-0 no-decoration text-base text-grey-500 text-sm font-medium flex flex-column justify-between items-start'
  ),
  {
    listStyleType: 'none',
    lineHeight: 1.5,
    margin: '20px 0',
  }
)
const Circle = styled.div(
  s('w-2 h-2 show-box border-grey-300 flex items-center mr-2 justify-center'),
  {
    borderRadius: '50%',
  },
  ({ isComplete }) =>
    isComplete &&
    s('text-green-700 bg-green-500 font-semibold border-0', {
      backgroundColor: 'rgba(88, 198, 189, 0.1)',
    }),
  ({ isCurrent }) => isCurrent && s('text-white font-semibold bg-green-700 border-0')
)
const DropdownArrowIcon = styled(DropdownArrowIconBase, {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})(({ isExpanded }) =>
  s('text-grey-400', {
    transition: 'all 50ms ease-in-out',
    transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
  })
)
const ContentContainer = styled.div(
  s('flex items-center justify-between w-full w-12', {
    cursor: 'pointer',
  })
)
const StepNumber = styled.p()
const Label = styled.p(s('m-0 mr-4'))

export const ProgressDrawer = ({
  label,
  isComplete = false,
  isCurrent = false,
  stepNumber,
  children,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Container {...props}>
      <ContentContainer onClick={() => setIsExpanded(!isExpanded)}>
        <div style={s('flex items-center justify-between')}>
          <Circle isCurrent={isCurrent} isComplete={isComplete}>
            <StepNumber>{stepNumber}</StepNumber>
          </Circle>
          <Label>{label}</Label>
        </div>

        <DropdownArrowIcon isExpanded={isExpanded} />
      </ContentContainer>
      {isExpanded && children}
    </Container>
  )
}
