import React from 'react'
import { styled, s } from '@vega/styled'
import { CheckboxField } from '@vega/components'

const Root = styled.div(s('mt-12'))
const Container = styled.div(
  s('w-full px-4 py-6 rounded-l-lg rounded-br-lg', {
    backgroundColor: '#FBE8D5',
  })
)
const Text = styled.p(s('m-0 mb-8 text-sm text-grey-600'))

const CreditAssessorCheckbox = ({ name, testId, ariaLabel }) => {
  return (
    <Root>
      <Container>
        <Text>
          As a credit assessor, you can confirm that this information meets your credit
          policy
        </Text>
        <CheckboxField
          name={name}
          size="md"
          aria-label={ariaLabel}
          testId={testId}
          boxColour="white"
        >
          I confirm this information is correct
        </CheckboxField>
      </Container>
    </Root>
  )
}

export { CreditAssessorCheckbox }
