import React, { useEffect, useState } from 'react'
import { eqBy, find, pipe, prop, propEq, unionWith } from '@neo/ramda-extra'
import { Button } from '@vega/components'
import { ReactComponent as PlusIcon } from '@vega/components/src/assets/images/plus.svg'
import { ASYNC_STATUSES } from '@vega/constants'
import { s, styled } from '@vega/styled'
import { TopTabFilter } from 'features/common/applications'
import { usePagedSearch } from 'hooks'
import {
  clearProperties,
  getWatchlistedProperties,
  selectPropertyById,
  selectGetWatchlistedPropertiesStatus,
  selectProperties,
  selectInspectedPropertyId,
  selectTotal,
} from 'modules/property'
import { useDispatch, useSelector } from 'react-redux'
import { GridView } from './GridView'
import { PropertyDetailsModal } from './PropertyDetailsModal'
import { SavePropertyModal } from './SavePropertyModal'
import { SubmitPropertyModal } from './SubmitPropertyModal'

const { PENDING } = ASYNC_STATUSES

const Container = styled.div(s('flex flex-column flex-1', { overflow: 'hidden' }))
const ContentContainer = styled.div(s('py-6 flex-1'))

const ContentHeader = styled.div(s('flex justify-between items-center mb-6 px-6'))
const GridViewContainer = styled.div({
  height: 'calc(100vh - 336px)',
  overflowY: 'auto',
})
const Title = styled.h2(s('m-0 text-grey-800 text-2xl'))

const statusFilters = [
  {
    value: 'All',
    label: 'All',
    status: 'all',
  },

  {
    value: 'Pending',
    label: 'Pending',
    status: 'pending',
  },
  {
    value: 'Declined',
    label: 'Declined',
    status: 'declined',
  },
]

function PropertyWatchlist({ loanApplicationId }) {
  const SEARCH_LIMIT = 9

  const dispatch = useDispatch()

  const totalProperties = useSelector(selectTotal)

  const [currentStatusFilter, setCurrentStatusFilter] = useState('All')
  const inspectedPropertyId = useSelector(selectInspectedPropertyId)
  const inspectedProperty = useSelector((state) =>
    selectPropertyById(state, inspectedPropertyId)
  )

  const [isSavePropertyModalOpen, setIsSavePropertyModalOpen] = useState(false)
  const [isPropertyDetailsModalOpen, setIsPropertyDetailsModalOpen] = useState(false)
  const [isSubmitPropertyModalOpen, setIsSubmitPropertyModalOpen] = useState(false)

  const newlyFetchedProperties = useSelector(selectProperties)
  const [propertiesForDisplay, setPropertiesForDisplay] = useState(
    newlyFetchedProperties
  )

  const isFetchingProperties =
    useSelector(selectGetWatchlistedPropertiesStatus) === PENDING

  const fetchData = (searchParams) => dispatch(getWatchlistedProperties(searchParams))

  const {
    setters: { updateParams },
    paginationProps,
  } = usePagedSearch(fetchData, {
    total: totalProperties,
    pageIndex: 0,
    limit: SEARCH_LIMIT,
    filters: { loanApplicationId },
  })

  const onStatusFiltersSelected = (status) => {
    setCurrentStatusFilter(status)
    setPropertiesForDisplay([])

    const statusFilter = pipe(
      find(propEq('value', status)),
      prop('status')
    )(statusFilters)

    updateParams({
      pageIndex: 0,
      filters: { loanApplicationId, status: statusFilter },
    })
  }

  useEffect(() => {
    appendPaginatedProperties()

    function appendPaginatedProperties() {
      setPropertiesForDisplay((displayedProperties) => {
        const checkIsEqualByPropertyId = eqBy(prop('id'))

        return unionWith(
          checkIsEqualByPropertyId,
          displayedProperties,
          newlyFetchedProperties
        )
      })
    }
  }, [newlyFetchedProperties])

  useEffect(() => {
    return () => {
      dispatch(clearProperties())
    }
  }, [dispatch])

  return (
    <Container>
      <TopTabFilter
        filters={statusFilters}
        onFilterSelected={onStatusFiltersSelected}
        currentFilter={currentStatusFilter}
        containerStyle={s('pl-3 mt-6 border-0 border-b-1 border-solid border-grey-200')}
      />

      <ContentContainer>
        <ContentHeader>
          <Title>{currentStatusFilter} properties</Title>
          <Button
            StartIcon={<PlusIcon width={13} />}
            onClick={() => setIsSavePropertyModalOpen(true)}
          >
            Add Property
          </Button>
        </ContentHeader>

        <GridViewContainer>
          <GridView
            properties={propertiesForDisplay}
            nextPage={paginationProps.nextPage}
            canNextPage={paginationProps.canNextPage}
            openPropertyDetailsModal={() => setIsPropertyDetailsModalOpen(true)}
            openSubmitPropertyModal={() => setIsSubmitPropertyModalOpen(true)}
            canLoadMore={paginationProps.canNextPage}
            isLoading={isFetchingProperties}
          />
        </GridViewContainer>
      </ContentContainer>

      <SavePropertyModal
        isOpen={isSavePropertyModalOpen}
        onClose={() => setIsSavePropertyModalOpen(false)}
        loanApplicationId={loanApplicationId}
        property={inspectedProperty}
      />
      <PropertyDetailsModal
        isOpen={isPropertyDetailsModalOpen}
        onClose={() => setIsPropertyDetailsModalOpen(false)}
        property={inspectedProperty}
      />
      <SubmitPropertyModal
        isOpen={isSubmitPropertyModalOpen}
        onClose={() => setIsSubmitPropertyModalOpen(false)}
        openSavePropertyModal={() => setIsSavePropertyModalOpen(true)}
        loanApplicationId={loanApplicationId}
        property={inspectedProperty}
      />
    </Container>
  )
}

export { PropertyWatchlist }
