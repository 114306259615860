import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { s, styled } from '@vega/styled'
import ClientManagement from './clientManagement'
import { TopTabNavigator, PageHeader } from '@vega/components'
import PendingInvitation from './pendingInvitation'

const Root = styled.div(
  s('flex flex-column w-full h-full min-h-32 bg-green-50', {
    overflow: 'hidden',
  })
)

const ClientsSubRouter = ({ parentRoute }) => {
  const prefix = `${parentRoute}/clients`
  const clientManagementPath = `${prefix}/management`
  const pendingInvitationPath = `${prefix}/pending-invitation`

  const routes = [
    {
      name: 'Client Management',
      path: clientManagementPath,
    },
    {
      name: 'Pending Invitation',
      path: pendingInvitationPath,
    },
  ]

  return (
    <Root>
      <PageHeader title="Clients" style={s('py-0 pt-2 pl-4')} />
      <TopTabNavigator routes={routes} containerStyle={s('px-0')} />
      <Switch>
        <Route exact path={clientManagementPath} component={ClientManagement} />
        <Route exact path={pendingInvitationPath} component={PendingInvitation} />
        <Redirect to={clientManagementPath} />
      </Switch>
    </Root>
  )
}

export default ClientsSubRouter
