import React from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as LightBulbIcon } from '@vega/components/src/assets/images/lightbulb.svg'

const Root = styled.div(s('fixed pt-1 w-full h-full pl-6 pr-4', { width: 250 }))
const TitleContainer = styled.div(
  s('flex text-xl text-grey-600 mb-4', {
    lineHeight: 1.5,
  })
)
const Title = styled.h4(s('m-0 p-0 flex items-center pt-1 font-normal'))
const IconContainer = styled.div(s('my-a mr-3 pt-1'))
const TextContainer = styled.div(
  s('w-full', {
    lineHeight: 1.5,
  })
)
const Question = styled.h5(s('m-0 p-0 mb-2 text-grey-600 font-medium text-base'))
const Answer = styled.div()
const Paragraph = styled.p(s('m-0 p-0 text-grey-600 font-normal text-base'))

const InfoSidebar = () => {
  return (
    <Root>
      <TitleContainer>
        <IconContainer>
          <LightBulbIcon height={18} width={18} />
        </IconContainer>
        <Title>Need to know</Title>
      </TitleContainer>
      <TextContainer>
        <Question />
        <Answer>
          <Paragraph />
        </Answer>
      </TextContainer>
    </Root>
  )
}
export { InfoSidebar }
