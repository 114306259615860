import React, { useState, useEffect } from 'react'
import { styled, s } from '@vega/styled'
import { ComparisonRate } from './ComparisonRate'
import { ReactComponent as ArrowRight } from '@vega/components/src/assets/images/arrow-right.svg'
import { ReactComponent as ArrowLeft } from '@vega/components/src/assets/images/arrow-left.svg'
import anz from '@vega/components/src/assets/images/anz.png'
import bnz from '@vega/components/src/assets/images/bnz.png'
import asap from '@vega/components/src/assets/images/asap.png'
import westpac from '@vega/components/src/assets/images/westpac.png'

const CarouselContainer = styled.div(s('flex relative'))
const CarouselWrapper = styled.div(s('flex w-full'))
const ContentWrapper = styled.div(
  s('flex h-full w-full justify between', { overflow: 'hidden' })
)
const Content = styled.div(s('flex w-full', { transition: 'all 250ms linear' }))
const Circle = styled.div(
  s('flex bg-white p-2 rounded-full shadow-xs justify-center items-center', {
    height: 40,
    width: 40,
    cursor: 'pointer',
  })
)

const rates = [
  { rate: '4.35', image: anz },
  { rate: '3.35', image: bnz },
  { rate: '2.35', image: asap },
  { rate: '3.45', image: westpac },
  { rate: '3.00', image: bnz },
]

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [length, setLength] = useState(rates.length)

  const canPrevious = currentIndex > 0
  const canNext = currentIndex < length - 3

  const goToPrevious = () => {
    if (canPrevious) setCurrentIndex((previousState) => previousState - 1)
  }

  const goToNext = () => {
    if (canNext) setCurrentIndex((previousState) => previousState + 1)
  }

  useEffect(() => {
    setLength(rates.length)
  }, [])

  return (
    <CarouselContainer>
      {canPrevious && (
        <Circle
          style={s('absolute z-1 pin-l-0', {
            top: '50%',
            transform: 'translate(-50%, -50%)',
          })}
          onClick={goToPrevious}
        >
          <ArrowLeft style={s('text-grey-500')} />
        </Circle>
      )}

      <CarouselWrapper>
        <ContentWrapper>
          <Content style={{ transform: `translateX(-${currentIndex * 224}px)` }}>
            {rates.map((rate) => (
              <ComparisonRate key={rate.rate} rate={rate.rate} image={rate.image} />
            ))}
          </Content>
        </ContentWrapper>
      </CarouselWrapper>

      {canNext && (
        <Circle
          style={s('absolute z-1 pin-r-0', {
            top: '50%',
            transform: 'translate(50%, -50%)',
          })}
          onClick={goToNext}
        >
          <ArrowRight style={s('text-grey-500')} />
        </Circle>
      )}
    </CarouselContainer>
  )
}

export { Carousel }
