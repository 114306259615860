import { Route, Switch, Redirect } from 'react-router-dom'
import { s, styled } from '@vega/styled'

import { AppSwitcher } from 'features/common/appSwitcher'
import { Calculator as BrokerCalculator } from './calculator'
import { Landing as BrokerLanding } from './landing'
import BrokerOverviewSubRouter from './overview/router'
import { Reports as BrokerReports } from './reports'
import { ApplicationsPage } from './applications'
import { Properties } from './properties'
import ClientsSubRouter from './clients'
import ClientProfileSubRouter from './clientProfile'
import React from 'react'
import { BrokerNavigationDrawer } from 'features/broker/brokerNavigationDrawer'
import { AccountSettings } from 'features/common/accountSettings'

const Root = styled.div(s('flex h-full min-h-32 bg-green-50'))

const prefixRoute = '/broker'

const BrokerRouter = () => {
  return (
    <Root>
      <BrokerNavigationDrawer />

      <AppSwitcher />

      <Switch>
        <Route path={`${prefixRoute}/overview`} component={BrokerOverviewSubRouter} />
        <Route exact path={`${prefixRoute}/calculator`} component={BrokerCalculator} />
        <Route exact path={`${prefixRoute}/reports`} component={BrokerReports} />
        <Route exact path={`${prefixRoute}/landing`} component={BrokerLanding} />
        <Route path={`${prefixRoute}/applications`} component={ApplicationsPage} />
        <Route exact path={`${prefixRoute}/properties`} component={Properties} />
        <Route path={`${prefixRoute}/clients/profile/:clientId`}>
          <ClientProfileSubRouter parentRoute={`${prefixRoute}/clients/profile`} />
        </Route>
        <Route path={`${prefixRoute}/clients`}>
          <ClientsSubRouter parentRoute={prefixRoute} />
        </Route>
        <Route exact path={`${prefixRoute}/account`} component={AccountSettings} />

        <Redirect to={`${prefixRoute}/overview`} />
      </Switch>
    </Root>
  )
}

export { BrokerRouter }
