import { concatAll, map, pipe, range, replace, unnest } from '@neo/ramda-extra'
import * as CONSTANTS from '@neo/constants'

const { FORM_STEPS } = CONSTANTS

export const generateSteps = (numOfApplicants = 1) => {
  const detailsSubsteps = [
    FORM_STEPS.DETAILS_DETAILS,
    FORM_STEPS.DETAILS_IDENTIFICATION,
  ]
  const financialsSubsteps = [
    FORM_STEPS.FINANCIALS_CREDIT_REPORT,
    FORM_STEPS.FINANCIALS_FILL_METHODS,
    FORM_STEPS.FINANCIALS_INCOME_ASSETS,
    FORM_STEPS.FINANCIALS_EXPENSES_LIABILITIES,
  ]
  const invitationStep = [FORM_STEPS.DETAILS_INVITATION]
  const lastThreeSteps = [
    FORM_STEPS.GOALS,
    FORM_STEPS.OPTIONS,
    FORM_STEPS.LOAN_OPTIONS,
    FORM_STEPS.PRE_APPROVAL,
  ]

  const rangeToAdd = range(1, numOfApplicants + 1)
  const addNumToStep = (number) => (step) => `${step}-applicant${number}`
  const addOneNum = (steps) => (number) => map(addNumToStep(number))(steps)
  const addNumbersToSteps = (numbers) => (steps) =>
    pipe(map(addOneNum(steps)), unnest)(numbers)

  const addNumbersInRange = addNumbersToSteps(rangeToAdd)

  const numberedDetailsSubsteps = addNumbersInRange(detailsSubsteps)
  const numberedFinancialsSubsteps = addNumbersInRange(financialsSubsteps)

  return concatAll([
    invitationStep,
    numberedDetailsSubsteps,
    numberedFinancialsSubsteps,
    lastThreeSteps,
  ])
}

export const removeIdentifier = replace(/-applicant[0-9]{1,2}/, '')

const makeStepId = (stepName) => (idx) => `${stepName}-applicant${idx + 1}`

export const makeDetailsStepId = makeStepId(FORM_STEPS.DETAILS_DETAILS)
export const makeIdentificationStepId = makeStepId(FORM_STEPS.DETAILS_IDENTIFICATION)
export const makeCreditReportStepId = makeStepId(FORM_STEPS.FINANCIALS_CREDIT_REPORT)
export const makeFillMethodsStepId = makeStepId(FORM_STEPS.FINANCIALS_FILL_METHODS)
export const makeIncomeAndAssetsStepId = makeStepId(FORM_STEPS.FINANCIALS_INCOME_ASSETS)
export const makeExpensesAndLiabilitiesStepId = makeStepId(
  FORM_STEPS.FINANCIALS_EXPENSES_LIABILITIES
)
