import React from 'react'
import { useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { Prompt as OriginalPrompt } from 'features/broker/applications/components/typography'
import { RadioButtonField, RadioButton } from '@vega/components'
import { ReactComponent as ArrowRightIcon } from '@vega/components/src/assets/images/arrowRight.svg'
import { SendEmailDialog } from './SendEmailDialog'
import { OpenLinkDialog } from './OpenLinkDialog'
import {
  selectFinancialsRetrievalMetadata,
  sendRequestApplicantFinancialsEmail,
  updateFinancialsAccessMethod,
} from 'modules/application'
import { FILL_METHOD } from '../constants'

const { AUTO_FILL_METHOD } = FILL_METHOD

const Prompt = styled(OriginalPrompt)(s('mb-4'))
const Note = styled.p(s('m-0 font-semibold text-sm text-grey-600 mb-4'))

function AutoFill({ applicantId, applicantPathId, parentPath }) {
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  const financialsRetrievalMetadata = useSelector(
    selectFinancialsRetrievalMetadata(applicantId)
  )
  const retrieveFinancialDataUrl = financialsRetrievalMetadata?.url
  const autoFillMethod = values?.financials[applicantPathId]?.fillMethod?.autoFillMethod

  const autoFillMethodNotSelected = autoFillMethod === undefined
  const openLinkIsSelected = autoFillMethod === AUTO_FILL_METHOD.OPEN_LINK
  const sendEmailIsSelected = autoFillMethod === AUTO_FILL_METHOD.SEND_EMAIL

  const onSendEmailBtnClicked = () => {
    dispatch(
      updateFinancialsAccessMethod({
        applicantId,
        autoFillMethod: AUTO_FILL_METHOD.SEND_EMAIL,
      })
    )
    dispatch(sendRequestApplicantFinancialsEmail(applicantId))
  }

  const onOpenLinkBtnClicked = () => {
    dispatch(
      updateFinancialsAccessMethod({
        applicantId,
        autoFillMethod: AUTO_FILL_METHOD.OPEN_LINK,
      })
    )
    window.open(retrieveFinancialDataUrl)
  }

  return (
    <div>
      {autoFillMethodNotSelected && (
        <>
          <Prompt style={s('mb-2')}>
            You can select &quot;Connect now&quot; and you&apos;ll be taken to a new
            site to continue. Or select &quot;Send invite&quot; to send an invite to
            your inbox
          </Prompt>
          <Note>
            Note: If you select &quot;Connect now,&quot; please come back to this tab
            when you complete set up
          </Note>
          <RadioButtonField
            name={`${parentPath}.fillMethod.autoFillMethod`}
            aria-label="fill option"
            containerStyle={s('w-full')}
          >
            <RadioButton
              value={AUTO_FILL_METHOD.OPEN_LINK}
              data-retrieveFinancial-url={retrieveFinancialDataUrl}
              data-test-id="open-link-btn"
              onClick={onOpenLinkBtnClicked}
            >
              <span style={s('ml-a')}>Connect now</span>
              <ArrowRightIcon style={s('ml-a', { transform: 'rotate(-45deg)' })} />
            </RadioButton>
            <RadioButton
              value={AUTO_FILL_METHOD.SEND_EMAIL}
              onClick={onSendEmailBtnClicked}
            >
              <span style={s('ml-a')}>Send invite</span>
              <ArrowRightIcon style={s('ml-a')} />
            </RadioButton>
          </RadioButtonField>
        </>
      )}

      {openLinkIsSelected && <OpenLinkDialog applicantId={applicantId} />}

      {sendEmailIsSelected && <SendEmailDialog applicantId={applicantId} />}
    </div>
  )
}

export { AutoFill }
