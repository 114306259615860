const extractFirstCharFromStr = (str) => str.charAt(0)

const generateBarChartDataByBorrowingAmount = (topClientsReports) => {
  const chartDataForLoanSize = []

  topClientsReports.forEach(({ primaryApplicant, totalBorrowingAmount }, index) => {
    const { firstName = '', lastName = '' } = primaryApplicant
    const clientInitial = extractFirstCharFromStr(firstName)
    const clientFullName = `${firstName} ${lastName}`

    chartDataForLoanSize.push({
      // append index to label, as we want full name as label but visx chart will ignore duplicated category and not render bar for it if the names are the same
      label: `${clientFullName}-${index}`,
      value: totalBorrowingAmount,
      clientInitial,
    })
  })

  return chartDataForLoanSize
}

const generateBarChartDataByNumOfLoans = (topClientsReports) => {
  const chartDataForNumOfLoans = []

  topClientsReports.forEach(({ primaryApplicant, numOfLoanApplications }, index) => {
    const { firstName = '', lastName = '' } = primaryApplicant
    const clientInitial = extractFirstCharFromStr(firstName)
    const clientFullName = `${firstName} ${lastName}`

    chartDataForNumOfLoans.push({
      // append index to label, as we want full name as label but visx chart will ignore duplicated category and not render bar for it if the names are the same
      label: `${clientFullName}-${index}`,
      value: numOfLoanApplications,
      clientInitial,
    })
  })

  return chartDataForNumOfLoans
}

export const mapTopClientsReportToChartData = (topClientsReports = [], criterion) => {
  const chartData =
    criterion === 'borrowingAmount'
      ? generateBarChartDataByBorrowingAmount(topClientsReports)
      : generateBarChartDataByNumOfLoans(topClientsReports)

  return chartData
}
