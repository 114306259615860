import React from 'react'

import { styled, s } from '@vega/styled'
import { Button } from '@vega/components'
import { BottomProgressBar } from 'features/creditAssessor/applications/components/BottomProgressBar'

const Root = styled.div(s('bg-green-50 flex flex-row justify-center w-full'), {
  height: '76px',
  paddingRight: 200,
  boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.1)',
})

const ButtonContainer = styled.div(s('flex-1 flex justify-between items-center'), {
  maxWidth: 652,
})

const NavButton = styled(Button)(
  s('w-6 text-base font-semibold shadow-none pb-3', {
    height: 40,
  })
)

const BackButton = styled(NavButton)(s('bg-green-50 text-grey-600 border-grey-300'))
const NextButton = styled(NavButton)(
  s('bg-green-500 text-grey-700'),
  ({ loading }) => loading && s('p-0')
)

function BottomNavigation({ goBack, canNext }) {
  return (
    <Root>
      <ButtonContainer>
        <BackButton type="button" variant="outlined" onClick={goBack}>
          Back
        </BackButton>

        <BottomProgressBar />

        <NextButton disabled={!canNext} type="submit">
          Next
        </NextButton>
      </ButtonContainer>
    </Root>
  )
}

export { BottomNavigation }
