import { styled, s } from '@vega/styled'

const SectionContainer = styled.div(s(''))
const FormContainer = styled.div(
  s('flex flex-column bg-white mt-5 border-1 border-solid border-green-600', {
    padding: 36,
    borderRadius: '12px 0 12px 12px',
    '& > div:not(:last-child)': s('mb-5'),
  })
)
const Row = styled.div(s('flex flex-row justify-between'))

export { Row, FormContainer, SectionContainer }
