import React from 'react'
import { TextField, RadioButtonField, RadioButton } from '@vega/components'
import { Subtitle } from 'features/broker/applications/components/typography'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@vega/constants'

import { ExpenseRow } from './ExpenseRow'
import { NotesProvider } from 'features/broker/applications/components/NotesProvider'

const Column = styled.div(s('flex flex-column mb-6'))
const Row = styled.div(s('flex items-end justify-between'))

function Housing({ totalHousingExpenses, parentPath }) {
  return (
    <>
      <ExpenseRow type="House" value={totalHousingExpenses} style={s('mb-6')} />

      <NotesProvider name={`${parentPath}.expenses.housing.rent`}>
        <Column>
          <Subtitle>Rent</Subtitle>
          <Row>
            <TextField
              name={`${parentPath}.expenses.housing.rent.value`}
              prefix="$"
              containerStyle={s('w-2/5 mr-5')}
              inputContainerStyle={{ height: 52 }}
              testId={TEST_IDS.RENT_COST_INPUT}
            />
            <RadioButtonField
              name={`${parentPath}.expenses.housing.rent.recurrence`}
              aria-label="rent"
              containerStyle={s('w-full')}
              testId={TEST_IDS.RENT_PAY_FREQ_RB}
            >
              <RadioButton value="weekly">Weekly</RadioButton>
              <RadioButton value="fortnightly">Fortnightly</RadioButton>
              <RadioButton value="monthly">Monthly</RadioButton>
              <RadioButton value="quarterly">Quarterly</RadioButton>
            </RadioButtonField>
          </Row>
        </Column>
      </NotesProvider>

      <NotesProvider name={`${parentPath}.expenses.housing.food`}>
        <Column>
          <Subtitle>Food and Groceries</Subtitle>
          <Row>
            <TextField
              name={`${parentPath}.expenses.housing.food.value`}
              prefix="$"
              containerStyle={s('w-2/5 mr-5')}
              inputContainerStyle={{ height: 52 }}
              testId={TEST_IDS.FOOD_COST_INPUT}
            />
            <RadioButtonField
              name={`${parentPath}.expenses.housing.food.recurrence`}
              aria-label="food and groceries"
              containerStyle={s('w-full')}
              testId={TEST_IDS.FOOD_PAY_FREQ_RB}
            >
              <RadioButton value="weekly">Weekly</RadioButton>
              <RadioButton value="fortnightly">Fortnightly</RadioButton>
              <RadioButton value="monthly">Monthly</RadioButton>
              <RadioButton value="quarterly">Quarterly</RadioButton>
            </RadioButtonField>
          </Row>
        </Column>
      </NotesProvider>

      <NotesProvider name={`${parentPath}.expenses.housing.insurance`}>
        <Column>
          <Subtitle>Insurance</Subtitle>
          <Row>
            <TextField
              name={`${parentPath}.expenses.housing.insurance.value`}
              prefix="$"
              containerStyle={s('w-2/5 mr-5')}
              inputContainerStyle={{ height: 52 }}
              testId={TEST_IDS.INSURANCE_COST_INPUT}
            />
            <RadioButtonField
              name={`${parentPath}.expenses.housing.insurance.recurrence`}
              aria-label="Insurance"
              containerStyle={s('w-full')}
              testId={TEST_IDS.INSURANCE_PAY_FREQ_RB}
            >
              <RadioButton value="weekly">Weekly</RadioButton>
              <RadioButton value="fortnightly">Fortnightly</RadioButton>
              <RadioButton value="monthly">Monthly</RadioButton>
              <RadioButton value="quarterly">Quarterly</RadioButton>
            </RadioButtonField>
          </Row>
        </Column>
      </NotesProvider>

      <NotesProvider name={`${parentPath}.expenses.housing.telecommunication`}>
        <Column>
          <Subtitle>
            Telephone, Mobile, Internet, Pay TV, & Media Streaming Subscriptions
          </Subtitle>
          <Row>
            <TextField
              name={`${parentPath}.expenses.housing.telecommunication.value`}
              prefix="$"
              containerStyle={s('w-2/5 mr-5')}
              inputContainerStyle={{ height: 52 }}
              testId={TEST_IDS.SUBSCRIPTIONS_COST_INPUT}
            />
            <RadioButtonField
              name={`${parentPath}.expenses.housing.telecommunication.recurrence`}
              aria-label="Telephone, mobile, internet, Pay TV & Media Streaming Subscriptions"
              containerStyle={s('w-full')}
              testId={TEST_IDS.SUBSCRIPTIONS_PAY_FREQ_RB}
            >
              <RadioButton value="weekly">Weekly</RadioButton>
              <RadioButton value="fortnightly">Fortnightly</RadioButton>
              <RadioButton value="monthly">Monthly</RadioButton>
              <RadioButton value="quarterly">Quarterly</RadioButton>
            </RadioButtonField>
          </Row>
        </Column>
      </NotesProvider>

      <NotesProvider name={`${parentPath}.expenses.housing.propertyMaintenance`}>
        <Column>
          <Subtitle>
            Investment property expenses (e.g. agent fees, repairs and maintenance)
          </Subtitle>
          <Row>
            <TextField
              name={`${parentPath}.expenses.housing.propertyMaintenance.value`}
              prefix="$"
              containerStyle={s('w-2/5 mr-5')}
              inputContainerStyle={{ height: 52 }}
              testId={TEST_IDS.INVESTMENT_MAINTENANCE_COST_INPUT}
            />
            <RadioButtonField
              name={`${parentPath}.expenses.housing.propertyMaintenance.recurrence`}
              aria-label="Investment property expense (e.g. agent fees, repairs and maintenance)"
              containerStyle={s('w-full')}
              testId={TEST_IDS.INVESTMENT_MAINTENANCE_PAY_FREQ_RB}
            >
              <RadioButton value="weekly">Weekly</RadioButton>
              <RadioButton value="fortnightly">Fortnightly</RadioButton>
              <RadioButton value="monthly">Monthly</RadioButton>
              <RadioButton value="quarterly">Quarterly</RadioButton>
            </RadioButtonField>
          </Row>
        </Column>
      </NotesProvider>
    </>
  )
}

export { Housing }
