import React from 'react'
import { APPLICATION_STATUSES } from './constants'
import { styled, s } from '@vega/styled'

const { DRAFT, PRE_APPROVED, FAILED } = APPLICATION_STATUSES

const Container = styled.div(
  s('h-2 px-2 py-1 rounded-l-md rounded-br-md', { lineHeight: 1.5 }),
  ({ status }) => status === DRAFT && s('bg-grey-200 text-grey-700'),
  ({ status }) => status === PRE_APPROVED && s('bg-green-100 text-grey-700'),
  ({ status }) => status === FAILED && s('bg-error-100 text-error-700')
)

const ProgressStatus = ({ status }) => {
  return (
    <Container status={status}>
      {status === DRAFT && 'Draft'}
      {status === PRE_APPROVED && 'Pre-approved'}
      {status === FAILED && 'Failed'}
    </Container>
  )
}

export { ProgressStatus }
