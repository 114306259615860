import { uniq, pipe, isNilOrEmpty, reject, update, findIndex } from '@neo/ramda-extra'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { normalizeSummaryReports } from './utils/normalizeSummaryReports'
import { normalizeApplicationsBreakdownReport } from './utils/normalizeApplicationsBreakdownReport'

import { reportService } from 'apiService'

const ensureNoDuplicateItem = (arr) => uniq(arr)
const removeNilOrEmpty = reject(isNilOrEmpty)

export const getLoanApplicationsSummary = createAsyncThunk(
  'reports/getLoanApplicationsSummary',
  async ({ years, months }, { rejectWithValue }) => {
    try {
      const parsedYears = pipe(ensureNoDuplicateItem, removeNilOrEmpty)(years)
      const parsedMonths = pipe(ensureNoDuplicateItem, removeNilOrEmpty)(months)

      const reports = await reportService.getLoanApplicationsSummaryReport(
        parsedYears,
        parsedMonths
      )

      const normalizedReports = normalizeSummaryReports(reports)

      return normalizedReports
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

export const getInProgressApplicationsReport = createAsyncThunk(
  'reports/getInProgressApplicationsReport',
  async ({ year, month }, { rejectWithValue }) => {
    try {
      const report = await reportService.getInProgressApplicationsReport(year, month)

      return report
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

export const getApplicationsBreakdownReport = createAsyncThunk(
  'reports/getApplicationsBreakdownReport',
  async ({ year, month }, { rejectWithValue }) => {
    try {
      const report = await reportService.getApplicationsBreakdownReport(year, month)
      const normalizedReport = normalizeApplicationsBreakdownReport(report)

      return normalizedReport
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

export const getClientsCountReport = createAsyncThunk(
  'reports/getClientsCountReport',
  async ({ year, month }, { rejectWithValue }) => {
    try {
      const report = await reportService.getClientsCountReport(year, month)

      return { report, year, month }
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

export const getTopClients = createAsyncThunk(
  'reports/getTopClients',
  async ({ year, month, amountOfResult, criterion }, { rejectWithValue }) => {
    try {
      const topClients = await reportService.getTopClientsReport(
        year,
        month,
        amountOfResult,
        criterion
      )

      return topClients
    } catch (err) {
      const error = await err.response.json()
      return rejectWithValue(error)
    }
  }
)

const reportSlice = createSlice({
  name: 'reports',
  initialState: {
    loanApplication: {
      summary: {},
      inProgressReport: {},
      breakdownReport: {},
    },
    client: {
      countReports: [],
      topClients: [],
    },
  },
  extraReducers: {
    [getLoanApplicationsSummary.fulfilled]: (state, action) => {
      const summaryReport = action.payload

      state.loanApplication.summary = summaryReport
    },

    [getInProgressApplicationsReport.fulfilled]: (state, action) => {
      const inProgressReport = action.payload

      state.loanApplication.inProgressReport = inProgressReport
    },

    [getApplicationsBreakdownReport.fulfilled]: (state, action) => {
      const breakdownReport = action.payload

      state.loanApplication.breakdownReport = breakdownReport
    },

    [getClientsCountReport.fulfilled]: (state, action) => {
      const { report, year, month } = action.payload

      const foundReportIdx = findIndex(
        (r) => r?.year === year && r?.month === month,
        state.client.countReports
      )

      const found = foundReportIdx !== -1
      if (found) update(foundReportIdx, report, state.client.countReports)
      else state.client.countReports.push(report)
    },

    [getTopClients.fulfilled]: (state, action) => {
      const topClients = action.payload

      state.client.topClients = topClients
    },
  },
})

const { reducer: reportsReducer } = reportSlice
export { reportsReducer }
