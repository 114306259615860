import React from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as Check } from '@vega/components/src/assets/images/check.svg'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'
import { STATUSES } from './constants'

const { APPROVED, REJECTED } = STATUSES

const circleStyleByStatus = {
  pending: s('border-green-600'),
  approved: s('border-green-600'),
  rejected: s('border-error-400'),
}

const getStyleByStatus = (status, styleByStatus) => styleByStatus[status]

const Container = styled.div(
  s('flex text-sm text-grey-700 items-center', { lineHeight: 1.3 })
)
const Circle = styled.div(
  s('flex border-1 border-solid mr-2 rounded-full justify-center items-center', {
    height: 20,
    width: 20,
  }),
  ({ status }) => getStyleByStatus(status, circleStyleByStatus)
)
const X = styled(XBase)({
  '& path': { stroke: s('text-error-400').color },
})

const ItemVerifyStatus = ({ status, children, ...otherProps }) => {
  return (
    <Container status={status} {...otherProps}>
      <Circle status={status}>
        {status === APPROVED && <Check style={s('text-green-600 w-1')} />}
        {status === REJECTED && <X />}
      </Circle>
      {children}
    </Container>
  )
}

export { ItemVerifyStatus }
