import React, { useEffect } from 'react'
import { Housing as HousingExpense } from './Housing'
import { Personal as PersonalExpense } from './Personal'
import { OtherExpense } from './OtherExpense'
import { TotalExpense } from './TotalExpense'
import { useFormikContext } from 'formik'
import calculateTotalMonthlyExpenses from './calculateTotalMonthlyExpenses'
import { styled, s } from '@vega/styled'

const SeparateLine = styled.div(
  s('w-full border-0 border-b-2 border-solid border-grey-600 mt-8 mb-5')
)

function Expenses({ parentPath, applicantPathId }) {
  const { values: formikValues, setFieldValue } = useFormikContext()
  const housingExpenseTotal = calculateTotalMonthlyExpenses(
    formikValues?.financials[applicantPathId]?.expenses?.housing
  )
  const personalExpenseTotal = calculateTotalMonthlyExpenses(
    formikValues?.financials[applicantPathId]?.expenses?.personal
  )
  const otherExpenseTotal = calculateTotalMonthlyExpenses(
    formikValues?.financials[applicantPathId]?.expenses?.other
  )

  useEffect(() => {
    setFieldValue(`${parentPath}.expenses.totalExpense.housing`, housingExpenseTotal)
  }, [housingExpenseTotal, parentPath, setFieldValue])
  useEffect(() => {
    setFieldValue(`${parentPath}.expenses.totalExpense.personal`, personalExpenseTotal)
  }, [parentPath, personalExpenseTotal, setFieldValue])
  useEffect(() => {
    setFieldValue(`${parentPath}.expenses.totalExpense.other`, otherExpenseTotal)
  }, [otherExpenseTotal, parentPath, setFieldValue])

  return (
    <>
      <HousingExpense
        totalHousingExpenses={housingExpenseTotal}
        parentPath={parentPath}
      />
      <PersonalExpense
        totalPersonalExpenses={personalExpenseTotal}
        parentPath={parentPath}
      />
      <OtherExpense totalOtherExpenses={otherExpenseTotal} parentPath={parentPath} />
      <SeparateLine />
      <TotalExpense
        totalHousingExpenses={housingExpenseTotal}
        totalPersonalExpenses={personalExpenseTotal}
        totalOtherExpenses={otherExpenseTotal}
        parentPath={parentPath}
      />
    </>
  )
}

export { Expenses }
