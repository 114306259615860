export const PageLong = {
  items: [
    {
      id: '5f3b7b07df5956ffc7b8aa09',
      fullName: 'Jack Wang',
      firstName: 'Jack',
      lastName: 'Wang',
      middleName: 'Lee Sin',
      preferredName: 'Lil Jack',
      email: 'jdoe@soltalabs.com',
      dob: '09/09/1990',
      gender: 'male',
      address: {
        streetAddress: '123 Smith St',
        suburb: 'Auckland CBD',
        city: 'Auckland',
        postcode: '9000',
      },
      phoneNumber: '263648364',
      note:
        'Follow up with some really long note Follow up with some really long note Follow up with some really long note',
    },
    {
      id: '5f3b815a59737f1ef0b09353',
      fullName: 'Mike Weng',
      firstName: 'Mike',
      lastName: 'Weng',
      middleName: 'Xin Zhao',
      preferredName: 'Big Mike',
      email: 'jdoe@soltalabs.com',
      currentAddress: '34 Elizabeth St Melbourne VIC 3000',
      location: {
        address: '34 Elizabeth St',
        suburb: 'Melbourne',
        state: 'Victoria',
        postcode: '3000',
      },
      phone: '+63 230 2145 8888',
      note: 'Follow up with some really long note',
    },
  ],
  paging: {
    limit: 5,
    next: 5,
    sortBy: 'fullName',
    total: 2,
  },
  total: 150,
}
