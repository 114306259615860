export const LIABILITY = {
  ACCOUNTS: {
    PERSONAL_LOAN: 'personalLoan',
    CREDIT_CARD: 'creditCard',
  },
  OWNERSHIP: {
    SELF: 'self',
    SHARED: 'shared',
  },
  LOAN_TYPES: {
    CAR_LOAN: 'Car loan',
    STUDENT_LOAN: 'Student loan',
    PERSONAL_LOAN: 'Personal loan',
    OTHERS: 'Others',
  },
}

export const FUTURE_PLAN = {
  ANTICIPATED_EVENT: {
    PERMANENT_INCOME_REDUCTION: 'permanent_income_reduction',
    TEMPORARY_INCOME_REDUCTION: 'temporary_income_reduction',
    PERMANENT_EXPENDITURE_REDUCTION: 'permanent_expenditure_increase',
    TEMPORARY_EXPENDITURE_REDUCTION: 'temporary_expenditure_increase',
    ONE_OFF_LARGE_EXPENDITURE: 'one_off_large_expenditure',
  },
  ADAPTATION_PLAN: {
    APPLICATION_REFLECTS_CHANGES: 'application_reflects_changes',
    EXPENDITURE_REDUCE: 'expenditure_reduce',
    USE_SAVINGS: 'use_savings',
    ADDITIONAL_INCOME: 'additional_income',
  },
}
