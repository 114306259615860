import React from 'react'

import { styled, s } from '@vega/styled'
import { Button } from '@vega/components'

const Root = styled.div(s('bg-green-50 flex flex-row justify-center w-full h-full'), {
  height: 76,
  paddingRight: 200,
  boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.1)',
})
const ButtonContainer = styled.div(s('flex-1 flex justify-between items-center'), {
  maxWidth: 652,
})
const NavButton = styled(Button)(
  s('w-8 text-base font-semibold shadow-none mr-0 bg-green-500 text-grey-700 pb-3', {
    height: 40,
    marginLeft: 'auto',
  })
)

function BottomNavigation({ isLoading }) {
  return (
    <Root>
      <ButtonContainer>
        <NavButton loading={isLoading} type="submit">
          Send email
        </NavButton>
      </ButtonContainer>
    </Root>
  )
}

export { BottomNavigation }
