import React, { useEffect, useCallback } from 'react'
import * as Validator from 'yup'
import { styled, s } from '@vega/styled'
import { useFormikContext } from 'formik'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetForm,
  selectProgressStepStatus,
  updateFormProgress,
  updateIntent,
} from 'modules/application'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'
import { FormProgress } from '../../components/FormProgress/FormProgress'
import { InfoSidebar } from './InfoSidebar'
import {
  Title,
  Subtitle,
  Prompt as PromptBase,
} from 'features/broker/applications/components/typography'
import { RadioButtonField, RadioButton, TextField } from '@vega/components'
import { ExpectedRentAmount } from './ExpectedRentAmount'
import { BottomNavigation } from './BottomNavigation'
import { isNotNilOrEmpty } from '@neo/ramda-extra'
import { TEST_IDS } from '@vega/constants'
import * as CONSTANTS from '@neo/constants'
import { markProgressStepAsVisited } from 'features/broker/applications/components/FormProgress/utils'
import { NotesProvider } from '../../components/NotesProvider'
import notesParentStyle from 'features/broker/applications/CreateApplicationWizard/notesParentStyle'
import { ErrorSummary } from 'features/common/applications'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
    STEP_NAMES: { GOALS },
  },
} = CONSTANTS
const { object, mixed, number } = Validator

export const validationSchema = Validator.object({
  intent: object({
    loanPurpose: mixed()
      .oneOf(['purchase', 'refinance'])
      .required('Please select loan purpose')
      .label('Loan Purpose'),
    propertyPurpose: mixed()
      .oneOf(['living', 'investment'])
      .required('Please select property purpose')
      .label('Property Purpose'),
    estimatedPropertyValue: number()
      .positive('Please enter a valid estimated property value')
      .required('Please enter the estimated property value')
      .typeError('Please enter a valid estimated property value')
      .label('Estimated Property Value'),
    borrowingAmount: number()
      .positive('Please enter a valid borrowing amount')
      .required('Please enter the borrowing amount')
      .label('Borrowing Amount')
      .typeError('Please enter a valid borrowing amount'),
    expectedRentAmount: number()
      .when(['propertyPurpose'], {
        is: (propertyPurpose) => propertyPurpose === 'investment',
        then: number()
          .positive('Please enter a valid expected rent amount')
          .required('Please enter the expected rent amount')
          .typeError('Please enter a valid expected rent amount'),
      })
      .label('Expected Rent Amount'),
  }),
})

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 1052,
  '> :last-child': s('pb-12'),
  ...notesParentStyle,
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const Prompt = styled(PromptBase)(s('mt-6 mb-4'))

const YourGoalsStep = ({ stepId }) => {
  const {
    values: { details, intent },
    errors,
    submitCount,
    isValid,
  } = useFormikContext()

  const applicantName = details?.applicant1?.firstName || 'your client'

  const { loanPurpose } = intent

  const dispatch = useDispatch()
  const history = useHistory()
  const { previous, currentStep, currentStepIndex, next } = useWizard()

  const saveAndContinue = ({ intent }) => {
    dispatch(updateIntent(intent))
    dispatch(updateFormProgress({ step: GOALS, status: COMPLETED }))

    next()
  }

  const goToApplicationList = () => history.replace(routes.broker.applications.list)

  const saveAndExit = () => {
    dispatch(updateIntent(intent))
    dispatch(resetForm())
    goToApplicationList()
  }

  const goBack = () => previous()
  const isLoanPurposePicked = isNotNilOrEmpty(loanPurpose)

  const currentStepStatus = useSelector(selectProgressStepStatus(GOALS))
  useEffect(() => {
    if (currentStep?.id === stepId) markProgressStepAsVisited(GOALS, currentStepStatus)
  }, [currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Your Goals"
      validationSchema={validationSchema}
      onSubmit={useCallback(saveAndContinue, [currentStepIndex])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-4')}>
                Let&apos;s discuss {applicantName}&apos;s goals
              </Title>

              <Subtitle style={s('mb-8')}>
                We love to say &quot;your wish is our command&quot;, but we&apos;re not
                a genie. Tell us {applicantName}&apos;s goals by answering the following
                questions
              </Subtitle>

              <NotesProvider name={`intent.loanPurpose`}>
                <Prompt style={s('mt-0')}>
                  What is {applicantName} looking to do?
                </Prompt>
                <RadioButtonField
                  name="intent.loanPurpose"
                  aria-label="loanPurpose"
                  testId={TEST_IDS.LOAN_PURPOSE_RB}
                >
                  <RadioButton value="purchase">Client is purchasing</RadioButton>
                  <RadioButton value="refinance">Client is refinancing</RadioButton>
                </RadioButtonField>
              </NotesProvider>

              {isLoanPurposePicked && (
                <>
                  <NotesProvider name="intent.propertyPurpose">
                    <Prompt>
                      Does {applicantName} live in this property, or is it an
                      investment?
                    </Prompt>
                    <RadioButtonField
                      name="intent.propertyPurpose"
                      aria-label="propertyPurpose"
                      testId={TEST_IDS.PROPERTY_PURPOSE_RB}
                    >
                      <RadioButton value="living">Live-in</RadioButton>
                      <RadioButton value="investment">Investment</RadioButton>
                    </RadioButtonField>
                  </NotesProvider>

                  <NotesProvider name="intent.estimatedPropertyValue">
                    <Prompt style={s('mb-0')}>
                      How much does {applicantName} think this property is worth? (an
                      estimated value is acceptable)
                    </Prompt>
                    <TextField
                      name="intent.estimatedPropertyValue"
                      aria-label="estimatedPropertyValue"
                      containerStyle={s('w-1/2')}
                      prefix="$"
                      testId={TEST_IDS.EST_PROPERTY_VALUE_INPUT}
                    />
                  </NotesProvider>

                  <NotesProvider name="intent.borrowingAmount">
                    <Prompt style={s('mb-0')}>
                      How much does {applicantName} want to borrow against this
                      property? This should cover their current loan, plus any
                      additional funds they&apos;d like to borrow
                    </Prompt>
                    <TextField
                      name="intent.borrowingAmount"
                      aria-label="borrowingAmount"
                      containerStyle={s('w-1/2')}
                      prefix="$"
                      testId={TEST_IDS.BORROWING_AMOUNT_INPUT}
                    />
                  </NotesProvider>

                  <ExpectedRentAmount applicantName={applicantName} />
                </>
              )}

              <ErrorSummary
                visible={submitCount > 0 && !isValid}
                errors={errors}
                style={s('mt-10 pb-0')}
              />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { YourGoalsStep }
