import React from 'react'
import { TextField, RadioButtonField, RadioButton } from '@vega/components'
import { Subtitle } from 'features/creditAssessor/applications/components/typography'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@vega/constants'

import { ExpenseRow } from './ExpenseRow'

const Column = styled.div(s('flex flex-column mb-6'))
const Row = styled.div(s('flex items-end justify-between'))

function OtherExpense({ totalOtherExpenses, parentPath }) {
  return (
    <>
      <ExpenseRow type="Other" value={totalOtherExpenses} style={s('mt-2 mb-6')} />

      <Column>
        <Subtitle>Self-education</Subtitle>
        <Row>
          <TextField
            name={`${parentPath}.expenses.other.selfEducation.value`}
            prefix="$"
            containerStyle={s('w-2/5 mr-5')}
            testId={TEST_IDS.SELF_EDUCATION_COST_INPUT}
            isReadOnly
          />
          <RadioButtonField
            name={`${parentPath}.expenses.other.selfEducation.recurrence`}
            aria-label="Self education"
            containerStyle={s('w-full')}
            testId={TEST_IDS.SELF_EDUCATION_PAY_FREQ_RB}
            isReadOnly
          >
            <RadioButton value="weekly">Weekly</RadioButton>
            <RadioButton value="fortnightly">Fortnightly</RadioButton>
            <RadioButton value="monthly">Monthly</RadioButton>
            <RadioButton value="quarterly">Quarterly</RadioButton>
          </RadioButtonField>
        </Row>
      </Column>

      <Column>
        <Subtitle>Transportation</Subtitle>
        <Row>
          <TextField
            name={`${parentPath}.expenses.other.transportation.value`}
            prefix="$"
            containerStyle={s('w-2/5 mr-5')}
            testId={TEST_IDS.TRANSPORTATION_COST_INPUT}
            isReadOnly
          />
          <RadioButtonField
            name={`${parentPath}.expenses.other.transportation.recurrence`}
            aria-label="Transportation"
            containerStyle={s('w-full')}
            testId={TEST_IDS.TRANSPORTATION_PAY_FREQ_RB}
            isReadOnly
          >
            <RadioButton value="weekly">Weekly</RadioButton>
            <RadioButton value="fortnightly">Fortnightly</RadioButton>
            <RadioButton value="monthly">Monthly</RadioButton>
            <RadioButton value="quarterly">Quarterly</RadioButton>
          </RadioButtonField>
        </Row>
      </Column>
    </>
  )
}

export { OtherExpense }
