import React from 'react'

import { styled, s } from '@vega/styled'
import { useSelector } from 'react-redux'
import { selectCompletionPercentage } from 'modules/application'
import { USER_ROLES } from '@vega/constants'

const { BROKER } = USER_ROLES

const ProgressBarContainer = styled.div(
  s('bg-grey-200 flex relative', {
    height: 8,
    width: 244,
    borderRadius: '2px 0 2px 2px',
  })
)
const ProgressBar = styled.div(s('bg-primary h-full'), ({ completionPercentage }) => ({
  width: `${completionPercentage ?? 100}%`,
}))

export const BottomProgressBar = (props) => {
  const completionPercentage = useSelector(selectCompletionPercentage(BROKER))

  return (
    <ProgressBarContainer {...props}>
      <ProgressBar completionPercentage={completionPercentage} />
    </ProgressBarContainer>
  )
}
