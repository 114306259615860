import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { pipe, pick, values, map, replace } from '@neo/ramda-extra'
import { selectApplicantsDetails } from 'modules/application'
import { joinNames, formatApplicantNames } from '../ApplicationDetails/utils'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'
import { ReactComponent as Bell } from '@vega/components/src/assets/images/bell.svg'
import { HeaderItem } from './HeaderItem'
import { TopTabNavigator } from '@vega/components'
import { routes } from 'routes'

const {
  broker: {
    applications: {
      list,
      overview: { details, propertySearch, submittedProperty, loanOutcome },
    },
  },
} = routes

const Container = styled.div(
  s('flex w-full bg-white flex-column', {
    minHeight: 200,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
  })
)
const IconContainer = styled.div(s('flex w-full justify-between pt-10 px-9'))
const ContentContainer = styled.div(s('flex pt-5', { paddingLeft: 25 }))
const Title = styled.h1(
  s('m-0 text-grey-800 font-semibold', {
    fontSize: 32,
    letterSpacing: '0.03em',
    marginRight: 55,
    lineHeight: 1.5,
  })
)
const NavigationContainer = styled.div(s('flex flex-1 items-end'))

const Header = ({ loanApplicationId }) => {
  const history = useHistory()

  const toApplicationListPage = () => history.push(list)

  const getApplicantFullNames = (applicants) =>
    pipe(
      map(pick(['firstName', 'lastName'])),
      map(values),
      map(joinNames),
      formatApplicantNames
    )(applicants)

  const applicants = useSelector(selectApplicantsDetails)
  const applicantNames = getApplicantFullNames(applicants)

  const routes = [
    {
      name: '1. Application Submitted',
      path: replace(':id', loanApplicationId, details),
    },
    {
      name: '2. Property Search',
      path: replace(':id', loanApplicationId, propertySearch),
    },
    {
      name: '3. Property Submitted',
      path: replace(':id', loanApplicationId, submittedProperty),
    },
    {
      name: '4. Loan approved',
      path: replace(':id', loanApplicationId, loanOutcome),
    },
  ]

  return (
    <Container>
      <IconContainer>
        <ChevronLeft style={{ cursor: 'pointer' }} onClick={toApplicationListPage} />
        <Bell style={{ width: 24 }} />
      </IconContainer>

      <ContentContainer>
        <Title>Overview</Title>
        <HeaderItem
          name="Applicant(s)"
          value={applicantNames}
          style={{ marginRight: 90 }}
        />
        <HeaderItem name="Available Cash" value="$50,000" style={{ marginRight: 90 }} />
        <HeaderItem
          name="Max Loan Amount"
          value="$500,000"
          style={{ marginRight: 90 }}
        />
        <HeaderItem name="Status" value="Pending" />
      </ContentContainer>

      <NavigationContainer>
        <TopTabNavigator
          routes={routes}
          containerStyle={s('bg-white', { paddingLeft: 9 })}
        />
      </NavigationContainer>
    </Container>
  )
}

export { Header }
