import React from 'react'

import moment from 'moment'

import { s, styled } from '@vega/styled'

const Root = styled.div(
  s('flex flex-column p-4 border-0 border-b-1 border-solid border-grey-200')
)

const Header = styled.div(s('flex flex-row'))

const HeaderText = styled.div(s('flex flex-column ml-1'))
const Name = styled.div(s('font-semibold text-grey-800 text-sm capitalize'))
const Timestamp = styled.div(s('mt-1 text-grey-600', { fontSize: 10 }))

const Content = styled.div(s('text-grey-800 text-sm mt-3'))

function formatNoteDate(createdAt) {
  const today = moment()
  const noteCreatedDate = moment(createdAt)

  const yearsSinceNoteCreated = today.diff(noteCreatedDate, 'year')

  if (yearsSinceNoteCreated > 0) {
    return noteCreatedDate.format('h:mm a MMMM d, yyyy')
  }

  const timeString = noteCreatedDate.format('h:mm a')
  const daysSinceNoteCreated = today.diff(noteCreatedDate, 'day')
  let dateString

  switch (daysSinceNoteCreated) {
    case 0:
      dateString = 'Today'
      break
    case 1:
      dateString = 'Yesterday'
      break
    default:
      dateString = noteCreatedDate.format('MMMM d')
  }

  return `${timeString} ${dateString}`
}

export const Note = ({ createdBy, createdAt, content }) => {
  return (
    <Root>
      <Header>
        <img
          src="https://ca.slack-edge.com/TFRDY29A7-U01LNDAF39A-9ad4e5bf5522-512"
          style={s('w-2 h-2 rounded-full mr-1')}
        />
        <HeaderText>
          <Name>{createdBy.name}</Name>
          <Timestamp>{formatNoteDate(createdAt)}</Timestamp>
        </HeaderText>
      </Header>
      <Content>{content}</Content>
    </Root>
  )
}
