import { styled, s } from '@vega/styled'
import {
  Prompt as OriginalPrompt,
  Title as OriginalTitle,
  Subtitle as OriginalSubtitle,
} from '../../components/typography'

const Title = styled(OriginalTitle)(s('mb-4'))
const Subtitle = styled(OriginalSubtitle)(s('mb-6'))
const Prompt = styled(OriginalPrompt)(s('mt-6 mb-4'))

export { Title, Subtitle, Prompt }
