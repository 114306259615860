import React from 'react'
import { Formik as FormProvider, Form } from 'formik'
import * as Validator from 'yup'
import { s } from '@vega/styled'
import { NumberField } from '@vega/components'
import { TextField, OutlinedBtn, Row, CardContainer } from './common'
import { checkAreObjectsTheSame } from './checkAreObjectsTheSame'

const { object, string } = Validator

const validationSchema = object({
  phoneNumber: string().required('Phone number is required'),
})

const handleSubmit = ({ phoneNumber }) => phoneNumber

const initialValues = {
  email: 'john@neobanks.com',
  phoneNumber: '',
}

export const ContactDetailsCard = ({ ...otherProps }) => {
  return (
    <FormProvider
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values: formValues, resetForm }) => {
        const isFormEdited = checkAreObjectsTheSame(formValues, initialValues)

        return (
          <Form>
            <CardContainer {...otherProps}>
              <TextField
                name="email"
                label="Email Address"
                placeholder="e.g. Joe"
                style={s('mb-6')}
                isDisabled
                onFocus={() => 'focus'}
              />

              <NumberField
                name="phoneNumber"
                aria-label="phone number"
                label="Phone number"
                prefix="+64"
                format="## ### ####"
              />

              {isFormEdited ? (
                <Row style={s('mt-6 justify-start')}>
                  <OutlinedBtn variant="contained" style={s('mr-4')}>
                    Save changes
                  </OutlinedBtn>

                  <OutlinedBtn onClick={resetForm}>Cancel</OutlinedBtn>
                </Row>
              ) : (
                <OutlinedBtn type="button" style={s('mt-6')}>
                  Save changes
                </OutlinedBtn>
              )}
            </CardContainer>
          </Form>
        )
      }}
    </FormProvider>
  )
}
