import { styled, s } from '@vega/styled'

const Row = styled.div(s('flex flex-row justify-between'))
const Column = styled.div(s('flex flex-1 border-red border-2'))
const Heading = styled.p(s('mb-3 text-near-black text-lg font-semibold'))
const Subheading = styled.p(s('mb-3 mt-3 text-near-black text-lg font-semibold'))
const Label = styled.span(s('text-base text-near-black mr-2'))
const Value = styled.span(s('text-base text-near-black font-semibold'))

export { Row, Column, Heading, Subheading, Label, Value }
