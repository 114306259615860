import React from 'react'
import { RadioButtonField, RadioButton, CheckboxField } from '@vega/components'
import { useFormikContext } from 'formik'
import {
  SectionTitle,
  Prompt as OriginalPrompt,
} from 'features/creditAssessor/applications/components/typography'
import { styled, s } from '@vega/styled'
import { Cards } from './Cards'
import { TEST_IDS } from '@vega/constants'

const FieldContainer = styled.div(s('mb-6'))

const Prompt = styled(OriginalPrompt)(s('mb-4'))

function CreditCard({ applicantName, parentPath, applicantPathId }) {
  const { values: formikValues } = useFormikContext()

  const hasCreditCard = Boolean(
    formikValues?.financials[applicantPathId]?.liabilities?.creditCard?.isOwned
  )

  return (
    <>
      <SectionTitle style={s('mt-6 mb-5')}>Next, credit card information</SectionTitle>

      <FieldContainer>
        <Prompt>Does {applicantName} have any credit cards?</Prompt>
        <RadioButtonField
          name={`${parentPath}.liabilities.creditCard.isOwned`}
          aria-label="personal loans"
          testId={TEST_IDS.HAS_CREDIT_CARDS_RB}
          isReadOnly
        >
          <RadioButton value={true}>Yes</RadioButton>
          <RadioButton value={false}>No</RadioButton>
        </RadioButtonField>
      </FieldContainer>

      {hasCreditCard && (
        <>
          <Cards
            parentPath={parentPath}
            applicantPathId={applicantPathId}
            applicantName={applicantName}
          />

          <CheckboxField
            name={`${parentPath}.liabilities.creditCard.confirmInfo`}
            size="md"
            aria-label="confirm info"
            containerProps={{ style: s('mt-8') }}
            testId={TEST_IDS.CONFIRM_CREDIT_CARD_CHECKBOX}
          >
            I confirm the credit card(s) information is correct
          </CheckboxField>
        </>
      )}
    </>
  )
}

export { CreditCard }
