import React from 'react'
import { styled, s } from '@vega/styled'

const TotalContainer = styled.div(s('absolute pin-tr-0', { right: 16, top: 74 }))
const TotalLabel = styled.p(
  s('text-base text-grey-500 font-normal', {
    lineHeight: 1.5,
  })
)
const TotalNumber = styled.span(s('text-base text-black font-medium ml-1'), {
  lineHeight: 1.5,
})

function Total({ total = 0, testId, ...otherProps }) {
  return (
    <TotalContainer {...otherProps}>
      <TotalLabel>
        Total:
        <TotalNumber data-test-id={testId}>
          {total < 10 && total > 0 ? `0${total}` : total}
        </TotalNumber>
      </TotalLabel>
    </TotalContainer>
  )
}

export { Total }
