import React from 'react'
import { ReactComponent as Check } from '@vega/components/src/assets/images/check.svg'
import { ReactComponent as XCircle } from '@vega/components/src/assets/images/x-circle.svg'

const StartIcon = ({ isComplete, hasError }) => {
  if (hasError) {
    return (
      <XCircle
        stroke="#F53244"
        width={20}
        height={20}
        style={{ transform: 'translateX(-20px)' }}
      />
    )
  }

  if (isComplete) {
    return <Check width={20} height={20} style={{ transform: 'translateX(-20px)' }} />
  }

  return null
}

export { StartIcon }
