import {
  equals,
  forEachObjIndexed,
  ifElse,
  isArray,
  length,
  map,
  partition,
  pipe,
  prop,
} from '@neo/ramda-extra'
import * as CONSTANTS from '@neo/constants'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
  },
} = CONSTANTS

export const calculateCompletionPercentage = pipe(
  getStatuses,
  countTotalAndCompletedSteps,
  calculatePercentage
)

function getStatuses(formProgress) {
  const statuses = []
  const isDynamic = isArray
  const forEachStep = forEachObjIndexed

  const addOneStatus = (status) => statuses.push(status)
  const addEachStatusToStatuses = (arr) => arr.forEach(addOneStatus)
  const extractStatusesFromEachApplicants = map(prop('status'))
  const aggregateEachApplicantStatus = pipe(
    extractStatusesFromEachApplicants,
    addEachStatusToStatuses
  )

  forEachStep(ifElse(isDynamic, aggregateEachApplicantStatus, addOneStatus))(
    formProgress
  )

  return statuses
}

function countTotalAndCompletedSteps(statuses) {
  const sortByCompletedStatus = partition(equals(COMPLETED))
  const countItemsInEachGroups = map(length)
  return pipe(sortByCompletedStatus, countItemsInEachGroups)(statuses)
}

function calculatePercentage([completed, incomplete]) {
  return (completed / (incomplete + completed)) * 100
}
