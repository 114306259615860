import React from 'react'
import { styled, s } from '@vega/styled'
import { useHistory } from 'react-router-dom'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'
import { HeaderItem } from './HeaderItem'
import { TopTabNavigator } from '@vega/components'
import { routes } from 'routes'
import { replace } from '@neo/ramda-extra'

const {
  creditAssessor: {
    applications: {
      list,
      overview: { personalAndFinance, property, approval },
    },
  },
} = routes

const Container = styled.div(
  s('flex w-full bg-white flex-column', {
    minHeight: 200,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
  })
)
const IconContainer = styled.div(s('flex w-full justify-between pt-10 px-5'))
const ContentContainer = styled.div(s('flex pt-5', { paddingLeft: 25 }))
const Title = styled.h1(
  s('m-0 text-grey-800 font-semibold', {
    fontSize: 32,
    letterSpacing: '0.03em',
    marginRight: 55,
    lineHeight: 1.5,
  })
)
const BackButton = styled.div(s('', { cursor: 'pointer' }))
const NavigationContainer = styled.div(s('flex flex-1 items-end'))

const Header = ({ applicantNames, brokerName, inspectedApplicationId }) => {
  const history = useHistory()

  const routes = [
    {
      name: '1. Personal and Finance',
      path: replace(':id', inspectedApplicationId, personalAndFinance),
    },
    {
      name: '2. Property',
      path: replace(':id', inspectedApplicationId, property),
    },
    {
      name: '3. Approval',
      path: replace(':id', inspectedApplicationId, approval),
    },
  ]

  const goToApplicationsList = () => history.push(list)

  return (
    <Container>
      <IconContainer>
        <BackButton onClick={goToApplicationsList}>
          <ChevronLeft />
        </BackButton>
      </IconContainer>

      <ContentContainer>
        <Title>Overview</Title>

        <HeaderItem
          name="Applicant(s)"
          value={applicantNames}
          style={{ marginRight: 90 }}
        />
        <HeaderItem name="Broker" value={brokerName} style={{ marginRight: 90 }} />
        <HeaderItem name="Available Cash" value="$50,000" style={{ marginRight: 90 }} />
        <HeaderItem
          name="Max Loan Amount"
          value="$500,000"
          style={{ marginRight: 90 }}
        />
      </ContentContainer>

      <NavigationContainer>
        <TopTabNavigator
          routes={routes}
          containerStyle={s('bg-white', { paddingLeft: 9 })}
        />
      </NavigationContainer>
    </Container>
  )
}

export { Header }
