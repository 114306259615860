import { trim, join, filter, isNotNilOrEmpty, pipe } from '@neo/ramda-extra'
import { APPLICATION_STATUSES, ITEM_STATUSES } from '../constants'
import * as CONSTANTS from '@neo/constants'

const {
  LOAN_APPLICATION: { STAGES },
} = CONSTANTS

const { DRAFT, PRE_APPROVED, FAILED } = APPLICATION_STATUSES
const { DEFAULT, VERIFIED, FAILED: VERIFY_FAILED } = ITEM_STATUSES

const joinNames = (names = []) =>
  pipe(filter(isNotNilOrEmpty), join(' '), trim)([...names])

const formatApplicantNames = (names = []) => {
  if (names.length > 2) return `${names[0]}, ${names[1]} & others`
  if (names.length === 2) return names.join(', ')
  return names[0]
}

const displayProgressStatus = (status) => {
  if (status === STAGES.PRE_APPROVED) return PRE_APPROVED
  if (status === STAGES.FAILED) return FAILED
  return DRAFT
}

const checkIdVerification = ({ documents = {} }) => {
  return documents?.passport?.isVerified || documents?.driverLicence?.isVerified
    ? VERIFIED
    : DEFAULT
}

const addBankAccountsLinked = (dataRetrieval) => (applicant) => {
  const data = dataRetrieval.find((client) => client.applicantId === applicant.id)

  applicant.accountsLinked = DEFAULT

  if (data?.status === 'completed') {
    applicant.accountsLinked = VERIFIED
  }

  return applicant
}

const addCreditReportStatus = (creditReports) => (applicant) => {
  const creditReport = creditReports.find(
    (report) => report.profile.applicantId === applicant.id
  )

  applicant.creditReportStatus = DEFAULT

  if (creditReport?.creditScores?.scoreResult === 'FAILED') {
    applicant.creditReportStatus = VERIFY_FAILED
  }

  if (creditReport?.creditScores?.scoreResult === 'PASSED') {
    applicant.creditReportStatus = VERIFIED
  }

  return applicant
}

export {
  joinNames,
  formatApplicantNames,
  displayProgressStatus,
  checkIdVerification,
  addBankAccountsLinked,
  addCreditReportStatus,
}
