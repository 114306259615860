import { defaultTo } from '@neo/ramda-extra'
import { normalize, schema } from 'normalizr'

export const normalizeSummaryReports = (reports) => {
  const monthlyReport = new schema.Entity('monthlyReport')

  const result = normalize(
    { target: reports },
    {
      target: [monthlyReport],
    }
  )

  return defaultTo({}, result.entities.monthlyReport)
}
