import { isNotNil } from '@neo/ramda-extra'

import { PageLong } from './mock/brokers'

const BrokerService = {
  async list({ query = '', limit, next }) {
    const config = {
      searchParams: {
        q: query,
        limit,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    return PageLong
    // Remove brokers mock data and uncomment code below to integrate when http client is ready
    // return service.get('brokers', config)
  },
}

export { BrokerService }
