import {
  all,
  clone,
  defaultTo,
  eqProps,
  identity,
  ifElse,
  isNotNil,
  isNotNilOrEmpty,
  isTrue,
  pipe,
  replaceAll,
  update,
} from '@neo/ramda-extra'

const hasDefinedAddress = (prop) => isNotNil(prop?.propertyAddress)
export const sameAddresses = (current = {}, mailing = {}) =>
  all(isTrue)([
    hasDefinedAddress(current),
    hasDefinedAddress(mailing),
    eqProps('propertyId')(current, mailing),
  ])

export const removePhoneNumberSpaces = (values, applicantPathId) => {
  const removeSpaces = replaceAll(' ', '')
  const copy = clone(values)
  const applicantPhoneNumber = copy?.details?.[applicantPathId].phoneNumber

  if (isNotNilOrEmpty(applicantPhoneNumber)) {
    copy.details[applicantPathId].phoneNumber = removeSpaces(applicantPhoneNumber)
  }

  return copy
}

const removeSpaces = replaceAll(' ', '')
const addSpaceAtIndex = (idx) => (input) =>
  update(idx, `${input[idx]} `)(input).join('')
const isAtLeastCharLong = (minLength) => (input) => input.length >= minLength
const addSpaceAfterNetworkCode = ifElse(
  isAtLeastCharLong(3),
  addSpaceAtIndex(1),
  identity
)
const splitPhoneNumberInTwo = ifElse(isAtLeastCharLong(7), addSpaceAtIndex(5), identity)

export const formatPhoneNumber = pipe(
  defaultTo(''),
  removeSpaces,
  addSpaceAfterNetworkCode,
  splitPhoneNumberInTwo
)
