import { useMemo } from 'react'
import {
  pipe,
  map,
  pick,
  join,
  ifElse,
  isNilOrEmpty,
  always,
  values,
  defaultTo,
} from '@neo/ramda-extra'

import fmt from 'utils/formatters'

const moreThanTwoApplicants = (names = []) => names.length > 2
const extractNames = pipe(map(pick(['firstName', 'lastName'])), map(values))
const makeFullName = pipe(map(fmt.capitalize), join(' '))
const joinApplicantNames = ifElse(
  isNilOrEmpty,
  always(''),
  pipe(
    extractNames,
    map(makeFullName),
    ifElse(moreThanTwoApplicants, (names) => `${names[0]} & Others`, join(', '))
  )
)

const mapToRowProps = map((app) => ({
  id: app?.id,
  createdAt: fmt.formatDate(app?.createdAt),
  brokerName: defaultTo('', app?.brokerName),
  applicantName: joinApplicantNames(app?.applicantsDetails),
  maxLoanAmount: fmt.formatMonetaryAmount(app?.intent?.borrowingAmount),
  // TODO: update this once we capture deposit in the backend
  deposit: fmt.formatMonetaryAmount(30000),
}))

export const Rows = (apps) => useMemo(() => mapToRowProps(apps), [apps])
