import React from 'react'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('flex flex-column bg-white rounded-lg border-1 border-solid border-grey-300 py-4', {
    width: '35%',
    '@media (max-width: 1060px)': s('w-full'),
  })
)

const Title = styled.h2(s('text-grey-800 text-base font-bold mb-3 px-8'))
const TableHeader = styled.div(
  s(
    'flex justify-between items-center border-0 border-b-1 border-solid border-grey-300 py-2 px-8'
  )
)
const HeaderItem = styled.span(s('text-grey-700 text-xs font-semibold'))

const TableBody = styled.div(s('text-center mt-4'))

function RecentActivitiesTable({ ...otherProps }) {
  return (
    <Container {...otherProps}>
      <Title>Recent activities</Title>

      <TableHeader>
        <HeaderItem>Applicant</HeaderItem>
        <HeaderItem>Date</HeaderItem>
        <HeaderItem>Action</HeaderItem>
      </TableHeader>

      <TableBody>
        <span style={s('text-grey-800 font-bold text-sm')}>No Transactions Yet</span>
      </TableBody>
    </Container>
  )
}

export { RecentActivitiesTable }
