import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { unwrapResult } from '@reduxjs/toolkit'
import { ASYNC_STATUSES, TEST_IDS, USER_ROLES } from '@vega/constants'
import { createInvitation, selectCreateInvitationStatus } from 'modules/invitation'
import { Formik as FormProvider, Form } from 'formik'
import * as Validator from 'yup'
import { ReactComponent as CloseIcon } from '@vega/components/src/assets/images/x.svg'
import { Button } from '@vega/components'
import { View } from './View'

const { string } = Validator

const Container = styled.div(s('w-full'))

const Title = styled.span(s('text-xl'))

const Header = styled.div(s('flex items-center justify-between mb-6'))

const InviteBtn = styled(Button)(s('ml-a text-base text-grey-600'))

const validationSchema = Validator.object({
  firstName: string().required('First name is required'),
  email: string().email().required('Email is required'),
  role: string()
    .test({
      name: 'is-client',
      exclusive: true,
      message: `Role must be Client`,
      test: (value) => value === USER_ROLES.CLIENT,
    })
    .required(),
})

function InvitationForm({ onClose }) {
  const dispatch = useDispatch()

  const onSubmit = async (values, action) => {
    try {
      const result = await dispatch(createInvitation(values))
      unwrapResult(result)
    } catch (error) {
      if (error?.description === 'EmailAlreadyUsed') {
        action.setFieldError(
          'email',
          'This email is already in use, please enter a different email'
        )
      }
    }
  }

  const isInvitingClient =
    useSelector(selectCreateInvitationStatus) === ASYNC_STATUSES.PENDING

  return (
    <Container>
      <Header>
        <Title>Invite new client</Title>
        <CloseIcon
          data-test-id={TEST_IDS.BROKER_INVITE_CLOSE_BUTTON}
          width={20}
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        />
      </Header>

      <FormProvider
        enableReinitialize
        initialValues={{
          firstName: '',
          email: '',
          role: USER_ROLES.CLIENT,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <View />
          <InviteBtn
            data-test-id={TEST_IDS.BROKER_INVITE_SUBMIT_BUTTON}
            type="submit"
            loading={isInvitingClient}
          >
            Invite
          </InviteBtn>
        </Form>
      </FormProvider>
    </Container>
  )
}

export { InvitationForm }
