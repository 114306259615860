import { pluck, equals, all } from '@neo/ramda-extra'

const getOverallApplicantsStatus = (applicants) => {
  const statuses = pluck('status', applicants)

  const isSubmitted = (value) => equals(value, 'Submitted')

  const allSubmitted = all(isSubmitted, statuses)

  const status = allSubmitted ? 'Submitted' : 'Pending'

  return status
}

export { getOverallApplicantsStatus }
