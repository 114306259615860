import React from 'react'
import { RadioButtonField, RadioButton } from '@vega/components'
import { Prompt } from './typography'
import { TEST_IDS } from '@vega/constants'

function FloatingOptions({ applicantName }) {
  return (
    <>
      <Prompt>Great. Now how would {applicantName} like to repay their loan?</Prompt>
      <RadioButtonField
        name="structure.repaymentType"
        aria-label="repayment type"
        isReadOnly
        testId={TEST_IDS.LOAN_REPAYMENT_TYPE_RB}
      >
        <RadioButton value="interest_and_principal">Principal and Interest</RadioButton>
        <RadioButton value="interest_only">Interest Only</RadioButton>
      </RadioButtonField>
      <Prompt>How regularly does {applicantName} want to make repayments?</Prompt>
      <RadioButtonField
        name="structure.repaymentFrequency"
        aria-label="repayment frequency"
        isReadOnly
        testId={TEST_IDS.LOAN_REPAYMENT_FREQ_RB}
      >
        <RadioButton value="weekly">Weekly</RadioButton>
        <RadioButton value="fortnightly">Fortnightly</RadioButton>
        <RadioButton value="monthly">Monthly</RadioButton>
      </RadioButtonField>
    </>
  )
}

export default FloatingOptions
