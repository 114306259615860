import React, { useRef } from 'react'

import { useSelector } from 'react-redux'

import { useOverlayTrigger } from '@react-aria/overlays'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { useTooltipTriggerState } from '@react-stately/tooltip'
import { mergeProps } from '@react-aria/utils'

import { s } from '@vega/styled'

import { selectNotes } from 'modules/application/selectors'

import { useTooltipTrigger } from './useTooltipTrigger'
import { NoteTooltip } from './NoteTooltip'
import { Notes } from './Notes'

// eslint-disable-next-line complexity
export const NotesProvider = ({
  name,
  alwaysShowTooltip = false,
  children,
  ...props
}) => {
  const tooltipState = useTooltipTriggerState(mergeProps(props, { delay: 0 }))
  const overlayState = useOverlayTriggerState({})

  const tooltipTriggerRef = useRef()
  const overlayTriggerRef = useRef()
  const overlayRef = useRef()

  const { triggerProps: tooltipTriggerProps, tooltipProps } = useTooltipTrigger(
    { isDisabled: overlayState.isOpen },
    tooltipState,
    tooltipTriggerRef
  )

  const { triggerProps: overlayTriggerProps, overlayProps } = useOverlayTrigger(
    { type: 'dialog' },
    overlayState,
    overlayTriggerRef
  )

  const notes = useSelector(selectNotes(name))

  const shouldShowTooltip = overlayState.isOpen
    ? false
    : notes.length > 0 || tooltipState.isOpen || alwaysShowTooltip

  return (
    <div style={s('relative')}>
      <div ref={tooltipTriggerRef} {...tooltipTriggerProps}>
        {children}
      </div>

      {shouldShowTooltip && (
        <NoteTooltip
          {...overlayTriggerProps}
          notes={notes}
          onClick={() => overlayState.open()}
          state={tooltipState}
          tooltipProps={tooltipProps}
          ref={overlayTriggerRef}
        />
      )}

      {overlayState.isOpen && (
        <Notes
          {...overlayProps}
          name={name}
          notes={notes}
          ref={overlayRef}
          isOpen={overlayState.isOpen}
          onClose={() => {
            overlayState.close()
            tooltipState.open(true)
          }}
        />
      )}
    </div>
  )
}
