import { createSelector } from '@reduxjs/toolkit'
import { financialInfo } from 'modules/application/initialFormValues'
import {
  defaultTo,
  equals,
  path,
  pipe,
  prop,
  propEq,
  isArray,
  omit,
  find,
  includes,
  keys,
  length,
  map,
  pick,
  concat,
} from '@neo/ramda-extra'
import { calculateCompletionPercentage } from './utils/formProgress'
import { USER_ROLES } from '@vega/constants'

const { CLIENT, BROKER } = USER_ROLES

const selectCreateApplicationStatus = path([
  'asyncState',
  'applications/create',
  'status',
])

const selectGetApplicationStatus = path(['asyncState', 'applications/get', 'status'])
const selectGetApplicationsStatus = path([
  'asyncState',
  'applications/getLoanApplications',
  'status',
])
const selectAddApplicantsToApplicationStatus = path([
  'asyncState',
  'applications/addApplicantsToApplication',
  'status',
])
const selectGetFinancialsRetrievalMetadataStatus = path([
  'asyncState',
  'applications/getFinancialsRetrievalMetadata',
  'status',
])

const selectCreateNoteStatus = path(['asyncState', 'applications/createNote', 'status'])

const selectApplicationsState = prop('applications')

const selectHasUnexpectedError = createSelector(
  selectApplicationsState,
  prop('hasUnexpectedError')
)

const selectApplicantIds = createSelector(selectApplicationsState, prop('applicantIds'))
const selectNotes = (name) =>
  createSelector(selectApplicationsState, pipe(path(['notes', name]), defaultTo([])))

const selectApplicationId = createSelector(
  selectApplicationsState,
  prop('inspectedApplicationId')
)

const selectFormState = createSelector(selectApplicationsState, prop('applicationForm'))
const selectNumOfApplicants = createSelector(selectApplicantIds, length)

const selectFormProgress = createSelector(selectFormState, path(['status', 'progress']))
const selectFormStatus = createSelector(selectFormState, prop('status'))

const selectProgressStepStatus = (stepName, applicantId) =>
  createSelector(selectFormProgress, (formProgress) => {
    const validProgressSteps = keys(formProgress)
    if (!includes(stepName, validProgressSteps))
      throw new Error(
        `selector:selectProgressStepStatus: Invalid stepName ${stepName}, please check the keys of formProgress.`
      )

    const stepStatus = formProgress[stepName]

    const isDynamic = isArray
    if (isDynamic(stepStatus))
      return pipe(find(propEq('applicantId', applicantId)), prop('status'))(stepStatus)

    return stepStatus
  })

const selectCompletionPercentage = (role) =>
  createSelector(selectFormProgress, (formProgress) => {
    const sharedIgnoredSteps = ['preApproval']
    const getIgnoredStepsByRole = {
      [CLIENT]: concat(sharedIgnoredSteps, ['clientInvitation', 'applicantApproval']),
      [BROKER]: concat(sharedIgnoredSteps, ['applicantApproval']),
    }

    return pipe(
      omit(getIgnoredStepsByRole[role]),
      calculateCompletionPercentage
    )(formProgress)
  })

const selectCanSubmitApplication = (role) =>
  createSelector(
    selectCompletionPercentage(role),
    (completionPercentage) => completionPercentage >= 100
  )

const selectUpdateStructureStatus = path([
  'asyncState',
  'loan-applications/updateStructure',
  'status',
])

const selectFinancialsRetrieval = createSelector(
  selectApplicationsState,
  prop('financialsRetrieval')
)

const selectFinancialsRetrievalMetadata = (applicantId) =>
  createSelector(selectFinancialsRetrieval, ({ metadata }) => metadata?.[applicantId])

const selectSubmitApplicationStatus = path([
  'asyncState',
  'applications/submit',
  'status',
])

const selectStartCreditCheckStatus = path([
  'asyncState',
  'applications/startCreditCheck',
  'status',
])

const selectAddApplicantsToApplicationError = path([
  'asyncState',
  'applications/addApplicantsToApplication',
  'error',
])

const selectUpdateClientIdentificationError = path([
  'asyncState',
  'applications/updateClientIdentification',
  'error',
])

const selectUpdateApplicantError = path([
  'asyncState',
  'applications/updateApplicant',
  'error',
])

const selectCreditReport = (applicantPathId) =>
  createSelector(selectApplicationsState, (applications) => {
    const creditReport =
      applications?.applicationForm?.financials?.[applicantPathId]?.creditReport

    const initialCreditReport = financialInfo?.creditReport
    const isDefaultReport = equals(creditReport, initialCreditReport)

    return isDefaultReport ? null : creditReport
  })

const selectTotal = createSelector(selectApplicationsState, prop('total'))

const selectSelectedLoanApplication = createSelector(
  selectApplicationsState,
  prop('selectedLoanApplication')
)

const selectApplicants = createSelector(
  selectSelectedLoanApplication,
  pipe(path(['loanApplication', 'applicantsDetails']), defaultTo([]))
)

const selectLoanStructure = createSelector(
  selectSelectedLoanApplication,
  prop('loanStructure')
)

const selectIntent = createSelector(
  selectSelectedLoanApplication,
  path(['loanApplication', 'intent'])
)

const selectApplicantsDetails = createSelector(
  selectSelectedLoanApplication,
  pipe(
    path(['loanApplication', 'applicantsDetails']),
    defaultTo([]),
    map(pick(['id', 'documents', 'firstName', 'lastName']))
  )
)

const selectDataRetrieval = createSelector(
  selectSelectedLoanApplication,
  pipe(path(['loanApplication', 'financialsMetadata', 'dataRetrieval']), defaultTo([]))
)

const selectCreditReports = createSelector(
  selectSelectedLoanApplication,
  pipe(path(['loanApplication', 'creditReports']), defaultTo([]))
)

const selectBrokerName = createSelector(
  selectSelectedLoanApplication,
  pipe(path(['loanApplication', 'brokerName']), defaultTo(''))
)

const selectCreditAssessorCheck = createSelector(
  selectSelectedLoanApplication,
  pipe(path(['loanApplication', 'creditAssessorCheck']), defaultTo({}))
)

export {
  selectCreateApplicationStatus,
  selectAddApplicantsToApplicationStatus,
  selectGetFinancialsRetrievalMetadataStatus,
  selectHasUnexpectedError,
  selectApplicationId,
  selectFormState,
  selectFormProgress,
  selectSelectedLoanApplication,
  selectUpdateStructureStatus,
  selectApplicantIds,
  selectGetApplicationStatus,
  selectFormStatus,
  selectAddApplicantsToApplicationError,
  selectUpdateClientIdentificationError,
  selectUpdateApplicantError,
  selectFinancialsRetrievalMetadata,
  selectSubmitApplicationStatus,
  selectCanSubmitApplication,
  selectProgressStepStatus,
  selectCompletionPercentage,
  selectCreditReport,
  selectStartCreditCheckStatus,
  selectNumOfApplicants,
  selectTotal,
  selectGetApplicationsStatus,
  selectDataRetrieval,
  selectApplicantsDetails,
  selectCreditReports,
  selectApplicants,
  selectLoanStructure,
  selectIntent,
  selectNotes,
  selectCreateNoteStatus,
  selectBrokerName,
  selectCreditAssessorCheck,
}
