import { all, map, prop, isNilOrEmpty, isFalse, values } from '@neo/ramda-extra'
import moment from 'moment'

const isNilOrEmptyOrFalse = (v) => isNilOrEmpty(v) || isFalse(v)

export const mapToApplicantDocModel = (documents) => {
  if (all(isNilOrEmptyOrFalse, values(prop('passport', documents)))) {
    delete documents.passport
  }

  if (all(isNilOrEmptyOrFalse, values(prop('driverLicence', documents)))) {
    delete documents.driverLicence
  }

  return map(({ expiryDate, ...props }) => {
    return {
      expiryDate: moment(expiryDate, 'DD MM YYYY').format('YYYY-MM-DD').toString(),
      ...props,
    }
  }, documents)
}
