import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { routes } from 'routes'
import { unwrapResult } from '@reduxjs/toolkit'
import { styled, s } from '@vega/styled'
import { Wizard, useWizard } from '@vega/formik-wizard'
import {
  addApplicantsToApplication,
  resetForm,
  updateFormProgress,
  selectAddApplicantsToApplicationStatus,
  getLoanApplication,
} from 'modules/application'
import { FormProgress } from '../../components/FormProgress/FormProgress'
import { formSubmitErrorHandler } from './formSubmitErrorHandler'
import { InfoSidebar } from './InfoSidebar'
import { BottomNavigation } from './BottomNavigation'
import { RegistrationForm } from './RegistrationForm'
import { Title } from 'features/broker/applications/components/typography'
import { ASYNC_STATUSES } from '@vega/constants'
import * as CONSTANTS from '@neo/constants'
import { validationSchema } from './validationSchema'

const {
  FORM_PROGRESS: {
    STEP_STATUS: { COMPLETED },
    STEP_NAMES: { CLIENT_INVITATION },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  '> :last-child': s('pb-12'),
  width: 652,
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const ClientInvitationStep = ({ stepId }) => {
  const { id } = useParams()
  const { currentStepIndex, next } = useWizard()
  const isAddingApplicantsToApplication =
    useSelector(selectAddApplicantsToApplicationStatus) === ASYNC_STATUSES.PENDING

  const dispatch = useDispatch()
  const history = useHistory()

  const saveAndContinue = async ({ invitations }, action) => {
    try {
      const result = await dispatch(addApplicantsToApplication(invitations))
      unwrapResult(result)

      dispatch(
        updateFormProgress({
          step: CLIENT_INVITATION,
          status: COMPLETED,
        })
      )

      next()
      dispatch(getLoanApplication(id))
    } catch (error) {
      formSubmitErrorHandler(error, action)
    }
  }

  const goToApplicationList = () => history.replace(routes.broker.applications.list)

  const saveAndExit = () => {
    dispatch(resetForm())
    goToApplicationList()
  }

  return (
    <Wizard.Step
      id={stepId}
      title="Client invitation"
      validationSchema={validationSchema}
      onSubmit={useCallback(saveAndContinue, [currentStepIndex])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title style={s('mb-6')}>Register your applicant(s)</Title>
              <RegistrationForm />
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation isLoading={isAddingApplicantsToApplication} />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { ClientInvitationStep }
