import React from 'react'
import { replace } from '@neo/ramda-extra'
import { Wizard } from '@vega/formik-wizard'
import { routes } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectGetApplicationStatus,
  selectFormState,
} from 'modules/application/selectors'

import { s } from '@vega/styled'

import View from './View'
import { useHistory, useParams } from 'react-router-dom'
import { getLoanApplication, submitApplication } from 'modules/application'
import { ErrorPage, Loading } from '@vega/components'
import { ASYNC_STATUSES } from '@vega/constants'
import { generateSteps, removeIdentifier } from './utils'
import { useHideNavDrawer } from 'hooks/useHideNavDrawer'

const { PENDING, REJECTED } = ASYNC_STATUSES

function CreateApplicationWizard() {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  useHideNavDrawer()

  React.useEffect(() => {
    dispatch(getLoanApplication(id))
  }, [dispatch, id])

  const getApplicationStatus = useSelector(selectGetApplicationStatus)

  const { details, financials, intent, structure, preApproval } = useSelector(
    selectFormState
  )

  const orderedSteps = React.useMemo(() => generateSteps(1), [])

  if (getApplicationStatus === REJECTED) return <ErrorPage />

  const goToApplicationStatusPreview = () =>
    history.replace(replace(':id', id, routes.client.applications.statusPreview))

  const submitAndExit = () => {
    dispatch(submitApplication())
    goToApplicationStatusPreview()
  }

  // NOTE: cannot use the Wizard loader as we need to wait for server to send back numOfApplicants before rendering the form.
  if (getApplicationStatus === PENDING)
    return (
      <div style={s('w-full')}>
        <Loading style={s('m-a')} />
      </div>
    )

  return (
    <Wizard
      savedStepId={orderedSteps[0]}
      order={orderedSteps}
      formatStepInUrl={removeIdentifier}
      initialValues={{
        details,
        financials,
        intent,
        structure,
        preApproval,
      }}
      basePath={`${routes.client.applications.create}/${id}`}
      formProps={{
        style: s('flex-1 flex flex-column', { overflow: 'hidden' }),
      }}
      onSubmit={submitAndExit}
    >
      <View loanApplicationId={id} />
    </Wizard>
  )
}

export { CreateApplicationWizard }
