import { financialInfo } from 'modules/application/initialFormValues'
import { filter, mergeDeepRight, defaultTo, pipe } from '@neo/ramda-extra'

const mergeToInitialValues = (creditReport) =>
  mergeDeepRight(financialInfo.creditReport, creditReport)

export const mapToCreditReportViewModel = (applicantId) => (creditReports = []) => {
  const filterApplicant = (applicantId) => (creditReport) =>
    creditReport.applicantId === applicantId

  const getCreditObj = (reports) => reports[0]

  return pipe(
    filter(filterApplicant(applicantId)),
    getCreditObj,
    defaultTo(financialInfo.creditReport),
    mergeToInitialValues
  )(creditReports)
}
