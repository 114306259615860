import React, { useEffect, useCallback } from 'react'
import * as Validator from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetForm,
  updateStructure,
  selectProgressStepStatus,
} from 'modules/application'
import { useWizard, Wizard } from '@vega/formik-wizard'
import { useHistory } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { styled, s } from '@vega/styled'
import { and, isNilOrEmpty, isNotNilOrEmpty, replace } from '@neo/ramda-extra'

import { routes } from 'routes'
import { BottomNavigation } from './BottomNavigation'
import { FormProgress } from '../../components/FormProgress/FormProgress'
import { InfoSidebar } from './InfoSidebar'
import { Prompt, Title, Subtitle } from './typography'

import {
  // FlexibilityLevel,
  // LoanFlexibilityPreferenceField,
  RadioButtonField,
  RadioButton,
} from '@vega/components'

import * as CONSTANTS from '@neo/constants'
// import FixedOptions from './FixedOptions'
import FloatingOptions from './FloatingOptions'
// import FixedAndFloatingOptions from './FixedAndFloatingOptions'
import LoanTerms from './LoanTerms'

import { TEST_IDS } from '@vega/constants'
import { markProgressStepAsVisited } from 'features/broker/applications/components/FormProgress/utils'

const {
  FORM_PROGRESS: {
    STEP_NAMES: { OPTIONS },
  },
} = CONSTANTS

const Root = styled.div(s('flex-1 flex items-center w-full h-full relative'))
const ProgressContainer = styled.div(s('bg-white h-full'))

const Core = styled.div(s('flex flex-column w-full h-full'))
const Content = styled.div(
  s('flex-1 flex justify-center w-full pt-6', {
    paddingRight: 200,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const FormContainer = styled.div(s('flex flex-column pt-1'), {
  width: 652,
  '> :last-child': s('pb-12'),
})
const InfoContainer = styled.div(s('relative', { pointerEvents: 'none' }))
const NavigationContainer = styled.div(s('relative zIndex-2'))

const { object, number, boolean, mixed } = Validator

const requiredEnum = mixed().required()
const requiredNumber = number().required()
const requiredBoolean = boolean().required()

const validationSchema = object({
  structure: object({
    loanFlexibilityPreference: mixed()
      .oneOf([1, 2, 3, 4, 5])
      .label('Client Priority')
      .default(1),
    loanRateType: requiredEnum
      .oneOf(['fixed', 'floating', 'fixed_and_floating'])
      .label('Loan Rate Type'),
    fixedLoanRateTerm: mixed().when('loanRateType', {
      is: (type) => ['fixed', 'fixed_and_floating'].includes(type),
      then: requiredEnum.oneOf([1, 2, 3, 4, 5]).label('Loan Rate Term'),
      otherwise: mixed().notRequired(),
    }),
    repaymentType: requiredEnum
      .oneOf(['interest_and_principal', 'interest_only'])
      .label('Repayment Type'),
    repaymentFrequency: requiredEnum
      .oneOf(['weekly', 'fortnightly', 'monthly'])
      .label('Repayment Frequency'),
    maxLoanTerm: requiredBoolean.label('Max Loan Term'),
    loanTerm: number()
      .when(['maxLoanTerm'], {
        is: true,
        then: requiredNumber.default(30),
        otherwise: requiredNumber.positive().max(29),
      })
      .label('Loan Term Length'),
    offsetAccount: requiredBoolean.label('Offset Account'),
  }),
})

function YourOptionsStep({ stepId, loanApplicationId }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const saveAndContinue = ({ structure }) => {
    dispatch(updateStructure(structure))
  }

  const {
    values: { structure },
    errors,
  } = useFormikContext()

  const loanRateType = structure?.loanRateType

  const goToOverview = () =>
    history.push(
      replace(':id', loanApplicationId, routes.client.applications.overview.details)
    )
  const saveAndExit = () => {
    dispatch(updateStructure(structure))
    dispatch(resetForm())
    goToOverview()
  }

  const { previous, currentStep } = useWizard()
  const goBack = () => previous()
  const isLoanRateTypePicked = isNotNilOrEmpty(loanRateType)
  const isFormValid = isNilOrEmpty(errors?.structure)
  const canNext = and(isLoanRateTypePicked, isFormValid)

  const renderLoanOptions = (type) => {
    return {
      // fixed: <FixedOptions />,
      floating: <FloatingOptions />,
      // eslint-disable-next-line camelcase
      // fixed_and_floating: <FixedAndFloatingOptions />,
    }[type]
  }

  const currentStepStatus = useSelector(selectProgressStepStatus(OPTIONS))
  useEffect(() => {
    if (currentStep?.id === stepId)
      markProgressStepAsVisited(OPTIONS, currentStepStatus)
  }, [currentStep, currentStepStatus, stepId])

  return (
    <Wizard.Step
      id={stepId}
      title="Your options"
      validationSchema={validationSchema}
      onSubmit={useCallback(saveAndContinue, [])}
    >
      <Root>
        <ProgressContainer>
          <FormProgress saveAndExit={saveAndExit} />
        </ProgressContainer>

        <Core>
          <Content>
            <FormContainer>
              <Title>Your Options</Title>
              <Subtitle>
                We&apos;re going to ask some questions to work out the best loan for
                your position
              </Subtitle>
              {/* <Prompt style={s('mt-0', { marginBottom: 2 })}>
                What is more important to you?
              </Prompt>
              <LoanFlexibilityPreferenceField
                name="structure.loanFlexibilityPreference"
                aria-label="client priority"
                defaultValue={1}
                data-test-id={TEST_IDS.LOAN_FLEXABILITY_RB}
              >
                <FlexibilityLevel value={1}>1</FlexibilityLevel>
                <FlexibilityLevel value={2}>2</FlexibilityLevel>
                <FlexibilityLevel value={3}>3</FlexibilityLevel>
                <FlexibilityLevel value={4}>4</FlexibilityLevel>
                <FlexibilityLevel value={5}>5</FlexibilityLevel>
              </LoanFlexibilityPreferenceField> */}
              <Prompt>How do you prefer your loan rate?</Prompt>
              <RadioButtonField
                name="structure.loanRateType"
                aria-label="loan rate type"
                testId={TEST_IDS.LOAN_RATE_TYPE_RB}
              >
                <RadioButton value="fixed" readOnly>
                  Fixed
                </RadioButton>
                <RadioButton value="floating">Floating</RadioButton>
                <RadioButton value="fixed_and_floating" readOnly>
                  Fixed and Floating Combo
                </RadioButton>
              </RadioButtonField>
              {loanRateType && renderLoanOptions(loanRateType)}
              {loanRateType && <LoanTerms />}
            </FormContainer>

            <InfoContainer>
              <InfoSidebar />
            </InfoContainer>
          </Content>

          <NavigationContainer>
            <BottomNavigation goBack={goBack} canNext={canNext} />
          </NavigationContainer>
        </Core>
      </Root>
    </Wizard.Step>
  )
}

export { YourOptionsStep }
