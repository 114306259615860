/* eslint-disable complexity */
import React, { useEffect } from 'react'
import { reduce } from '@neo/ramda-extra'
import { FieldArray, useFormikContext } from 'formik'
import { financialInfo } from 'modules/application/initialFormValues'
import {
  EMPLOYMENT_BASIS_OPTS,
  ROLES_OPTS,
  EMPLOYMENT_NATURE_OPTS,
} from '../selectOptions'
import { ReactComponent as PlusIcon } from '@vega/components/src/assets/images/plus.svg'
import {
  Button,
  TextField,
  RadioButton,
  RadioButtonField,
  CheckboxField,
  SelectField,
} from '@vega/components'
import { EmploymentPeriod } from './EmploymentPeriod'

import { NotesProvider } from 'features/broker/applications/components/NotesProvider'
import { Prompt as OriginalPrompt } from 'features/broker/applications/components/typography'

import { styled, s } from '@vega/styled'

import { TEST_IDS } from '@vega/constants'

const Prompt = styled(OriginalPrompt)(s('mt-6 mb-4'))
const EmploymentTitle = styled.h2(s('text-xl mb-4'))
const DeleteEmploymentBtn = styled(Button)(
  s(
    'bg-transparent border-b-1 border-solid rounded-none p-0 mt-4 text-grey-800 border-grey-800'
  )
)
const AddEmploymentBtn = styled(Button)(
  s(
    'bg-transparent border-b-1 border-solid rounded-none p-0 mt-4 text-primary border-primary'
  )
)

const accumulateTotalCurrentSalary = (accumulator, employment) => {
  if (employment?.inRole) return accumulator + Number(employment.remunerationAmount)
  return accumulator
}

function Employment({ applicantName, parentPath, applicantPathId }) {
  const { values, setFieldValue } = useFormikContext()
  const employments = values?.financials[applicantPathId]?.employment || []

  useEffect(() => {
    setTotalSalary()

    function setTotalSalary() {
      const totalSalary = reduce(accumulateTotalCurrentSalary, 0, employments)
      setFieldValue(`${parentPath}.salary.amount`, totalSalary)
    }
  }, [employments, parentPath, setFieldValue])

  return (
    <FieldArray
      name={`${parentPath}.employment`}
      render={(arrayHelpers) => (
        <div>
          {employments.map((employment, idx) => {
            const employmentPath = `${parentPath}.employment.${idx}`

            const inRole = values?.financials[applicantPathId]?.employment[idx]?.inRole
            const isOvertimeEligible =
              values?.financials[applicantPathId]?.employment[idx]?.overtimeEligible
            const typeOfAdditionalPayment =
              values?.financials[applicantPathId]?.employment[idx]
                ?.typeOfAdditionalPayment

            return (
              <div key={idx} name={employment[idx]}>
                <EmploymentTitle>Employment {idx + 1}</EmploymentTitle>

                <NotesProvider name={`${employmentPath}.employmentNature`}>
                  <Prompt style={s('mt-0')}>
                    What is the nature of {applicantName}&apos;s employment?
                  </Prompt>
                  <SelectField
                    name={`${employmentPath}.employmentNature`}
                    aria-label="nature of employment"
                    options={EMPLOYMENT_NATURE_OPTS}
                    testId={`${TEST_IDS.EMPLOYMENT_NATURE_DROPDOWN}${idx + 1}`}
                    menuId={`${TEST_IDS.EMPLOYMENT_NATURE_DROPDOWN_MENU}${idx + 1}`}
                  />
                </NotesProvider>

                <NotesProvider name={`${employmentPath}.employmentBasis`}>
                  <Prompt>What is {applicantName}&apos;s employment basis?</Prompt>
                  <SelectField
                    name={`${employmentPath}.employmentBasis`}
                    aria-label="nature of employment"
                    options={EMPLOYMENT_BASIS_OPTS}
                    testId={`${TEST_IDS.EMPLOYMENT_BASIS_DROPDOWN}${idx + 1}`}
                    menuId={`${TEST_IDS.EMPLOYMENT_BASIS_DROPDOWN_MENU}${idx + 1}`}
                  />
                </NotesProvider>

                <NotesProvider name={`${employmentPath}.employerName`}>
                  <Prompt style={s('mb-0')}>Who is the employer?</Prompt>
                  <TextField
                    name={`${employmentPath}.employerName`}
                    aria-label="employer name"
                    options={EMPLOYMENT_BASIS_OPTS}
                    placeholder="ex. Google"
                    testId={`${TEST_IDS.EMPLOYER_NAME_INPUT}${idx + 1}`}
                  />
                </NotesProvider>

                <NotesProvider name={`${employmentPath}.inRole`}>
                  <Prompt>How long has {applicantName} worked here?</Prompt>
                  <CheckboxField
                    name={`${employmentPath}.inRole`}
                    size="md"
                    aria-label="still in role"
                    style={s('mb-4')}
                    testId={`${TEST_IDS.CURRENT_EMPLOYER_CHECKBOX}${idx + 1}`}
                  >
                    Currently working here
                  </CheckboxField>

                  <EmploymentPeriod
                    employmentPath={employmentPath}
                    inRole={inRole}
                    idx={idx}
                  />
                </NotesProvider>

                <NotesProvider name={`${employmentPath}.roleType`}>
                  <Prompt>
                    Which of these roles best describes what {applicantName} does?
                  </Prompt>
                  <SelectField
                    name={`${employmentPath}.roleType`}
                    options={ROLES_OPTS}
                    testId={`${TEST_IDS.ROLE_TYPE_DROPDOWN}${idx + 1}`}
                    menuId={`${TEST_IDS.ROLE_TYPE_DROPDOWN_MENU}${idx + 1}`}
                  />
                </NotesProvider>

                <NotesProvider name={`${employmentPath}.remunerationAmount`}>
                  <Prompt style={s('mb-0')}>
                    What is {applicantName}&apos;s annual salary? (excluding overtime,
                    commissions, and bonuses). How much do they earn before tax, but not
                    including super? (gross paid)
                  </Prompt>
                  <TextField
                    name={`${employmentPath}.remunerationAmount`}
                    aria-label="annualSalary"
                    prefix="$"
                    suffix="p.a."
                    containerStyle={s('w-1/2')}
                    testId={`${TEST_IDS.ANNUAL_SALARY_INPUT}${idx + 1}`}
                  />
                </NotesProvider>

                <NotesProvider name={`${employmentPath}.payRecurrence`}>
                  <Prompt>How frequently does {applicantName} get paid?</Prompt>
                  <RadioButtonField
                    name={`${employmentPath}.payRecurrence`}
                    aria-label="payment frequency"
                    testId={`${TEST_IDS.PAY_FREQUENCY_RB}${idx + 1}`}
                  >
                    <RadioButton value="weekly">Weekly</RadioButton>
                    <RadioButton value="fortnightly">Fortnightly</RadioButton>
                    <RadioButton value="monthly">Monthly</RadioButton>
                    <RadioButton value="irregular">Irregular</RadioButton>
                  </RadioButtonField>
                </NotesProvider>

                <NotesProvider name={`${employmentPath}.overtimeEligible`}>
                  <Prompt>Does {applicantName} get paid regular overtime?</Prompt>
                  <RadioButtonField
                    name={`${employmentPath}.overtimeEligible`}
                    aria-label="Overtime Eligibility"
                    testId={`${TEST_IDS.OVERTIME_PAY_RB}${idx + 1}`}
                  >
                    <RadioButton value={true}>Yes</RadioButton>
                    <RadioButton value={false}>No</RadioButton>
                  </RadioButtonField>
                </NotesProvider>

                {isOvertimeEligible && (
                  <>
                    <NotesProvider name={`${employmentPath}.overtimeAmount`}>
                      <Prompt style={s('mb-0')}>
                        What is the total overtime amount?
                      </Prompt>
                      <TextField
                        name={`${employmentPath}.overtimeAmount`}
                        aria-label="bonus amount"
                        prefix="$"
                        containerStyle={s('w-1/2')}
                        testId={`${TEST_IDS.OVERTIME_AMOUNT_INPUT}${idx + 1}`}
                      />
                    </NotesProvider>
                    <NotesProvider
                      name={`${employmentPath}.overtimePaymentRecurrence}`}
                    >
                      <Prompt>
                        How frequently does {applicantName} get overtime payments?
                      </Prompt>
                      <RadioButtonField
                        name={`${employmentPath}.overtimePaymentRecurrence`}
                        aria-label="overtime recurrence"
                        testId={`${TEST_IDS.OVERTIME_FREQ_RB}${idx + 1}`}
                      >
                        <RadioButton value="weekly">Weekly</RadioButton>
                        <RadioButton value="fortnightly">Fortnightly</RadioButton>
                        <RadioButton value="monthly">Monthly</RadioButton>
                        <RadioButton value="irregular">Irregular</RadioButton>
                      </RadioButtonField>
                    </NotesProvider>
                  </>
                )}

                <NotesProvider name={`${employmentPath}.typeOfAdditionalPayment`}>
                  <Prompt>
                    Does {applicantName} earn commission or bonus payments?
                  </Prompt>
                  <RadioButtonField
                    name={`${employmentPath}.typeOfAdditionalPayment`}
                    aria-label="Commission or Bonus"
                    containerStyle={s('mb-6')}
                    testId={`${TEST_IDS.BONUS_PAY_RB}${idx + 1}`}
                  >
                    <RadioButton value="no">No</RadioButton>
                    <RadioButton value="commission">Commission</RadioButton>
                    <RadioButton value="bonus">Bonus</RadioButton>
                  </RadioButtonField>
                </NotesProvider>

                {typeOfAdditionalPayment === 'bonus' && (
                  <>
                    <NotesProvider name={`${employmentPath}.bonusAmount`}>
                      <Prompt style={s('mb-0')}>
                        What is the total bonus payments amount?
                      </Prompt>
                      <TextField
                        name={`${employmentPath}.bonusAmount`}
                        aria-label="bonus amount"
                        prefix="$"
                        containerStyle={s('w-1/2')}
                        testId={`${TEST_IDS.BONUS_AMOUNT_INPUT}${idx + 1}`}
                      />
                    </NotesProvider>

                    <NotesProvider name={`${employmentPath}.bonusRecurrence`}>
                      <Prompt>
                        How frequently does {applicantName} get bonus payments?
                      </Prompt>
                      <RadioButtonField
                        name={`${employmentPath}.bonusRecurrence`}
                        aria-label="bonus recurrence"
                        testId={`${TEST_IDS.BONUS_FREQ_RB}${idx + 1}`}
                      >
                        <RadioButton value="weekly">Weekly</RadioButton>
                        <RadioButton value="fortnightly">Fortnightly</RadioButton>
                        <RadioButton value="monthly">Monthly</RadioButton>
                        <RadioButton value="irregular">Irregular</RadioButton>
                      </RadioButtonField>
                    </NotesProvider>
                  </>
                )}
                {typeOfAdditionalPayment === 'commission' && (
                  <>
                    <NotesProvider name={`${employmentPath}.commissionAmount`}>
                      <Prompt style={s('mb-0')}>
                        What is the total commission amount?
                      </Prompt>
                      <TextField
                        name={`${employmentPath}.commissionAmount`}
                        aria-label="commission amount"
                        prefix="$"
                        containerStyle={s('w-1/2')}
                        testId={`${TEST_IDS.COMMISSION_AMOUNT_INPUT}${idx + 1}`}
                      />
                    </NotesProvider>

                    <NotesProvider name={`${employmentPath}.comissionRecurrence`}>
                      <Prompt>
                        How frequently does {applicantName} get commission?
                      </Prompt>
                      <RadioButtonField
                        name={`${employmentPath}.commissionRecurrence`}
                        aria-label="commission recurrence"
                        containerStyle={s('mb-6')}
                        testId={`${TEST_IDS.COMMISSION_FREQ_RB}${idx + 1}`}
                      >
                        <RadioButton value="weekly">Weekly</RadioButton>
                        <RadioButton value="fortnightly">Fortnightly</RadioButton>
                        <RadioButton value="monthly">Monthly</RadioButton>
                        <RadioButton value="irregular">Irregular</RadioButton>
                      </RadioButtonField>
                    </NotesProvider>
                  </>
                )}

                {idx !== 0 && (
                  <DeleteEmploymentBtn
                    type="button"
                    onClick={() => arrayHelpers.remove(idx)}
                    style={s('my-6')}
                    data-test-id={`${TEST_IDS.DELETE_EMPLOYMENT_BTN}${idx + 1}`}
                  >
                    Delete employment
                  </DeleteEmploymentBtn>
                )}
              </div>
            )
          })}

          <EmploymentTitle>Have another employment?</EmploymentTitle>

          <AddEmploymentBtn
            StartIcon={<PlusIcon width={13} />}
            type="button"
            onClick={() => arrayHelpers.push(financialInfo?.employment?.[0])}
            style={s('my-6')}
          >
            <span style={s('mt-1 ml-1')} data-test-id={TEST_IDS.ADD_EMPLOYMENT_BTN}>
              Add employment details
            </span>
          </AddEmploymentBtn>
        </div>
      )}
    />
  )
}

export { Employment }
