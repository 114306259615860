import { s } from '@vega/styled'
import React from 'react'

import Actions from './Actions'

export const addRowActions = ({ rows, actions }) =>
  rows.map((r) => {
    return {
      ...r,
      actions: <Actions recordId={r.id} actions={actions} />,
    }
  })

const textEllipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const Columns = () =>
  React.useMemo(
    () => [
      {
        Header: 'Full Name',
        accessor: 'fullName',
        tableHeaderStyles: s('inline-block text-left pl-5 pt-4', {
          width: '15%',
        }),
        tableCellStyles: s('inline-block text-left pl-5 pt-1 flex items-center', {
          width: '15%',
          ...textEllipsis,
        }),
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableSortBy: true,
        tableHeaderStyles: s('inline-block text-left pt-4', {
          width: '20%',
        }),
        tableCellStyles: s('inline-block text-left pt-1 flex items-center', {
          width: '20%',
          ...textEllipsis,
        }),
      },
      {
        Header: 'Current Address',
        accessor: 'currentAddress',
        disableSortBy: true,
        tableHeaderStyles: s('inline-block text-left pt-4', {
          width: '30%',
        }),
        tableCellStyles: s('inline-block text-left pt-1 flex items-center', {
          width: '30%',
          ...textEllipsis,
        }),
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        disableSortBy: true,
        tableHeaderStyles: s('inline-block text-left pt-4', {
          width: '15%',
        }),
        tableCellStyles: s('inline-block text-left pt-1 flex items-center', {
          width: '15%',
          ...textEllipsis,
        }),
      },
      {
        Header: 'Note',
        accessor: 'note',
        disableSortBy: true,
        tableHeaderStyles: s('inline-block text-left pt-4', {
          width: '15%',
        }),
        tableCellStyles: s('inline-block text-left pt-1 flex items-center', {
          width: '15%',
          ...textEllipsis,
        }),
      },
      {
        accessor: 'actions',
        disableSortBy: true,
        tableHeaderStyles: s('inline-block', { width: '5%' }),
        tableCellStyles: s('inline-block flex items-center justify-center', {
          width: '5%',
        }),
      },
    ],
    []
  )
