export const ANTICIPATED_EVENT_OPTS = [
  { value: 'permanent_income_reduction', label: 'A permanent reduction in income' },
  { value: 'temporary_income_reduction', label: 'A temporary reduction in income' },
  {
    value: 'permanent_expenditure_increase',
    label: 'A permanent increase in expenditure',
  },
  {
    value: 'temporary_expenditure_increase',
    label: 'A temporary increase in expenditure',
  },
  { value: 'one_off_large_expenditure', label: 'An anticipated large expenditure' },
]

export const ADAPTATION_PLAN_OPTS = [
  {
    value: 'application_reflects_changes',
    label: 'My application reflects these changes',
  },
  { value: 'expenditure_reduce', label: 'Reduce expenditure' },
  { value: 'use_savings', label: 'Use savings' },
  { value: 'additional_income', label: 'Secure additional income' },
]

export const LOAN_TYPE_OPTS = [
  { value: 'Car loan', label: 'Car loan' },
  { value: 'Student loan', label: 'Student loan' },
  { value: 'Personal loan', label: 'Personal loan' },
  { value: 'Others', label: 'Others' },
]
