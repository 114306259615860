import React from 'react'
import { Paper } from '@vega/components'
import { Row, Column, Heading, Label, Value } from './layoutComponents'

function CreditScoreSection({
  creditScores: {
    primaryScore,
    scorecardName,
    scorecardVersion,
    riskRank,
    riskIndex,
    riskOdds,
  },
}) {
  return (
    <Paper>
      <Heading>Credit score result is:</Heading>
      <p>Here is the score breakdown</p>
      <Row>
        <Column>
          <Label>Score:</Label>
          <Value>{primaryScore}</Value>
        </Column>
        <Column>
          <Label>Risk rank:</Label>
          <Value>{riskRank}</Value>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Score card:</Label>
          <Value>{scorecardName}</Value>
        </Column>
        <Column>
          <Label>Risk index:</Label>
          <Value>{riskIndex}</Value>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Report version:</Label>
          <Value>{scorecardVersion}</Value>
        </Column>
        <Column>
          <Label>Risk odds:</Label>
          <Value>{riskOdds}</Value>
        </Column>
      </Row>
    </Paper>
  )
}

export default CreditScoreSection
