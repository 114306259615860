import React from 'react'
import { s, styled } from '@vega/styled'
import { Button } from '@vega/components'
import { ReactComponent as Check } from '@vega/components/src/assets/images/check.svg'
import { InterestRate } from 'features/broker/applications/components/InterestRate'
import { SectionContainer, FormContainer, Row } from './components/layout'
import TextField from './components/PreviewTextField'
import { Carousel } from './components/Carousel'
import fmt from './formatters'

const emphasizedText = s('text-grey-800 text-2xl font-medium')
const Label = styled.label(
  s('text-grey-600 font-medium text-base', {
    pointerEvents: 'none',
  })
)
const HeadingText = styled.div(s('mt-10 mb-6 text-grey-800 text-2xl font-semi-bold'))

const LoanOptionsSummary = () => {
  const parentPath = 'structure'

  return (
    <SectionContainer>
      <FormContainer>
        <Row style={s('m-0')}>
          <Label>Your match</Label>

          <Button
            size="sm"
            StartIcon={<Check style={s('w-1 mr-2')} />}
            style={s('ml-a p-2 text-base', { width: 121 })}
          >
            Select
          </Button>
        </Row>
        <Row style={s('mb-8')}>
          <InterestRate
            rate="2.00"
            label="Interest rate"
            containerStyle={s('flex-1')}
          />
          <InterestRate
            rate="4.35"
            label="Comparison rate"
            containerStyle={{ flex: 3 }}
          />
        </Row>
        <Row style={s('mb-8')}>
          <TextField
            containerStyle={{ flex: 1.5 }}
            isReadOnly
            name={`${parentPath}.loanRateType`}
            aria-label="loanPreference"
            label="Loan preference"
            formatValue={fmt.formatLoanRateType}
            inputStyle={emphasizedText}
          />
          <TextField
            containerStyle={{ flex: 1.5 }}
            isReadOnly
            name={`intent.borrowingAmount`}
            aria-label="loanAmount"
            label="Loan amount"
            formatValue={fmt.formatMoneyValue}
            inputStyle={{ ...emphasizedText, ...s('font-bold') }}
          />
          <TextField
            containerStyle={{ flex: 1 }}
            isReadOnly
            name={`${parentPath}`}
            aria-label="YourRepayment"
            label="Your repayment"
            formatValue={() => '$2000'}
            inputStyle={{ ...emphasizedText, ...s('font-bold') }}
          />
        </Row>

        <Row style={s('mb-8')}>
          <TextField
            containerStyle={{ flex: 1.5 }}
            isReadOnly
            name={`${parentPath}.loanTerm`}
            aria-label="loanTerm"
            label="Loan term"
            formatValue={fmt.formatLoanTerm}
            inputStyle={emphasizedText}
          />
          <TextField
            containerStyle={{ flex: 1.5 }}
            isReadOnly
            name={`${parentPath}.fixedLoanRateTerm`}
            aria-label="fixedLoanRateTerm"
            label="Fixed loan rate term"
            formatValue={fmt.formatFixedLoanRateTerm}
            inputStyle={emphasizedText}
          />
          <TextField
            containerStyle={{ flex: 1 }}
            isReadOnly
            name={`${parentPath}.offsetAccount`}
            aria-label="offsetAccount"
            label="Offset account"
            formatValue={fmt.formatBoolean}
            inputStyle={emphasizedText}
          />
        </Row>
        <Row>
          <TextField
            isReadOnly
            name={`${parentPath}.repaymentFrequency`}
            aria-label="repaymentFrequency"
            label="Repayment frequency"
            formatValue={fmt.formatRepaymentFrequency}
            inputStyle={emphasizedText}
          />
        </Row>
      </FormContainer>

      <HeadingText>Comparison rate</HeadingText>

      <Carousel />
    </SectionContainer>
  )
}

export { LoanOptionsSummary }
