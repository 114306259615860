import React from 'react'
import { useDispatch } from 'react-redux'
import { styled, s } from '@vega/styled'
import { removeApplicant } from 'modules/application'
import { Modal, Button as ButtonBase } from '@vega/components'
import { ReactComponent as Trash } from '@vega/components/src/assets/images/trash.svg'
import { ReactComponent as X } from '@vega/components/src/assets/images/x.svg'

const Root = styled.div(
  s('bg-white rounded-l-lg rounded-br-lg p-6', { width: 478, height: 195 })
)
const Header = styled.div(s('flex items-center mb-5'))
const Title = styled.p(
  s('m-0 text-xl font-medium text-grey-700', { lineHeight: '24px' })
)
const Text = styled.p(
  s('m-0 text-sm text-grey-700 font-normal mb-6', {
    lineHeight: 1.5,
    letterSpacing: '0.015em',
  })
)
const ButtonContainer = styled.div(s('flex'))
const Button = styled(ButtonBase)(s('', { width: 160 }))

const RemoveApplicant = ({ isOpen, closePreview, applicantId, applicantName }) => {
  const dispatch = useDispatch()

  const remove = () => {
    dispatch(removeApplicant(applicantId))
    closePreview()
  }

  return (
    <Modal isOpen={isOpen} onClose={closePreview}>
      <Root>
        <Header>
          <Trash style={s('mr-2 text-error-600', { height: 20 })} />
          <Title>Remove Applicant?</Title>
          <X
            style={s('text-grey-500 ml-a', { height: 20, cursor: 'pointer' })}
            onClick={closePreview}
          />
        </Header>
        <Text>
          Are you sure you want to remove &apos;
          <span style={s('font-bold')}>{applicantName}</span>&apos; from this
          application? All information will be deleted from this application
        </Text>
        <ButtonContainer>
          <Button
            variant="outlined"
            containerStyle={s('text-grey-600 border-grey-300')}
            style={s('mr-4')}
            onClick={closePreview}
          >
            No, Keep them
          </Button>
          <Button containerStyle={s('text-white bg-error-600')} onClick={remove}>
            Yes, Remove them
          </Button>
        </ButtonContainer>
      </Root>
    </Modal>
  )
}

export { RemoveApplicant }
