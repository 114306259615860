import { sum, reject, isNilOrEmpty, compose, values, map } from '@neo/ramda-extra'

const convertExpenseToMonthlyByRecurrence = {
  monthly: (value) => value,
  weekly: (value) => value * 4.34524,
  fortnightly: (value) => value * 2.17262,
  quarterly: (value) => value * 0.333554,
}

const toTwoDecimalPlaces = (expenses) =>
  map((expense) => Number(expense).toFixed(2), expenses)

const mapExpensesToMonthlyValues = (expenses) =>
  map(
    ({ value, recurrence }) => convertExpenseToMonthlyByRecurrence[recurrence](value),
    expenses
  )

const getExpenses = (expenses) => values(expenses)

const sumUpExpenses = (expenses = []) =>
  compose(
    sum,
    reject(isNilOrEmpty),
    reject(Number.isNaN),
    map((n) => Number(n))
  )(expenses)

const calculateTotalMonthlyExpenses = (expenses) => {
  return compose(
    sumUpExpenses,
    toTwoDecimalPlaces,
    mapExpensesToMonthlyValues,
    getExpenses
  )(expenses)
}

export default calculateTotalMonthlyExpenses
