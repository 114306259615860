import { forEachObjIndexed, includes } from '@neo/ramda-extra'
import * as CONSTANTS from '@neo/constants'

const {
  LOAN_APPLICATION: {
    STAGES: {
      UNCONDITIONAL_OFFER,
      AFFORDABILITY_PRE_APPROVED,
      PROPERTY_PRE_APPROVED,
      REJECTED,
    },
  },
} = CONSTANTS

const NON_PENDING_STAGES = [
  UNCONDITIONAL_OFFER,
  AFFORDABILITY_PRE_APPROVED,
  PROPERTY_PRE_APPROVED,
  REJECTED,
]

export const calculateNumOfPendingAppsForBreakdown = (breakdownReport = {}) => {
  let numOfPendingApps = 0

  forEachObjIndexed((stageDetails, stageName) => {
    const isStageCountsAsPending = !includes(stageName, NON_PENDING_STAGES)

    if (isStageCountsAsPending) numOfPendingApps += stageDetails?.cnt
  }, breakdownReport)

  return numOfPendingApps
}
