import React from 'react'
import moment from 'moment'
import { differenceInYears } from 'date-fns'
import { Formik as FormProvider, Form } from 'formik'
import { styled, s } from '@vega/styled'
import * as Validator from 'yup'
import { DateField as DateFieldBase, SelectField } from '@vega/components'
import { TextField, OutlinedBtn, Row, CardContainer } from './common'
import { checkAreObjectsTheSame } from './checkAreObjectsTheSame'

const DateField = styled(({ containerStyle, ...otherProps }) => (
  <DateFieldBase
    containerProps={{
      style: s('flex flex-column flex-1', containerStyle),
    }}
    textFieldProps={{
      inputContainerStyle: s('border-grey-300'),
    }}
    {...otherProps}
  />
))()

const { object, string } = Validator

const parseDateStringToJsDateFormat = (dateString, originalFormat = 'DD/MM/YYYY') =>
  moment(dateString, originalFormat).toDate()

const validationSchema = object({
  firstName: string()
    .required('First name is required')
    .max(50, 'First name exceeds the maximum length of 50 characters'),

  lastName: string()
    .required('Last name is required')
    .max(50, 'Last name exceeds the maximum length of 50 characters'),

  dob: string()
    .required('Date of birth is required')
    .test('customer-has-to-be-eighteen-years-old', 'Age is not eligible', (value) => {
      const dob = parseDateStringToJsDateFormat(value)
      const today = new Date()

      return differenceInYears(today, dob) >= 18
    }),

  gender: string().required('Gender is required'),
})

const handleSubmit = ({ firstName, lastName, dob, gender }) => ({
  firstName,
  lastName,
  dob,
  gender,
})

const initialValues = {
  firstName: '',
  lastName: '',
  dob: '',
  gender: '',
}

export const PersonalDetailsCard = ({ ...otherProps }) => {
  return (
    <FormProvider
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values: formValues, resetForm }) => {
        const isFormEdited = checkAreObjectsTheSame(formValues, initialValues)

        return (
          <Form>
            <CardContainer {...otherProps}>
              <Row style={s('mb-6')}>
                <TextField
                  name="firstName"
                  label="First name"
                  placeholder="e.g. Joe"
                  style={s('mr-2')}
                />

                <TextField
                  name="lastName"
                  label="Last name"
                  placeholder="e.g. Smith"
                  style={s('ml-2')}
                />
              </Row>

              <Row>
                <DateField
                  name="dob"
                  label="Date of birth"
                  placeholder="DD/MM/YYYY"
                  containerStyle={s('mr-2')}
                />

                <SelectField
                  name="gender"
                  style={s('flex-1 ml-2')}
                  label="Gender"
                  options={[
                    {
                      label: 'Male',
                      value: 'male',
                    },
                    {
                      label: 'Female',
                      value: 'female',
                    },
                    {
                      label: 'Prefer not to say',
                      value: 'prefer not to say',
                    },
                  ]}
                />
              </Row>

              {isFormEdited ? (
                <Row style={s('mt-6 justify-start')}>
                  <OutlinedBtn variant="contained" style={s('mr-4')}>
                    Save changes
                  </OutlinedBtn>

                  <OutlinedBtn onClick={resetForm}>Cancel</OutlinedBtn>
                </Row>
              ) : (
                <OutlinedBtn type="button" style={s('mt-6')}>
                  Save changes
                </OutlinedBtn>
              )}
            </CardContainer>
          </Form>
        )
      }}
    </FormProvider>
  )
}
