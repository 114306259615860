import * as CONSTANTS from '@neo/constants'

const {
  APPLICANT: { APPLICANT_TYPE },
} = CONSTANTS

export function mapToAddApplicantModel({ id, isPrimary }) {
  return {
    id,
    type: isPrimary ? APPLICANT_TYPE.PRIMARY : APPLICANT_TYPE.SUPPORTING,
  }
}
