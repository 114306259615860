import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import {
  getLoanApplicationsSummary,
  getInProgressApplicationsReport,
  getApplicationsBreakdownReport,
  selectApplicationsSummary,
} from 'modules/report'
import { calculatePrevMonth } from '../utils/calculatePrevMonth'
import { ComparisonSection } from './ComparisonSection'
import { ChartSection } from './ChartSection'
import { s, styled } from '@vega/styled'
import { extractSummaryReportsForComparison } from './utils/extractSummaryReportsForComparison'

const Container = styled.div(
  s('flex-1 flex flex-column items-center h-full', {
    padding: 28,
    overflowY: 'auto',
    height: 'calc(100vh - 163px)',
  })
)

function ApplicationOverview() {
  const dispatch = useDispatch()
  const { values: formikValues } = useFormikContext()
  const { year, month } = formikValues
  const { prevYear, prevMonth } = calculatePrevMonth(year, month)

  const queryDateRange = useMemo(
    () => ({ years: [year, prevYear], months: [month, prevMonth] }),
    [month, prevMonth, prevYear, year]
  )

  React.useEffect(() => {
    dispatch(getLoanApplicationsSummary(queryDateRange))
    dispatch(getInProgressApplicationsReport({ year, month }))
    dispatch(getApplicationsBreakdownReport({ year, month }))
  }, [dispatch, month, queryDateRange, year])

  const summaryReports = useSelector(selectApplicationsSummary)
  const {
    summaryReportOfQueriedDate,
    summaryReportOfPrevMonth,
  } = extractSummaryReportsForComparison(summaryReports, { year, month })

  return (
    <Container>
      <ComparisonSection
        summaryReportOfQueriedDate={summaryReportOfQueriedDate}
        summaryReportOfPrevMonth={summaryReportOfPrevMonth}
        dateComparedWith={{ prevYear, prevMonth }}
        style={s('mb-8')}
      />

      <ChartSection summaryReportOfQueriedDate={summaryReportOfQueriedDate} />
    </Container>
  )
}

export { ApplicationOverview }
