import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { styled, s } from '@vega/styled'
import { routes } from 'routes'
import {
  getCreditReport,
  selectApplicantsDetails,
  selectDataRetrieval,
  selectCreditReports,
  selectApplicantIds,
  selectSelectedLoanApplication,
  selectFormStatus,
} from 'modules/application'
import {
  displayProgressStatus,
  checkIdVerification,
  addBankAccountsLinked,
  addCreditReportStatus,
  isPrimaryApplicant,
} from './utils'
import { ProgressStatus } from './ProgressStatus'
import { FormProgress } from './FormProgress'
import { Button } from '@vega/components'
import { ReactComponent as ArrowRight } from '@vega/components/src/assets/images/arrowRight.svg'
import { ReactComponent as PlusCircle } from '@vega/components/src/assets/images/plus-circle.svg'
import { ItemVerifyStatus } from './ItemVerifyStatus'
import { InviteApplicant } from './InviteApplicant'
import { Trash } from './Trash'
import { pipe, map } from '@neo/ramda-extra'
import { ActivitiesPanel } from './ActivitiesPanel'

const Root = styled.div(s('flex flex-1 flex-column bg-green-50', { overflowY: 'auto' }))
const OverviewContainer = styled.div(s('p-8'))
const TopContainer = styled.div(s('flex mb-5'))
const CardContainer = styled.div(
  s('grid', {
    gridTemplateColumns: 'repeat(auto-fill, 280px)',
    gridAutoRows: '170px',
    gap: '2em',
  })
)
const Title = styled.h1(
  s('m-0 mr-5 text-xl text-grey-800 font-semibold', {
    letterSpacing: '0.03em',
    lineHeight: 1.5,
  })
)
const SubTitle = styled.h3(
  s('m-0 text-grey-800', { letterSpacing: '0.03em', lineHeight: 1.5 })
)
const Card = styled.div(
  s('bg-white border-solid border-1 border-grey-200 rounded-lg p-5')
)
const Name = styled.p(s('m-0 mb-5'))
const ItemStatus = styled(ItemVerifyStatus)({ marginBottom: 14 })
const Text = styled.p('m-0 mt-4')

const ApplicationDetails = ({ loanApplicationId }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const applicants = useSelector(selectApplicantsDetails)
  const dataRetrieval = useSelector(selectDataRetrieval)
  const creditReports = useSelector(selectCreditReports)
  const applicantIds = useSelector(selectApplicantIds)
  const status = useSelector(selectFormStatus)
  const selectedLoanApplication = useSelector(selectSelectedLoanApplication)

  const applicantTypes = selectedLoanApplication?.loanApplication?.applicants

  const [isModalOpened, setIsModalOpened] = useState(false)

  const getApplicantDetails = (applicants) =>
    pipe(
      map(addCreditReportStatus(creditReports)),
      map(addBankAccountsLinked(dataRetrieval))
    )(applicants)

  const continueForm = () =>
    history.push(`${routes.broker.applications.create}/${loanApplicationId}`)

  useEffect(() => {
    applicantIds.forEach((applicantId) => dispatch(getCreditReport(applicantId)))
  }, [applicantIds, dispatch])

  return (
    <Root>
      <OverviewContainer>
        <TopContainer>
          <Title>Current Progress</Title>
          <ProgressStatus status={displayProgressStatus(status)} />
          <Button
            size="lg"
            EndIcon={<ArrowRight style={{ marginLeft: 20 }} />}
            style={s('ml-a w-10 p-4 text-base')}
            onClick={continueForm}
          >
            Continue
          </Button>
        </TopContainer>
        <FormProgress creditReportStatus={'PASSED'} />

        <Title style={s('mt-8 mb-5')}>Applicants Details</Title>
        <SubTitle style={s('mb-4')}>Applicant(s)</SubTitle>

        <CardContainer>
          {getApplicantDetails(applicants).map((applicant) => (
            <div key={applicant.email}>
              <Card>
                <Name>
                  {applicant.firstName} {applicant.lastName || ''}
                </Name>
                <ItemStatus status={checkIdVerification(applicant)}>
                  Verify IDs
                </ItemStatus>
                <ItemStatus status={applicant.creditReportStatus}>
                  Verify credit check
                </ItemStatus>
                <ItemStatus status={applicant.accountsLinked} style={s('mb-0')}>
                  Bank accounts linked
                </ItemStatus>
              </Card>
              {!isPrimaryApplicant(applicant.id, applicantTypes) && (
                <Trash
                  applicantId={applicant.id}
                  firstName={applicant.firstName}
                  lastName={applicant.lastName || ''}
                />
              )}
            </div>
          ))}

          <Card
            onClick={() => setIsModalOpened(true)}
            style={s('flex flex-column justify-center items-center', {
              cursor: 'pointer',
            })}
          >
            <PlusCircle style={s('mx-a', { width: 20 })} />
            <Text>Invite New Applicant</Text>
          </Card>
        </CardContainer>

        <Title style={s('mt-8 mb-5')}>Activities</Title>
        <ActivitiesPanel loanApplicationId={loanApplicationId} />
      </OverviewContainer>

      <InviteApplicant isOpen={isModalOpened} setIsOpen={setIsModalOpened} />
    </Root>
  )
}

export { ApplicationDetails }
