import { s, styled } from '@vega/styled'

export const NotesContainer = styled.div(
  s('absolute pin-t-0 ml-4 bg-white border-1 border-solid border-grey-100'),
  s('rounded-b-lg rounded-tl-lg shadow-md max-h-24'),
  {
    left: '100%',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  ({ expanded }) =>
    expanded
      ? { zIndex: 2, width: '20vw' }
      : { zIndex: 1, width: 'initial', cursor: 'pointer' }
)
