/* eslint-disable react/display-name */
import React from 'react'
import { s } from '@vega/styled'

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Create date',
        accessor: 'createdAt',
        tableCellStyles: s('flex justify-start flex-1'),
        tableHeaderStyles: s('flex justify-start flex-1'),
      },
      {
        Header: 'Broker name',
        accessor: 'brokerName',
        disableSortBy: true,
        tableCellStyles: s('flex justify-start flex-1'),
        tableHeaderStyles: s('flex justify-start flex-1'),
      },
      {
        Header: 'Applicant name',
        accessor: 'applicantName',
        disableSortBy: true,
        tableCellStyles: s('flex justify-start', { flex: 2 }),
        tableHeaderStyles: s('flex justify-start', { flex: 2 }),
      },
      {
        Header: 'Max loan amount',
        accessor: 'maxLoanAmount',
        disableSortBy: true,
        tableCellStyles: s('flex justify-start flex-1'),
        tableHeaderStyles: s('flex justify-start flex-1'),
      },
      {
        Header: 'Deposit',
        accessor: 'deposit',
        disableSortBy: true,
        tableCellStyles: s('flex justify-start flex-1'),
        tableHeaderStyles: s('flex justify-start flex-1'),
      },
    ],
    []
  )
}
