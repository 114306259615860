import React from 'react'
import { s, styled } from '@vega/styled'
import { InterestRate } from 'features/broker/applications/components/InterestRate'
import { SectionContainer, FormContainer, Row } from './components/layout'
import TextField from './components/PreviewTextField'
import fmt from './formatters'

const emphasizedText = s('text-grey-800 text-2xl font-medium')
const Label = styled.label(
  s('text-grey-600 font-medium text-base', {
    pointerEvents: 'none',
  })
)

const LoanOptionsSummary = ({
  loanRateType,
  loanTerm,
  offsetAccount,
  repaymentFrequency,
  borrowingAmount,
}) => {
  return (
    <SectionContainer>
      <FormContainer>
        <Row style={s('m-0')}>
          <Label style={s('mb-2')}>Your pre-approval summary</Label>
        </Row>
        <Row style={s('mb-8')}>
          <InterestRate
            rate="2.00"
            label="Interest rate"
            containerStyle={s('flex-1')}
          />
          <InterestRate
            rate="4.35"
            label="Comparison rate"
            containerStyle={{ flex: 3 }}
          />
        </Row>
        <Row style={s('mb-8')}>
          <TextField
            containerStyle={{ flex: 1.5 }}
            value={loanRateType}
            isReadOnly
            aria-label="loanPreference"
            label="Loan preference"
            formatValue={fmt.formatLoanRateType}
            inputStyle={emphasizedText}
          />
          <TextField
            containerStyle={{ flex: 1.5 }}
            value={borrowingAmount}
            isReadOnly
            aria-label="loanAmount"
            label="Loan amount"
            formatValue={fmt.formatMoneyValue}
            inputStyle={{ ...emphasizedText, ...s('font-bold') }}
          />
          <TextField
            containerStyle={{ flex: 1 }}
            isReadOnly
            aria-label="YourRepayment"
            label="Your repayment"
            formatValue={() => '$2,000'}
            inputStyle={{ ...emphasizedText, ...s('font-bold') }}
          />
        </Row>

        <Row style={s('mb-8')}>
          <TextField
            containerStyle={{ flex: 1.5 }}
            value={loanTerm}
            isReadOnly
            aria-label="loanTerm"
            label="Loan term"
            formatValue={fmt.formatLoanTerm}
            inputStyle={emphasizedText}
          />
          <TextField
            containerStyle={{ flex: 1.5 }}
            isReadOnly
            value={repaymentFrequency}
            aria-label="repaymentFrequency"
            label="Repayment frequency"
            formatValue={fmt.formatRepaymentFrequency}
            inputStyle={emphasizedText}
          />
          <TextField
            containerStyle={{ flex: 1 }}
            value={offsetAccount}
            isReadOnly
            aria-label="offsetAccount"
            label="Offset account"
            formatValue={fmt.formatBoolean}
            inputStyle={emphasizedText}
          />
        </Row>
      </FormContainer>
    </SectionContainer>
  )
}

export { LoanOptionsSummary }
