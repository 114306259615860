import React from 'react'

import { s, styled } from '@vega/styled'
import { GreetingTopBar, InviteButton } from '@vega/components'

const Container = styled.div(s('flex-1 h-full', { overflowY: 'hidden' }))

function Calculator() {
  const parentURL = window.location.href

  return (
    <Container>
      <GreetingTopBar
        userName="John"
        RightSideComponent={<InviteButton invitee="broker" />}
      />
      <div style={s('flex w-full h-full')}>
        <iframe
          src={`${process.env.REACT_APP_CALCULATOR_IFRAME}?parentURL=${parentURL}`}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        />
      </div>
    </Container>
  )
}

export { Calculator }
