/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { s } from '@vega/styled'
import { USER_ROLES, USER_STATUSES } from '@vega/constants'
import {
  prop,
  flip,
  map,
  pipe,
  intersperse,
  isNotNilOrEmpty,
  join,
  filter,
  drop,
  or,
  noop,
} from '@neo/ramda-extra'
import { Toggle } from '@vega/components'
import Actions from './Actions'
import { toggleStatus, toggleSuspension } from 'modules/user'

const textEllipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Full name',
        accessor: 'fullName',
        tableCellStyles: { width: '20%', wordBreak: 'break-all' },
        tableHeaderStyles: s('text-left text-grey-600', { width: '20%' }),
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableSortBy: true,
        tableCellStyles: { width: '20%', wordBreak: 'break-all' },
        tableHeaderStyles: s('text-left text-grey-600', { width: '20%' }),
      },
      {
        Header: 'Date of birth',
        accessor: 'dob',
        disableSortBy: true,
        tableCellStyles: { width: '12%' },
        tableHeaderStyles: s('text-left text-grey-600', { width: '12%' }),
      },
      {
        Header: 'Default roles',
        accessor: 'defaultRole',
        disableSortBy: true,
        tableCellStyles: { width: '12%' },
        tableHeaderStyles: s('text-left text-grey-600', { width: '12%' }),
      },
      {
        Header: 'Other role(s)',
        accessor: 'otherRoles',
        disableSortBy: true,
        tableCellStyles: { ...textEllipsis, width: '15%' },
        tableHeaderStyles: s('text-left text-grey-600', { width: '15%' }),
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        tableCellStyles: { width: '12%' },
        tableHeaderStyles: s('text-left text-grey-600', { width: '12%' }),
      },
      {
        Header: 'Suspend',
        accessor: 'suspended',
        disableSortBy: true,
        tableCellStyles: s('justify-center', { width: '8%' }),
        tableHeaderStyles: s('text-center text-grey-600', { width: '8%' }),
      },
      {
        accessor: 'actions',
        disableSortBy: true,
        tableHeaderStyles: s('inline-block', { width: '5%' }),
        tableCellStyles: s('inline-block flex items-center justify-center', {
          width: '5%',
        }),
      },
    ],
    []
  )
}

const { BROKER, LENDING_ADMIN, APPLICANT, CLIENT } = USER_ROLES
const { ACTIVE, INACTIVE, PENDING } = USER_STATUSES

const mapToRoleLabel = (role = APPLICANT) =>
  ({
    [BROKER]: 'Broker',
    [LENDING_ADMIN]: 'Admin',
    [APPLICANT]: 'Applicant',
    [CLIENT]: 'Client',
  }[role])

const mapToStatusLabel = (status = ACTIVE) =>
  ({
    [ACTIVE]: 'Active',
    [INACTIVE]: 'Inactive',
    [PENDING]: 'Pending',
  }[status])

const joinLabels = (labels) =>
  pipe(filter(isNotNilOrEmpty), intersperse(', '), join(''))(labels)

const removeDefaultRole = (roles) => drop(1)(roles)

const formatRoles = (roles = []) =>
  pipe(removeDefaultRole, map(mapToRoleLabel), joinLabels)(roles)

export const Rows = ({ userList }) => {
  const dispatch = useDispatch()

  return React.useMemo(
    () =>
      userList.map((user) => {
        const propUser = flip(prop)(user)
        const toggleUserStatus = (id) =>
          dispatch(
            toggleStatus({
              id,
              currentStatus: propUser('status'),
            })
          )

        const getActionItemProps = (status = ACTIVE) =>
          ({
            [ACTIVE]: { name: 'Deactivate', onClick: toggleUserStatus },
            [INACTIVE]: { name: 'Activate', onClick: toggleUserStatus },
            [PENDING]: { name: 'Revoke Invite', onClick: noop },
          }[status])

        const dropDownActions = [
          getActionItemProps(propUser('status')),
          {
            name: 'Cancel',
          },
        ]
        return {
          id: propUser('id'),
          avatar: propUser('avatar'),
          fullName: propUser('fullName'),
          email: propUser('email'),
          defaultRole: mapToRoleLabel(propUser('roles')[0]),
          otherRoles: formatRoles(propUser('roles')),
          dob: moment(propUser('dob')).format('DD-MM-YYYY'),
          status: mapToStatusLabel(propUser('status')),
          active: propUser('isActive'),
          createAt: moment(propUser('createdAt')).format('DD-MM-YYYY'),
          invitedBy: propUser('invitedBy'),
          suspended: (
            <Toggle
              checked={propUser('suspended')}
              onChange={(e) => {
                dispatch(
                  toggleSuspension({
                    id: propUser('id'),
                    suspended: !e.target.checked,
                  })
                )
              }}
              icons={false}
              style={s('my-2 ml-2')}
            />
          ),
          actions: (
            <Actions
              recordId={propUser('id')}
              actions={dropDownActions}
              itemProps={{
                textColorMap: {
                  Deactivate: 'text-error-600',
                  Activate: 'text-green-600',
                  'Revoke Invite': 'text-error-600',
                },
                style: s('w-6'),
              }}
            />
          ),
          customRowStyle: or(propUser('suspended'), propUser('status') === INACTIVE)
            ? s('text-grey-light', {
                ' > td:not(:last-child)': { opacity: 0.2 },
              })
            : undefined,
        }
      }),
    [dispatch, userList]
  )
}
