import React from 'react'
import { styled, s } from '@vega/styled'
import { Modal } from '@vega/components'
import { ReactComponent as ArrowsExpand } from '@vega/components/src/assets/images/arrows-expand.svg'

const Root = styled.div(
  s('flex justify-center bg-grey-200 rounded-l-lg rounded-br-lg p-3 relative', {
    height: 508,
    width: 1094,
  })
)
const IconContainer = styled.div(
  s('absolute p-1 pin-r-3 z-1 text-grey-400 bg-grey-100 rounded-l-md rounded-br-md', {
    height: 24,
    width: 24,
    cursor: 'pointer',
  })
)

const IdPreviewExpanded = ({ isOpen, closePreview, image }) => {
  return (
    <Modal isOpen={isOpen} onClose={closePreview}>
      <Root>
        <IconContainer onClick={closePreview}>
          <ArrowsExpand />
        </IconContainer>

        <img src={image} />
      </Root>
    </Modal>
  )
}

export { IdPreviewExpanded }
