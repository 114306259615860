import React from 'react'
import { prop } from '@neo/ramda-extra'
import { useWizard } from '@vega/formik-wizard'
import { ReactComponent as TickIconBase } from '@vega/components/src/assets/images/tick.svg'
import { ReactComponent as XIconBase } from '@vega/components/src/assets/images/x.svg'
import { styled, s } from '@vega/styled'

const Step = styled.li(
  s(
    'no-decoration text-base text-grey-500 text-sm font-medium flex flex-column justify-between items-start'
  ),
  { cursor: 'pointer', lineHeight: 0.4 }
)
const Circle = styled.div(
  s('show-box border-grey-300 flex items-center mr-2 justify-center'),
  {
    borderRadius: '50%',
  },
  ({ isComplete }) => isComplete && s('border-green-700'),
  ({ isFailed }) => isFailed && s('border-error-500'),
  ({ isCurrent, type }) =>
    isCurrent &&
    s('text-white font-semibold  border-2 border-solid border-green-700', {
      background: type === 'section' && s('bg-green-700').backgroundColor,
    }),

  prop('circleSize')
)
const XIcon = styled(XIconBase)(
  s('text-red-300', {
    '& path': {
      stroke: s('text-error-500').color,
    },
  }),
  ({ size }) => ({
    width: size,
    height: size,
  })
)
const TickIcon = styled(TickIconBase)(s('text-green-700'), ({ size }) => ({
  width: size,
  height: size,
}))
const Row = styled.div(s('flex items-center justify-center'))
const Label = styled.p(s('mr-4'))
const StepNumber = styled.p()

const getCircleSize = {
  sm: { width: 20, height: 20 },
  lg: s('w-2 h-2'),
}

// eslint-disable-next-line complexity
const renderIcon = (
  type,
  { isCurrent, isComplete, isFailed, stepNumber },
  { tickIconSize, xIconSize }
) => {
  if (type === 'section') {
    if (isCurrent) return <StepNumber>{stepNumber}</StepNumber>
    if (isComplete) return <TickIcon size={tickIconSize} />
    if (isFailed) return <XIcon size={xIconSize} />
  }

  if (type === 'step') {
    if (isComplete) return <TickIcon size={tickIconSize} />
    if (isCurrent) return <StepNumber>{stepNumber}</StepNumber>
    if (isFailed) return <XIcon size={xIconSize} />
  }

  return <StepNumber>{stepNumber}</StepNumber>
}

// eslint-disable-next-line react/no-multi-comp
export const FormProgressLink = ({
  label,
  isComplete = false,
  isCurrent = false,
  isVisited = false,
  isFailed = false,
  stepNumber,
  type = 'step',
  size = 'sm',
  successIconSize,
  failIconSize,
  stepId,
  children,
  ...props
}) => {
  const { go } = useWizard()

  const circleSize = getCircleSize[size]
  const tickIconSize = successIconSize || circleSize.width * 0.5
  const xIconSize = failIconSize || circleSize.width * 0.8

  const onLinkClicked = (stepId) => {
    // TODO: We need to capture if this step is visited before, if yes then the user can navigate to it again
    if (isVisited) go(stepId)
  }

  return (
    <Step onClick={() => onLinkClicked(stepId)} {...props}>
      <Row>
        <Circle
          type={type}
          isCurrent={isCurrent}
          isComplete={isComplete}
          isFailed={isFailed}
          circleSize={circleSize}
        >
          {renderIcon(
            type,
            { isCurrent, isComplete, isFailed, stepNumber },
            { tickIconSize, xIconSize }
          )}
        </Circle>
        <Label>{label}</Label>
      </Row>
      {children}
    </Step>
  )
}
