import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setIsNavDrawerOpen } from 'modules/ui'

const useHideNavDrawer = () => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(setIsNavDrawerOpen(false))

    return () => dispatch(setIsNavDrawerOpen(true))
  }, [dispatch])
}

export { useHideNavDrawer }
