export const COUNTRY_OPTIONS = [
  { value: 'af', flag: '🇦🇫', label: 'Afghanistan' },
  { value: 'ax', flag: '🇦🇽', label: 'Åland Islands' },
  { value: 'al', flag: '🇦🇱', label: 'Albania' },
  { value: 'dz', flag: '🇩🇿', label: 'Algeria' },
  { value: 'as', flag: '🇦🇸', label: 'American Samoa' },
  { value: 'ad', flag: '🇦🇩', label: 'Andorra' },
  { value: 'ao', flag: '🇦🇴', label: 'Angola' },
  { value: 'ai', flag: '🇦🇮', label: 'Anguilla' },
  { value: 'aq', flag: '🇦🇶', label: 'Antarctica' },
  { value: 'ag', flag: '🇦🇬', label: 'Antigua And Barbuda' },
  { value: 'ar', flag: '🇦🇷', label: 'Argentina' },
  { value: 'am', flag: '🇦🇲', label: 'Armenia' },
  { value: 'aw', flag: '🇦🇼', label: 'Aruba' },
  { value: 'au', flag: '🇦🇺', label: 'Australia' },
  { value: 'at', flag: '🇦🇹', label: 'Austria' },
  { value: 'az', flag: '🇦🇿', label: 'Azerbaijan' },
  { value: 'bs', flag: '🇧🇸', label: 'Bahamas' },
  { value: 'bh', flag: '🇧🇭', label: 'Bahrain' },
  { value: 'bd', flag: '🇧🇩', label: 'Bangladesh' },
  { value: 'bb', flag: '🇧🇧', label: 'Barbados' },
  { value: 'by', flag: '🇧🇾', label: 'Belarus' },
  { value: 'be', flag: '🇧🇪', label: 'Belgium' },
  { value: 'bz', flag: '🇧🇿', label: 'Belize' },
  { value: 'bj', flag: '🇧🇯', label: 'Benin' },
  { value: 'bm', flag: '🇧🇲', label: 'Bermuda' },
  { value: 'bt', flag: '🇧🇹', label: 'Bhutan' },
  { value: 'bo', flag: '🇧🇴', label: 'Bolivia, Plurinational State Of' },
  { value: 'bq', flag: '🇧🇶', label: 'Bonaire, Saint Eustatius And Saba' },
  { value: 'ba', flag: '🇧🇦', label: 'Bosnia & Herzegovina' },
  { value: 'bw', flag: '🇧🇼', label: 'Botswana' },
  { value: 'bv', flag: '🇧🇻', label: 'Bouvet Island' },
  { value: 'br', flag: '🇧🇷', label: 'Brazil' },
  { value: 'io', flag: '🇮🇴', label: 'British Indian Ocean Territory' },
  { value: 'bn', flag: '🇧🇳', label: 'Brunei Darussalam' },
  { value: 'bg', flag: '🇧🇬', label: 'Bulgaria' },
  { value: 'bf', flag: '🇧🇫', label: 'Burkina Faso' },
  { value: 'bi', flag: '🇧🇮', label: 'Burundi' },
  { value: 'cv', flag: '🇨🇻', label: 'Cabo Verde' },
  { value: 'kh', flag: '🇰🇭', label: 'Cambodia' },
  { value: 'cm', flag: '🇨🇲', label: 'Cameroon' },
  { value: 'ca', flag: '🇨🇦', label: 'Canada' },
  { value: 'ky', flag: '🇰🇾', label: 'Cayman Islands' },
  { value: 'cf', flag: '🇨🇫', label: 'Central African Republic' },
  { value: 'td', flag: '🇹🇩', label: 'Chad' },
  { value: 'cl', flag: '🇨🇱', label: 'Chile' },
  { value: 'cn', flag: '🇨🇳', label: 'China' },
  { value: 'cx', flag: '🇨🇽', label: 'Christmas Island' },
  { value: 'cc', flag: '🇨🇨', label: 'Cocos (Keeling) Islands' },
  { value: 'co', flag: '🇨🇴', label: 'Colombia' },
  { value: 'km', flag: '🇰🇲', label: 'Comoros' },
  { value: 'ck', flag: '🇨🇰', label: 'Cook Islands' },
  { value: 'cr', flag: '🇨🇷', label: 'Costa Rica' },
  { value: 'hr', flag: '🇭🇷', label: 'Croatia' },
  { value: 'cu', flag: '🇨🇺', label: 'Cuba' },
  { value: 'cw', flag: '🇨🇼', label: 'Curacao' },
  { value: 'cy', flag: '🇨🇾', label: 'Cyprus' },
  { value: 'cz', flag: '🇨🇿', label: 'Czech Republic' },
  { value: 'ci', flag: '🇨🇮', label: "Côte d'Ivoire" },
  { value: 'cd', flag: '🇨🇩', label: 'Democratic Republic Of Congo' },
  { value: 'dk', flag: '🇩🇰', label: 'Denmark' },
  { value: 'dj', flag: '🇩🇯', label: 'Djibouti' },
  { value: 'dm', flag: '🇩🇲', label: 'Dominica' },
  { value: 'do', flag: '🇩🇴', label: 'Dominican Republic' },
  { value: 'ec', flag: '🇪🇨', label: 'Ecuador' },
  { value: 'eg', flag: '🇪🇬', label: 'Egypt' },
  { value: 'sv', flag: '🇸🇻', label: 'El Salvador' },
  { value: 'gq', flag: '🇬🇶', label: 'Equatorial Guinea' },
  { value: 'er', flag: '🇪🇷', label: 'Eritrea' },
  { value: 'ee', flag: '🇪🇪', label: 'Estonia' },
  { value: 'et', flag: '🇪🇹', label: 'Ethiopia' },
  { value: 'fk', flag: '🇫🇰', label: 'Falkland Islands' },
  { value: 'fo', flag: '🇫🇴', label: 'Faroe Islands' },
  { value: 'fj', flag: '🇫🇯', label: 'Fiji' },
  { value: 'fi', flag: '🇫🇮', label: 'Finland' },
  { value: 'fr', flag: '🇫🇷', label: 'France' },
  { value: 'gf', flag: '🇬🇫', label: 'French Guiana' },
  { value: 'pf', flag: '🇵🇫', label: 'French Polynesia' },
  { value: 'tf', flag: '🇹🇫', label: 'French Southern Territories' },
  { value: 'ga', flag: '🇬🇦', label: 'Gabon' },
  { value: 'gm', flag: '🇬🇲', label: 'Gambia' },
  { value: 'ge', flag: '🇬🇪', label: 'Georgia' },
  { value: 'de', flag: '🇩🇪', label: 'Germany' },
  { value: 'gh', flag: '🇬🇭', label: 'Ghana' },
  { value: 'gi', flag: '🇬🇮', label: 'Gibraltar' },
  { value: 'gr', flag: '🇬🇷', label: 'Greece' },
  { value: 'gl', flag: '🇬🇱', label: 'Greenland' },
  { value: 'gd', flag: '🇬🇩', label: 'Grenada' },
  { value: 'gp', flag: '🇬🇵', label: 'Guadeloupe' },
  { value: 'gu', flag: '🇬🇺', label: 'Guam' },
  { value: 'gt', flag: '🇬🇹', label: 'Guatemala' },
  { value: 'gg', flag: '🇬🇬', label: 'Guernsey' },
  { value: 'gn', flag: '🇬🇳', label: 'Guinea' },
  { value: 'gw', flag: '🇬🇼', label: 'Guinea-bissau' },
  { value: 'gy', flag: '🇬🇾', label: 'Guyana' },
  { value: 'ht', flag: '🇭🇹', label: 'Haiti' },
  { value: 'hm', flag: '🇭🇲', label: 'Heard Island And McDonald Islands' },
  { value: 'hn', flag: '🇭🇳', label: 'Honduras' },
  { value: 'hk', flag: '🇭🇰', label: 'Hong Kong' },
  { value: 'hu', flag: '🇭🇺', label: 'Hungary' },
  { value: 'is', flag: '🇮🇸', label: 'Iceland' },
  { value: 'in', flag: '🇮🇳', label: 'India' },
  { value: 'id', flag: '🇮🇩', label: 'Indonesia' },
  { value: 'ir', flag: '🇮🇷', label: 'Iran, Islamic Republic Of' },
  { value: 'iq', flag: '🇮🇶', label: 'Iraq' },
  { value: 'ie', flag: '🇮🇪', label: 'Ireland' },
  { value: 'im', flag: '🇮🇲', label: 'Isle Of Man' },
  { value: 'il', flag: '🇮🇱', label: 'Israel' },
  { value: 'it', flag: '🇮🇹', label: 'Italy' },
  { value: 'jm', flag: '🇯🇲', label: 'Jamaica' },
  { value: 'jp', flag: '🇯🇵', label: 'Japan' },
  { value: 'je', flag: '🇯🇪', label: 'Jersey' },
  { value: 'jo', flag: '🇯🇴', label: 'Jordan' },
  { value: 'kz', flag: '🇰🇿', label: 'Kazakhstan' },
  { value: 'ke', flag: '🇰🇪', label: 'Kenya' },
  { value: 'ki', flag: '🇰🇮', label: 'Kiribati' },
  { value: 'kp', flag: '🇰🇵', label: "Korea, Democratic People's Republic Of" },
  { value: 'kr', flag: '🇰🇷', label: 'Korea, Republic Of' },
  { value: 'kw', flag: '🇰🇼', label: 'Kuwait' },
  { value: 'kg', flag: '🇰🇬', label: 'Kyrgyzstan' },
  { value: 'la', flag: '🇱🇦', label: "Lao People's Democratic Republic" },
  { value: 'lv', flag: '🇱🇻', label: 'Latvia' },
  { value: 'lb', flag: '🇱🇧', label: 'Lebanon' },
  { value: 'ls', flag: '🇱🇸', label: 'Lesotho' },
  { value: 'lr', flag: '🇱🇷', label: 'Liberia' },
  { value: 'ly', flag: '🇱🇾', label: 'Libya' },
  { value: 'li', flag: '🇱🇮', label: 'Liechtenstein' },
  { value: 'lt', flag: '🇱🇹', label: 'Lithuania' },
  { value: 'lu', flag: '🇱🇺', label: 'Luxembourg' },
  { value: 'mo', flag: '🇲🇴', label: 'Macao' },
  { value: 'mk', flag: '🇲🇰', label: 'Macedonia, The Former Yugoslav Republic Of' },
  { value: 'mg', flag: '🇲🇬', label: 'Madagascar' },
  { value: 'mw', flag: '🇲🇼', label: 'Malawi' },
  { value: 'my', flag: '🇲🇾', label: 'Malaysia' },
  { value: 'mv', flag: '🇲🇻', label: 'Maldives' },
  { value: 'ml', flag: '🇲🇱', label: 'Mali' },
  { value: 'mt', flag: '🇲🇹', label: 'Malta' },
  { value: 'mh', flag: '🇲🇭', label: 'Marshall Islands' },
  { value: 'mq', flag: '🇲🇶', label: 'Martinique' },
  { value: 'mr', flag: '🇲🇷', label: 'Mauritania' },
  { value: 'mu', flag: '🇲🇺', label: 'Mauritius' },
  { value: 'yt', flag: '🇾🇹', label: 'Mayotte' },
  { value: 'mx', flag: '🇲🇽', label: 'Mexico' },
  { value: 'fm', flag: '🇫🇲', label: 'Micronesia, Federated States Of' },
  { value: 'md', flag: '🇲🇩', label: 'Moldova' },
  { value: 'mc', flag: '🇲🇨', label: 'Monaco' },
  { value: 'mn', flag: '🇲🇳', label: 'Mongolia' },
  { value: 'me', flag: '🇲🇪', label: 'Montenegro' },
  { value: 'ms', flag: '🇲🇸', label: 'Montserrat' },
  { value: 'ma', flag: '🇲🇦', label: 'Morocco' },
  { value: 'mz', flag: '🇲🇿', label: 'Mozambique' },
  { value: 'mm', flag: '🇲🇲', label: 'Myanmar' },
  { value: 'na', flag: '🇳🇦', label: 'Namibia' },
  { value: 'nr', flag: '🇳🇷', label: 'Nauru' },
  { value: 'np', flag: '🇳🇵', label: 'Nepal' },
  { value: 'nl', flag: '🇳🇱', label: 'Netherlands' },
  { value: 'nc', flag: '🇳🇨', label: 'New Caledonia' },
  { value: 'nz', flag: '🇳🇿', label: 'New Zealand' },
  { value: 'ni', flag: '🇳🇮', label: 'Nicaragua' },
  { value: 'ne', flag: '🇳🇪', label: 'Niger' },
  { value: 'ng', flag: '🇳🇬', label: 'Nigeria' },
  { value: 'nu', flag: '🇳🇺', label: 'Niue' },
  { value: 'nf', flag: '🇳🇫', label: 'Norfolk Island' },
  { value: 'mp', flag: '🇲🇵', label: 'Northern Mariana Islands' },
  { value: 'no', flag: '🇳🇴', label: 'Norway' },
  { value: 'om', flag: '🇴🇲', label: 'Oman' },
  { value: 'pk', flag: '🇵🇰', label: 'Pakistan' },
  { value: 'pw', flag: '🇵🇼', label: 'Palau' },
  { value: 'ps', flag: '🇵🇸', label: 'Palestinian Territory, Occupied' },
  { value: 'pa', flag: '🇵🇦', label: 'Panama' },
  { value: 'pg', flag: '🇵🇬', label: 'Papua New Guinea' },
  { value: 'py', flag: '🇵🇾', label: 'Paraguay' },
  { value: 'pe', flag: '🇵🇪', label: 'Peru' },
  { value: 'ph', flag: '🇵🇭', label: 'Philippines' },
  { value: 'pn', flag: '🇵🇳', label: 'Pitcairn' },
  { value: 'pl', flag: '🇵🇱', label: 'Poland' },
  { value: 'pt', flag: '🇵🇹', label: 'Portugal' },
  { value: 'pr', flag: '🇵🇷', label: 'Puerto Rico' },
  { value: 'qa', flag: '🇶🇦', label: 'Qatar' },
  { value: 'cg', flag: '🇨🇬', label: 'Republic Of Congo' },
  { value: 're', flag: '🇷🇪', label: 'Reunion' },
  { value: 'ro', flag: '🇷🇴', label: 'Romania' },
  { value: 'ru', flag: '🇷🇺', label: 'Russian Federation' },
  { value: 'rw', flag: '🇷🇼', label: 'Rwanda' },
  { value: 'bl', flag: '🇧🇱', label: 'Saint Barthélemy' },
  { value: 'sh', flag: '🇸🇭', label: 'Saint Helena, Ascension And Tristan Da Cunha' },
  { value: 'kn', flag: '🇰🇳', label: 'Saint Kitts And Nevis' },
  { value: 'lc', flag: '🇱🇨', label: 'Saint Lucia' },
  { value: 'mf', flag: '🇲🇫', label: 'Saint Martin' },
  { value: 'pm', flag: '🇵🇲', label: 'Saint Pierre And Miquelon' },
  { value: 'vc', flag: '🇻🇨', label: 'Saint Vincent And The Grenadines' },
  { value: 'ws', flag: '🇼🇸', label: 'Samoa' },
  { value: 'sm', flag: '🇸🇲', label: 'San Marino' },
  { value: 'st', flag: '🇸🇹', label: 'Sao Tome and Principe' },
  { value: 'sa', flag: '🇸🇦', label: 'Saudi Arabia' },
  { value: 'sn', flag: '🇸🇳', label: 'Senegal' },
  { value: 'rs', flag: '🇷🇸', label: 'Serbia' },
  { value: 'sc', flag: '🇸🇨', label: 'Seychelles' },
  { value: 'sl', flag: '🇸🇱', label: 'Sierra Leone' },
  { value: 'sg', flag: '🇸🇬', label: 'Singapore' },
  { value: 'sx', flag: '🇸🇽', label: 'Sint Maarten' },
  { value: 'sk', flag: '🇸🇰', label: 'Slovakia' },
  { value: 'si', flag: '🇸🇮', label: 'Slovenia' },
  { value: 'sb', flag: '🇸🇧', label: 'Solomon Islands' },
  { value: 'so', flag: '🇸🇴', label: 'Somalia' },
  { value: 'za', flag: '🇿🇦', label: 'South Africa' },
  { value: 'gs', flag: '🇬🇸', label: 'South Georgia And The South Sandwich Islands' },
  { value: 'ss', flag: '🇸🇸', label: 'South Sudan' },
  { value: 'es', flag: '🇪🇸', label: 'Spain' },
  { value: 'lk', flag: '🇱🇰', label: 'Sri Lanka' },
  { value: 'sd', flag: '🇸🇩', label: 'Sudan' },
  { value: 'sr', flag: '🇸🇷', label: 'Suriname' },
  { value: 'sj', flag: '🇸🇯', label: 'Svalbard And Jan Mayen' },
  { value: 'sz', flag: '🇸🇿', label: 'Swaziland' },
  { value: 'se', flag: '🇸🇪', label: 'Sweden' },
  { value: 'ch', flag: '🇨🇭', label: 'Switzerland' },
  { value: 'sy', flag: '🇸🇾', label: 'Syrian Arab Republic' },
  { value: 'tw', flag: '🇹🇼', label: 'Taiwan' },
  { value: 'tj', flag: '🇹🇯', label: 'Tajikistan' },
  { value: 'tz', flag: '🇹🇿', label: 'Tanzania, United Republic Of' },
  { value: 'th', flag: '🇹🇭', label: 'Thailand' },
  { value: 'tl', flag: '🇹🇱', label: 'Timor-Leste, Democratic Republic of' },
  { value: 'tg', flag: '🇹🇬', label: 'Togo' },
  { value: 'tk', flag: '🇹🇰', label: 'Tokelau' },
  { value: 'to', flag: '🇹🇴', label: 'Tonga' },
  { value: 'tt', flag: '🇹🇹', label: 'Trinidad And Tobago' },
  { value: 'tn', flag: '🇹🇳', label: 'Tunisia' },
  { value: 'tr', flag: '🇹🇷', label: 'Turkey' },
  { value: 'tm', flag: '🇹🇲', label: 'Turkmenistan' },
  { value: 'tc', flag: '🇹🇨', label: 'Turks And Caicos Islands' },
  { value: 'tv', flag: '🇹🇻', label: 'Tuvalu' },
  { value: 'ug', flag: '🇺🇬', label: 'Uganda' },
  { value: 'ua', flag: '🇺🇦', label: 'Ukraine' },
  { value: 'ae', flag: '🇦🇪', label: 'United Arab Emirates' },
  { value: 'gb', flag: '🇬🇧', label: 'United Kingdom' },
  { value: 'us', flag: '🇺🇸', label: 'United States' },
  { value: 'um', flag: '🇺🇲', label: 'United States Minor Outlying Islands' },
  { value: 'uy', flag: '🇺🇾', label: 'Uruguay' },
  { value: 'uz', flag: '🇺🇿', label: 'Uzbekistan' },
  { value: 'vu', flag: '🇻🇺', label: 'Vanuatu' },
  { value: 'va', flag: '🇻🇦', label: 'Vatican City State' },
  { value: 've', flag: '🇻🇪', label: 'Venezuela, Bolivarian Republic Of' },
  { value: 'vn', flag: '🇻🇳', label: 'Viet Nam' },
  { value: 'vg', flag: '🇻🇬', label: 'Virgin Islands (British)' },
  { value: 'vi', flag: '🇻🇮', label: 'Virgin Islands (US)' },
  { value: 'wf', flag: '🇼🇫', label: 'Wallis And Futuna' },
  { value: 'eh', flag: '🇪🇭', label: 'Western Sahara' },
  { value: 'ye', flag: '🇾🇪', label: 'Yemen' },
  { value: 'zm', flag: '🇿🇲', label: 'Zambia' },
  { value: 'zw', flag: '🇿🇼', label: 'Zimbabwe' },
]
