import React from 'react'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s(
    'flex items-center justify-between pb-2 border-0 border-b-1 border-solid border-grey-200'
  )
)

const ExpenseType = styled.span(s('text-xl font-semibold text-grey-600'))

const Value = styled.span(s('text-xl font-bold text-grey-800'))

const Recurrence = styled.span(s('text-xs font-medium text-grey-800 ml-1'))

function ExpenseRow({ name, type, value, ...otherProps }) {
  return (
    <Container {...otherProps}>
      <ExpenseType>{type}</ExpenseType>
      <Value>
        ${value}
        <Recurrence>monthly</Recurrence>
      </Value>
    </Container>
  )
}

export { ExpenseRow }
