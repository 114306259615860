import React, { useState } from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as ArrowsExpand } from '@vega/components/src/assets/images/arrows-expand.svg'
import { IdPreviewExpanded } from './IdPreviewExpanded'

const Root = styled.div(
  s('flex justify-center w-full bg-grey-200 border-1 border-dashed border-grey-500'),
  s('relative rounded-l-lg rounded-br-lg p-3', { minHeight: 234 })
)
const IconContainer = styled.div(
  s('absolute z-1 pin-r-3 p-1 text-grey-500 bg-grey-100 rounded-l-md rounded-br-md', {
    height: 24,
    width: 24,
    cursor: 'pointer',
  })
)
const Image = styled.img(s(''))

const IdPreview = ({ image }) => {
  const [isModalOpened, setIsModalOpened] = useState(false)

  const open = () => setIsModalOpened(true)
  const close = () => setIsModalOpened(false)

  return (
    <Root>
      <IconContainer onClick={open}>
        <ArrowsExpand />
      </IconContainer>

      <Image src={image} />

      <IdPreviewExpanded isOpen={isModalOpened} closePreview={close} image={image} />
    </Root>
  )
}

export { IdPreview }
