import React from 'react'
import { s, styled } from '@vega/styled'

const Root = styled.div(
  s('show-box bg-error-50 border-0 flex mt-4', {
    borderRadius: '8px 0 8px 8px',
    transition: 'opacity 250ms ease-in-out',
    transform: 'scale(1)',
    opacity: 1,
  }),
  ({ visible }) =>
    !visible && {
      transition: 'opacity 250ms ease-in-out, transform 100ms ease-in-out 500ms',
      transform: 'scale(0)',
      opacity: 0,
    }
)

const LeftVerticalBar = styled.div({
  borderWidth: 4,
  borderRadius: 2,
  width: 6,
  backgroundColor: '#B90919',
})

export const UnexpectedError = ({ visible = false, hideError, ...props }) => {
  const DISMISS_ERROR_DELAY_MS = 3000

  React.useEffect(() => {
    const hideErrorTimer = setTimeout(() => {
      hideError()
    }, [DISMISS_ERROR_DELAY_MS])

    return () => {
      clearTimeout(hideErrorTimer)
    }
  }, [hideError])

  return (
    <Root visible={visible} id="unexpectedError" {...props}>
      <LeftVerticalBar>&nbsp;</LeftVerticalBar>
      <div style={s('flex-1 py-4 px-5')}>
        <h3 style={s('text-error-800 font-bold text-xl m-0')}>
          Something went wrong, please refresh and try again
        </h3>
      </div>
    </Root>
  )
}
