import React, { useEffect } from 'react'
import { Prompt as OriginalPrompt } from 'features/broker/applications/components/typography'
import { TextField } from '@vega/components'
import { styled, s } from '@vega/styled'
import { useFormikContext } from 'formik'
import { TEST_IDS } from '@vega/constants'
import { NotesProvider } from '../../components/NotesProvider'

const Prompt = styled(OriginalPrompt)(s('mt-6 mb-0'))

const ExpectedRentAmount = ({ applicantName }) => {
  const {
    setFieldValue,
    values: {
      intent: { propertyPurpose },
    },
  } = useFormikContext()

  useEffect(() => {
    if (propertyPurpose !== 'investment') setFieldValue('intent.expectedRentAmount', '')
  }, [propertyPurpose, setFieldValue])
  return (
    propertyPurpose === 'investment' && (
      <>
        <NotesProvider name="intent.expectedRentAmount">
          <Prompt>
            How much rent does {applicantName} expect to earn from this property?
          </Prompt>
          <TextField
            name="intent.expectedRentAmount"
            aria-label="expectedRentAmount"
            containerStyle={s('w-1/2')}
            prefix="$"
            testId={TEST_IDS.EXPECTED_RENT_INPUT}
          />
        </NotesProvider>
      </>
    )
  )
}

export { ExpectedRentAmount }
