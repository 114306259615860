import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { routes } from 'routes'
import { styled, s } from '@vega/styled'
import { replace } from '@neo/ramda-extra'
import {
  getLoanApplication,
  selectGetApplicationStatus,
  selectIntent,
} from 'modules/application'
import { selectLoanStructure } from 'modules/application/selectors'
import { ReactComponent as VegaLogo } from '@vega/components/src/assets/images/vega-logo.svg'
import { Title, Subtitle } from 'features/broker/applications/components/typography'
import { useHideNavDrawer } from 'hooks/useHideNavDrawer'
import { LoanOptionsSummary } from './LoanOptionsSummary'
import { Button as ButtonBase, ErrorPage, Loading } from '@vega/components'
import { ASYNC_STATUSES } from '@vega/constants'

const { PENDING, REJECTED } = ASYNC_STATUSES

const Root = styled.div(
  s('w-full', {
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)
const Header = styled.div(
  s('flex items-center w-full', { height: 64, paddingLeft: 64 })
)
const Container = styled.div(s('flex justify-center'))
const Content = styled.div(s('', { width: 652 }))
const PreviewContainer = styled.div(s('mb-6'))
const BoldText = styled.p(
  s('m-0 text-grey-800 text-xl font-semibold', { lineHeight: 1.5 })
)
const Text = styled.p(s('m-0 text-grey-800 text-base font-normal', { lineHeight: 1.5 }))
const List = styled.ul(
  s('m-0', { listStylePosition: 'outside', padding: '0px 0px 0px 20px' })
)
const ListItem = styled.li(
  s('m-0 text-grey-800 text-base font-normal', { lineHeight: 1.5 })
)
const ButtonContainer = styled.div(s('flex mt-5'))
const Button = styled(ButtonBase)(s('', { width: 148 }))
const PaddingBox = styled.div(s('pb-16'))

const StatusPreview = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const getApplicationStatus = useSelector(selectGetApplicationStatus)
  const loanStructure = useSelector(selectLoanStructure)
  const intent = useSelector(selectIntent)

  useHideNavDrawer()

  const goToPropertySearch = () =>
    history.replace(
      replace(':id', id, routes.broker.applications.overview.propertySearch)
    )

  const goToApplicationsList = () => history.replace(routes.broker.applications.list)

  useEffect(() => {
    dispatch(getLoanApplication(id))
  }, [dispatch, id])

  if (getApplicationStatus === PENDING)
    return (
      <div style={s('w-full')}>
        <Loading style={s('m-a')} />
      </div>
    )

  if (getApplicationStatus === REJECTED) return <ErrorPage />

  return (
    <Root>
      <Header>
        <VegaLogo />
      </Header>

      <Container>
        <Content>
          <Title style={s('mb-4')}>Application status: Pre-approved</Title>
          <Subtitle style={s('mb-5')}>
            This application is successfully pre-approved. We will send emails informing
            all applicants of the status and next step
          </Subtitle>

          <PreviewContainer>
            <LoanOptionsSummary
              loanRateType={loanStructure?.loanRateType}
              loanTerm={loanStructure?.loanTerm}
              offsetAccount={loanStructure?.offsetAccount}
              repaymentFrequency={loanStructure?.repaymentFrequency}
              borrowingAmount={intent?.borrowingAmount}
            />
          </PreviewContainer>

          <BoldText>Next step</BoldText>
          <Text>Property search:</Text>
          <List>
            <ListItem>
              Your applicant(s) are one step closer to their dream mortgage. You can
              add, and modify any property your applicants have eyes on
            </ListItem>
          </List>

          <ButtonContainer>
            <Button style={s('mr-5')} onClick={goToPropertySearch}>
              Property search
            </Button>
            <Button
              variant="outlined"
              containerStyle={s('text-grey-600 border-grey-300')}
              onClick={goToApplicationsList}
            >
              Exit
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
      <PaddingBox />
    </Root>
  )
}

export { StatusPreview }
