import React from 'react'
import { useDispatch } from 'react-redux'
import { Prompt, Subtitle } from 'features/broker/applications/components/typography'
import { styled, s } from '@vega/styled'
import { getFinancialsRetrievalData } from 'modules/application'
import { useInterval } from './useInterval'

const Dialog = styled.div(
  s(
    'w-full py-6 px-8 mt-5 bg-white border-1 border-solid border-grey-200 rounded-b-lg rounded-tl-lg'
  )
)

function OpenLinkDialog({ applicantId }) {
  const POLLING_INTERVAL_MS = 30000

  const dispatch = useDispatch()

  useInterval(
    async () => {
      dispatch(getFinancialsRetrievalData(applicantId))
    },
    true,
    POLLING_INTERVAL_MS
  )

  return (
    <Dialog>
      <Prompt style={s('mb-4')}>Linking bank account in progress!</Prompt>
      <Subtitle>
        It will take 2-3 minutes to connect the bank data to our systems. We will keep
        you updated on the progress. Hang on...
      </Subtitle>
    </Dialog>
  )
}

export { OpenLinkDialog }
