import { useEffect, useRef } from 'react'
import { and, isNotNil, noop } from '@neo/ramda-extra'

export function useInterval(
  callback = noop,
  conditionToSetInterval = true,
  delay = 5000
) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (and(isNotNil(delay), conditionToSetInterval)) {
      const intervalId = setInterval(tick, delay)
      return () => clearInterval(intervalId)
    }
    return 0
  }, [conditionToSetInterval, delay])
}
