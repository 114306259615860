import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Switch, Redirect, useParams, useHistory } from 'react-router-dom'
import { isNilOrEmpty, prop } from '@neo/ramda-extra'

import { s, styled } from '@vega/styled'
import { TopTabNavigator, ProfilePageHeader } from '@vega/components'

import {
  fetchClient,
  selectInspectedClient,
  setInspectedClientId,
} from 'modules/client'

import { Overview } from './overview'
import Goals from './goals'
import FinancialStatus from './financialStatus'
import SupportingDocs from './supportingDocs'

const Root = styled.div(
  s('flex flex-column w-full h-full min-h-32 bg-green-50', { overflowY: 'hidden' })
)
const containerStyle = { div: { '> a': s('font-semibold') } }

const ClientProfileSubRouter = ({ parentRoute }) => {
  const { clientId } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()
  const inspectedClient = useSelector(selectInspectedClient)

  useEffect(() => {
    dispatch(fetchClient(clientId))
  }, [dispatch, clientId])

  useEffect(() => {
    return () => dispatch(setInspectedClientId(undefined))
  }, [dispatch])

  if (isNilOrEmpty(inspectedClient)) {
    return null
  }

  const title = `${prop('firstName', inspectedClient)}'s Profile`

  const overviewPath = `${parentRoute}/${clientId}/overview`
  const goalsPath = `${parentRoute}/${clientId}/goals`
  const financialStatusPath = `${parentRoute}/${clientId}/financial-status`
  const supportingDocsPath = `${parentRoute}/${clientId}/supporting-docs`

  const routes = [
    {
      name: 'Overview',
      path: overviewPath,
    },
    {
      name: 'Goals',
      path: goalsPath,
    },
    {
      name: 'Financial Status',
      path: financialStatusPath,
    },
    {
      name: 'Supporting Docs',
      path: supportingDocsPath,
    },
  ]

  const onBackClick = () => {
    history.goBack()
  }

  return (
    <Root>
      <ProfilePageHeader title={title} onBackClick={onBackClick} />
      <TopTabNavigator routes={routes} containerStyle={containerStyle} />
      <Switch>
        <Route exact path={overviewPath}>
          <Overview client={inspectedClient} />
        </Route>
        <Route exact path={goalsPath} component={Goals} />
        <Route exact path={financialStatusPath} component={FinancialStatus} />
        <Route exact path={supportingDocsPath} component={SupportingDocs} />
        <Redirect to={overviewPath} />
      </Switch>
    </Root>
  )
}

export default ClientProfileSubRouter
