import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import {
  Button,
  TextField,
  RadioButtonField,
  RadioButton,
  SelectField,
} from '@vega/components'
import { financialInfo } from 'modules/application/initialFormValues'
import { ReactComponent as PlusIcon } from '@vega/components/src/assets/images/plus.svg'
import { Prompt as OriginalPrompt } from 'features/broker/applications/components/typography'
import { LIABILITY } from '../constants'
import { LOAN_TYPE_OPTS } from '../selectOptions'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@vega/constants'

const FieldContainer = styled.div(s('mb-6'))
const Prompt = styled(OriginalPrompt)(s('mb-4'))
const LoanTitle = styled.h2(s('text-xl mb-4'))

const DeleteLoanBtn = styled(Button)(
  s(
    'bg-transparent border-b-1 border-solid rounded-none p-0 mt-4 text-grey-800 border-grey-800'
  )
)
const AddLoanBtn = styled(Button)(
  s(
    'bg-transparent border-b-1 border-solid rounded-none p-0 mt-4 text-primary border-primary'
  )
)

function Loans({ parentPath, applicantPathId }) {
  const { values: formikValues } = useFormikContext()

  const loans =
    formikValues?.financials?.[applicantPathId]?.liabilities?.personalLoan?.loans

  return (
    <FieldArray
      name={`${parentPath}.liabilities.personalLoan.loans`}
      render={(arrayHelpers) => (
        <div>
          {loans.map((loan, idx) => {
            const loanPath = `${parentPath}.liabilities.personalLoan.loans.${idx}`

            return (
              <div key={idx} name={loan[idx]}>
                <LoanTitle>Loan {idx + 1}</LoanTitle>

                <FieldContainer>
                  <Prompt>What is the description for this loan?</Prompt>
                  <SelectField
                    name={`${loanPath}.type`}
                    aria-label="Personal Loan Type"
                    options={LOAN_TYPE_OPTS}
                    testId={`${TEST_IDS.PERSONAL_LOAN_DESC_DROPDOWN}${idx + 1}`}
                    menuId={`${TEST_IDS.PERSONAL_LOAN_DESC_DROPDOWN_MENU}${idx + 1}`}
                  />
                </FieldContainer>

                <FieldContainer>
                  <Prompt>
                    Did you apply this loan alone or with their co-applicant?
                  </Prompt>
                  <RadioButtonField
                    name={`${loanPath}.ownership`}
                    aria-label="personal loans"
                    testId={`${TEST_IDS.PERSONAL_LOAN_CO_APPLICANT_RB}${idx + 1}`}
                  >
                    <RadioButton value={LIABILITY.OWNERSHIP.SELF}>
                      Apply alone
                    </RadioButton>
                    <RadioButton value={LIABILITY.OWNERSHIP.SHARED}>
                      With others
                    </RadioButton>
                  </RadioButtonField>
                </FieldContainer>

                <FieldContainer>
                  <Prompt style={s('mb-0')}>
                    What&apos;s the total outstanding balance of the personal loan?
                  </Prompt>
                  <TextField
                    name={`${loanPath}.outstandingBalance`}
                    aria-label="outstanding balance"
                    prefix="$"
                    containerStyle={s('w-1/2')}
                    testId={`${TEST_IDS.PERSONAL_LOAN_TOTAL_INPUT}${idx + 1}`}
                  />
                </FieldContainer>

                <FieldContainer>
                  <Prompt style={s('mb-0')}>
                    How much are the repayments against the personal loan?
                  </Prompt>
                  <TextField
                    name={`${loanPath}.repayments`}
                    aria-label="Repayments of the loan"
                    prefix="$"
                    suffix="p.mth"
                    containerStyle={s('w-1/2')}
                    testId={`${TEST_IDS.PERSONAL_LOAN_REPAYMENT_INPUT}${idx + 1}`}
                  />
                </FieldContainer>

                {idx !== 0 && (
                  <DeleteLoanBtn
                    type="button"
                    onClick={() => arrayHelpers.remove(idx)}
                    style={s('my-6')}
                    data-test-id={`${TEST_IDS.DELETE_PERSONAL_LOAN_BTN}${idx + 1}`}
                  >
                    Delete loan
                  </DeleteLoanBtn>
                )}
              </div>
            )
          })}

          <LoanTitle>Have another loan?</LoanTitle>

          <AddLoanBtn
            StartIcon={<PlusIcon width={13} />}
            type="button"
            onClick={() =>
              arrayHelpers.push(financialInfo?.liabilities?.personalLoan?.loans?.[0])
            }
            style={s('my-6')}
            data-test-id={TEST_IDS.ADD_PERSONAL_LOAN_BTN}
          >
            <span style={s('mt-1 ml-1')}>Add loan</span>
          </AddLoanBtn>
        </div>
      )}
    />
  )
}

export { Loans }
