import * as Validator from 'yup'
import {
  isNotNil,
  pluck,
  isFalse,
  isTrue,
  any,
  findIndex,
  range,
} from '@neo/ramda-extra'
import { CUSTOM_ERROR_MESSAGES } from './constants'

const { object, string, boolean, array, ValidationError } = Validator

const invitationSchema = object({
  id: string().optional(),
  isNew: boolean()
    .required()
    .when('id', {
      is: isNotNil,
      then: boolean().test({ test: isFalse }),
      otherwise: boolean().test({ test: isTrue }),
    }),
  isPrimary: boolean().optional().label('This'),
  firstName: string().required().label('First Name'),
  email: string()
    .required()
    .email('Please enter valid email address')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email address'
    )
    .label('Email'),
})
  .test({
    name: 'no-duplicate-email',
    test: hasNoDuplicateEmail,
  })
  .test({
    name: 'only-one-primary-applicant',
    test: onlyOnePrimaryApplicant,
  })

export const validationSchema = Validator.object({
  invitations: array().required().of(invitationSchema),
})
function hasNoDuplicateEmail(invite) {
  const invitations = this.parent
  const emails = pluck('email', invitations)

  if (hasNoDuplicate(emails)) return true

  const firstInstanceIndex = findIndex((i) => i.email === invite.email, invitations)
  const invalidInviteIndex = findIndex(
    (i) => i.email === invite.email && i.firstName === invite.firstName,
    invitations
  )

  if (firstInstanceIndex === invalidInviteIndex) return true

  // Must use this to set error in Formik https://github.com/formium/formik/issues/2146
  return this.createError({
    path: `invitations.[${invalidInviteIndex}].email`,
    message: CUSTOM_ERROR_MESSAGES.DUPLICATE_EMAIL,
  })
}

function hasNoDuplicate(arr) {
  return arr.length === new Set(arr).size
}

function onlyOnePrimaryApplicant() {
  const invitations = this.parent
  const isPrimaryInputs = pluck('isPrimary', invitations)

  const primaryApplicantSelected = any(isTrue, isPrimaryInputs)

  if (!primaryApplicantSelected) {
    const summaryError = this.createError({
      // what comes after custom. is the field name that this is linked to
      path: 'custom.invitations.0.isPrimary',
      message: CUSTOM_ERROR_MESSAGES.NO_PRIMARY_APPLICANT,
    })

    const checkboxErrors = range(0, invitations.length).map((idx) =>
      this.createError({
        path: `invitations.${idx}.isPrimary`,
        message: 'Please select a primary applicant',
      })
    )

    return new ValidationError([summaryError, ...checkboxErrors])
  }

  const moreThanOne = isPrimaryInputs.filter(isTrue).length > 1

  if (moreThanOne) {
    const summaryError = this.createError({
      // what comes after custom. is the field name that this is linked to
      path: 'custom.invitations.0.isPrimary',
      message: CUSTOM_ERROR_MESSAGES.MORE_THAN_ONE_PRIMARY_APPLICANT,
    })

    const checkboxErrors = range(0, invitations.length).map((idx) =>
      this.createError({
        path: `invitations.${idx}.isPrimary`,
        message: CUSTOM_ERROR_MESSAGES.MORE_THAN_ONE_PRIMARY_APPLICANT,
      })
    )
    return new ValidationError([summaryError, ...checkboxErrors])
  }

  return true
}
