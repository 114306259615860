import React from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as LightBulbIcon } from '@vega/components/src/assets/images/lightbulb.svg'

const Root = styled.div(s('fixed pt-1 w-full h-full pl-6 pr-4'))
const TitleContainer = styled.div(
  s('flex text-xl text-grey-600 mb-4', {
    lineHeight: 1.5,
  })
)
const Title = styled.h4(s('m-0 p-0 flex items-center pt-1 font-normal'))
const IconContainer = styled.div(s('my-a mr-3 pt-1'))
const TextContainer = styled.div(
  s('w-full', {
    lineHeight: 1.5,
  })
)
const Question = styled.h5(s('m-0 p-0 mb-2 text-grey-700 font-medium text-base'))
const Paragraph = styled.p(s('m-0 p-0 text-grey-600 font-normal text-sm'))

export const InfoSidebar = () => {
  return (
    <Root>
      <TitleContainer>
        <IconContainer>
          <LightBulbIcon height={18} width={18} />
        </IconContainer>
        <Title>Need to know</Title>
      </TitleContainer>
      <TextContainer>
        <Question>Overall quality:</Question>
        <Paragraph>
          - Please ensure that documents uploaded are high-quality, colour copies
        </Paragraph>
        <Paragraph style={s('mb-6')}>
          - Files accepted are .png, .jpg, and .pdf
        </Paragraph>
        <Question>For passport:</Question>
        <Paragraph style={s('mb-6')}>
          - The image must show the full document&apos;s page
        </Paragraph>
        <Question>For driver licence:</Question>
        <Paragraph>- Please upload both front and back images</Paragraph>
      </TextContainer>
    </Root>
  )
}
