import { configureStore } from '@reduxjs/toolkit'
import { userReducer } from 'modules/user'
import { brokerReducer } from 'modules/broker'
import { asyncStateReducer } from '@vega/redux.async'
import { profileReducer } from '@vega/redux.profile'
import { uploadReducer } from '@vega/redux.upload'
import { invitationReducer } from 'modules/invitation'
import { clientReducer } from 'modules/client'
import { applicationsReducer } from 'modules/application'
import { propertiesReducer } from 'modules/property'
import { activityLogsReducer } from 'modules/activityLog'
import { reportsReducer } from 'modules/report'
import { uiReducer } from 'modules/ui'

export default configureStore({
  reducer: {
    users: userReducer,
    brokers: brokerReducer,
    clients: clientReducer,
    invitations: invitationReducer,
    asyncState: asyncStateReducer,
    applications: applicationsReducer,
    profile: profileReducer,
    upload: uploadReducer,
    properties: propertiesReducer,
    activityLogs: activityLogsReducer,
    reports: reportsReducer,
    ui: uiReducer,
  },
})
